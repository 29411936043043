import { Grid } from "@material-ui/core";
import styled from "styled-components";

export const StyledHeaderContainer = styled(Grid)``;
export const StyledHederGrid = styled(Grid)``;

export const StyledActionsContainer = styled(Grid)`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
`;

const StyledLayoutGrid = styled(Grid)`
  padding: 0 10px 0 10px;
  margin-top: 20px;
  .MuiPaper-root {
    width: 100%;
  }
  .MuiTableCell-root {
    padding: 7px;
    border: 1px solid lightgray;
  }

  .MuiToolbar-regular {
    min-height: 0px;
  }
  .MuiTableCell-head {
    background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
    color: white;
    text-align: center;
    font-weight: 700;
    border: 1px solid lightgray;
  }

  .MuiTableCell-root {
    text-align: center;
    max-width: 100px;
  }

  .MuiTableCell-head .MuiButton-root {
    color: white;
    text-align: center;
    font-weight: 700;
  }

  .linkCss {
    text-decoration: underline;
    color: blue;
    cursor: pointer;
  }

  .buttonGray {
    background: ${(props) => props.theme.colors.base.gray};
  }

  .MuiTableCell-footer {
    padding: 2px;
    font-size: 0.2em;
    height: 40px;
  }
`;

const StyledBtnContainer = styled.div`
  text-align: center;
`;

const StyledCoreDataGridContainer = styled(Grid)``;
const StyledTextWrapper = styled(Grid)`
  margin: auto;
  text-align: center;
`;
const StyledActionBarContainer = styled(Grid)`
  padding: 10px;
  margin: 10px 0px 10px 0px;
  border-radius: 5px;
  box-shadow: 2px 0px 5px 2px ${(props) => props.theme.colors.base.lightGrey};
  justify-content: flex-end;
  &:focus {
    outline: none;
  }
`;

const StyledSelectAllButtonHeader = styled.button`
  color: ${(props) => props.theme.colors.base.white};
  font-size: 1em;
  padding: 0.4em;
  margin: 0.3em;
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.colors.base.lightGrey};
  background: #2660ad;
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.base.textColor};
    cursor: pointer;
  }
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 0.8em;
  }
  white-space: pre;
`;

const StyledButtonHeader = styled.button`
  color: ${(props) => props.theme.colors.base.grey};
  font-size: 1em;
  padding: 0.4em;
  margin: 0.3em;
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.colors.base.lightGrey};
  background: ${(props) => props.theme.colors.base.veryVeryLightGrey};
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.base.textColor};
    cursor: pointer;
  }
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 0.8em;
  }
`;

const StyledTimeGridContainer = styled(Grid)`
  padding: 10px;
  justify-content: flex-end;
`;

const StyledTimer = styled.div`
  font-size: 1.3em;
  font-family: "Calibre-Regular";
  color: ${(props) => props.theme.colors.base.textColor};

  .countdownText {
    font-weight: bold;
    width: 100%;
    text-align: center;
  }
  .dateTimeStyle {
    width: 100%;
    display: flex;
    padding: 5px;
    align-items: center;
    justify-content: center;
  }

  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 1em;
    margin-top: 0.5em;
  }
`;

const StyledButtonGray = styled.button`
  color: ${(props) => props.theme.colors.base.black};
  border: none;
  font-size: 0.8em;
  padding: 0.6em;
  margin: 0.3em;
  border-radius: 5px;
  background: ${(props) => props.theme.colors.base.gray};
  border: 1px solid ${(props) => props.theme.colors.base.white};
`;

const StyledTabLayout = styled(Grid)``;

const StyledContent = styled(Grid)`
  text-align: end;
  font-size: 1.3em;
  font-family: "Calibre-Regular";
  color: ${(props) => props.theme.colors.base.textColor};
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 1.1em;
    margin-top: 0.4em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const StyledName = styled(Grid)`
  dispaly: flex;
  padding: 10px;
  @media (min-width: 1024px) {
    text-align: end;
  }
`;

const StyledBackImageContianer = styled(Grid)`
  display: flex;
  align-items: center;
  padding: 5px;
`;
const StyledBackBtn = styled.img`
  width: 1.5em;
  height: 1.5em;
  cursor: pointer;
`;

const StyledBuyitNow = styled.button`
  color: ${(props) => props.theme.colors.base.white};
  border: none;
  font-size: 0.6em;
  padding: 0.4em;
  margin: 0.3em;
  border-radius: 5px;
  background: #e14843;
  border: 1px solid ${(props) => props.theme.colors.base.white};
`;

const StyledBuyitNowOnDetailsPage = styled.button`
  color: ${(props) => props.theme.colors.base.white};
  border: none;
  font-size: 0.8em;
  padding: 0.4em;
  border-radius: 5px;
  background: #e14843;
  border: 1px solid ${(props) => props.theme.colors.base.white};
`;

export {
  StyledName,
  StyledContent,
  StyledTabLayout,
  StyledButtonGray,
  StyledTimer,
  StyledTimeGridContainer,
  StyledButtonHeader,
  StyledActionBarContainer,
  StyledTextWrapper,
  StyledBtnContainer,
  StyledCoreDataGridContainer,
  StyledLayoutGrid,
  StyledBackImageContianer,
  StyledBackBtn,
  StyledSelectAllButtonHeader,
  StyledBuyitNow,
  StyledBuyitNowOnDetailsPage,
};
