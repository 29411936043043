import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import {
  StyledButton,
  StyledComponent,
} from "../../../../common/materials/GenericStyleComponent";
import {
  backgroundGradient,
  dateBackgroundGradient,
} from "../../../../common/materials/LinearGradient";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ProductDescription from "../../../shared/ProductDescription";
import { convertedValue } from "../TenderGenericFunc";
import { useTranslation } from "react-i18next";
import update from "immutability-helper";
import LoopIcon from "@material-ui/icons/Loop";
import { useHistory } from "react-router-dom";
const StyledContainerWrapper = styled.div`
  background: ${(props) => props.theme.colors.base.white};
  width: 400px;
  padding: 0.4em;
  border-radius: 10px;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    width: 300px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    width: 400px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    width: 930px;
  }
  @media (width: 768px) {
    width: 730px;
  }

  .onClickButton {
    cursor: default;
    background: ${dateBackgroundGradient};
  }
  .alignRight {
    text-align: right;
  }
`;

const StyledCloseicon = styled.img`
  height: 1.4em;
  width: 1.4em;
  &:hover {
    cursor: pointer;
  }
`;

const ProductNameContainer = styled(Grid)`
  color: ${(props) => props.theme.colors.base.black};
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 10px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    font-size: 12px;
  }
`;
const BulkBidButtonContainer = styled(Grid)`
  color: ${(props) => props.theme.colors.base.black};
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 10px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    font-size: 12px;
  }
`;
const ProductCountContainer = styled(Grid)`
  color: ${(props) => props.theme.colors.base.black};
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 14px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    font-size: 14px;
  }
`;
const StyledButtonWrapper = styled(Grid)`
  text-align: center;
  display: flex;
  background: ${(props) => props.theme.colors.base.white};
  justify-content: center;
  align-items: center;
  border-radius: 20px;

  .notDisablebutton {
    background: ${backgroundGradient};
    cursor: pointer;
  }
  .disableButton {
    cursor: default;
    background: ${(props) => props.theme.colors.base.lightGrey};
  }
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    margin-top: 5px;
    padding: 2px 2px;
    font-size: 1em;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    padding: 2px 2px;
    margin-top: 5px;
    font-size: 1em;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    // padding: 2px 2px;
    font-size: 1em;
  }
`;
const StyledAuctionbidWrapper = styled(Grid)`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  .notDisablebutton {
    background: ${backgroundGradient};
    cursor: pointer;
  }
  .disableButton {
    cursor: default;
    background: ${(props) => props.theme.colors.base.lightGrey};
  }

  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    padding: 10px 10px;
  }
`;
interface IProps {
  items?: any;
  popupClose: any;
  handleBtnValue: (values: any[], myBidBulkValues: any) => void;
  auctionUserId: any;
  length: any;
  productIndexs?: any;
  auctionDate?: any;
  myLatestBidForProducts?: any;
}

let getUpdatedBidValue: any;
let productPricePerCaratUSD: any = 0;
let rapnetPercentage: number = 0;
let bulkBidValue: number = 0;
let tempVal;

let getUpdatedRapValue: any;
let rapPriceMapping: any = {
  perIdMap: {},
  rapnetPercentageMap: {},
};

const BulkBidPopup = (props: IProps) => {

  let {
    items,
    productIndexs,
    popupClose,
    handleBtnValue,
    auctionUserId,
    auctionDate,
    myLatestBidForProducts,
  } = props;
  const { t } = useTranslation();

  const [bidPriceInputData, setBidPriceInputData] = useState<any>({});
  const [buttonVal, setButtonVal] = useState({
    first: 0,
    second: 0,
    third: 0,
  });
  let history = useHistory();
  const getBidValue = (e: any, value: any) => {
    //bids update in  productBidValue variable

    const targeName = e.target.name;
    const targeValue = value;

    const num = Math.trunc(targeValue);
    if (!isNaN(num)) {
      const hasProperty = Object.keys(bidPriceInputData).includes(targeName);
      if (hasProperty === false) {
        bulkBidValue = 0;
        tempVal = 0;
      }
      if (hasProperty) {
        tempVal = bulkBidValue + num;
        bidPriceInputData[targeName] = tempVal;
        bulkBidValue = tempVal;
      } else {
        tempVal = 0;
        bidPriceInputData[targeName] = num;
        bulkBidValue = num;
      }
      //somehow update data
      setBidPriceInputData({ ...bidPriceInputData });
    }
    getRapnetPercentage(bulkBidValue, e);
  };

  const getRapnetPercentage = (price: any, e: any) => {
    const targetId = e.target.name;
    const targetName = e.target.name;
    const productIndex = parseInt(targetId, 10);
    rapPriceMapping.perIdMap[targetName] = price;

    const obj = items.find((item: any) => item.perId === targetId);
    if (productIndex !== undefined) {
      if (obj.rapnetPercentage === undefined || obj.rapnetPercentage === 0) {
      } else {
        const totalBidPrice =
          obj.currentBidPrice + rapPriceMapping.perIdMap[obj.perId];
        if (obj.currency.code === "JPY") {
          productPricePerCaratUSD = totalBidPrice * obj.exchangeRates[0].rate;
        } else {
          productPricePerCaratUSD = totalBidPrice;
        }
        rapPriceMapping.rapnetPercentageMap[obj.perId] = parseFloat(((1 - (productPricePerCaratUSD / obj.rapnetPrice)) * -100.0).toFixed(2));
      }
    }
  };

  //bulk bid payload
  const bulkBidBtnValuehandle = () => {
    const requestData: any = [];
    const requestMyBidData: any = [];
    productIndexs.map((rowIndex: any) => {
      const bidValue = parseInt(bidPriceInputData[items[rowIndex].perId], 10);
      if (bidValue) {
        const obj = {
          bidIncreasedBy: bidValue,
          bidPrice: items[rowIndex].currentBidPrice,
          auctionUserId: auctionUserId,
          auctionId: items[rowIndex].auctionId,
          productId: items[rowIndex].perId,
        };
        const myBidsValueObj = {
          productId: items[rowIndex].perId,
          myLatestBid: items[rowIndex].currentBidPrice + bidValue,
        };

        let updateRowIndex = myLatestBidForProducts.findIndex(
          (product: any) => product.productId === myBidsValueObj.productId
        );
        if (updateRowIndex === -1) {
          var myLatestBidForProductObjectData: any = [
            ...myLatestBidForProducts,
            myBidsValueObj,
          ];
          myLatestBidForProducts = myLatestBidForProductObjectData;
        } else {
          var updatedArray = update(myLatestBidForProducts, {
            $splice: [[updateRowIndex, 1, myBidsValueObj]],
          });
          myLatestBidForProducts = updatedArray;
        }
        requestData.push(obj);
        requestMyBidData.push(myBidsValueObj);
      }
    });
    handleBtnValue(requestData, myLatestBidForProducts);
    popupClose();
  };

  const getTableHight = () => {
    if (productIndexs.length > 4) {
      return 390;
    }
  };

  const openProductDetail = (
    auctionId: string,
    perId: string,
    auctionDate: string,
    parcelType: string
  ) => {
    const datePattern = /\d{4}-\d{2}-\d{2}/;

    // Find the first occurrence of the date pattern in the string
    const match: any = auctionDate.match(datePattern);
    // Extract the date from the match array
    let myString: any = match[0];
    if (parcelType === "Jewellery") {
      history.push(
        `/preview/tender/jewellery/details/${auctionId}/${perId}/${myString}`
      );
    } else {
      history.push(
        `/preview/tender/details/${auctionId}/${perId}/${myString}?uniqid=${auctionUserId}`
      );
    }
  };

  const bidButtonHandeler = (data: any) => {
    const isJPY = data.currency.code === "JPY" ? true : false;
    const bidPrice = data.currentBidPrice;

    var button1 = 0;
    var button2 = 0;
    var button3 = 0;

    var isFirstBtnEnable = false;

    if (data.parcelType === "Parcel" && data.parcelType !== undefined) {
      if (isJPY) {
        if (bidPrice < 10000) {
          button1 = isJPY ? 100 : 1;
          button2 = isJPY ? 500 : 5;
          button3 = isJPY ? 1000 : 10;
          isFirstBtnEnable = true;
        } else if (bidPrice >= 10000 && bidPrice < 100000) {
          button1 = isJPY ? 100 : 10;
          button2 = isJPY ? 500 : 50;
          button3 = isJPY ? 1000 : 100;
        } else if (bidPrice >= 100000) {
          button1 = isJPY ? 1000 : 10;
          button2 = isJPY ? 5000 : 50;
          button3 = isJPY ? 10000 : 100;
        }
      } else {
        if (bidPrice < 100) {
          button1 = 1;
          button2 = 5;
          button3 = 10;
          isFirstBtnEnable = true;
        } else if (bidPrice >= 100 && bidPrice < 1000) {
          button1 = 1;
          button2 = 5;
          button3 = 10;
        } else if (bidPrice >= 1000 && bidPrice < 30000) {
          button1 = 10;
          button2 = 50;
          button3 = 100;
        } else if (bidPrice >= 30000) {
          button1 = 1000;
          button2 = 5000;
          button3 = 10000;
        }
      }
    } else {
      if (isJPY) {
        if (bidPrice < 10000) {
          button1 = isJPY ? 100 : 1;
          button2 = isJPY ? 500 : 5;
          button3 = isJPY ? 1000 : 10;
          isFirstBtnEnable = true;
        } else if (bidPrice >= 10000 && bidPrice < 100000) {
          button1 = isJPY ? 1000 : 10;
          button2 = isJPY ? 5000 : 50;
          button3 = isJPY ? 10000 : 100;
          isFirstBtnEnable = true;
        } else if (bidPrice >= 100000) {
          button1 = isJPY ? 1000 : 10;
          button2 = isJPY ? 5000 : 50;
          button3 = isJPY ? 10000 : 100;
        }
      } else {
        if (bidPrice < 100) {
          button1 = 1;
          button2 = 5;
          button3 = 10;
          isFirstBtnEnable = true;
        } else if (bidPrice >= 100 && bidPrice < 1000) {
          button1 = 10;
          button2 = 50;
          button3 = 100;
          isFirstBtnEnable = true;
        } else if (bidPrice >= 1000 && bidPrice < 30000) {
          button1 = 10;
          button2 = 50;
          button3 = 100;
        } else if (bidPrice >= 30000) {
          button1 = 1000;
          button2 = 5000;
          button3 = 10000;
        }
      }
    }

    return { button1, button2, button3, isFirstBtnEnable };
  };

  const commasButtonRender = (value: number) => {
    return new Intl.NumberFormat("us-en").format(
      Math.round(value)
    );
  }
  const getbidButtonRender = (productObj: any, index: any) => {
    let { button1, button2, button3, isFirstBtnEnable } =
      bidButtonHandeler(productObj);

    return (
      <StyledAuctionbidWrapper item xs={12}>
        <Grid item xs={4}>
          {isFirstBtnEnable ? (
            <StyledButton
              className="notDisablebutton"
              id={`${index}`}
              name={`${productObj.perId}`}
              onClick={(e) => getBidValue(e, button1)}
            >
              + {productObj.currency.symbol} {commasButtonRender(button1)}
            </StyledButton>
          ) : (
            <StyledButton className="disableButton">
              + {productObj.currency.symbol} {commasButtonRender(button1)}
            </StyledButton>
          )}
        </Grid>
        <Grid item xs={4}>
          <StyledButton
            className="notDisablebutton"
            id={`${index}`}
            name={`${productObj.perId}`}
            onClick={(e) => getBidValue(e, button2)}
          >
            + {productObj.currency.symbol} {commasButtonRender(button2)}
          </StyledButton>
        </Grid>
        <Grid item xs={4}>
          <StyledButton
            className="notDisablebutton"
            name={`${productObj.perId}`}
            id={`${index}`}
            onClick={(e) => getBidValue(e, button3)}
          >
            + {productObj.currency.symbol} {commasButtonRender(button3)}
          </StyledButton>
        </Grid>
      </StyledAuctionbidWrapper>
    );
  };

  const refreshButtonHandler = (productCurrentBid: any, currentProductId: any) => {
    rapPriceMapping.perIdMap[currentProductId] = 0;
    const obj = items.find((item: any) => item.perId === currentProductId);
    if (currentProductId !== undefined) {
      if (obj.rapnetPercentage === undefined || obj.rapnetPercentage === 0) {
      } else {
        const totalBidPrice = rapPriceMapping.perIdMap[obj.perId];
        if (obj.currency.code === "JPY") {
          productPricePerCaratUSD = totalBidPrice * obj.exchangeRates[0].rate;
        } else {
          productPricePerCaratUSD = totalBidPrice;
        }
        rapPriceMapping.rapnetPercentageMap[obj.perId] = parseFloat(
          ((1 - productPricePerCaratUSD / obj.rapnetPrice) * -100.0).toFixed(2)
        );
      }
    }

    if (!isNaN(productCurrentBid)) {
      const hasProperty = Object.keys(bidPriceInputData).includes(currentProductId);
      if (hasProperty) {
        var tempVal = 0;
        bidPriceInputData[currentProductId] = tempVal;
        bulkBidValue = 0;
      } else {
        bidPriceInputData[currentProductId] = 0;
        bulkBidValue = 0;
      }
      setBidPriceInputData({ ...bidPriceInputData });
    }
  }

  const bidButtonRender = (items: any, index: number) => {
    if (items.sellerAuctionUserId === auctionUserId) {
      return (
        <StyledAuctionbidWrapper item xs={12}>
          <Grid item xs={4}>
            <StyledButton className="disableButton">
              + {items.currency.symbol} {commasButtonRender(buttonVal.first)}
            </StyledButton>
          </Grid>
          <Grid item xs={4}>
            <StyledButton className="disableButton">
              + {items.currency.symbol} {commasButtonRender(buttonVal.second)}
            </StyledButton>
          </Grid>
          <Grid item xs={4}>
            <StyledButton className="disableButton">
              + {items.currency.symbol} {commasButtonRender(buttonVal.third)}
            </StyledButton>
          </Grid>
        </StyledAuctionbidWrapper>
      );
    } else if (items.holdPreviousBidder !== undefined) {
      if (auctionUserId === items.holdPreviousBidder) {
        return (
          <StyledAuctionbidWrapper item xs={12}>
            <Grid item xs={4}>
              <StyledButton className="disableButton">
                + {items.currency.symbol} {commasButtonRender(buttonVal.first)}
              </StyledButton>
            </Grid>
            <Grid item xs={4}>
              <StyledButton className="disableButton">
                + {items.currency.symbol} {commasButtonRender(buttonVal.second)}
              </StyledButton>
            </Grid>
            <Grid item xs={4}>
              <StyledButton className="disableButton">
                + {items.currency.symbol} {commasButtonRender(buttonVal.third)}
              </StyledButton>
            </Grid>
          </StyledAuctionbidWrapper>
        );
      } else {
        return <>{getbidButtonRender(items, index)}</>;
      }
    } else if (items.previousHighestBidder !== undefined) {
      if (auctionUserId === items.previousHighestBidder) {
        return (
          <StyledAuctionbidWrapper item xs={12}>
            <Grid item xs={4}>
              <StyledButton className="disableButton">
                + {items.currency.symbol} {commasButtonRender(buttonVal.first)}
              </StyledButton>
            </Grid>
            <Grid item xs={4}>
              <StyledButton className="disableButton">
                + {items.currency.symbol} {commasButtonRender(buttonVal.second)}
              </StyledButton>
            </Grid>
            <Grid item xs={4}>
              <StyledButton className="disableButton">
                + {items.currency.symbol} {commasButtonRender(buttonVal.third)}
              </StyledButton>
            </Grid>
          </StyledAuctionbidWrapper>
        );
      } else {
        return <>{getbidButtonRender(items, index)}</>;
      }
    } else if (items.auctionUserId === auctionUserId) {
      return (
        <StyledAuctionbidWrapper item xs={12}>
          <Grid item xs={4}>
            <StyledButton className="disableButton">
              + {items.currency.symbol} {commasButtonRender(buttonVal.first)}
            </StyledButton>
          </Grid>
          <Grid item xs={4}>
            <StyledButton className="disableButton">
              + {items.currency.symbol} {commasButtonRender(buttonVal.second)}
            </StyledButton>
          </Grid>
          <Grid item xs={4}>
            <StyledButton className="disableButton">
              + {items.currency.symbol} {commasButtonRender(buttonVal.third)}
            </StyledButton>
          </Grid>
        </StyledAuctionbidWrapper>
      );
    } else {
      if (items.auctionUserId === auctionUserId) {
        return (
          <StyledAuctionbidWrapper item xs={12}>
            <Grid item xs={4}>
              <StyledButton className="disableButton">
                + {items.currency.symbol} {commasButtonRender(buttonVal.first)}
              </StyledButton>
            </Grid>
            <Grid item xs={4}>
              <StyledButton className="disableButton">
                + {items.currency.symbol} {commasButtonRender(buttonVal.second)}
              </StyledButton>
            </Grid>
            <Grid item xs={4}>
              <StyledButton className="disableButton">
                + {items.currency.symbol} {commasButtonRender(buttonVal.third)}
              </StyledButton>
            </Grid>
          </StyledAuctionbidWrapper>
        );
      } else {
        return <>{getbidButtonRender(items, index)}</>;
      }
    }
  };

  return (
    <StyledComponent>
      <StyledContainerWrapper>
        <div
          style={{
            background: "lightgray",
            flexDirection: "row",
            display: "flex",
            height: "40px",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: "1px solid lightgray",
          }}
        >
          <ProductCountContainer style={{ color: "#1e3c63" }}>
            {t("common:Tender:tableColumnNames:bulkBid")}
          </ProductCountContainer>
          <ProductNameContainer className="fontBold">
            <StyledCloseicon
              src="/images/close-button2.ico"
              onClick={popupClose}
            ></StyledCloseicon>
          </ProductNameContainer>
        </div>

        <TableContainer component={Paper} style={{ height: getTableHight() }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow style={{ border: "1px solid lightgray" }}>
                <TableCell
                  style={{
                    border: "1px solid lightgray",
                  }}
                >
                  {t("common:preview:productsList:serialNo")}
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgray",
                    minWidth: "200px",
                  }}
                >
                  {t("common:Tender:tableColumnNames:details")}
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgray",
                  }}
                >
                  {t("common:Tender:tableColumnNames:liveHighestBid")}
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgray",
                  }}
                >
                  {t("common:Tender:tableColumnNames:rapOff")}
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgray",
                  }}
                >
                  {t("common:Tender:tableColumnNames:yourBidPrice")}
                </TableCell>
                <TableCell>{/* refresh icon */}</TableCell>
                <TableCell>
                  {/* {t("common:Tender:tableColumnNames:yourBidPrice")} */}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {productIndexs.slice(0, 10).map((row: any, index: any) => (
                <TableRow key={index} style={{ border: "1px solid lightgray" }}>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      color: "#1e3c63",
                      textDecoration: "underline",
                      cursor: "pointer",
                      border: "1px solid lightgray",
                    }}
                    onClick={() =>
                      openProductDetail(
                        items[row].auctionId,
                        items[row].perId,
                        items[row].auctionStartDate,
                        items[row].parcelType
                      )
                    }
                  >
                    {items[row].serialNo}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "1px solid lightgray",
                      minWidth: "200px",
                    }}
                  >
                    <ProductDescription
                      truncateLength={65}
                      data={items[row]}
                      isTendor={true}
                    />
                  </TableCell>
                  <TableCell
                    style={{
                      border: "1px solid lightgray",
                    }}
                  >
                    {convertedValue(
                      items[row].currency,
                      items[row].currentBidPrice,
                      items[row].exchangeRates[0].rate
                    )}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "1px solid lightgray",
                    }}
                  >
                    {items[row].parcelType !== "Jewellery" ? (
                      <>
                        {rapPriceMapping.perIdMap[items[row].perId]
                          ? rapPriceMapping.rapnetPercentageMap[
                          items[row].perId
                          ]
                          : items[row].rapnetPercentage}
                      </>
                    ) : (
                      <>-</>
                    )}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "1px solid lightgray",
                    }}
                  >
                    {convertedValue(
                      items[row].currency,
                      (getUpdatedBidValue = parseInt(
                        bidPriceInputData[items[row].perId],
                        10
                      )
                        ? items[row].currentBidPrice +
                        parseInt(bidPriceInputData[items[row].perId], 10)
                        : items[row].currentBidPrice),
                      items[row].exchangeRates[0].rate
                    )}
                  </TableCell>
                  <TableCell>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        refreshButtonHandler(
                          items[row].currentBidPrice,
                          items[row].perId
                        )
                      }
                    >
                      <LoopIcon />
                    </div>
                  </TableCell>
                  <TableCell
                    style={{
                      minWidth: "250px",
                      border: "1px solid lightgray",
                    }}
                  >
                    <StyledButtonWrapper item xs={12}>
                      {bidButtonRender(items[row], index)}
                    </StyledButtonWrapper>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <BulkBidButtonContainer>
          <StyledButton
            onClick={bulkBidBtnValuehandle}
            style={{ float: "right" }}
          >
            {t("common:Tender:button:placeBid")}
          </StyledButton>
        </BulkBidButtonContainer>
      </StyledContainerWrapper>
    </StyledComponent>
  );
};
export default BulkBidPopup;
