import React, { useEffect, useRef, useState } from "react";
import { match } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import MUIDataTable, {
  MUIDataTableColumnDef,
  MUIDataTableOptions,
} from "mui-datatables";
import IRouterComponentProps from "../../../models/IRouterComponentProps";
import AuthService from "../../auth/AuthService";
import {
  tendorBidUrl,
  productDownload,
  getBidInfo,
  getAuctionEndTimeUrl,
  auctionTendorMyBids,
  productUrl,
  getMyLatestBidsForUser,
} from "../../../common/config/app.endpoints";
import Alert from "../../../common/elements/Alert";
import LoadingSpinner from "../../../common/elements/LoadingSpinner";
import { oAuth } from "../../../routes/basic/BasicAppRouter";
import {
  StyledButton,
  StyledModal,
} from "../../../common/materials/GenericStyleComponent";
import BlockUserModal from "../BlockUser/BlockUserModal";
import { MuiThemeProvider } from "@material-ui/core/styles";
import update from "immutability-helper";
import moment from "moment";
import {
  StyeldShowAllcontainer,
  StyledshowAllButton,
} from "./TendorJewelleryMyBids";
import { StyledButtonGray } from "../../../common/materials/GenericStyleMuitable";
import {
  getAbbreviation,
  openCertificateLinkPopup,
} from "../../../common/GenericFunctions";
import EllipsisText from "../../../common/elements/EllipsisText";
import ActionTenderPagesize from "../../core-grid/grid-actions/ActionTenderPagesize";
import {
  convertedValue,
  getMyLatestStoredBids,
  getRapNet,
  getReturnMyLatestStoredBid,
} from "./TenderGenericFunc";
import {
  createTheme,
  IconButton,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import TenderSlider from "./bidPopupComponent/TenderSlider";
import {
  StyledButtonView,
  StyledButtonViewFilter,
  Styledview,
} from "./TendorPreviewView";
import copy from "copy-to-clipboard";
import { faEdit, faHeart, faShare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ViewEndedModal from "../auction/ViewEndedModal";
import ViewTenderExtendedModal from "../auction/ViewTenderExtendedModal";
import CoreDataGridOfTender from "./tenderMobileComponets/CoreDataGridOfTender";
import BulkBidPopup from "./bidPopupComponent/BulkBidPopup";
import ColorLensIcon from "@material-ui/icons/ColorLens";
import { useTranslation } from "react-i18next";
import PubSub from "pubsub-js";
import AlertReadOnlyUser from "../../../common/elements/AlertReadOnlyUser";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  isMyBidstabSelected,
  setIsMybids,
} from "../../../redux/slice/commanPagination/commanPaginationSlice";
import {
  resetColorFilter,
  resetTenderDiamondGridProductIndexes,
  resetTenderDiamondSelectAll,
  resetTenderIsGridView,
  selectedColorFilter,
  selectTenderGridView,
  setColorFilter,
  setTenderDiamondGridProductIndexes,
  setTenderDiamondSelectAll,
  setTenderIsGridView,
} from "../../../redux/slice/annual/commanAnnualSlice";
import { AppDispatch } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducer";
import {
  StyledContainer,
  StyledPageSizeSelectorContainer,
} from "../../365Auction/component/DiamondProductMyBids";
import { StyledSelectAllButtonHeader } from "../../365Auction/component/DiamondProductStyle";
import TenderListOfProductMybids from "./tenderMobileComponets/TenderListOfProductMybids";

const faEditProps = faEdit as IconProp;
const faHeartProps = faHeart as IconProp;
const faShareSquareProps = faShare as IconProp;

interface DetailParams {
  auctionId: string;
  auctionUserId: string;
  auctionDate: string;
  auctionName: string;
}

export interface IProductViewProps extends IRouterComponentProps {
  match: match<DetailParams>;
  t?: any;
  location: any;
}
const StyledHeaderContainer = styled(Grid)`
  padding-top: 0px;
`;
const StyledLayoutGridMain = styled(Grid)`
  .MuiIconButton-edgeEnd {
    display: contents !important;
    transition: width 5s !important;
  }
  .MuiAccordionSummary-root {
    min-height: 10px !important;
    transition: width 4s !important;
  }
  .MuiPaper-root {
    box-shadow: none !important;
  }
  .AccordionHeader {
    top: 70px;
    width: 100%;
    transition: width 1s;

    @keyframes fadeMe {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    ${(props) => props.theme.breakpoints.down("md")} {
      width: 100%;
    }
    ${(props) => props.theme.breakpoints.between("xs", "md")} {
      top: 58px;
      width: 100% !important;
    }
  }
`;

const StyledLayoutGrid = styled(Grid)`
  width: 100%;
  .MuiPaper-root {
    width: 100%;
  }
  .MuiTableCell-root {
    padding: 7px;
    border: 1px solid lightgray;
  }
  .MuiToolbar-regular {
    min-height: 0px;
  }
  .MuiTableCell-head {
    background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
    color: white;
    text-align: center;
    font-weight: 700;
    border: 1px solid lightgray;
  }

  .MuiTableCell-root {
    text-align: center;
    max-width: 100px;
    // position: relative;
  }

  .MuiTableCell-head .MuiButton-root {
    color: white;
    text-align: center;
    font-weight: 700;
  }
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    // padding: 10px 10px;
    .MuiTableCell-root {
      text-align: center;
      max-width: 400px;
      border: none;
    }
    .MuiTableHead-root {
      display: flex !important;
    }
  }
`;
const StyledBtnContainer = styled.div`
  text-align: center;
`;

const StyledCoreDataGridContainer = styled(Grid)``;
const StyledTextWrapper = styled(Grid)`
  margin: auto;
  text-align: center;
`;
const StyledActionBarContainer = styled(Grid)`
  align-items: center;
  padding: 10px;
  margin: 10px 0px 10px 0px;
  border-radius: 5px;
  box-shadow: 2px 0px 5px 2px ${(props) => props.theme.colors.base.lightGrey};
  justify-content: flex-end;
  &:focus {
    outline: none;
  }
`;

const StyledButtonHeader = styled.button`
  color: ${(props) => props.theme.colors.base.grey};
  font-size: 0.9em;
  padding: 0.4em;
  margin: 0.3em;
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.colors.base.lightGrey};
  background: ${(props) => props.theme.colors.base.veryVeryLightGrey};
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.base.textColor};
    cursor: pointer;
  }

  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 0.8em;
  }
`;

let body: any = [];
let totalElement: number = 0;
let page: number = 0;
let testno: number = 100;

let payload: any = {};
let startCount: number = 0;
let endCount: number = 100;
let endCountmob: number = 10;
let rowSelectedData: any;
let updatedRowData: any;
let updateRowIndex: any;
let auctionProductResult: any;
let seconds: any = "";

let filter: any = [];
let filteredValues: any = "";
let filteredParcelValues: any = "";

let length: any;

//same as Tender auction
const columnIndexes = {
  serialNo: 0,
  productId: 1,
  auctionUserId: 2,
  productCode: 4,
  lab: 5,
  certificate: 6,
  description: 7,
  productDescription: 8,
  carat: 9,
  color: 10,
  remarks: 11,
  noOfPieces: 12,
  shape: 13,
  sieve: 14,
  cutGrade: 15,
  clarity: 16,
  polish: 17,
  symmetryAbbreviation: 18,
  flour: 19,
  currency: 20,
  peicePerCt: 21,
  myLatestBids: 22,
  notes: 23,
  currentBidPrice: 24,
  repnetPrice: 25,
  noOfBids: 26,
  rowColor: 27,
  exchangeRate: 28,
  parcelType: 29,
  noOfBidders: 30,
  currentBidValue: 30,
  hieghestBidValue: 31,
  watched: 31,
  sellerAuctionUserId: 32,
  rapnetPercentage: 33,
  previousHighestBidder: 35,
  holdPreviousBidder: 36,
};

let details: any;
let date: string | string[] = "";
let auctionDate: any;
let auctionStateforPopup: string;
let auctionTenderExtended: boolean = false;
let indexsForBulkBidItems: any = [];
let myLatestBidForProduct: any = [];
let isAuctionUserId: boolean;
let isReadOnlyUser: any;
const initialState = {
  mouseX: null,
  mouseY: null,
};
let auctionsState: any;

const TendorMybids = (props: IProductViewProps) => {
  const getMuiTheme = () =>
    createTheme({
      overrides: {
        MUIDataTableSelectCell: {
          fixedHeader: {
            background: "white",
          },
          fixedLeft: {
            background: "white",
          },
        },
        MuiTableSortLabel: {
          active: {
            color: "green", // your color here
          },
        },
      },
    });
  const { t } = useTranslation();
  const [mybidProducts, setmybidProducts] = useState([]);
  const [openToast, setOpenToast] = useState(false);
  const [showAlertMessage, setShowAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [alertType, setAlertType] = useState("");

  const [apiTimeout, setApiTimeout] = useState(false);
  const [onAction, setOnAction] = useState(false);

  const [userBlock, setUserBlock] = useState(false);
  const [showTenderSlider, setshowTenderSlider] = useState(false);
  const [showBulkBidPopup, setShowBulkBidPopup] = useState(false);
  const [showBulkBidButton, setShowBulkBidButton] = useState(false);

  const [isFirstButtonDisable, setisFirstButtonDisable] = useState(false);
  const [openEndedModal, setOpenEndedModal] = useState(false);
  const [openTenderExtended, setOpenTenderExtended] = useState(false);
  const theme = useTheme();

  const notSmallDevices = useMediaQuery(theme.breakpoints.between("md", "xl"));
  const [uiBoxView, setUiBoxView] = useState(false);
  const [isColorDataFilter, setIsColorDataFilter] = useState(false);
  const [isDataRefresh, setIsDataRefresh] = useState(false);
  const [isTopElementHide, setIsTopElementHide] = useState(false);
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [openReadOnlyUserToast, setOpenReadOnlyUserToast] = useState(false);
  const [currentProductId, setCurrentProductId] = useState<string>("");
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [firstTimeExetendPopup, setFirstTimeExetendPopup] =
    useState<boolean>(false);
  const [mouseState, setMouseState] = React.useState<{
    mouseX: null | number;
    mouseY: null | number;
  }>(initialState);
  const [colorMybidsName, setColorMybidsName] = React.useState<string>("");
  const [totalAmount, setTotalAmount] = React.useState(0);
  const [totalAmountLabel, setTotalAmountLabel] = React.useState<string>("");
  let isAuctionPage: any = true;
  let isCategoryPageType: any = true;
  const Auth = new AuthService();
  const maxRetryCount = 2;
  const countRef = useRef(0);
  const bufferTotalPay = useRef(0);
  const usdBufferTotalPay = useRef(0);
  const [shouldGetAllData, setShouldGetAllData] = useState(false);
  const itemsPerPageUseRef = useRef(10);
  const selectPageUseRef = useRef(0);
  let userCurrencyCode = localStorage.getItem("preferredCurrency");
  let userCurrencySymbol = localStorage.getItem("preferredCurrencySymbol");

  const eventSource: any = useRef<any>();
  const dispatch: AppDispatch = useDispatch();

  const isGridView: any = useSelector((state: RootState) =>
    selectTenderGridView(state)
  );

  const selectedColor: any = useSelector((state: RootState) =>
    selectedColorFilter(state)
  );

  const isMyBidstab: any = useSelector((state: RootState) =>
    isMyBidstabSelected(state)
  );

  useEffect(() => {
    setIsLoading(true);
    dispatch(resetTenderDiamondGridProductIndexes());
    dispatch(resetTenderDiamondSelectAll());
    isReadOnlyUser = localStorage.getItem("isReadOnlyUser");
    isReadOnlyUser = JSON.parse(isReadOnlyUser);
    localStorage.removeItem("dropDownFilterVal");
    localStorage.removeItem("jwlauctionUserId");
    // localStorage.removeItem("tenderitemsPerPage");
    // localStorage.removeItem("currentStart");
    //data table api calling
    getAllUpdatedData();
    getAuctionEndTime(props.match.params.auctionId);
    getMyLatestBids(props.match.params.auctionId);

    dispatch(setIsMybids({ isMybidstab: true }));

    setTimeout(() => {
      setIsDataRefresh(!isDataRefresh);
    }, 3000);
    return () => {
      if (eventSource.current !== undefined || eventSource.current !== null) {
        if (eventSource.current.close !== undefined) {
          eventSource.current.close();
        }
      }
      dispatch(resetColorFilter());
    };
  }, []);

  useEffect(() => {
    if (shouldGetAllData) {
      getAllUpdatedData();
      setShouldGetAllData(false);
    }
  }, [shouldGetAllData]);

  const getAllUpdatedData = () => {
    if (countRef.current < maxRetryCount) {
      eventSource.current = new EventSource(getBidInfo);
      eventSource.current.onmessage = (event: any) => {
        updatedRowData = JSON.parse(event.data);
        if (updatedRowData !== undefined) {
          if (updatedRowData.alive) {
            //This will listen only for events
          } else {
            if (props.match.params.auctionId === updatedRowData.auctionId) {
              updateRowIndex = auctionProductResult.findIndex(
                (product: any) => product.perId === updatedRowData.perId
              );
              // setTotalAmount(bufferTotalPay.current + updatedRowData.currentBidPrice);
              const mapping: any = {};
              if (
                auctionProductResult !== undefined &&
                auctionProductResult.length > 0
              ) {
                auctionProductResult.map((items: any) => {
                  mapping[items.perId] = {
                    watched: items.watched,
                  };
                });
              }

              var updatedArr: any;
              if (updateRowIndex !== -1) {
                updatedArr = update(auctionProductResult, {
                  $splice: [[updateRowIndex, 1, updatedRowData]],
                });
              }

              // const arr = [...updatedArr];
              const arr = updatedArr;
              // auctionProductResult = updatedArr;
              arr.map((items: any) => {
                if (mapping[items.perId] !== undefined) {
                  items.watched = mapping[items.perId].watched;
                }
              });
              auctionProductResult = updatedArr;
              setmybidProducts(auctionProductResult);
              //update total amount of user pay
              bufferTotalPay.current =
                bufferTotalPay.current +
                updatedRowData.liveHighestBidIncreasedBy;
              usdBufferTotalPay.current =
                usdBufferTotalPay.current +
                updatedRowData.liveHighestBidIncreasedByInCurrencies[0].amount;
              //get auction currency symbol
              let { currency } = updatedRowData;
              if (userCurrencyCode !== "JPY") {
                //usre currency code in jpy
                let conversion = new Intl.NumberFormat("us-en").format(
                  Math.round(usdBufferTotalPay.current)
                );
                let conversions = new Intl.NumberFormat("us-en").format(
                  Math.round(bufferTotalPay.current)
                );
                if (currency.code === "JPY") {
                  setTotalAmountLabel(
                    `${updatedRowData.currency.symbol} ${conversions} (${userCurrencySymbol} ${conversion})`
                  );
                } else {
                  setTotalAmountLabel(
                    `${updatedRowData.currency.symbol} ${conversions}`
                  );
                }
              } else {
                let conversion = new Intl.NumberFormat("us-en").format(
                  Math.round(usdBufferTotalPay.current)
                );
                let conversions = new Intl.NumberFormat("us-en").format(
                  Math.round(bufferTotalPay.current)
                );

                if (currency.code === "JPY") {
                  setTotalAmountLabel(
                    `${updatedRowData.currency.symbol} ${conversions}`
                  );
                } else {
                  setTotalAmountLabel(
                    `${updatedRowData.currency.symbol} ${conversions} (${userCurrencySymbol} ${conversion})`
                  );
                }
              }
              setShouldGetAllData(false);
            }
          }
        }
      };
      eventSource.current.onerror = (err: any) => {
        console.error("EventSource failed:", err);
        countRef.current = countRef.current + 1;
        setShouldGetAllData(true);
      };
    }
  };

  const getAuctionEndTime = (auctionId: string, callBack?: any) => {
    if (!oAuth()) {
      props.history.push("/");
    } else {
      Auth.getRequest(
        `${getAuctionEndTimeUrl}/${auctionId}/end_time`,
        (status: number, response: any) => {
          if (status === 200) {
            setIsLoading(false);
            auctionsState = response.state;
            setFirstTimeExetendPopup(response.tenderExtended);
            getLeftTime(response.endDate);
            // if (callBack !== undefined) {
            //   callBack(response);
            // }
            if (response.state === "Ended") {
              setOpenEndedModal(true);
            }
          } else if (status === 403) {
            setUserBlock(true);
          } else if (status === 505) {
            setApiTimeout(true);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            setOnAction(false);
            setShowAlertMessage(response[0].message);
            setAlertType("error");
            setOpenToast(true);
          }
        }
      );
    }
  };

  //Note: Get my All LatestBid using this Api
  const getMyLatestBids = (auctionId: string) => {
    if (!oAuth()) {
      props.history.push("/");
    } else {
      Auth.getRequest(
        `${getMyLatestBidsForUser}?auctionId=${auctionId}`,
        (status: number, response: any) => {
          if (status === 200) {
            setIsLoading(false);
            myLatestBidForProduct = response;
          } else if (status === 403) {
            setUserBlock(true);
          } else if (status === 505) {
            setApiTimeout(true);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            setOnAction(false);
            setShowAlertMessage("Internal Server Error");
            setAlertType("error");
            setOpenToast(false);
          }
        }
      );
    }
  };

  const handleBidClick = (tableData: any) => {
    rowSelectedData = tableData.rowData;
    getProductsDetails(mybidProducts);
    setshowTenderSlider(true);
  };

  const getProductsDetails = (product: any) => {
    length = mybidProducts.length;
    product.map((items: any, index: any) => {
      if (items.perId === rowSelectedData[columnIndexes.productId]) {
        details = index;
      }
    });
    return details;
  };

  const getBoxIndex = (boxIndex: any) => {
    length = mybidProducts.length;
    details = boxIndex;
    setshowTenderSlider(true);
  };

  // Tender bulk bid popup
  const bulkBidPopupHandler = () => {
    setShowBulkBidPopup(true);
  };

  const getTendorMybidsAucProducts = (
    auctionId: number | string,
    currentPage: number,
    ismob: boolean
  ) => {
    setOpenToast(false);

    body = [];
    filteredValues = "";
    filteredParcelValues = "";

    if (localStorage.getItem("tabSelectedVal") === "1") {
      filteredValues = localStorage.getItem("filteredValues");
      filteredValues = JSON.parse(filteredValues);

      if (filteredValues !== undefined) {
        if (filteredValues === null || filteredValues.length === 0) {
          filter = null;
        } else {
          filteredValues.map((item: any, index: number) => {
            if (item.name === "auctionId") {
              filteredValues.splice(index, 1);
            }
          });
          filter = filteredValues;
        }
      }
    } else {
      filteredParcelValues = localStorage.getItem("filteredParcelValues");
      filteredParcelValues = JSON.parse(filteredParcelValues);

      if (filteredParcelValues !== undefined) {
        if (
          filteredParcelValues === null ||
          filteredParcelValues.length === 0
        ) {
          filter = null;
        } else {
          filteredParcelValues.map((item: any, index: number) => {
            if (item.name === "auctionId") {
              filteredParcelValues.splice(index, 1);
            }
          });
          filter = filteredParcelValues;
        }
      }
    }

    getBody(auctionId, currentPage, endCount, ismob);
  };

  const getBody = (
    auctionId: string | number,
    currentPage: number,
    endValue: number,
    ismobDevice: boolean
  ) => {
    page = currentPage;

    if (ismobDevice) {
      startCount = page * endCount;
    } else {
      startCount = page * endValue;
    }
    payload = {};

    if (localStorage.getItem("tabSelectedVal") === "1") {
      filteredValues = localStorage.getItem("filteredValues");
      filteredValues = JSON.parse(filteredValues);
    } else {
      filteredParcelValues = localStorage.getItem("filteredParcelValues");
      filteredParcelValues = JSON.parse(filteredParcelValues);
    }

    if (
      localStorage.getItem("dropDownFilterVal") !== undefined &&
      localStorage.getItem("dropDownFilterVal") !== null
    ) {
      var tempArr = localStorage.getItem("dropDownFilterVal");
      if (tempArr !== null) var dropDownValues = JSON.parse(tempArr);
      var property = dropDownValues[0].property;
      var direction = dropDownValues[0].direction;
    }
    if (
      localStorage.getItem("currentStart") !== null &&
      localStorage.getItem("currentStart") !== "0" &&
      localStorage.getItem("currentStart") !== undefined
    ) {
      let savedCount: any = localStorage.getItem("currentStart");
      startCount = parseInt(savedCount);
      selectPageUseRef.current = startCount;
    } else {
      startCount = 0;
      selectPageUseRef.current = startCount;
    }

    if (localStorage.getItem("tenderitemsPerPage") !== null) {
      let savedCount: any = localStorage.getItem("tenderitemsPerPage");
      endCount = parseInt(savedCount);
      itemsPerPageUseRef.current = endCount;
    } else {
      endCount = 10;
      itemsPerPageUseRef.current = 10;
    }

    if (
      (filter === null &&
        (filteredValues == null ||
          filteredValues === undefined ||
          filteredValues === "null" ||
          filteredValues.length === 0)) ||
      (filter === null &&
        (filteredParcelValues == null ||
          filteredParcelValues === undefined ||
          filteredParcelValues === "null" ||
          filteredParcelValues.length === 0))
    ) {
      payload = {
        filters: {
          and: [
            {
              match: "exact",
              name: "auctionId",
              value: auctionId,
            },
          ],
        },
        page: {
          start: ismobDevice ? startCount : 0,
          count: ismobDevice ? endCount : isMyBidstab ? 5000 : endValue,
          sort: {
            orders: [
              {
                property:
                  property !== null && property !== undefined
                    ? property
                    : "createdDate",
                direction:
                  direction !== null && direction !== undefined
                    ? direction
                    : "DESC",
              },
            ],
          },
        },
      };
    } else {
      if (filteredValues !== null && filteredValues.length > 0) {
        body = filteredValues ? filteredValues : filter;
      } else {
        body = filteredParcelValues ? filteredParcelValues : filter;
      }

      body.map((item: any, index: number) => {
        if (item.name === "auctionId") {
          body.splice(index, 1);
        }
      });

      if (localStorage.getItem("tabSelectedVal") === "1") {
        body.push({
          match: "exact",
          name: "auctionId",
          value: props.match.params.auctionId,
        });
        payload = {
          filters: {
            and: body,
          },
          page: {
            start: ismobDevice ? startCount : 0,
            count: ismobDevice ? endCount : isMyBidstab ? 5000 : endValue,
            sort: {
              orders: [
                {
                  property:
                    property !== null && property !== undefined
                      ? property
                      : "createdDate",
                  direction:
                    direction !== null && direction !== undefined
                      ? direction
                      : "DESC",
                },
              ],
            },
          },
        };
      } else {
        let andArr = [];
        andArr.push({
          match: "exact",
          name: "auctionId",
          value: props.match.params.auctionId,
        });
        payload = {
          filters: {
            or: body,
            and: andArr,
          },
          page: {
            start: ismobDevice ? startCount : 0,
            count: ismobDevice ? endCount : isMyBidstab ? 5000 : endValue,
            sort: {
              orders: [
                {
                  property:
                    property !== null && property !== undefined
                      ? property
                      : "createdDate",
                  direction:
                    direction !== null && direction !== undefined
                      ? direction
                      : "DESC",
                },
              ],
            },
          },
        };
      }
    }
    let val = localStorage.getItem("optionValue");
    if (val !== "" && val !== "null" && val != null) {
      payload = {
        filters: {
          or: [
            {
              match: "anywhere",
              name: "productDescription",
              value: val,
            },
            {
              match: "anywhere",
              name: "remarks",
              value: val,
            },
          ],
          and: [
            {
              match: "exact",
              name: "auctionId",
              value: props.match.params.auctionId,
            },
          ],
        },
        page: {
          start: ismobDevice ? startCount : 0,
          count: ismobDevice ? endCount : endValue,
          sort: {
            orders: [
              {
                property: "createdDate",
                direction: "DESC",
              },
            ],
          },
        },
      };
    }

    callApi(payload);
  };

  const callApi = (payload: any) => {
    if (!oAuth()) {
      props.history.push("/");
    } else {
      Auth.postRequest(
        `${auctionTendorMyBids}?auctionId=${props.match.params.auctionId}&rowColorState=${selectedColor}`,
        payload,
        (status: any, response: any) => {
          setIsLoading(false);
          if (status === 200) {
            totalElement = response.totalElements;
            auctionProductResult = response.results;
            if (response.results.length > 0) {
              auctionDate = response?.results[0]?.auctionStartDate;
              date = auctionDate.split(" ", 2);
            }
            let requestData: any = [];
            setmybidProducts(response.results);

            // total element logic we will find total pay amount
            let totalpay = 0;
            let usdTotalPay = 0;
            //calculate total sum of current Bid Price
            if (
              response.additionalData !== undefined &&
              response.results.length !== 0
            ) {
              let { totalAmount, totalAmountUSD, totalAmountJPY } =
                response.additionalData;
              let { currency } = response.results[0];
              totalpay = totalAmount;

              //get auction currency symbol
              if (userCurrencyCode !== "JPY") {
                usdTotalPay = totalAmountUSD;
                if (currency.code === "JPY") {
                  let conversion = new Intl.NumberFormat("us-en").format(
                    Math.round(totalAmountUSD)
                  );
                  let conversions = new Intl.NumberFormat("us-en").format(
                    Math.round(totalAmount)
                  );
                  setTotalAmountLabel(
                    `${currency.symbol} ${conversions} (${userCurrencySymbol} ${conversion})`
                  );
                } else {
                  let conversions = new Intl.NumberFormat("us-en").format(
                    Math.round(totalAmount)
                  );
                  setTotalAmountLabel(`${currency.symbol} ${conversions}`);
                }
              } else {
                if (currency.code === "JPY") {
                  // let conversion = new Intl.NumberFormat('us-en').format(
                  //   Math.round(totalAmountJPY)
                  // );
                  let conversions = new Intl.NumberFormat("us-en").format(
                    Math.round(totalAmount)
                  );
                  setTotalAmountLabel(`${currency.symbol} ${conversions}`);
                } else {
                  usdTotalPay = totalAmountJPY;
                  let conversion = new Intl.NumberFormat("us-en").format(
                    Math.round(totalAmountJPY)
                  );
                  let conversions = new Intl.NumberFormat("us-en").format(
                    Math.round(totalAmount)
                  );
                  setTotalAmountLabel(
                    `${currency.symbol} ${conversions} (${userCurrencySymbol} ${conversion})`
                  );
                }
              }
            } else {
              setTotalAmountLabel(`-`);
              setmybidProducts(response.results);
            }
            bufferTotalPay.current = totalpay;
            usdBufferTotalPay.current = usdTotalPay;
            setTotalAmount(totalpay);
            //add color new object into api response
            for (var i = 0; i < response.totalElements; i++) {
              let obj = response.results[i];
              const { auctionUserId, rowColor, previousHighestBidder } = obj;
              if (previousHighestBidder === undefined) {
                if (auctionUserId !== props.match.params.auctionUserId) {
                  obj["sortingColorCode"] = 113;
                  obj["colorFilter"] = "WHITE";
                } else {
                  if (rowColor === "#FFEB9C") {
                    obj["sortingColorCode"] = 112;
                    obj["colorFilter"] = "ORANGE";
                  } else {
                    obj["sortingColorCode"] = 111;
                    obj["colorFilter"] = "PINK";
                  }
                }
              } else {
                if (
                  props.match.params.auctionUserId === previousHighestBidder
                ) {
                  obj["sortingColorCode"] = 111;
                  obj["colorFilter"] = "PINK";
                } else {
                  if (rowColor === "#FFEB9C") {
                    obj["sortingColorCode"] = 112;
                    obj["colorFilter"] = "ORANGE";
                  } else {
                    obj["sortingColorCode"] = 111;
                    obj["colorFilter"] = "PINK";
                  }
                }
              }
              requestData.push(obj);
            }
            setmybidProducts(requestData);
            setColorMybidsName(selectedColor);
          } else if (status === 403) {
            setUserBlock(true);
          } else if (status === 505) {
            setApiTimeout(true);
            setOnAction(false);
          } else if (status === 404) {
            setIsLoading(false);
            setOnAction(false);
            setShowAlertMessage(`${t("common:invalidRequest")}`);
            setAlertType("error");
            setOpenToast(true);
          } else {
            setIsLoading(false);
            setOnAction(false);
            setShowAlertMessage(response[0].message);
            setAlertType("error");
            setOpenToast(true);
          }
        }
      );
    }
  };

  const filterColorSort = () => {
    //todo
    if (!isColorDataFilter) {
      setmybidProducts(
        mybidProducts.sort((a: any, b: any) =>
          a.sortingColorCode > b.sortingColorCode ? 1 : -1
        )
      );
    } else {
      setmybidProducts(
        mybidProducts.sort((a: any, b: any) =>
          a.sortingColorCode < b.sortingColorCode ? 1 : -1
        )
      );
    }
    setIsColorDataFilter(!isColorDataFilter);
  };

  const handleBtnValue = (
    values: number,
    currentBidPrice: any,
    productId: any,
    myBidValues: any
  ) => {
    var data = [
      {
        bidIncreasedBy: values,
        bidPrice: currentBidPrice,
        auctionUserId: props.match.params.auctionUserId,
        auctionId: props.match.params.auctionId,
        productId: productId,
      },
    ];

    let updateRowIndex = myLatestBidForProduct.findIndex(
      (product: any) => product.productId === myBidValues.productId
    );
    if (updateRowIndex === -1) {
      var myLatestBidForProductObjectData: any = [
        ...myLatestBidForProduct,
        myBidValues,
      ];
      myLatestBidForProduct = myLatestBidForProductObjectData;
    } else {
      var updatedArray = update(myLatestBidForProduct, {
        $splice: [[updateRowIndex, 1, myBidValues]],
      });
      myLatestBidForProduct = updatedArray;
    }
    bidProduct(data);
    PubSub.publish("resetGridBoxIndex", []);
  };

  const bulkBidHandler = (values: any[], myBidBulkValues: any) => {
    myLatestBidForProduct = myBidBulkValues;
    bidProduct(values);
    onGridSelectionChange(0);
    PubSub.publish("resetGridBoxIndex", []);
    dispatch(resetTenderDiamondGridProductIndexes());
    dispatch(resetTenderDiamondSelectAll());
    setIsSelected(false);
  };

  const getTableBodyHeight = () => {
    var h = window.innerHeight;
    let heightOfInnerTable = h - 260;
    if (mybidProducts.length > 5) {
      return `${heightOfInnerTable}px`;
    } else {
      return `auto`;
    }
  };

  const options: MUIDataTableOptions = {
    filter: true,
    download: "false",
    print: "false",
    viewColumns: "false",
    count: totalElement,
    // page: page,
    serverSide: false,
    search: true,
    sort: true,
    pagination: false,
    rowsPerPage: endCount,
    rowsPerPageOptions: [0],
    selectableRows: "multiple",
    selectableRowsHeader: true,
    selectableRowsOnClick: false,
    isRowSelectable: (dataIndex: any, selectedRows: any) => {
      const disableRowIndexsArray = getDisableRowIndex();
      return disableRowIndexsArray.includes(dataIndex) ? false : true;
    },
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
      return (
        <div className="mailIconDiv">
          <IconButton color="primary">
            <i className="zmdi zmdi-email"></i>
          </IconButton>
        </div>
      );
    },
    onChangePage: (currentPage: number) => {
      getTendorMybidsAucProducts(
        props.match.params.auctionId,
        currentPage,
        false
      );
    },

    onSearchChange: (searchTxt: any) => {
      //
    },
    setRowProps: (row: any, dataIndex: any, rowIndex: any) => {
      if (row[columnIndexes.previousHighestBidder] === undefined) {
        if (row[columnIndexes.holdPreviousBidder] === undefined) {
          if (
            row[columnIndexes.auctionUserId] !==
            props.match.params.auctionUserId
          ) {
            return { style: { background: "white" } };
          } else {
            return { style: { background: row[columnIndexes.rowColor] } };
          }
        } else {
          if (
            props.match.params.auctionUserId ===
            row[columnIndexes.holdPreviousBidder]
          ) {
            return { style: { background: "#ffeb9c" } };
          } else {
            return { style: { background: row[columnIndexes.rowColor] } };
          }
        }
      } else {
        if (
          props.match.params.auctionUserId ===
          row[columnIndexes.previousHighestBidder]
        ) {
          return { style: { background: "#FBE4D5" } };
        } else {
          return { style: { background: row[columnIndexes.rowColor] } };
        }
      }
    },
    onRowSelectionChange: (
      currentRowsSelected: any,
      allRowsSelected: any,
      rowsSelected: any
    ) => {
      indexsForBulkBidItems = rowsSelected;
      setOpenToast(false);
      indexsForBulkBidItems.map((indexs: any) => {
        let {
          sellerAuctionUserId,
          previousHighestBidder,
          auctionUserId,
          holdPreviousBidder,
        } = mybidProducts[indexs];
        if (sellerAuctionUserId === props.match.params.auctionUserId) {
          return { ...options, isRowSelectable: "false" };
        } else if (previousHighestBidder !== undefined) {
          if (props.match.params.auctionUserId === previousHighestBidder) {
            return { ...options, isRowSelectable: "false" };
          }
        } else if (holdPreviousBidder !== undefined) {
          //hold user bid
          if (props.match.params.auctionUserId === holdPreviousBidder) {
            return { ...options, isRowSelectable: "false" };
          }
        } else if (auctionUserId === props.match.params.auctionUserId) {
          return { ...options, isRowSelectable: "false" };
        }
      });
      if (indexsForBulkBidItems.length >= 0) {
        setShowBulkBidButton(true);
      } else {
        setShowBulkBidButton(false);
      }
      if (indexsForBulkBidItems.length === 0) {
        setShowBulkBidButton(false);
      }
      if (indexsForBulkBidItems.length > 10) {
        setShowAlertMessage(
          `${t("common:Tender:validation:productSelection")}`
        );
        setAlertType("error");
        setOpenToast(true);
      }
    },
    tableBodyHeight: getTableBodyHeight(),
    textLabels: {
      body: {
        noMatch: `${t("common:preview:noProductFound")}`,
      },
    },
  };

  const onGridSelectionChange = (rowsSelected: any) => {
    indexsForBulkBidItems = rowsSelected;
    setOpenToast(false);
    if (indexsForBulkBidItems.length >= 0) {
      setShowBulkBidButton(true);
    } else {
      setShowBulkBidButton(false);
    }
    if (indexsForBulkBidItems.length === 0) {
      setShowBulkBidButton(false);
    }
    if (indexsForBulkBidItems.length > 10) {
      setShowAlertMessage(`${t("common:Tender:validation:productSelection")}`);
      setAlertType("error");
      setOpenToast(true);
    }
  };

  const boxColor = (items: any) => {
    const {
      auctionUserId,
      rowColor,
      previousHighestBidder,
      holdPreviousBidder,
    } = items;
    if (previousHighestBidder === undefined) {
      if (holdPreviousBidder === undefined) {
        if (auctionUserId !== props.match.params.auctionUserId) {
          return "white";
        } else {
          return rowColor;
        }
      } else {
        //hold sold user flag
        if (props.match.params.auctionUserId === holdPreviousBidder) {
          return "#ffeb9c";
        } else {
          return rowColor;
        }
      }
    } else {
      if (props.match.params.auctionUserId === previousHighestBidder) {
        return "#FBE4D5";
      } else {
        return rowColor;
      }
    }
  };
  const getDisableRowIndex = () => {
    return mybidProducts.map((items: any, index: number) => {
      if (items.sellerAuctionUserId === props.match.params.auctionUserId) {
        return index;
      } else if (items.holdPreviousBidder !== undefined) {
        if (props.match.params.auctionUserId === items.holdPreviousBidder) {
          return index;
        }
      } else if (items.previousHighestBidder !== undefined) {
        if (props.match.params.auctionUserId === items.previousHighestBidder) {
          return index;
        }
      } else if (items.auctionUserId === props.match.params.auctionUserId) {
        return index;
      }
    });
  };

  const filterSort = (sortValue: any, direction: any) => {
    let order = "";
    if (direction === "asc") {
      order = "desc";
    } else {
      order = "asc";
    }
    // calling sorting function and pass sorting
    sortData(sortValue, order);
  };

  const sortData = (values: any, direction: any) => {
    let tempArr: any = [{ property: values, direction: direction }];

    if (direction === "asc") {
      tempArr = [{ property: values, direction: "ASC" }];
    } else {
      tempArr = [{ property: values, direction: "DESC" }];
    }
    localStorage.setItem("dropDownFilterVal", JSON.stringify(tempArr));
    selectPageUseRef.current = 0;
    localStorage.setItem("currentStart", "0");
    getTendorMybidsAucProducts(props.match.params.auctionId, 0, true);
  };

  const columns: MUIDataTableColumnDef[] = [
    {
      name: "serialNo",
      label: `${t("common:Tender:tableColumnNames:serialNo")}`,
      options: {
        filter: true,
        sort: true,
        display: true,
        filterType: "textField",
        sortThirdClickReset: true,
        sortDescFirst: true,
        setCellProps: () => ({
          style: { minWidth: "100px", maxWidth: "100px" },
        }),
      },
    },
    {
      name: "perId",
      label: "Product Id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "auctionUserId",
      label: "Auction User Id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "",
      label: `${t("common:Tender:tableColumnNames:ActionsTender")}`,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <div
              style={{
                display: "flex",
                gridGap: "10px",
                justifyContent: "center",
              }}
            >
              <div
                title={`Note`}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  openProductDetail(
                    props.match.params.auctionId,
                    tableMeta.rowData[1],
                    props.match.params.auctionDate
                  )
                }
              >
                <FontAwesomeIcon
                  icon={faEditProps}
                  style={{ color: "#014a99" }}
                />
              </div>
              <div
                title={`Add Product into Wishlist`}
                style={{ cursor: "pointer" }}
              >
                {isReadOnlyUser ? (
                  <FontAwesomeIcon
                    icon={faHeartProps}
                    style={{ color: "gray" }}
                    onClick={() => {
                      renderReadOnlyUserToast(
                        tableMeta.rowData[columnIndexes.sellerAuctionUserId]
                      );
                    }}
                  />
                ) : (
                  <>
                    {tableMeta.rowData[columnIndexes.sellerAuctionUserId] !==
                    props.match.params.auctionUserId ? (
                      <>
                        {getWatchedProduct(
                          tableMeta.rowData[columnIndexes.watched],
                          tableMeta.rowData[columnIndexes.productId],
                          props.match.params.auctionId,
                          tableMeta.rowData[1]
                        )}
                      </>
                    ) : (
                      <FontAwesomeIcon
                        icon={faHeartProps}
                        style={{ color: "gray" }}
                        onClick={() => {
                          renderReadOnlyUserToast(
                            tableMeta.rowData[columnIndexes.sellerAuctionUserId]
                          );
                        }}
                      />
                    )}
                  </>
                )}
              </div>
              <div
                title={`Share Product`}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  shareButtonAction(tableMeta.rowData[columnIndexes.productId])
                }
              >
                <FontAwesomeIcon
                  icon={faShareSquareProps}
                  style={{ color: "#014a99" }}
                />
              </div>
            </div>
          );
        },
      },
    },
    {
      name: "code",
      label: `${t("common:Tender:tableColumnNames:productId")}`,
      options: {
        filter: true,
        sort: false,
        filterType: "textField",
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <span
              className="linkCss"
              onClick={() =>
                openProductDetail(
                  props.match.params.auctionId,
                  tableMeta.rowData[1],
                  props.match.params.auctionDate
                )
              }
              onContextMenu={(event: any) =>
                rightClickEvent(event, tableMeta.rowData[1])
              }
            >
              {value}
            </span>
          );
        },
      },
    },
    {
      name: "lab",
      label: `${t("common:Tender:tableColumnNames:lab")}`,
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any, tableMeta: any) => {
          if (
            value !== "AGT" &&
            value !== "agt" &&
            value !== "" &&
            value !== undefined
          ) {
            return (
              <span
                className="linkCss"
                onClick={() =>
                  openCertificateLinkPopup(
                    value,
                    tableMeta.rowData[columnIndexes.certificate]
                  )
                }
              >
                {value}
              </span>
            );
          } else {
            return <span>{value}</span>;
          }
        },
      },
    },
    {
      name: "certificate",
      label: `${t("common:Tender:tableColumnNames:certification")}`,
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "description",
      label: `${t("common:Tender:tableColumnNames:description")}`,
      options: {
        filter: false,
        sort: false,
        display: false,
        filterType: "textField",
      },
    },
    {
      name: "productDescription",
      label: "",
      options: {
        filter: true,
        sort: true,
        display: false,
        searchable: true,
      },
    },
    {
      name: "carat",
      label: `${t("common:Tender:tableColumnNames:carat")}`,
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: "color",
      label: `${t("common:Tender:tableColumnNames:color")}`,
      options: {
        filter: true,
        sort: true,
        display: true,
        customBodyRender: (dataIndex: any, rowIndex: any) => {
          let val: any = mybidProducts[rowIndex.rowIndex];
          return <>{getAbbreviation(val?.shapeAbbreviation)}</>;
        },
      },
    },
    {
      name: "remarks",
      label: `${t("common:Tender:tableColumnNames:remark")}`,
      options: {
        filter: true,
        sort: false,
        searchable: true,
        filterType: "textField",
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <EllipsisText
              text={getDescription(
                tableMeta.rowData[columnIndexes.parcelType],
                tableMeta.rowData[columnIndexes.productDescription],
                tableMeta.rowData[columnIndexes.remarks]
              )}
              length={20}
              tail={"..."}
            />
          );
        },
      },
    },
    {
      name: "noOfPieces",
      label: `${t("common:Tender:tableColumnNames:noOfPieces")}`,
      options: {
        filter: false,
        sort: true,
        display: true,
        customBodyRender: (value: any, tableMeta: any) => {
          return <>{value !== 0 ? value : "-"}</>;
        },
      },
    },
    {
      name: "shape",
      label: `${t("common:Tender:tableColumnNames:shape")}`,
      options: {
        filter: true,
        sort: true,
        display: true,
        customBodyRender: (dataIndex: any, rowIndex: any) => {
          let val: any = mybidProducts[rowIndex.rowIndex];
          return <>{getAbbreviation(val?.shapeAbbreviation)}</>;
        },
      },
    },
    {
      name: "sieve",
      label: `${t("common:Tender:tableColumnNames:Sieve")}`,
      options: {
        filter: false,
        sort: true,
        display: true,
        customBodyRender: (value: any, tableMeta: any) => {
          return <>{value !== "-" ? value : ""}</>;
        },
      },
    },
    {
      name: "cutGrade",
      label: `${t("common:Tender:tableColumnNames:cutGrade")}`,
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: "clarity",
      label: `${t("common:Tender:tableColumnNames:clarity")}`,
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: "polish",
      label: `${t("common:Tender:tableColumnNames:polish")}`,
      options: {
        filter: false,
        sort: true,
        display: true,
        customBodyRender: (dataIndex: any, rowIndex: any) => {
          let val: any = mybidProducts[rowIndex.rowIndex];
          return <>{getAbbreviation(val?.polishAbbreviation)}</>;
        },
      },
    },
    {
      name: "symmetryAbbreviation",
      label: `${t("common:Tender:tableColumnNames:symmetryAbbreviation")}`,
      options: {
        filter: false,
        sort: true,
        display: true,
        customBodyRender: (dataIndex: any, rowIndex: any) => {
          let val: any = mybidProducts[rowIndex.rowIndex];
          return <>{getAbbreviation(val?.symmetryAbbreviation)}</>;
        },
      },
    },
    {
      name: "flour",
      label: `${t("common:Tender:tableColumnNames:flour")}`,
      options: {
        filter: false,
        sort: true,
        display: true,
        customBodyRender: (dataIndex: any, rowIndex: any) => {
          let val: any = mybidProducts[rowIndex.rowIndex];
          return <>{getAbbreviation(val?.flourAbbreviation)}</>;
        },
      },
    },
    {
      name: "currency",
      label: "currency",
      options: {
        filter: false,
        sort: false,
        display: false,
        customBodyRender: (value: any) => {
          return <div>{value.code}</div>;
        },
      },
    },
    {
      name: "caratMinimumPrice",
      label: `${t("common:Tender:tableColumnNames:pricePct")}`,
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <div>
              {convertedValue(
                tableMeta.rowData[columnIndexes.currency],
                value,
                tableMeta.rowData[columnIndexes.exchangeRate][0].rate
              )}
            </div>
          );
        },
      },
    },
    {
      name: "myLatestBid",
      label: `${t("common:Tender:button:placeBid")}`,
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: { minWidth: "130px", maxWidth: "140px" },
        }),
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <>
              {isDataRefresh ? (
                <div>
                  {value === undefined || value === null
                    ? 0
                    : handelMyBidsLabel(
                        myLatestBidForProduct,
                        tableMeta.rowData[columnIndexes.productId],
                        tableMeta.rowData[columnIndexes.currency],
                        tableMeta.rowData[columnIndexes.exchangeRate][0].rate
                      )}
                </div>
              ) : (
                <div>
                  {value === undefined || value === null
                    ? 0
                    : handelMyBidsLabel(
                        myLatestBidForProduct,
                        tableMeta.rowData[columnIndexes.productId],
                        tableMeta.rowData[columnIndexes.currency],
                        tableMeta.rowData[columnIndexes.exchangeRate][0].rate
                      )}
                </div>
              )}
              {getBidNowButtonDisable(
                tableMeta.rowData[columnIndexes.sellerAuctionUserId],
                props.match.params.auctionUserId,
                tableMeta.rowData[columnIndexes.auctionUserId],
                tableMeta,
                getReturnMyLatestStoredBid(
                  myLatestBidForProduct,
                  tableMeta.rowData[columnIndexes.productId]
                ),
                tableMeta.rowData[columnIndexes.currentBidPrice],
                tableMeta.rowData[columnIndexes.productId],
                tableMeta.rowData[columnIndexes.previousHighestBidder],
                tableMeta.rowData[columnIndexes.holdPreviousBidder]
              )}
            </>
          );
        },
      },
    },
    {
      name: "notes",
      label: `${t("common:Tender:tableColumnNames:notes")}`,
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "currentBidPrice",
      label: `${t("common:Tender:tableColumnNames:liveHighestBid")}`,
      options: {
        filter: false,
        sort: true,
        display: true,
        setCellProps: () => ({
          style: { minWidth: "130px", maxWidth: "140px" },
        }),
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <div>
              {convertedValue(
                tableMeta.rowData[columnIndexes.currency],
                value,
                tableMeta.rowData[columnIndexes.exchangeRate][0].rate
              )}
            </div>
          );
        },
      },
    },
    {
      name: "rapnetPercentage",
      label: `${t("common:Tender:tableColumnNames:rapOff")}`,
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <div>
              {getRapNet(tableMeta.rowData[columnIndexes.rapnetPercentage])}
            </div>
          );
        },
      },
    },
    {
      name: "noOfBidders",
      label: `${t("common:newCommanTrans:noOfBiddersBids")}`,
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: { minWidth: "130px", maxWidth: "140px" },
        }),
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <div>
              {getNoOfBiddersAndNoOfBids(
                tableMeta.rowData[columnIndexes.noOfBids],
                tableMeta.rowData[columnIndexes.noOfBidders]
              )}
            </div>
          );
        },
      },
    },
    {
      name: "rowColor",
      label: "Bid Color",
      options: {
        filter: false,
        sort: true,
        display: false,
      },
    },
    {
      name: "exchangeRates",
      label: "Exchange Rate",
      options: {
        filter: false,
        sort: false,
        display: false,
        customBodyRender: (value: any) => {
          return <div>{value[0].rate}</div>;
        },
      },
    },
    {
      name: "parcelType",
      label: "Category",
      options: {
        filter: true,
        display: false,
      },
    },
    {
      name: "noOfBids",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "watched",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "sellerAuctionUserId",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "rapnetPercentage",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "tenderSecondHighestBid",
      label: `${t("common:Tender:tableColumnNames:liveHighestBid")}`,
      options: {
        filter: false,
        sort: false,
        display: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <div>
              {convertedValue(
                tableMeta.rowData[columnIndexes.currency],
                value,
                tableMeta.rowData[columnIndexes.exchangeRate][0].rate
              )}
            </div>
          );
        },
      },
    },
    {
      name: "previousHighestBidder",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "holdPreviousBidder",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
  ];

  //rediract from bid now button of product details page -------
  const renderAutomaticBidPopup = (data: any) => {
    const rediractProductId: any = localStorage.getItem(
      "currentProductIdOfBidNow"
    );
    if (rediractProductId !== null) {
      const indexOfProduct = mybidProducts.findIndex(
        (item: any) => item.perId === rediractProductId
      );
      rowSelectedData = data.rowData;
      details = indexOfProduct;
      length = mybidProducts.length;
      if (details !== -1) {
        setshowTenderSlider(true);
      }
      localStorage.removeItem("currentProductIdOfBidNow");
    }
  };

  const renderAutomaticBidPopupInMobile = (data: any) => {
    const rediractProductId: any = localStorage.getItem(
      "currentProductIdOfBidNow"
    );
    if (rediractProductId !== null) {
      const indexOfProduct = mybidProducts.findIndex(
        (item: any) => item.perId === rediractProductId
      );
      rowSelectedData = data;
      details = indexOfProduct;
      length = mybidProducts.length;
      if (details !== -1) {
        setshowTenderSlider(true);
      }
      setTimeout(() => {
        localStorage.removeItem("currentProductIdOfBidNow");
      }, 3000);
    }
  };

  //get bid now button disable
  const getBidNowButtonDisable = (
    sellerId: any,
    auctionUserIdForProduct: any,
    tableAuctionUserId: any,
    tableDataForUIBox: any,
    myBidsForProduct: any,
    currentBidPriceForProduct: any,
    currentproductId: any,
    previousBidderId: any,
    holdPreviousBidder: any
  ) => {
    renderAutomaticBidPopup(tableDataForUIBox);
    if (isReadOnlyUser) {
      return (
        <StyledBtnContainer
          onClick={() => {
            renderReadOnlyUserToast(sellerId);
          }}
        >
          <StyledButtonGray>
            {t("common:inquiry:button:bidNow")}
          </StyledButtonGray>
        </StyledBtnContainer>
      );
    } else {
      if (sellerId === auctionUserIdForProduct) {
        return (
          <StyledBtnContainer>
            <StyledButtonGray>
              {t("common:inquiry:button:bidNow")}
            </StyledButtonGray>
          </StyledBtnContainer>
        );
      } else if (holdPreviousBidder !== undefined) {
        if (props.match.params.auctionUserId === holdPreviousBidder) {
          return (
            <StyledBtnContainer>
              <StyledButtonGray>
                {t("common:inquiry:button:bidNow")}
              </StyledButtonGray>
            </StyledBtnContainer>
          );
        } else {
          return (
            <StyledBtnContainer>
              <StyledButton onClick={() => handleBidClick(tableDataForUIBox)}>
                {t("common:inquiry:button:bidNow")}
              </StyledButton>
            </StyledBtnContainer>
          );
        }
      } else if (previousBidderId !== undefined) {
        if (props.match.params.auctionUserId === previousBidderId) {
          return (
            <StyledBtnContainer>
              <StyledButtonGray>
                {t("common:inquiry:button:bidNow")}
              </StyledButtonGray>
            </StyledBtnContainer>
          );
        } else {
          return (
            <StyledBtnContainer>
              <StyledButton onClick={() => handleBidClick(tableDataForUIBox)}>
                {t("common:inquiry:button:bidNow")}
              </StyledButton>
            </StyledBtnContainer>
          );
        }
      } else if (tableAuctionUserId === props.match.params.auctionUserId) {
        return (
          <StyledBtnContainer>
            <StyledButtonGray>
              {" "}
              {t("common:inquiry:button:bidNow")}
            </StyledButtonGray>
          </StyledBtnContainer>
        );
      } else {
        return (
          <StyledBtnContainer>
            <StyledButton onClick={() => handleBidClick(tableDataForUIBox)}>
              {t("common:inquiry:button:bidNow")}
            </StyledButton>
          </StyledBtnContainer>
        );
      }
    }
  };

  const handelMyBidsLabel = (
    myStoredBids: any,
    currentProductId: any,
    currency: any,
    exchangeRate: any
  ) => {
    const Obj = myLatestBidForProduct.filter(
      (item: any) => item.productId === currentProductId
    );
    const mybidPriceforProduct = Obj.map((item: any, index: number) => {
      return item.productId;
    });

    return getMyLatestStoredBids(
      myLatestBidForProduct,
      currentProductId,
      currency,
      exchangeRate,
      mybidPriceforProduct
    );
  };

  const getWatchedProduct = (
    watched: any,
    productId: any,
    auctionId: any,
    serialNo: any
  ) => {
    if (watched === true) {
      return (
        <FontAwesomeIcon
          icon={faHeartProps}
          style={{ color: "red", cursor: "pointer" }}
          onClick={() => removeFromWatchlist(productId, auctionId)}
        />
      );
    } else {
      return (
        <FontAwesomeIcon
          icon={faHeartProps}
          style={{ color: "gray", cursor: "pointer" }}
          onClick={() => addToWatchList(productId, auctionId, serialNo)}
        />
      );
    }
  };

  const addToWatchList = (
    productId: string | number,
    auctionId: string | number,
    serialNo: string | number
  ) => {
    setOnAction(true);
    setOpenToast(false);
    if (!oAuth()) {
      props.history.push("/");
    } else {
      Auth.postRequest(
        `${productUrl}/${auctionId}/${productId}/watch`,
        "payload",
        (status: any, response: any) => {
          if (response !== undefined) {
            if (status === 200) {
              callApi(payload);
              setIsLoading(false);
              setOnAction(false);
              setShowAlertMessage(`${t("common:preview:addedToWatchMsg")}`);
              setAlertType("success");
              setOpenToast(true);
            }
          } else {
            setIsLoading(false);
            setShowAlertMessage(response);
            setOnAction(false);
            setAlertType("error");
            setOpenToast(true);
          }
        }
      );
    }
  };

  const removeFromWatchlist = (
    productId: string | number,
    auctionId: string | number
  ) => {
    setOnAction(true);
    setOpenToast(false);
    if (Auth.loggedIn() === null) {
      props.history.push("/");
    } else {
      Auth.postRequest(
        `${productUrl}/${auctionId}/${productId}/unwatch`,
        "payload",
        (status: any, response: any) => {
          if (status === 200) {
            callApi(payload);
            setIsLoading(false);
            setOnAction(false);
            setShowAlertMessage(response.data.message);
            setAlertType("success");
            setOpenToast(true);
          }
        }
      );
    }
  };

  const shareButtonAction = (perId: any) => {
    const { t } = props;
    let languageCode =
      localStorage.getItem("language") === "EN"
        ? "en"
        : localStorage.getItem("language") === "zh_CN"
        ? "zh_CN"
        : "jp";
    copy(
      `${window.location.hostname}/${languageCode}/product/${perId}/${props.match.params.auctionDate}/detail`
    );
    setAlertType("success");
    setOpenToast(true);
    setShowAlertMessage(`${t("common:preview:linkCopied")}`);
    setTimeout(() => {
      setOpenToast(false);
    }, 2000);
  };

  const getNoOfBiddersAndNoOfBids = (noOfBidders: any, noOfBids: any) => {
    return `${noOfBidders}/${noOfBids}`;
  };

  const getDescription = (
    parcelType: any,
    productDescription: any,
    remarks: any
  ) => {
    if (parcelType === "Diamond") {
      if (remarks !== "" && remarks !== undefined && remarks !== null) {
        return remarks;
      } else {
        return "";
      }
    } else {
      if (
        productDescription !== "" &&
        productDescription !== undefined &&
        productDescription !== null
      ) {
        return productDescription;
      } else {
        return "";
      }
    }
  };

  const closeModal = () => {
    Auth.logout();
    props.history.replace("/");
  };

  const openProductDetail = (
    auctionId: string,
    perId: string,
    auctionDate: string
  ) => {
    localStorage.setItem("auctionUserId", props.match.params.auctionUserId);
    props.history.push(
      `/preview/tender/details/${auctionId}/${perId}/${date[0]}?isAuction=true&uniqid=${props.match.params.auctionUserId}`
    );
  };
  const openDetailsView = (
    auctionId: string,
    perId: string,
    auctionDate: string
  ) => {
    localStorage.setItem("auctionUserId", props.match.params.auctionUserId);
    const language = localStorage.getItem("language");

    const languageCode =
      language === "EN" ? "en" : language === "zh_CN" ? "zh_CN" : "jp";
    window.open(
      `/${languageCode}/preview/tender/details/${auctionId}/${perId}/${date[0]}?isAuction=true&uniqid=${props.match.params.auctionUserId}`,
      "_blank"
    );
  };

  const rightClickEvent = (
    event: React.MouseEvent<HTMLDivElement>,
    productId: string
  ) => {
    event.preventDefault();
    setMouseState({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
    setCurrentProductId(productId);
  };

  const handleClose = () => {
    setMouseState(initialState);
  };

  const bidProduct = (payloadBid: any) => {
    if (!oAuth()) {
      props.history.push("/");
    } else {
      Auth.postRequest(
        tendorBidUrl,
        payloadBid,
        (status: any, response: any) => {
          if (status === 200) {
            setIsDataRefresh(!isDataRefresh);
            getMyLatestBids(props.match.params.auctionId);
          } else if (status === 403) {
            setUserBlock(true);
          } else if (status === 505) {
            setApiTimeout(true);
            setOnAction(false);
          } else if (status === 404) {
            setIsLoading(false);
            setOnAction(false);
            setShowAlertMessage(`${t("common:invalidRequest")}`);
            setAlertType("error");
            setOpenToast(true);
          } else {
            setIsLoading(false);
            setOnAction(false);
            setShowAlertMessage(response[0].message);
            setAlertType("error");
            setOpenToast(true);
          }
        }
      );
    }
  };

  const exportExcel = () => {
    let exportProductIds = mybidProducts.slice(0, 1).map((item: any) => {
      return item.auctionId;
    });
    Auth.postRequestForExcel(
      `${productDownload}?auctionId=${exportProductIds}&auctionProductsType=mybids`,
      { ids: "" },
      (status: any, response: any) => {
        if (status === 200) {
          if (response !== undefined) {
            const url = window.URL.createObjectURL(
              new Blob([response], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `Products.xlsx`);
            document.body.appendChild(link);
            link.click();
          }
        } else {
          //todo
        }
      }
    );
  };

  const ShowAllProduct = (auctionId: string | number, currentPage: number) => {
    if (uiBoxView) {
      getBody(auctionId, 0, 1000, true);
    } else {
      getBody(auctionId, 0, 1000, false);
    }
  };

  const getLeftTime = (startDate: any) => {
    let date: string | null = "";
    let time: string | null = "";
    let hour: any = "";
    let minutes: any = "";
    let timeDifference: any = "";
    let timezone: any = "";

    if (startDate !== null || startDate !== undefined) {
      let previewTime = moment.utc(startDate);
      if (timezone !== null) {
        timezone = localStorage.getItem("timezoneName");
      }
      if (previewTime !== null || previewTime !== undefined) {
        let result = moment.tz(previewTime, timezone);
        let resultFormatter = result.format();
        if (resultFormatter !== "Invalid date") {
          let splitDateTime = resultFormatter.split("T", 2);
          let splitTime: any = "";
          if (splitDateTime[1].includes("+")) {
            splitTime = splitDateTime[1].split("+", 2);
          } else {
            splitTime = splitDateTime[1].split("-", 2);
          }
          date = splitDateTime[0];
          time = splitTime[0];
          let dateTime = `${date} ${time}`;
          //let start: any = moment(dateTime);
          let end: any = moment(dateTime);
          timezone = localStorage.getItem("timezoneName");
          //let end: any = moment(new Date().toLocaleString("en-US", { timeZone: timezone }));
          let start: any = moment(
            new Date().toLocaleString("en-US", { timeZone: timezone })
          );
          hour = start.diff(end, "hours");
          minutes = start.diff(end, "minutes");
          timeDifference = localStorage.getItem("timeDifference");
          timeDifference = timeDifference / 1000;
          timeDifference = parseInt(timeDifference);
          //var timeDiff = start - end;
          var timeDiff = end - start;
          seconds = Math.round(timeDiff);
          {
            timeDifference[0] === "-"
              ? (seconds = seconds - timeDifference)
              : (seconds = seconds + timeDifference);
          }
        }
      }
    }
    setTimeout(() => {
      getLeftTime(startDate);
    }, 1000);
  };

  const renderer = ({
    formatted: { days, hours, minutes, seconds },
    completed,
  }: any) => {
    if (completed) {
      if (
        days === "00" &&
        hours === "00" &&
        minutes === "00" &&
        seconds === "00"
      ) {
        getTimePopup();
        return <span>00:00:00:00</span>;
      }
    } else {
      return (
        <span>
          {days}:{hours}:{minutes}:{seconds}
        </span>
      );
    }
  };

  const getTimePopup = () => {
    if (auctionsState === "Started" && firstTimeExetendPopup === false) {
      setOpenTenderExtended(true);
    }
    setTimeout(() => {
      getAuctionEndTime(props.match.params.auctionId);
    }, 2400);
  };

  const TenderTimeExtended = () => {
    getAllUpdatedData();
    getTendorMybidsAucProducts(props.match.params.auctionId, 0, false);
    setOpenTenderExtended(false);
  };

  const TenderauctionEndedModal = () => {
    props.history.push("/auction");
  };

  const setItemsPerPageHandler = (itemsPerPage: number) => {
    setCurrentPage(1);
    itemsPerPageUseRef.current = itemsPerPage;
    startCount = 0;
    setItemsPerPage(itemsPerPage);
    localStorage.removeItem("currentStart");
  };

  function setCurrentPage(currentPage: number) {
    return currentPage;
  }

  function setItemsPerPage(itemsPerPage: number) {
    return itemPerPage(true, 0, itemsPerPage);
  }
  const itemPerPage = (value: boolean, start: number, itemPerPage: number) => {
    if (value === true) {
      localStorage.setItem("tenderitemsPerPage", JSON.stringify(itemPerPage));
      getUpdatedProducts(value, start, itemPerPage);
    }
  };

  const getUpdatedProducts = (value: any, start: any, endRecords: any) => {
    if (value === true) {
      getProducts(start, endRecords);
    }
  };

  const getProducts = (start: number, end: number) => {
    startCount = start;
    endCountmob = end;
    localStorage.setItem("currentStart", JSON.stringify(start));
    let Page: any = localStorage.getItem("currentPageOfTenderAllProducts");
    getBody(props.match.params.auctionId, parseInt(Page), endCountmob, true);
    setIsSelected(true);
    PubSub.publish("resetGridBoxIndex", []);
    dispatch(resetTenderDiamondGridProductIndexes());
    dispatch(resetTenderDiamondSelectAll());
  };

  const closebidPopup = () => {
    setshowTenderSlider(false);
    setShowBulkBidPopup(false);
  };

  const watchlist = () => {
    localStorage.removeItem("selectedFilterSort");
    props.history.push("/my-page");
  };

  const listGridview = () => {
    selectPageUseRef.current = 0;
    localStorage.removeItem("tenderitemsPerPage");
    localStorage.removeItem("currentStart");
    setIsLoading(true);
    onGridSelectionChange(0);
    PubSub.publish("resetGridBoxIndex", []);
    getBody(props.match.params.auctionId, 0, endCountmob, true);
    setUiBoxView(true);
    setOpenToast(false);
    dispatch(resetTenderIsGridView());
  };

  const Gridview = () => {
    dispatch(setTenderIsGridView({ gridView: false }));
    setIsLoading(true);
    setOpenToast(false);
    onGridSelectionChange(0);
    PubSub.publish("resetGridBoxIndex", []);
    getBody(props.match.params.auctionId, 0, 100, false);
    setUiBoxView(false);
  };

  const handleMyBids = (mybid: any) => {
    //todo
  };

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  const isSticky = (e: any) => {
    const header: any = document.querySelector(".sticky-actionbar");
    const scrollTop = window.scrollY;
    const className =
      scrollTop >= 520
        ? header?.classList.add("is-sticky")
        : header?.classList.remove("is-sticky");
  };

  useEffect(() => {
    let isShowDrawerIcon: any = localStorage.getItem("isShowDrawerIcon");
    isShowDrawerIcon = JSON.parse(isShowDrawerIcon);
    if (isShowDrawerIcon !== null) {
      if (isShowDrawerIcon) {
        const accordionHeader: any = document.querySelector(".AccordionHeader");
        if (accordionHeader !== null) {
          accordionHeader.style.width = isShowDrawerIcon
            ? "calc(100% - 60px)"
            : "calc(100% - 240px)";
        }
      }
    }
  });

  const renderReadOnlyUserToast = async (id: any) => {
    var joinedAuctions: any = localStorage.getItem("joinedAuctions");
    joinedAuctions = JSON.parse(joinedAuctions);
    if (joinedAuctions !== null) {
      joinedAuctions.filter((items: any, index: number) => {
        if (id === items.auctionUserId) {
          isAuctionUserId = true;
        }
      });
    } else {
      if (id === props.match.params.auctionUserId) {
        isAuctionUserId = true;
      } else {
        isAuctionUserId = false;
      }
    }
    await renderPopup();
  };

  const renderPopup = () => {
    if (isReadOnlyUser) {
      setOpenReadOnlyUserToast(true);
    } else if (isAuctionUserId) {
      setOpenReadOnlyUserToast(true);
    } else {
      setOpenReadOnlyUserToast(false);
    }
    setTimeout(() => {
      setOpenReadOnlyUserToast(false);
    }, 3000);
  };

  const colorMybidsFilter = (e: any) => {
    setColorMybidsName(e.target.value);
    const value = e.target.value;
    selectPageUseRef.current = 0;
    localStorage.setItem("currentStart", "0");
    dispatch(setColorFilter({ color: value }));
  };

  useEffect(() => {
    let totalpay = 0;
    // return filtred array
    let filtredArr =
      colorMybidsName !== "ALL"
        ? mybidProducts?.filter((x: any) => x.colorFilter === colorMybidsName)
        : mybidProducts;
    //calculate total sum of current Bid Price
    filtredArr?.map((x: any) => {
      totalpay = totalpay + x.currentBidPrice;
    });
    bufferTotalPay.current = totalpay;
    setTotalAmount(totalpay);
  }, [colorMybidsName]);

  useEffect(() => {
    let innerwidth: any = window.innerWidth;
    if (innerwidth > 700) {
      if (isGridView) {
        getTendorMybidsAucProducts(props.match.params.auctionId, 0, true);
      } else {
        getBody(props.match.params.auctionId, 0, 100, false);
      }
    } else {
      getTendorMybidsAucProducts(props.match.params.auctionId, 0, true);
    }
  }, [selectedColor]);

  const selectAllInGridView = () => {
    let arr: any = [];
    mybidProducts.map((productVal: any, index: any) => {
      if (arr.length < 10) {
        if (isReadOnlyUser === false) {
          if (
            productVal.sellerAuctionUserId === props.match.params.auctionUserId
          ) {
            //
          } else if (productVal.holdPreviousBidder !== undefined) {
            if (
              props.match.params.auctionUserId === productVal.holdPreviousBidder
            ) {
              ///
            } else {
              arr = [...arr, index];
            }
          } else if (productVal.previousBidderId !== undefined) {
            if (
              props.match.params.auctionUserId === productVal.previousBidderId
            ) {
              //
            } else {
              arr = [...arr, index];
            }
          } else if (
            productVal.auctionUserId === props.match.params.auctionUserId
          ) {
            //
          } else {
            if (productVal.auctionUserId === props.match.params.auctionUserId) {
              //
            } else {
              arr = [...arr, index];
            }
          }
        }
      }
    });

    if (isSelected) {
      setIsSelected(false);
      PubSub.publish("resetGridBoxIndex", []);
      arr = [];
      dispatch(resetTenderDiamondGridProductIndexes());
      dispatch(resetTenderDiamondSelectAll());
      // PubSub.publish("jewellerySelectAllCheckBox", { isItemSelected: false, selectedIndexes: arr });
    } else {
      setIsSelected(true);
      dispatch(setTenderDiamondGridProductIndexes({ indexes: arr }));
      dispatch(setTenderDiamondSelectAll({ isSelected: true }));
      // PubSub.publish("jewellerySelectAllCheckBox", { isItemSelected: true, selectedIndexes: arr });
    }
  };
  return (
    <StyledLayoutGrid>
      {isLoading === true ? (
        <div className="loader">
          <LoadingSpinner />
        </div>
      ) : (
        <StyledLayoutGridMain>
          <div style={{ width: "100%", display: "block" }}>
            <StyledHeaderContainer container={true} spacing={0}>
              <StyledCoreDataGridContainer container={true} spacing={0}>
                <StyledActionBarContainer
                  container={true}
                  spacing={0}
                  className="coreActionBarContainer"
                >
                  <StyledContainer>
                    {notSmallDevices ? (
                      <>
                        {isGridView === true ? (
                          <StyledSelectAllButtonHeader
                            onClick={selectAllInGridView}
                          >
                            {t("common:newCommanTrans:selectAll")}
                          </StyledSelectAllButtonHeader>
                        ) : null}
                      </>
                    ) : (
                      <StyledSelectAllButtonHeader
                        onClick={selectAllInGridView}
                      >
                        {t("common:newCommanTrans:selectAll")}
                      </StyledSelectAllButtonHeader>
                    )}
                    <StyledPageSizeSelectorContainer>
                      <select
                        name="sortingControl"
                        onChange={colorMybidsFilter}
                        value={colorMybidsName}
                      >
                        <option value="ALL">----My Bids---</option>
                        <option value="ALL">
                          {t("common:colorFilter:all")}
                        </option>
                        <option value="PINK">
                          {t("common:colorFilter:pink")}
                        </option>
                        <option value="ORANGE">
                          {t("common:colorFilter:orange")}
                        </option>
                        <option value="WHITE">
                          {t("common:colorFilter:white")}
                        </option>
                      </select>
                    </StyledPageSizeSelectorContainer>

                    <StyledButtonHeader onClick={watchlist}>
                      {/* {t("common:menu:watchlist")} */}
                      My-page
                    </StyledButtonHeader>
                    <StyledButtonHeader onClick={exportExcel}>
                      {t("common:preview:pagination:export")}
                    </StyledButtonHeader>
                  </StyledContainer>
                  <StyledContainer>
                    {showBulkBidButton === true ? (
                      <StyledButtonHeader onClick={bulkBidPopupHandler}>
                        {t("common:preview:tendor:bulkBid")}
                      </StyledButtonHeader>
                    ) : (
                      <></>
                    )}
                  </StyledContainer>
                  {notSmallDevices ? (
                    <>
                      {isGridView === true ? (
                        <ActionTenderPagesize
                          setItemsPerPageHandler={setItemsPerPageHandler}
                          t={t}
                        />
                      ) : null}
                    </>
                  ) : (
                    <ActionTenderPagesize
                      setItemsPerPageHandler={setItemsPerPageHandler}
                      t={t}
                    />
                  )}

                  {notSmallDevices ? (
                    <>
                      {isGridView === true ? (
                        <StyledButtonView onClick={Gridview}>
                          <Styledview src="/images/view/listView.png" />
                        </StyledButtonView>
                      ) : (
                        <StyledButtonView onClick={listGridview}>
                          <Styledview src="/images/view/gridview.jpeg" />
                        </StyledButtonView>
                      )}
                    </>
                  ) : null}
                  <StyledButtonViewFilter onClick={filterColorSort}>
                    <ColorLensIcon />
                  </StyledButtonViewFilter>
                </StyledActionBarContainer>
              </StyledCoreDataGridContainer>
            </StyledHeaderContainer>
          </div>
          <StyledLayoutGrid item={true} xs={12}>
            <div style={{ float: "right", width: "100%", display: "flex" }}>
              {t("common:newCommanTrans:totalAmounts")}:{" "}
              <span style={{ color: "green" }}>{totalAmountLabel}</span>
            </div>

            <div>
              {mybidProducts.length > 0 ? (
                <>
                  {isGridView === false && notSmallDevices && (
                    <MuiThemeProvider theme={getMuiTheme()}>
                      <MUIDataTable
                        title={""}
                        data={mybidProducts}
                        columns={columns}
                        options={options}
                      />
                    </MuiThemeProvider>
                  )}

                  {isGridView === true
                    ? notSmallDevices && (
                        <>
                          <CoreDataGridOfTender
                            t={t}
                            data={mybidProducts}
                            totalElements={totalElement}
                            itemPerPage={itemPerPage}
                            value={getUpdatedProducts}
                            noProductFound={handleMyBids}
                            itemsPerPageUseRef={itemsPerPageUseRef.current}
                            selectPageUseRef={selectPageUseRef.current}
                          >
                            {mybidProducts.length > 0 ? (
                              <TenderListOfProductMybids
                                {...props}
                                watchlist={addToWatchList}
                                setShowBidPopupBidModal={getBoxIndex}
                                isCategoryPageType={isCategoryPageType}
                                auctionName={props.match.params.auctionName}
                                auctionDate={date[0]}
                                t={t}
                                isAuctionPage={isAuctionPage}
                                boxColor={boxColor}
                                unWatchlist={removeFromWatchlist}
                                auctionUserId={props.match.params.auctionUserId}
                                sortingValue={filterSort}
                                bulkBidIndexHandler={onGridSelectionChange}
                                myForProductAllProduct={myLatestBidForProduct}
                                renderAutomaticBidPopupInMobile={
                                  renderAutomaticBidPopupInMobile
                                }
                                renderReadOnlyUserToast={
                                  renderReadOnlyUserToast
                                }
                                rightClickEvent={rightClickEvent}
                              />
                            ) : (
                              <Grid container={true} spacing={0}>
                                <StyledTextWrapper item xs={12}>
                                  {t("common:preview:noProductFound")}
                                </StyledTextWrapper>
                              </Grid>
                            )}
                          </CoreDataGridOfTender>
                        </>
                      )
                    : null}
                  {notSmallDevices || (
                    <>
                      <CoreDataGridOfTender
                        t={t}
                        data={mybidProducts}
                        totalElements={totalElement}
                        itemPerPage={itemPerPage}
                        value={getUpdatedProducts}
                        noProductFound={handleMyBids}
                        itemsPerPageUseRef={itemsPerPageUseRef.current}
                        selectPageUseRef={selectPageUseRef.current}
                      >
                        {mybidProducts.length > 0 ? (
                          <TenderListOfProductMybids
                            {...props}
                            watchlist={addToWatchList}
                            setShowBidPopupBidModal={getBoxIndex}
                            isCategoryPageType={isCategoryPageType}
                            auctionName={props.match.params.auctionName}
                            auctionDate={date[0]}
                            t={t}
                            isAuctionPage={isAuctionPage}
                            boxColor={boxColor}
                            unWatchlist={removeFromWatchlist}
                            auctionUserId={props.match.params.auctionUserId}
                            sortingValue={filterSort}
                            bulkBidIndexHandler={onGridSelectionChange}
                            myForProductAllProduct={myLatestBidForProduct}
                            renderAutomaticBidPopupInMobile={
                              renderAutomaticBidPopupInMobile
                            }
                            renderReadOnlyUserToast={renderReadOnlyUserToast}
                            rightClickEvent={rightClickEvent}
                          />
                        ) : (
                          <Grid container={true} spacing={0}>
                            <StyledTextWrapper item xs={12}>
                              {t("common:preview:noProductFound")}
                            </StyledTextWrapper>
                          </Grid>
                        )}
                      </CoreDataGridOfTender>
                    </>
                  )}
                  {notSmallDevices
                    ? isGridView === false && (
                        <StyeldShowAllcontainer container={true} spacing={0}>
                          {totalElement > testno ? (
                            <StyledshowAllButton>
                              <StyledButton
                                onClick={() =>
                                  ShowAllProduct(
                                    props.match.params.auctionId,
                                    0
                                  )
                                }
                              >
                                {t("common:Tender:button:showAll")}
                              </StyledButton>
                            </StyledshowAllButton>
                          ) : (
                            <></>
                          )}
                        </StyeldShowAllcontainer>
                      )
                    : null}
                </>
              ) : (
                <Grid
                  container={true}
                  spacing={0}
                  style={{ marginTop: "20px" }}
                >
                  {notSmallDevices ? (
                    <MuiThemeProvider theme={getMuiTheme()}>
                      <MUIDataTable
                        title={""}
                        data={mybidProducts}
                        columns={columns}
                        options={options}
                      />
                    </MuiThemeProvider>
                  ) : (
                    <StyledTextWrapper item xs={12}>
                      {t("common:preview:noProductFound")}
                    </StyledTextWrapper>
                  )}
                </Grid>
              )}
            </div>
            <Menu
              keepMounted
              open={mouseState.mouseY !== null}
              onClose={handleClose}
              anchorReference="anchorPosition"
              anchorPosition={
                mouseState.mouseY !== null && mouseState.mouseX !== null
                  ? { top: mouseState.mouseY, left: mouseState.mouseX }
                  : undefined
              }
            >
              <MenuItem
                onClick={() =>
                  openDetailsView(
                    props.match.params.auctionId,
                    currentProductId,
                    props.match.params.auctionDate
                  )
                }
              >
                Details
              </MenuItem>
            </Menu>
            {openToast === true && (
              <Alert
                showAlertMessage={showAlertMessage}
                alertType={alertType}
              />
            )}
            {onAction === true && (
              <div className="loading">
                <LoadingSpinner />
              </div>
            )}
            {userBlock === true && (
              <StyledModal open={userBlock}>
                <BlockUserModal t={t} {...props} closeModal={closeModal} />
              </StyledModal>
            )}

            {openEndedModal === true && (
              <StyledModal
                open={openEndedModal}
                onClose={TenderauctionEndedModal}
              >
                <ViewEndedModal
                  t={t}
                  {...props}
                  closeModal={TenderauctionEndedModal}
                />
              </StyledModal>
            )}
            {openTenderExtended === true && (
              <StyledModal
                open={openTenderExtended}
                onClose={TenderTimeExtended}
              >
                <ViewTenderExtendedModal
                  t={t}
                  {...props}
                  closeModal={TenderTimeExtended}
                />
              </StyledModal>
            )}
            {showTenderSlider === true && (
              <StyledModal open={showTenderSlider} onClose={closebidPopup}>
                <TenderSlider
                  t={t}
                  {...props}
                  items={mybidProducts}
                  currentIndex={details}
                  sec={seconds}
                  timeRender={renderer}
                  PopupClose={closebidPopup}
                  handleBtnValue={handleBtnValue}
                  isFirstButtonDisables={isFirstButtonDisable}
                  auctionDate={date[0]}
                  totalElements={totalElement}
                  endCount={endCount}
                  auctionUserId={props.match.params.auctionUserId}
                  length={length}
                  watchedFunction={addToWatchList}
                  unwatched={removeFromWatchlist}
                  boxColor={boxColor}
                />
              </StyledModal>
            )}
            {showBulkBidPopup === true && (
              <StyledModal open={showBulkBidPopup} onClose={closebidPopup}>
                <BulkBidPopup
                  {...props}
                  items={mybidProducts}
                  popupClose={closebidPopup}
                  handleBtnValue={bulkBidHandler}
                  auctionUserId={props.match.params.auctionUserId}
                  length={length}
                  productIndexs={indexsForBulkBidItems}
                  myLatestBidForProducts={myLatestBidForProduct}
                />
              </StyledModal>
            )}
            {openReadOnlyUserToast === true && (
              <AlertReadOnlyUser
                isReadOnlyUser={isReadOnlyUser}
                isSeller={isAuctionUserId}
              />
            )}
          </StyledLayoutGrid>
        </StyledLayoutGridMain>
      )}
    </StyledLayoutGrid>
  );
};

export default TendorMybids;
