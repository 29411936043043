import React, { ChangeEvent, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import { TextField } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import {
  clockNotInSyncUrl,
  joinedAuctionsUrl,
  sendLoginOTP,
  tokenUrl,
} from "../../../common/config/app.endpoints";
import LoadingSpinner from "../../../common/elements/LoadingSpinner";
import {
  StyledTextInput,
  StyledButton,
  StyledTitleText,
} from "../../../common/materials/GenericStyleComponent";
import { dateBackgroundGradient } from "../../../common/materials/LinearGradient";
import AuthService from "../../../components/auth/AuthService";
import { useTranslation } from "react-i18next";
import { getProfileData, getTime } from "../../../common/GenericFunctions";
import { useHistory } from "react-router";
import CancelIcon from "@material-ui/icons/Cancel";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { getLiveNotificationsFun } from "../../../redux/slice/notificationLive/notificationLiveSlice";

const faInfoCircleProps = faInfoCircle as IconProp;

const StyledComponent = styled.div`
  outline: none;
  #container {
    position: relative;
  }

  #x {
    position: absolute;
    top: 7px;
    right: 4px;
    cursor: pointer;
  }
`;

const StyledContainerWrapper = styled.div`
  background: ${(props) => props.theme.colors.base.white};
  width: 400px;
  padding: 1em;
  border-radius: 10px;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    width: 300px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    width: 400px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    width: 450px;
  }
  .onClickButton {
    cursor: default;
    background: ${dateBackgroundGradient};
  }
`;

const StyledButtonContainer = styled.div`
  text-align: center;
  // margin: 20px 0;
`;

const StyledMessage = styled.div`
  color: ${(props) => props.theme.colors.base.red};
  font-size: 12px;
  margin: 10px 0px;
  margin-left: 1.2em;
`;

const StyledOTPNote = styled(Grid)`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 10px 15px;
  border-radius: 5px;
  background: ${(props) => props.theme.colors.base.veryLightGrey};
  gap: 10px;
`;

const StyledNoteText = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.base.black};
`;

const StyledtimerWrapper = styled(Grid)`
  padding: 0.5em 1em;
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const StyledotpTextContainer = styled.span`
  background: ${(props) => props.theme.colors.base.transparent};
  color: #4a95f0;
  font-family: "Calibre-Regular";
  font-size: 1em;
  cursor: pointer;
`;
const StyledForgotPassword = styled.div`
  color: ${(props) => props.theme.colors.base.textColor};
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 0.8em;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    font-size: 0.8em;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    font-size: 1em;
  }
  cursor: pointer;
  padding-top: 1.5em;
  padding-left: 1.5em;
  font-weight: bold;
`;

const StyledRegisterWrapper = styled.div`
  color: ${(props) => props.theme.colors.base.black};
  padding: 0.5em;
  padding-left: 1.5em;
  padding-bottom: 1em;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 0.8em;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    font-size: 0.8em;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    font-size: 1em;
  }
`;

const StyledRegisterNow = styled.span`
  cursor: pointer;
  color: ${(props) => props.theme.colors.base.textColor};
`;

const StyledTitle = styled.div`
    color:${(props) => props.theme.colors.base.black}; 
    padding: 0.5em  1em;
    
    font-size: 2.5em;
`;


interface IProps {
  forgotPasswordModal: () => void;
  registerModal: () => void;
  oncloseModal: () => void;
  loginButtonHandler: () => void;
  isFromSubDomain: boolean;
  updateValue: any;
  subdomainfunc: (value: any) => void;
}

const LoginWithOtp = (props: IProps) => {
  const { isFromSubDomain, forgotPasswordModal, registerModal, oncloseModal,loginButtonHandler, updateValue } =
    props;
  const { t } = useTranslation();
  let history = useHistory();

  const [email, setEmail] = useState<string>("");
  const [otp, setOtp] = useState("");
  const [message, setMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [showNewPasswordPopup, setShowNewPasswordPopup] = useState(false);
  const [isResend, setIsResend] = useState(false);
  const Auth = new AuthService();

  // Used to format left time
  const formatNumber = (number: any) => `0${number}`.slice(-2);

  // Used to get remaining time
  const getRemaining = (time: any) => {
    const mins: any = Math.floor(time / 60);
    const secs: any = time - mins * 60;
    return { mins: formatNumber(mins), secs: formatNumber(secs) };
  };

  const [reaminingSecs, setReaminingSecs] = useState(1800);
  const { mins, secs } = getRemaining(reaminingSecs);

  useEffect(() => {
    if(updateValue !== undefined){
      const { email } = updateValue;
      setEmail(email);
    }
  }, []);

  // Start timer for 30 mins for enable resend otp
  useEffect(() => {
    let interval: any = null;

    interval = setInterval(() => {
      setReaminingSecs((reaminingSecs) => reaminingSecs - 1);
    }, 1000);

    if (reaminingSecs === 0) {
      setIsResend(true);
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [reaminingSecs]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const targeName = e.target.name;
    const targeValue = e.target.value;
    if (targeName === "email") {
      setEmail(targeValue);
      localStorage.setItem("changePlanEmail", targeValue);
    }
    if (e.target.name === "otp") {
      setOtp(e.target.value);
    }
  };

  const handleFormSubmit = (e: any) => {
    e.preventDefault();
    let digits = /^[0-9]+$/;
     localStorage.setItem("otpAuth", "true"); 
    if (email === "" && otp === "") {
      setMessage(`${t("common:allValidations:emptyFields")}`);
    } else if (email === "") {
      setMessage(`${t("common:register:errorMessage:onEmail")}`);
    } else if (!otp.match(digits)) {
      setIsLoading(false);
      setMessage(`${t("common:profile:forgotPassword:validation:correctOTP")}`);
    } else {
      setIsLoading(true);
      const data = {
        username: email,
        password: otp,
        type: "OTP",
      };
      localStorage.setItem("email", email);
      Auth.postRequestWithoutAuthorization(
        tokenUrl,
        data,
        (status: number, response: any) => {
          if (status === 200) {
            Auth.setToken(response.token);
            localStorage.setItem("otpAuth", "true");
            joinedAuction();
          } else if (status > 200 && status < 500) {
            if (response[0].code !== undefined) {
              if (response[0].code === "KB412003") {
                setIsLoading(false);
                setMessage(
                  `${t("common:profile:forgotPassword:validation:invalidOTP")}`
                );
              } else if (response[0].code === "KB409001") {
                setMessage(
                  `${t("common:register:errorMessage:profileVerification")}`
                );
                setIsLoading(false);
              } else if (response[0].code === "KB412004") {
                setMessage(
                  `${t("common:login:validation:invalidEmailorPassword")}`
                );
                setIsLoading(false);
              } else if (response[0].code === "KB401002") {
                setMessage(
                  `${t("common:login:validation:registeredAsAuctioneer")}`
                );
                setIsLoading(false);
              } else if (response[0].code === "KB404001") {
                setMessage(`${t("common:login:validation:userNotRegistered")}`);
                setIsLoading(false);
              } else if (response[0].code === "KB401004") {
                setMessage(
                  ``
                );
                setIsLoading(false);
                props.subdomainfunc(response[0].field);
              } else if (response[0].code === "KB401005") {
                setMessage(`You don't have any invitation to access.`);
                setIsLoading(false);
              } else {
                setMessage(`${t("common:invalidRequest")}`);
                setIsLoading(false);
              }
            }
          } else if (status === 500) {
            setMessage(response[0].message);
            setIsLoading(false);
          }
          setIsLoading(false);
        }
      );
    }
  };

  const redirectToApp = () => {
    history.push("/home");
  };

  const resendOtp = () => {
    Auth.postRequestWithoutAuthorization(
      `${sendLoginOTP}?un=${email}`,
      "data",
      (status: number, response: any) => {
        if (status === 200) {
          setIsResend(false);
          setReaminingSecs(1800);
        } else if (status > 200 && status < 500) {
          if (response[0].code === "KB404001") {
            setIsLoading(false);
            setMessage(
              `${t(
                "common:profile:forgotPassword:validation:notRegisteredEmail"
              )}`
            );
          }
        } else {
          setIsLoading(false);
        }
      }
    );
  };

  const joinedAuction = () => {
    getTime();
    getProfileData();
    Auth.getRequest(joinedAuctionsUrl, (status: any, response: any) => {
      setIsLoading(false);
      let clockSynced = localStorage.getItem("clockNotSynced");
      if (clockSynced === "true") {
        let time: any = localStorage.getItem("timeInMilliSeconds");
        time = parseInt(time);
        Auth.postRequest(`${clockNotInSyncUrl}${time}`, "data", () => {});
      }
      if (status === 200) {
        if (response !== undefined) {
          var pathname = localStorage.getItem("pathname");
          localStorage.setItem("otpAuth", "true");
          localStorage.removeItem("changePlanEmail");
          if (response.results.length > 0) {
            const urlRedirectFromEmail = localStorage.getItem(
              "urlRedirectFromEmail"
            );
            const urlRedirectFromAdmin = localStorage.getItem(
              "urlRedirectFromAdmin"
            );
            const urlPendingProductFromEmail = localStorage.getItem(
              "urlPendingProductFromEmail"
            );
            const urlProductAuctionFromEmail = localStorage.getItem(
              "urlProductAuctionFromEmail"
            );


            if (
              urlRedirectFromEmail !== undefined &&
              urlRedirectFromEmail !== "" &&
              urlRedirectFromEmail !== null
            ) {
              pathname = urlRedirectFromEmail;
            }
            if (
              urlRedirectFromAdmin !== undefined &&
              urlRedirectFromAdmin !== "" &&
              urlRedirectFromAdmin !== null
            ) {
              pathname = urlRedirectFromAdmin;
            }
            if (
              urlPendingProductFromEmail !== undefined &&
              urlPendingProductFromEmail !== "" &&
              urlPendingProductFromEmail !== null
            ) {
              pathname = urlPendingProductFromEmail;
            }

            if (
              urlProductAuctionFromEmail !== undefined &&
              urlProductAuctionFromEmail !== "" &&
              urlProductAuctionFromEmail !== null
            ) {
              pathname = urlProductAuctionFromEmail;
            }
            //redirect path for route
            let isSubDomainUser = localStorage.getItem("subDomain");
            let redireactionalUrl = localStorage.getItem("pathname");
            let isSubdomainPreview =
              `/${isSubDomainUser}/preview` === redireactionalUrl;

            if (isSubdomainPreview) {
              pathname = `/preview`;
            }
            
            var redirectPath = pathname;
            if (redirectPath) {
              history.push(redirectPath);
            } else {
              if (pathname) {
                const pathArray = pathname.split("/");
                if (
                  pathArray[1].includes("KycForm") ||
                  pathArray[1].includes("PaymentForm") ||
                  pathArray[1].includes("paymentForm")
                ) {
                  redirectPath = "/";
                  localStorage.setItem("pathname", redirectPath);
                }
              }
              redirectToApp();
            }
          } else {
            if (pathname === "/365products") {
              redirectPath = "/365products";
              localStorage.setItem("pathname", redirectPath);
            } else if (pathname === "/365products/jewellery") {
              redirectPath = "/365products/jewellery";
              localStorage.setItem("pathname", redirectPath);
            } else if (pathname === "/365products/Diamonds") {
              redirectPath = "/365products";
              localStorage.setItem("pathname", redirectPath);
            } else if ((pathname = `/preview`)) {
              redirectPath = "/365products";
              localStorage.setItem("pathname", redirectPath);
            } else {
              redirectToApp();
            }
          }
          resetValue();
        }
      } else {
        history.push("/home");
      }
      localStorage.removeItem("optionValue");
    });
  };

  const resetValue = () => {
    let email = document.getElementById("email") as HTMLInputElement;
    if (email) {
      email.value = "";
      setEmail("");
    }
  };

  const renderSetNewPasswordPopup = () => {
   return( <div>hellow</div>)
  };

  return (
    <StyledComponent tabIndex={-1}>
      <form onSubmit={handleFormSubmit} noValidate={true}>
        <StyledContainerWrapper id="container">
          <div
            id="x"
            onClick={() => {
              oncloseModal();
            }}
          >
            <CancelIcon />
          </div>
          <StyledTitle className="fontBold">
            <StyledTitleText>{t("common:login:title")}</StyledTitleText>
          </StyledTitle>
          <Grid style={{ padding: "0.5em 1em" }}>
            <StyledOTPNote item={true} xs={12}>
              <FontAwesomeIcon icon={faInfoCircleProps} />
              <StyledNoteText style={{ fontSize: "10px" }}>
                {t("common:profile:forgotPassword:note:newOtpNote")}
              </StyledNoteText>
            </StyledOTPNote>
          </Grid>
          <Grid item={true} xs={12}>
            <StyledMessage>{message}</StyledMessage>
          </Grid>
          <StyledTextInput>
            <TextField
              required
              disabled
              fullWidth
              name="email"
              autoFocus={true}
              id="user_email"
              label={t("common:login:labels:email")}
              type="text"
              variant="outlined"
              value={email}
              onChange={handleChange}
            />
          </StyledTextInput>
          <StyledTextInput>
            <TextField
              fullWidth
              required
              id="otp"
              label={t("common:profile:forgotPassword:labels:otp")}
              variant="outlined"
              name="otp"
              inputProps={{ maxLength: 6 }}
              onChange={handleChange}
            />
          </StyledTextInput>
          <StyledtimerWrapper>
            <StyledotpTextContainer
              onClick={() => {
                loginButtonHandler();
              }}
            >
              {t("common:button:passwordSignIn")}
            </StyledotpTextContainer>
            <div style={{ display: "flex" }}>
              {isResend ? (
                <StyledotpTextContainer
                  onClick={() => resendOtp()}
                  style={{ textDecoration: "underline", textAlign: "end" }}
                >
                  {t("common:button:resendOtp")}
                </StyledotpTextContainer>
              ) : (
                <StyledotpTextContainer style={{ textAlign: "end" }}>
                  {t("common:button:resendOtpText")} {`${mins}:${secs}`} &nbsp;
                  {t("common:button:resendOtpMinutes")}
                </StyledotpTextContainer>
              )}
            </div>
          </StyledtimerWrapper>
          <StyledButtonContainer>
            {isLoading === false ? (
              <StyledButton onSubmit={handleFormSubmit}>
                {" "}
                {t("common:button:verify")}
              </StyledButton>
            ) : (
              <StyledButton className="onClickButton">
                {" "}
                {t("common:button:verify")}
              </StyledButton>
            )}
          </StyledButtonContainer>
          <div>
            <StyledForgotPassword onClick={() => forgotPasswordModal()}>
              {t("common:login:forgotPassword")}?
            </StyledForgotPassword>
            {isFromSubDomain === false && (
              <StyledRegisterWrapper onClick={() => registerModal()}>
                {t("common:login:dontHaveAccount")}?{" "}
                <StyledRegisterNow>
                  {t("common:login:registerNow")}
                </StyledRegisterNow>
              </StyledRegisterWrapper>
            )}
          </div>
        </StyledContainerWrapper>
        {isLoading === true && (
          <div className="loading">
            <LoadingSpinner />
          </div>
        )}
      </form>
      {showNewPasswordPopup && renderSetNewPasswordPopup()}
    </StyledComponent>
  );
};

export default LoginWithOtp;
