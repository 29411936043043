import React, { useEffect, useState } from "react";
import GenericCarousel from "../../../common/elements/GenericCarousel";
import { useMediaQuery } from "@material-ui/core";
import styled from "styled-components";

const StyledImageContainer = styled.img<{ isMobile: boolean }>`
  width: auto;
  height: ${(props) => (props.isMobile ? "70vh" : "400px")};
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    padding: 10px;
    width: 100%;
    height: 60vh;
  }
  @media (min-width: 768px) and (max-width: 830px) {
    width: 85%;
  }
`;

const StyledDiv = styled.div<{ isMobile: boolean }>`
  display: flex;
  justify-content: center;
  height: ${(props) => (props.isMobile ? "70vh" : "400px")};
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    height: 60vh;
  }
`;

const StyledHeroContainer = styled.div<{ isMobile: boolean }>`
  height: ${(props) => (props.isMobile ? "70vh" : "430px")};
  min-width: 100%;
  max-width: 100%;
  position: relative;
  margin-top: ${(props) => (props.isMobile ? "10px" : "0")};
  margin-bottom: ${(props) => (props.isMobile ? "2em" : "0")};
`;

const StyledCarouselContainer = styled.div`
  position: absolute;
  top: 0;
  width: 50%;
  z-index: 999;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    width: auto;
  }

  @media (min-width: 768px) and (max-width: 830px) {
    width: 80%;
  }
`;

const StyledImage = ({
  src = "",
  alt = "carouselstyledimage",
  mobileSrc = "",
}) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  return (
    <StyledDiv isMobile={isMobile}>
      <StyledImageContainer
        isMobile={isMobile}
        src={isMobile ? mobileSrc : src}
        alt={alt}
      />
    </StyledDiv>
  );
};

const heroImages = [
  {
    alt: "sept image #1",
    src: "https://d1xs39ogteakr9.cloudfront.net/zipang/SEPTEMBER.png",
    mobileSrc: "https://d1xs39ogteakr9.cloudfront.net/zipang/SEPTEMBER.png",
  },
  {
    alt: "oct image #1",
    src: "https://d1xs39ogteakr9.cloudfront.net/zipang/OCTOBER.png",
    mobileSrc: "https://d1xs39ogteakr9.cloudfront.net/zipang/OCTOBER.png",
  },
  {
    alt: "nov image #1",
    src: "https://d1xs39ogteakr9.cloudfront.net/zipang/NOVEMBER.png",
    mobileSrc: "https://d1xs39ogteakr9.cloudfront.net/zipang/NOVEMBER.png",
  }
];

const ZipangUpcomingAuc = ({
  isMobile,
  inViewRef,
}: {
  isMobile: boolean;
  inViewRef: React.Ref<any>;
}) => {
  const [slideNumber, setSlideNumber] = useState(0);

  const handleCarouselSlideChange = (slideNumber: number) =>
    setSlideNumber(slideNumber);

  useEffect(() => {
    trialModalHandler();
  }, []);

  const trialModalHandler = () => {
    let modalDisplayed = localStorage.getItem("modalDisplayed");
    !modalDisplayed &&
      setTimeout(() => {
        localStorage.setItem("modalDisplayed", "1");
      }, 4000);
  };

  return (
    <StyledHeroContainer
      isMobile={isMobile}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <StyledCarouselContainer>
        <GenericCarousel
          navButtonsAlwaysVisible={true}
          indicators={heroImages.length > 1}
          onChange={handleCarouselSlideChange}
        >
          {heroImages.map((item, i) => (
            <StyledImage
              key={i}
              src={item.src}
              mobileSrc={item.mobileSrc}
              alt={item.alt}
            />
          ))}
        </GenericCarousel>
      </StyledCarouselContainer>
    </StyledHeroContainer>
  );
};

export default ZipangUpcomingAuc;
