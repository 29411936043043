import React, {
  ChangeEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Box, Dialog, Grid } from "@material-ui/core";
import styled, { css } from "styled-components";
import AuthService from "../../../auth/AuthService";
import { oAuth } from "../../../../routes/basic/BasicAppRouter";
import { productUrl } from "../../../../common/config/app.endpoints";
import { useHistory } from "react-router";
import AlertReadOnlyUser from "../../../../common/elements/AlertReadOnlyUser";
import Alert from "../../../../common/elements/Alert";
import { StyledModal } from "../../../../common/materials/GenericStyleComponent";
import PreBidConfirmationPopup from "../../../products/details/PreBidConfirmationPopup";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { getNumberFormat } from "../../../../services/NumberFormatService";
import RefreshIcon from "@material-ui/icons/Refresh";
import { AuctionState } from "../../../../enums/auctionEnum";

const StyledGrid = styled(Grid)`
  display: flex;
`;

interface IButtonProps {
  cancel?: boolean;
  solid?: boolean;
  disabled?: boolean;
}

const StyledButtonWrapper = styled(Grid)`
  display: flex;
`;

const StyledButton = styled.button<IButtonProps>`
  padding: 8px 12px;
  border-radius: 3px;
  border: none;
  font-size: 0.8em;
  margin-left: 5px;
  margin-right: 5px;
  color: ${(props) => props.theme.colors.base.white};
  background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  ${(props) =>
    props.cancel &&
    css`
      color: ${(props) => props.theme.colors.base.white};
      background: ${(props) =>
        props.theme.colors.base.buttonGradientFirstColor};
    `}
  ${(props) =>
    props.disabled &&
    css`
      cursor: default;
      background: ${(props) => props.theme.colors.base.lightGrey};
      color: ${(props) => props.theme.colors.base.black};
    `}
`;

const StyledCancelButton = styled.button<IButtonProps>`
  padding: 8px;
  width: 100px;
  border-radius: 3px;
  border: none;
  font-size: 0.8em;
  margin-left: 5px;
  color: ${(props) => props.theme.colors.base.white};
  background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  ${(props) =>
    props.cancel &&
    css`
      color: ${(props) => props.theme.colors.base.white};
      background: ${(props) =>
        props.theme.colors.base.buttonGradientFirstColor};
    `}
  ${(props) =>
    props.disabled &&
    css`
      cursor: default;
      background: ${(props) => props.theme.colors.base.lightGrey};
      color: ${(props) => props.theme.colors.base.black};
    `}
`;
const StyledInput = styled.input`
  padding: 7px;
  width: 100%;
  text-align: right;
  border: none;
  border-radius: 2px;
  color: ${(props) => props.theme.colors.base.black};
  font-size: 0.8em;
`;

const StyledInputWrapper = styled.div`
  display: flex;
  border-radius: 3px;
  border: 1px solid ${(props) => props.theme.colors.base.lightGrey};
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const StyledTextWrapper = styled.div`
  padding: 2px 2px;
  border: none;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${(props) => props.theme.colors.base.white};
  background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  font-size: 0.7em;
`;
interface IProps {
  items: any;
  count: number;
  isReadOnlyUser: any;
  preBidAmountSaved: (auctionId: string) => void;
  cancelPreBid: (value: boolean) => void;
  handleClose: () => void;
  open: boolean;
}
let joinedAuctions: any = [];
let count = 0;

const styles: any = (theme: any) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props: any) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const PrebidFormModal = React.forwardRef((props: IProps, ref) => {
  let { items, isReadOnlyUser, open, handleClose } = props;
  let {
    auctionState,
    parcelType,
    sellerAuctionUserId,
    preBidPrice,
    currency,
    exchangeRates,
    caratMinimumPrice,
    auctionId,
    perId,
  } = items;

  const { t } = useTranslation();

  const [biddingPrice, setBiddingPrice] = useState("");
  const [openToast, setOpenToast] = useState(false);
  const [openReadOnlyUserToast, setOpenReadOnlyUserToast] = useState(false);
  const [showAlertMessage, setShowAlertMessage] = useState("");
  const [isPreBidDone, setIsPreBidDone] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [isAuctionUserId, setIsAuctionUserId] = useState(false);
  const [userBlock, setUserBlock] = useState(false);
  const [openPreBidPopUpModal, setOpenPreBidPopUpModal] = useState(false);
  const [eStore, setEStore] = useState();
  const [preBiddingPrice, setPreBiddingPrice] = useState("");
  const [preBidConversion, setPreBidConversion] = useState("");
  const Auth = new AuthService();
  let history = useHistory();
  const prebidPriceIncby = useRef(0);
  const userCurrencyCode = localStorage.getItem("preferredCurrency");
  const userCurrencySymbol = localStorage.getItem("preferredCurrencySymbol");

  useEffect(() => {
    joinedAuctions = localStorage.getItem("joinedAuctions");
    joinedAuctions = JSON.parse(joinedAuctions);
    if (joinedAuctions !== null) {
      joinedAuctions.filter((items: any, index: number) => {
        if (sellerAuctionUserId === items.auctionUserId) {
          setIsAuctionUserId(true);
        }
      });
    } else {
      setIsAuctionUserId(false);
    }
    if (preBidPrice === null || preBidPrice === undefined) {
      setIsPreBidDone(false);
    } else {
      setIsPreBidDone(true);
    }
    return () => {
      setIsPreBidDone(false);
      setIsAuctionUserId(false);
    };
  }, [preBidPrice, sellerAuctionUserId]);

  const onSubmit = (e: any) => {
    if (preBidPrice === null || preBidPrice === undefined) {
      handlePreBidSubmit(e);
      e.preventDefault();
      showPreBidPopUp();
      setEStore(e);
    } else {
      handleCancelSubmit(e);
    }
  };

  const refreshButton = () => {
    setBiddingPrice("");
    prebidPriceIncby.current = 0;
    setPreBidConversion("");
  };
  const getBracketValue = () => {
    let bidingPriceZero: any = "";
    const isJPY = currency["code"] === "JPY" ? true : false;
    const bidPrice = caratMinimumPrice; // starting price

    if (parcelType === "Parcel" && parcelType !== undefined) {
      if (isJPY) {
        if (bidPrice < 10000) {
          bidingPriceZero = "00";
        } else if (bidPrice >= 10000) {
          bidingPriceZero = "000";
        }
      } else {
        if (bidPrice >= 100 && bidPrice < 30000) {
          bidingPriceZero = "0";
        } else if (bidPrice >= 30000) {
          bidingPriceZero = "000";
        }
      }
    } else {
      if (isJPY) {
        if (bidPrice < 10000) {
          bidingPriceZero = "00";
        } else if (bidPrice >= 10000) {
          bidingPriceZero = "000";
        }
      } else {
        if (bidPrice >= 100 && bidPrice < 30000) {
          bidingPriceZero = "0";
        } else if (bidPrice >= 30000) {
          bidingPriceZero = "000";
        }
      }
    }
    return bidingPriceZero;
  };
  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setBiddingPrice(e.target.value);

    let targetVal: any = e.target.value;
    let userCurrencyCode = localStorage.getItem("preferredCurrency");
    let userCurrencySymbol = localStorage.getItem("preferredCurrencySymbol");
    let bidingPriceZero = await getBracketValue();
    if (parcelType !== "Diamond") {
      if (userCurrencyCode !== currency["code"]) {
        let bidPrice = targetVal + bidingPriceZero;
        let conversion = new Intl.NumberFormat("us-en").format(
          Math.round(Number(bidPrice) * exchangeRates[0]["rate"])
        );
        let conversions = new Intl.NumberFormat("us-en").format(
          Math.round(Number(bidPrice))
        );
        setPreBidConversion(
          `${currency["symbol"]} ${conversions} (${userCurrencySymbol} ${conversion})`
        );
      } else {
        setPreBidConversion("");
      }
    } else {
      if (userCurrencyCode !== currency["code"]) {
        let bidPrice = targetVal + bidingPriceZero;
        let conversion = new Intl.NumberFormat("us-en").format(
          Math.round(Number(bidPrice) * exchangeRates[0]["rate"])
        );
        let conversions = new Intl.NumberFormat("us-en").format(
          Math.round(Number(bidPrice))
        );
        setPreBidConversion(
          `${currency["symbol"]} ${conversions} (${userCurrencySymbol} ${conversion})`
        );
      } else {
        setPreBidConversion("");
      }
    }
    // when user clear the prebid amount
    if (targetVal === "") {
      setPreBidConversion("");
    }
  };

  const showPreBidPopUp = async () => {
    const validate = (bidPrice: string) => {
      if (biddingPrice === "") {
        setShowAlertMessage(`${t("common:preview:preBidding:enterAmount")}`);
        setAlertType("error");
        setOpenToast(true);
      } else if (bidPrice <= caratMinimumPrice) {
        setBiddingPrice("");
        setShowAlertMessage(
          `${t("common:preview:preBidding:validationOnCorrectAmount")}`
        );
        setAlertType("error");
        setOpenToast(true);
      } else {
        if (userCurrencyCode !== currency["code"]) {
          const conversion = getNumberFormat(
            Math.round(Number(bidPrice) * exchangeRates[0]["rate"])
          );
          setPreBidConversion(`${userCurrencySymbol}${conversion}`);
        } else {
          setPreBidConversion("");
        }

        setPreBiddingPrice(
          `${currency["symbol"]}${getNumberFormat(Number(bidPrice))}`
        );
        setOpenPreBidPopUpModal(true);
      }
    };
    setTimeout(() => {
      setOpenToast(false);
    }, 4000);
    let bidingPriceZero = await getBracketValue();
    const bidPrice = biddingPrice + bidingPriceZero;
    validate(bidPrice);
  };

  const preBidModalYesButtonPressed = () => {
    handlePreBidSubmitPopup(eStore);
  };

  const handlePreBidSubmitPopup = async (e: any) => {
    e.preventDefault();
    const letters = /^[0-9]+$/;
    let bidPrice = "";
    let bidingPriceZero = await getBracketValue();
    bidPrice = biddingPrice + bidingPriceZero;
    if (parcelType !== "Diamond") {
      if (bidPrice.match(letters) && bidPrice > caratMinimumPrice) {
        if (!oAuth()) {
          history.push("/");
        } else {
          Auth.postRequest(
            `${productUrl}/${auctionId}/${perId}/setPreBidPrice?amt=${bidPrice}`,
            "data",
            (status: number, response: any) => {
              setOpenToast(false);
              setOpenPreBidPopUpModal(false);
              if (status === 200) {
                setAlertType("success");
                setShowAlertMessage(response.data.message);
                setOpenToast(true);
                setIsPreBidDone(true);
                refreshButton();
                props.preBidAmountSaved(auctionId);
              } else if (status === 403) {
                setUserBlock(true);
              } else if (status === 404) {
                setBiddingPrice("");
                setShowAlertMessage(`${t("common:invalidRequest")}`);
                setAlertType("error");
                setOpenToast(true);
              } else if (status === 412) {
                setTimeout(() => {
                  history.push("/preview");
                }, 2000);
                setIsPreBidDone(false);
                setBiddingPrice("");
                setShowAlertMessage(response);
                setAlertType("error");
                setOpenToast(true);
              } else if (status === 505) {
                //  props.apiTimeout(true);
              } else {
                setIsPreBidDone(false);
                setBiddingPrice("");
                setShowAlertMessage(`${t("common:somethingWentWrong")}`);
                setAlertType("error");
                setOpenToast(true);
              }
            }
          );
        }
      } else if (biddingPrice === "") {
        setAlertType("error");
        setShowAlertMessage(`${t("common:preview:preBidding:enterAmount")}`);
        setOpenToast(true);
      } else {
        setIsPreBidDone(false);
        setBiddingPrice("");
        setShowAlertMessage(
          `${t("common:preview:preBidding:validationOnCorrectAmount")}`
        );
        setAlertType("error");
        setOpenToast(true);
      }
    } else {
      if (bidPrice.match(letters) && bidPrice > caratMinimumPrice) {
        if (!oAuth()) {
          history.push("/");
        } else {
          Auth.postRequest(
            `${productUrl}/${auctionId}/${perId}/setPreBidPrice?amt=${bidPrice}`,
            "data",
            (status: number, response: any) => {
              setOpenToast(false);
              setOpenPreBidPopUpModal(false);
              if (status === 200) {
                setAlertType("success");
                setShowAlertMessage(response.data.message);
                setOpenToast(true);
                setIsPreBidDone(true);
                refreshButton();
                props.preBidAmountSaved(auctionId);
              } else if (status === 403) {
                setUserBlock(true);
              } else if (status === 404) {
                setBiddingPrice("");
                setShowAlertMessage(`${t("common:invalidRequest")}`);
                setAlertType("error");
                setOpenToast(true);
              } else if (status === 412) {
                setTimeout(() => {
                  history.push("/preview");
                }, 2000);
                setIsPreBidDone(false);
                setBiddingPrice("");
                setShowAlertMessage(response);
                setAlertType("error");
                setOpenToast(true);
              } else if (status === 505) {
                //  apiTimeout(true);
              } else {
                setIsPreBidDone(false);
                setBiddingPrice("");
                setShowAlertMessage(`${t("common:somethingWentWrong")}`);
                setAlertType("error");
                setOpenToast(true);
              }
            }
          );
        }
      } else if (biddingPrice === "") {
        setAlertType("error");
        setShowAlertMessage(`${t("common:preview:preBidding:enterAmount")}`);
        setOpenToast(true);
      } else {
        setIsPreBidDone(false);
        setBiddingPrice("");
        setShowAlertMessage(
          `${t("common:preview:preBidding:validationOnCorrectAmount")}`
        );
        setAlertType("error");
        setOpenToast(true);
      }
    }
  };

  const handlePreBidSubmit = async (e: any) => {
    e.preventDefault();
    let bidPrice = "";
    const letters = /^[0-9]+$/;
    let bidingPriceZero = await getBracketValue();
    bidPrice = biddingPrice + bidingPriceZero;
    if (parcelType !== "Diamond") {
      if (bidPrice.match(letters) && bidPrice > caratMinimumPrice) {
        setOpenPreBidPopUpModal(true);
      } else if (biddingPrice === "") {
        setAlertType("error");
        setShowAlertMessage(`${t("common:preview:preBidding:enterAmount")}`);
        setOpenToast(true);
      } else {
        setIsPreBidDone(false);
        refreshButton();
        setShowAlertMessage(
          `${t("common:preview:preBidding:validationOnCorrectAmount")}`
        );
        setAlertType("error");
        setOpenToast(true);
      }
    } else {
      if (bidPrice.match(letters) && bidPrice > caratMinimumPrice) {
        setOpenPreBidPopUpModal(true);
      } else if (biddingPrice === "") {
        setAlertType("error");
        setShowAlertMessage(`${t("common:preview:preBidding:enterAmount")}`);
        setOpenToast(true);
      } else {
        setIsPreBidDone(false);
        setBiddingPrice("");
        setShowAlertMessage(
          `${t("common:preview:preBidding:validationOnCorrectAmount")}`
        );
        setAlertType("error");
        setOpenToast(true);
      }
    }
  };

  const handleCancelSubmit = (e: any) => {
    e.preventDefault();
    setTimeout(() => {
      setOpenToast(false);
    }, 1000);
    if (!oAuth()) {
      history.push("/");
    } else {
      Auth.postRequest(
        `${productUrl}/${auctionId}/${perId}/cancelPreBidPrice`,
        "data",
        (status: number, response: any) => {
          if (status === 200) {
            setIsPreBidDone(false);
            setShowAlertMessage("Pre-bid price removed successfully");
            setAlertType("success");
            setOpenToast(true);
            refreshButton();
            props.cancelPreBid(true);
          } else if (status === 403) {
            setUserBlock(true);
          } else if (status === 404) {
            setAlertType("error");
            setShowAlertMessage(`${t("common:invalidRequest")}`);
            setOpenToast(true);
          } else if (status === 412) {
            setTimeout(() => {
              history.push("/preview");
            }, 2000);
            setIsPreBidDone(false);
            setBiddingPrice("");
            setShowAlertMessage(response);
            setAlertType("error");
            setOpenToast(true);
          } else if (status === 505) {
            //  props.apiTimeout(true);
          } else {
            setShowAlertMessage(`${t("common:somethingWentWrong")}`);
            setAlertType("error");
            setOpenToast(true);
          }
        }
      );
    }
  };

  const renderInput = (disabled: boolean) => {
    const isDisabled = disabled ? disabled : false;
    return (
      <StyledInput
        type="number"
        min="0"
        name="preBiddingPrice"
        id="preBiddingPrice"
        placeholder={`${t("common:preview:preBidding:placeholderText")}`}
        onChange={(e: any) => {
          if (isDisabled === false) {
            const isJPY = currency["code"] === "JPY" ? true : false;
            let lengthValue: any = isJPY ? 6 : 8;
            if (e.target?.value?.length <= lengthValue) {
              handleChange(e);
            }
            return;
          }
        }}
        style={{ width: "180px" }}
        value={biddingPrice}
        disabled={isDisabled}
      />
    );
  };

  const renderCurrencyCode = () => {
    let bidZeroText;
    const isJPY = currency["code"] === "JPY" ? true : false;
    const bidPrice = caratMinimumPrice; // starting price

    if (parcelType === "Parcel" && parcelType !== undefined) {
      if (isJPY) {
        if (bidPrice < 10000) {
          bidZeroText = "00";
        } else if (bidPrice >= 10000) {
          bidZeroText = "000";
        }
      } else {
        if (bidPrice >= 100 && bidPrice < 30000) {
          bidZeroText = 0;
        } else if (bidPrice >= 30000) {
          bidZeroText = "000";
        }
      }
    } else {
      if (isJPY) {
        if (bidPrice < 10000) {
          bidZeroText = "00";
        } else if (bidPrice >= 10000) {
          bidZeroText = "000";
        }
      } else {
        if (bidPrice >= 100 && bidPrice < 30000) {
          bidZeroText = 0;
        } else if (bidPrice >= 30000) {
          bidZeroText = "000";
        }
      }
    }

    return (
      <StyledTextWrapper>
        {currency && currency["code"] !== "JPY" ? (
          <>{bidZeroText}$</>
        ) : (
          <>{bidZeroText}¥</>
        )}
      </StyledTextWrapper>
    );
  };

  const renderDisabledInput = (currencyCodeLabel: string) => {
    return (
      <div style={{ display: "flex", color: "white" }}>
        <StyledInput
          style={{ background: "lightgrey", border: "1px solid grey" }}
          name="preBiddingPrice"
          disabled={true}
          placeholder={`${t("common:preview:preBidding:placeholderText")}`}
          value={biddingPrice}
        />
        {renderCurrencyCode()}
      </div>
    );
  };

  const renderReadOnlyUserToast = () => {
    if (isReadOnlyUser) {
      setOpenReadOnlyUserToast(true);
    } else if (isAuctionUserId) {
      setOpenReadOnlyUserToast(true);
    } else {
      setOpenReadOnlyUserToast(false);
    }
    setTimeout(() => {
      setOpenReadOnlyUserToast(false);
    }, 3000);
  };

  const checkAuctionUserIdAndCount = () => {
    return isAuctionUserId === false && count === 0;
  };

  const checkPrebidPrice = () => {
    return preBidPrice === null || preBidPrice === undefined;
  };
  const checkAuctionState = () => {
    return (
      auctionState === AuctionState.NOTSTARTED ||
      auctionState === "" ||
      auctionState === "undefined" ||
      auctionState === null
    );
  };

  const renderInputForParcelTypeDiamond = () => {
    return (
      <>
        {checkPrebidPrice() ? (
          <StyledInputWrapper>
            <>
              {checkAuctionState() ? (
                <>
                  {checkAuctionUserIdAndCount()
                    ? renderInput(false)
                    : renderInput(true)}
                </>
              ) : (
                renderInput(true)
              )}
              {renderCurrencyCode()}
            </>
          </StyledInputWrapper>
        ) : (
          renderDisabledInput("jpy")
        )}
      </>
    );
  };

  const renderInputForJewellery = () => {
    return (
      <>
        {checkPrebidPrice() ? (
          <StyledInputWrapper>
            <>
              {checkAuctionState() ? (
                <>
                  {checkAuctionUserIdAndCount()
                    ? renderInput(false)
                    : renderInput(true)}
                </>
              ) : (
                renderInput(true)
              )}
              {renderCurrencyCode()}
            </>
          </StyledInputWrapper>
        ) : (
          renderDisabledInput("jpyJewellery")
        )}
      </>
    );
  };

  const renderSubmitButtonTemplate = () => {
    return (
      <>
        {checkAuctionUserIdAndCount() ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <StyledButton
              type="submit"
              solid={true}
              onSubmit={handlePreBidSubmit}
            >
              {t("common:button:preBid")}
            </StyledButton>
          </div>
        ) : (
          <StyledButton
            disabled={true}
            onClick={() => {
              renderReadOnlyUserToast();
            }}
          >
            {t("common:button:preBid")}
          </StyledButton>
        )}
      </>
    );
  };

  const renderCancelButtonTemplate = () => {
    return (
      <>
        {checkAuctionUserIdAndCount() ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <StyledCancelButton cancel={true} onClick={handleCancelSubmit}>
              {t("common:button:cancelPreBid")}
            </StyledCancelButton>
          </div>
        ) : (
          <StyledButton
            disabled={true}
            onClick={() => {
              renderReadOnlyUserToast();
            }}
          >
            {t("common:button:preBid")}
          </StyledButton>
        )}
      </>
    );
  };

  const convertedPlaceHolderValue = (
    currencyCodeVal: any,
    price: any,
    exchangeRate: any
  ) => {
    //get user currency code form local storeage

    if (
      currencyCodeVal === undefined ||
      currencyCodeVal === null ||
      price === undefined ||
      price === null ||
      price === "" ||
      exchangeRate === undefined ||
      exchangeRate === null
    ) {
      return " ";
    } else {
      if (userCurrencyCode !== currencyCodeVal.code) {
        let conversion = new Intl.NumberFormat("us-en").format(
          Math.round(price * exchangeRate)
        );
        let conversions = new Intl.NumberFormat("us-en").format(
          Math.round(price)
        );
        return `${currencyCodeVal.symbol} ${conversions} (${userCurrencySymbol} ${conversion})`;
      } else {
        let conversions = new Intl.NumberFormat("us-en").format(
          Math.round(price)
        );
        return `${userCurrencySymbol} ${conversions}`;
      }
    }
  };

  const convertedAmt = (
    currencyCodeVal: any,
    price: any,
    exchangeRate: any,
    carat: any,
    parcelType: any
  ) => {
    //get user currency code form local storeage
    let amt: any = price.replace(/[^0-9]/g, "") * carat;
    if (
      currencyCodeVal === undefined ||
      currencyCodeVal === null ||
      amt === undefined ||
      amt === null ||
      amt === 0 ||
      exchangeRate === undefined ||
      exchangeRate === null ||
      parcelType === "Jewellery"
    ) {
      return "";
    } else {
      const rate = exchangeRate?.[0]?.["rate"] ?? 1;
      if (userCurrencyCode !== currencyCodeVal.code) {
        let conversion = new Intl.NumberFormat("us-en").format(
          Math.round(amt * rate)
        );
        let conversions = new Intl.NumberFormat("us-en").format(
          Math.round(amt)
        );
        return ` ${conversions} ${currencyCodeVal.symbol}  (${conversion}${userCurrencySymbol})`;
      } else {
        let conversions = new Intl.NumberFormat("us-en").format(
          Math.round(amt)
        );
        return `${conversions} ${userCurrencySymbol}`;
      }
    }
  };

  return (
    <Dialog onClose={handleClose} open={open} scroll={"paper"}>
      <DialogTitle onClose={handleClose}>
        <DialogTitle onClose={handleClose}>
          {t("common:preview:preBidding:preBidding")}
        </DialogTitle>
      </DialogTitle>
      <DialogContent dividers>
        <form onSubmit={onSubmit} name="preBiddingForm" id="preBiddingForm">
          <StyledGrid
            item={true}
            xs={12}
            style={{ width: "310px", padding: "10px" }}
          >
            <Box>
              {parcelType !== "Jewellery"
                ? renderInputForParcelTypeDiamond()
                : renderInputForJewellery()}
            </Box>
            <StyledButtonWrapper item xl={12} lg={12} sm={12} md={12}>
              <Box>
                <Box>
                  {checkPrebidPrice() ? (
                    <>
                      {checkAuctionState() ? (
                        renderSubmitButtonTemplate()
                      ) : (
                        <StyledButton disabled={true}>
                          {t("common:button:preBid")}
                        </StyledButton>
                      )}
                    </>
                  ) : (
                    <>
                      {checkAuctionState() ? (
                        renderCancelButtonTemplate()
                      ) : (
                        <StyledCancelButton disabled={true}>
                          {t("common:button:cancelPreBid")}
                        </StyledCancelButton>
                      )}
                    </>
                  )}
                </Box>
              </Box>
            </StyledButtonWrapper>
          </StyledGrid>
        </form>
        <Grid item style={{ marginTop: "10px", textAlign: "center" }}>
          {checkPrebidPrice() ? (
            <>
              {preBidConversion}{" "}
              {parcelType === "Jewellery"
                ? ""
                : preBidConversion !== ""
                  ? "/ct"
                  : ""}
            </>
          ) : (
            <>
              {convertedPlaceHolderValue(
                currency,
                preBidPrice,
                exchangeRates[0].rate
              )}
              {parcelType === "Jewellery" ? "" : "/ct"}
            </>
          )}
        </Grid>
        {openReadOnlyUserToast === true && (
          <AlertReadOnlyUser
            isReadOnlyUser={isReadOnlyUser}
            isSeller={isAuctionUserId}
          />
        )}
        {openToast === true && (
          <Alert showAlertMessage={showAlertMessage} alertType={alertType} />
        )}
        {openPreBidPopUpModal === true && (
          <StyledModal open={openPreBidPopUpModal}>
            <PreBidConfirmationPopup
              t={t}
              {...props}
              message={t("common:preview:preBidding:preBidPopUpMessage") + ": "}
              biddingAmount={`${preBiddingPrice} ${parcelType === "Jewellery"
                ? ""
                : preBidConversion !== ""
                  ? ""
                  : "/ct"
                }`}
              conversionAmount={
                preBidConversion === ""
                  ? ""
                  : `(${preBidConversion}) ${parcelType === "Jewellery" ? "" : "/ct"
                  }`
              }
              onConfirmModal={preBidModalYesButtonPressed}
              onCancelModal={() => setOpenPreBidPopUpModal(false)}
              caratAmount={convertedAmt(
                items.currency,
                preBiddingPrice ?? 0,
                items?.exchangeRates,
                Number(items.carat),
                items.parcelType
              )}
            />
          </StyledModal>
        )}
      </DialogContent>
    </Dialog>
  );
});

export default PrebidFormModal;
