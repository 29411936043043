
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import Grid from "@material-ui/core/Grid";
import moment from 'moment';

import { buttonGradient } from '../../../common/materials/LinearGradient';
import IRouterComponentProps from '../../../models/IRouterComponentProps';
import { StyledModal } from '../../../common/materials/GenericStyleComponent';
import ShippingAllAddress from './shippingAddMore/ShippingAllAddress';
import EllipsisText from '../../../common/elements/EllipsisText';
import { selectIsRequestButtonEnable } from '../../../redux/slice/annual/commanAnnualSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducer';

const StyledContainer = styled(Grid)`
    .name {
        font-size: 1.2em;
        font-family: Calibre-SemiBold;
        color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
    }
    .heading {
        font-size: 0.7em;
        padding: 2px;
        color: ${(props) => props.theme.colors.base.grey};
    }
    .secondary {
        font-size: 1em;
        padding: 5px;
        font-family: Calibre-Regular;
        color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
    }
    .textWrap {
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .borderBottom {
        border-bottom: 1px solid ${(props) => props.theme.colors.base.lightGrey};
    }
    .borderLeft {
        border-left: 1px solid ${(props) => props.theme.colors.base.lightGrey};
    }
`;

const StyledContainerWrapper = styled(Grid)`
    padding: 10px;
`;

const StyledGridContainer = styled(Grid)`
    margin: 10px 10px;
    padding: 10px;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 8px 0px ${(props) => props.theme.colors.base.lightGrey};
    -moz-box-shadow: 0px 0px 8px 0px ${(props) => props.theme.colors.base.lightGrey};
    box-shadow: 0px 0px 8px 0px ${(props) => props.theme.colors.base.lightGrey};
    &:focus {
        outline: none;
    }
`;

const StyledDateInvoiceContainer = styled(Grid)``;

const StyledInvoiceContainer = styled(Grid)`
    text-align: center;
`;

const StyledDateContainer = styled(Grid)`
    text-align: center;
    padding-top: 10px;
`;

const StyledNameContainer = styled(Grid)`
    padding: 10px;
    text-align: center;
`;

const StyledGrid = styled(Grid)`
    width: 95%;
    margin: 5px;
    text-align: center;
    background: ${(props) => props.theme.colors.base.veryVeryLightGrey};
`;

const StyledButtonContainer = styled(Grid)`
    padding: 0px 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    text-align: center;
`;

interface IButtonProps {
    selected?: boolean;
    notSelected?: boolean;
}

const StyledButton = styled.button<IButtonProps>`
    width: 90%;
    border: none;
    margin-bottom: 10px;
    font-size: 0.8em;
    padding: 0.5em;
    font-weight: bold;
    border-radius: 5px;
    border: 1px solid ${(props) => props.theme.colors.base.lightGrey};
    &:hover {
        border: 1px solid ${(props) => props.theme.colors.base.textColor};
        cursor: pointer;
    }
    ${props => props.selected && css`
        color: ${(props) => props.theme.colors.base.white};
        background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};     
    `}
    ${props => props.notSelected && css`
        cursor: default;
        color: ${(props) => props.theme.colors.base.grey};
        background: ${(props) => props.theme.colors.base.lightGrey}; 
    `}
`;


interface IProps extends IRouterComponentProps {
    t: any;
    auctionId: string;
    id: string;
    lastModifiedDate: any;
    amountPaidByBidder: any;
    amountPaidBySeller: any;
    shippingAddress: any;
    auctionName: string;
    sendMessage: (message: string) => void;
    apiTimeout: (timeout: boolean) => void;
    downloadpdf: (invoiceId: string, auctionName: string, onClick: boolean) => void;
    downloadinternationalInvoice: (invoiceId: string, auctionName: string, onClick: boolean) => void;
    openTrackingUiWithId: (autionId: any) => void;
    pushRequestInvoice: any;
    invoiceType: any;
    downloadInternationalPerformaInvoice: (
    invoiceId: string,
    auctionName: string,
    onClick: boolean
  ) => void;
  allreadysent: any;
}

let date: string | null = '';
let timezone: any = '';
let hour: any = '';

const InvoiceListViewProductAuctions = React.forwardRef((props: IProps, ref) => {
    const [openModal, setOpenModal] = useState(false);
    const {
      t,
      pushRequestInvoice,
      id,
      invoiceType,
      auctionId,
      lastModifiedDate,
      amountPaidByBidder,
      amountPaidBySeller,
      shippingAddress,
      auctionName,
      sendMessage,
      downloadpdf,
      downloadinternationalInvoice,
      downloadInternationalPerformaInvoice,
      allreadysent
    } = props;

    const isRequestButtonEnable: any = useSelector((state: RootState) =>
      selectIsRequestButtonEnable(state)
    );
    
    let previewTime = moment.utc(lastModifiedDate);
    let amountToPay: any = Math.round(amountPaidBySeller);
    let amountToReceive: any = Math.round(amountPaidByBidder);
    amountToPay = new Intl.NumberFormat('us-en').format(amountToPay);
    amountToReceive = new Intl.NumberFormat('us-en').format(amountToReceive);

    let userCurrencyCode = localStorage.getItem("preferredCurrency");
    let userCurrencySymbol = localStorage.getItem("preferredCurrencySymbol");
    
    const isUserCurrencyCode = () => {
      return userCurrencyCode && userCurrencyCode === "JPY";
    };

    const openPopup = () => {
        setOpenModal(true);
    }

    const onClose = () => {
        setOpenModal(false);
    }

    const forDownloadInvoice = (id: string, auctionName: string) => {
        downloadpdf(id, auctionName, true);
    }
    const internationalInvoice =(auctionId: string, auctionName: string)=>{
        downloadinternationalInvoice(auctionId, auctionName, true);
    }
    const internationalPerformaInvoice =(id: string, auctionName: string)=>{
        downloadInternationalPerformaInvoice(id, auctionName, true);
    }
    const sendAlertMessage = (message: string) => {
        sendMessage(message);
    }

    const timeout = (timeout: boolean) => {
        props.apiTimeout(timeout);
    }

    if (timezone !== null) {
        timezone = localStorage.getItem("timezoneName");
    }
    if (previewTime !== null || previewTime !== undefined) {
        let result = moment.tz(previewTime, timezone);
        let resultFormatter = result.format();
        if (resultFormatter !== 'Invalid date') {
            let splitDateTime = resultFormatter.split("T", 2);
            let splitTime: any = '';
            if (splitDateTime[1].includes("+")) {
                splitTime = splitDateTime[1].split("+", 2);
            }
            else {
                splitTime = splitDateTime[1].split("-", 2);
            }
            date = splitDateTime[0];
            let time = splitTime[0];
            let dateTime = `${date} ${time}`;
            let start: any = moment(dateTime).add(744, "h");
            timezone = localStorage.getItem("timezoneName");
            let end: any = moment(new Date().toLocaleString("en-US", { timeZone: timezone }));
            hour = start.diff(end, 'hours');
        }
    }

    const renderDownloadButton = () => {
        return isUserCurrencyCode() ? (
          <Grid item xs={4}>
            <StyledButton
              selected={true}
              onClick={() => forDownloadInvoice(id, auctionName)}
            >
              {t("common:invoice:buttons:download")}
            </StyledButton>
          </Grid>
        ) : (
          <Grid item xs={4}>
            <StyledButton
              selected={true}
              onClick={() => internationalInvoice(id, auctionName)}
            >
              {t("common:invoice:buttons:invoiceButton")}
            </StyledButton>
          </Grid>
        );
    };

    const renderTrackingButton = () => {
        return (
          <Grid item xs={4}>
            <StyledButton
              selected={true}
              onClick={() => props.openTrackingUiWithId(id)}
            >
              {t("common:invoice:buttons:trackingButton")}
            </StyledButton>
          </Grid>
        );
    };

    const renderProductPERFORMADownloadButton = () => {
        return isUserCurrencyCode() ? (
          <Grid item xs={6}>
            {id !== "" ? (
              <StyledButton
                selected={true}
                onClick={() =>
                  id !== "" ? internationalPerformaInvoice(id, auctionName) : ""
                }
              >
                {t("common:invoice:buttons:download")}
              </StyledButton>
            ) : (
              <StyledButton
                selected={false}
              >
                {t("common:invoice:buttons:download")}
              </StyledButton>
            )}
          </Grid>
        ) : (
          <Grid item xs={6}>
             {id !== "" ? 
            <StyledButton
              selected={true}
              onClick={() => internationalPerformaInvoice(id, auctionName)}
            >
              {t("common:invoice:buttons:invoiceButton")}
            </StyledButton>
            :
             <StyledButton
              selected={false}
            >
              {t("common:invoice:buttons:invoiceButton")}
            </StyledButton>}
          </Grid>
        );
    };


    const renderPERFORMAtRequestButton = () => {
      return (
        <Grid item xs={6}>
          {id !== "" ? (
            <StyledButton
              selected={isRequestButtonEnable ? false : true}
              title="Request invoice"
              onClick={() =>
                isRequestButtonEnable ? allreadysent() : pushRequestInvoice()
              }
            >
              <EllipsisText text={"Request invoice"} length={10} tail={"..."} />
            </StyledButton>
          ) : (
            <StyledButton selected={false} title="Request invoice">
              <EllipsisText text={"Request invoice"} length={10} tail={"..."} />
            </StyledButton>
          )}
        </Grid>
      );
    };

    return (
      <StyledContainer container spacing={0}>
        <StyledGridContainer item xs={12}>
          <StyledContainerWrapper container spacing={0}>
            <Grid item xs={12}>
              <StyledNameContainer>
                {" "}
                <div className="name textWrap">{auctionName}</div>{" "}
              </StyledNameContainer>
            </Grid>
          </StyledContainerWrapper>
          <StyledDateInvoiceContainer item xs={12}>
            <StyledGrid container spacing={0}>
              <StyledDateContainer item xs={12} className="borderBottom">
                <div className="secondary fontBold textWrap">{date}</div>
              </StyledDateContainer>
              <StyledGrid container spacing={0}>
                <StyledInvoiceContainer item xs={6}>
                  <div className="heading fontBold">
                    {t("common:invoice:heading:totalAmountToPay")}
                  </div>
                  <div className="secondary fontBold textWrap">
                    {userCurrencySymbol} {amountToReceive}
                  </div>
                </StyledInvoiceContainer>
                <StyledInvoiceContainer item xs={6} className="borderLeft">
                  <div className="heading fontBold ">
                    {t("common:invoice:heading:totalAmountToReceive")}
                  </div>
                  <div className="secondary fontBold textWrap">
                    {userCurrencySymbol}
                    {amountToPay}
                  </div>
                </StyledInvoiceContainer>
              </StyledGrid>
            </StyledGrid>
          </StyledDateInvoiceContainer>
        </StyledGridContainer>
        {invoiceType !== "PERFORMA" ? (
          <StyledButtonContainer container spacing={0}>
            <Grid item xs={4}>
              {hour <= 0 ? (
                <StyledButton notSelected={true}>
                  <EllipsisText
                    text={t("common:invoice:buttons:shippingAddress")}
                    length={10}
                    tail={"..."}
                  />
                </StyledButton>
              ) : (
                <Grid onClick={openPopup}>
                  <StyledButton selected={true}>
                    <EllipsisText
                      text={t("common:invoice:buttons:shippingAddress")}
                      length={10}
                      tail={"..."}
                    />
                  </StyledButton>
                </Grid>
              )}
            </Grid>
            {renderDownloadButton()}
            {renderTrackingButton()}
          </StyledButtonContainer>
        ) : (
          <StyledButtonContainer container spacing={0}>
            {renderProductPERFORMADownloadButton()}
            {renderPERFORMAtRequestButton()}
          </StyledButtonContainer>
        )}
        {openModal === true && (
          <StyledModal open={openModal} onClose={onClose}>
            <ShippingAllAddress
              {...props}
              shippingAddress={shippingAddress}
              auctionId={id}
              onClose={onClose}
              sendMessage={sendAlertMessage}
              apiTimeout={timeout}
              isAnnualType={true}
            />
          </StyledModal>
        )}
      </StyledContainer>
    );
});

export default InvoiceListViewProductAuctions;