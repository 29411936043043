import React from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import Styled, { css } from "styled-components";
import Select from "react-select";

import ColorsList from "./ColorsList";
import ClarityList from "./ClarityList";
import GradingList from "./GradingList";
import ShapesList from "./ShapesList";
import { cutOptions, polistAndSymmetryOptions } from "./CutPolishFilter";
import { intensityOptions, fancyColorOptions } from "./FancyFilter";
import FieldSet from "../../../common/elements/FieldSet";
import {
  StyledButton,
  StyledWrapper,
} from "../../../common/materials/GenericStyleComponent";
import AuthService from "../../auth/AuthService";
import { searchAuctionFilterUrl } from "../../../common/config/app.endpoints";
import LoadingSpinner from "../../../common/elements/LoadingSpinner";
import IRouterComponentProps from "../../../models/IRouterComponentProps";
import { oAuth } from "../../../routes/basic/BasicAppRouter";

const StyledFilterGrid = styled(Grid)``;

const StyledButtonContainer = styled(Grid)`
  text-align: center;
`;

const StyledGridWrapper = styled.div`
  margin-top: 2em;
  input {
    color: ${(props) => props.theme.colors.base.black};
  }
`;

const StyledFilterPriceWrapper = styled(Grid)``;

const StyledGrid = styled(Grid)`
  padding: 5px;
`;

const StyledLabel = styled.div`
  text-align: left;
  font-size: 0.8em;
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  font-weight: bold;
`;

const StyledValue = styled.input`
  border: 1px solid ${(props) => props.theme.colors.base.lightGrey};
  padding: 7px;
  width: 100%;
  border-radius: 2px;
  color: ${(props) => props.theme.colors.base.grey};
  font-size: 0.8em;
`;

const StyledFilterValue = styled(Grid)`
  font-size: 0.8em;
  border-radius: 2px;
  color: ${(props) => props.theme.colors.base.black};
`;

const StyledFilterWeightContainer = styled(Grid)``;

const StyledFilterFancyColorContainer = styled(Grid)``;

const StyledFilterGridWrapper = styled(Grid)`
  padding: 5px;
`;

const StyledHorizontalList = Styled.ul`
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    width: 100%;
    text-align: center;
`;

interface IName {
  isSelected: any;
}

const StyledName = Styled.div<IName>`
    border: 1px solid ${(props) =>
    props.theme.colors.base.buttonGradientFirstColor};
    border-radius: 2px;
    font-size: 1em;
    padding: 0.2em 0.5em;
    color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
    cursor: pointer;
    &:hover {
        background: ${(props) =>
    props.theme.colors.base.buttonGradientFirstColor};
        color: ${(props) => props.theme.colors.base.white};
    }
    ${(props) =>
    props.isSelected &&
    css`
        background: ${(props) =>
        props.theme.colors.base.buttonGradientFirstColor};
        color: ${(props) => props.theme.colors.base.white};
      `}
`;

const StyledShapeImage = Styled("img")`
    height: 2.8em;
    width: 2.8em;
`;

const StyledShapeName = Styled.div`
    font-size: 0.8em;
`;

export const StyledMessage = styled.div`
  color: ${(props) => props.theme.colors.base.red};
  font-size: 14px;
  margin: 15px 0px;
  margin-left: 1.2em;
`;

const StyledShapeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5em;
  width: 110px;
  align-items: center;
  justify-content: center;
  @media (width: 320px) {
    width: 90px;
  }
`;

interface IProps extends IRouterComponentProps {
  t: any;
  filteredAuctions: any;
  auctionId: string | null;
  annualAuction?: boolean;
  isPreviewPage?: boolean;
}

interface IState {
  colors: any;
  clarity: any;
  grading: any;
  startingCaratPrice: string;
  endingCaratPrice: string;
  startingTotalPrice: string;
  endingTotalPrice: string;
  fromWeight: string;
  toWeight: string;
  intensity: any;
  fancyColor: any;
  cutOption: any;
  polishOption: any;
  symmetryOption: any;
  shapes: any;
  opened: boolean;
  temp: any;
  loadData: boolean;
  isInputDisable: boolean;
  message: string;
}

var filteredValues: any = "";

export default class ProductFilter extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      colors: [],
      clarity: [],
      grading: [],
      shapes: [],
      startingCaratPrice: "",
      endingCaratPrice: "",
      startingTotalPrice: "",
      endingTotalPrice: "",
      fromWeight: "",
      toWeight: "",
      intensity: "--Please Select--",
      fancyColor: "--Please Select--",
      cutOption: "--Please Select--",
      polishOption: "--Please Select--",
      symmetryOption: "--Please Select--",
      opened: false,
      temp: [],
      loadData: false,
      isInputDisable: false,
      message: "",
    };
  }

  Auth = new AuthService();
  array: any = [];

  componentDidMount() {
    filteredValues = localStorage.getItem("filteredValues");
    filteredValues = JSON.parse(filteredValues);
    if (filteredValues === null) {
      ColorsList.map((color: any, index: number) => {
        return (ColorsList[index].selectedValue = false);
      });
      ClarityList.map((clarity: any, index: number) => {
        return (ClarityList[index].selectedValue = false);
      });
      GradingList.map((grading: any, index: number) => {
        return (GradingList[index].selectedValue = false);
      });
      ShapesList.map((shape: any, index: number) => {
        ShapesList[index].selectedValue = false;
        return this.setState({
          temp: null,
        });
      });
    } else {
      filteredValues.map((item: any, index: number) => {
        if (
          item.name === "cutGrade" ||
          item.name === "cutGrade,subItems.cutGrade"
        ) {
          this.setState({
            cutOption: item.value,
          });
        }
        if (
          item.name === "fancyIntensity" ||
          item.name === "fancyIntensity,subItems.fancyIntensity"
        ) {
          this.setState({
            intensity: item.value,
          });
        }
        if (
          item.name === "fancyColor" ||
          item.name === "fancyColor,subItems.fancyColor"
        ) {
          this.setState({
            fancyColor: item.value,
          });
        }
        if (
          item.name === "polishAbbreviation" ||
          item.name === "polishAbbreviation,subItems.polishAbbreviation"
        ) {
          this.setState({ polishOption: item.value });
        }
        if (
          item.name === "symmetryAbbreviation" ||
          item.name === "symmetryAbbreviation,subItems.symmetryAbbreviation"
        ) {
          this.setState({ symmetryOption: item.value });
        }
        if (
          item.name === "caratMinimumPrice" ||
          item.name === "caratMinimumPrice,subItems.caratMinimumPrice"
        ) {
          if (item.gte !== undefined) {
            this.setState({ startingCaratPrice: item.gte });
          }
          if (item.lte !== undefined) {
            this.setState({ endingCaratPrice: item.lte });
          }
        }
        if (
          item.name === "startingPrice" ||
          item.name === "startingPrice,subItems.startingPrice"
        ) {
          if (item.gte !== undefined) {
            this.setState({ startingTotalPrice: item.gte });
          }
          if (item.lte !== undefined) {
            this.setState({ endingTotalPrice: item.lte });
          }
        }
        if (item.name === "carat" || item.name === "carat,subItems.carat") {
          if (item.gte !== undefined) {
            this.setState({ fromWeight: item.gte });
          }
          if (item.lte !== undefined) {
            this.setState({ toWeight: item.lte });
          }
        }
      });
    }
  }

  selectedColor = (color: any, index: number) => {
    ColorsList[index].selectedValue = !color.selectedValue;
    this.setState({
      temp: null,
    });
  };

  selectedClarity(clarity: any, index: number) {
    ClarityList[index].selectedValue = !clarity.selectedValue;
    this.setState({
      temp: null,
    });
  }

  selectedGrading(grading: any, index: number) {
    GradingList[index].selectedValue = !grading.selectedValue;
    this.setState({
      temp: null,
    });
  }

  shapeName(shape: any, index: number) {
    ShapesList[index].selectedValue = !shape.selectedValue;
    this.setState({
      temp: null,
    });
  }

  intensity = (intensity: any) => {
    this.setState({
      intensity: intensity,
    });
  };

  fancyColor = (fancyColor: any) => {
    this.setState({
      fancyColor: fancyColor,
    });
  };

  cutOption = (cutOption: any) => {
    this.setState({
      cutOption: cutOption,
    });
  };

  symmetryOption = (symmetry: any) => {
    this.setState({
      symmetryOption: symmetry,
    });
  };

  polishOption = (polishOption: any) => {
    this.setState({
      polishOption: polishOption,
    });
  };

  handleChange = (e: any) => {
    const targeName = e.target.name;
    const targetValue = e.target.value;
    if (targeName === "fromCarat") {
      this.setState({ startingCaratPrice: targetValue });
    }
    if (targeName === "toCarat") {
      this.setState({ endingCaratPrice: targetValue });
    }
    if (targeName === "fromPrice") {
      this.setState({ startingTotalPrice: targetValue });
    }
    if (targeName === "toPrice") {
      this.setState({ endingTotalPrice: targetValue });
    }
    if (targeName === "fromWeight") {
      this.setState({ fromWeight: targetValue });
    }
    if (targeName === "toWeight") {
      this.setState({ toWeight: targetValue });
    }
  };

  handleSubmit(e: any) {
    e.preventDefault();
    this.setState({
      loadData: true,
      message: "",
    });
    let startingCaratPrice = parseFloat(this.state.startingCaratPrice);
    let endingCaratPrice = parseFloat(this.state.endingCaratPrice);
    let startingTotalPrice = parseFloat(this.state.startingTotalPrice);
    let endingTotalPrice = parseFloat(this.state.endingTotalPrice);
    let fromWeight = parseFloat(this.state.fromWeight);
    fromWeight = fromWeight - 0.0001;
    let toWeight = parseFloat(this.state.toWeight);
    toWeight = toWeight + 0.0001;
    let localArray: any = [];
    let colors: any = ColorsList.filter((item: any) => {
      return item.selectedValue === true;
    }).map((item: any) => {
      if (item.name === "UNDER N") {
        item.name = "UNDER N";
      }
      if (item.name === "UNDER S") {
        item.name = "UNDER S";
      }
      return item.name === "Fancy" ? "FANCY" : item.name.toUpperCase();
    });
    ColorsList.map((item: any) => {
      if (item.name === "N") {
        item.name = "UNDER N";
      }
      if (item.name === "S") {
        item.name = "UNDER S";
      }
      return item.name;
    });
    let clarity: any = ClarityList.filter((item: any) => {
      return item.selectedValue === true;
    }).map((item: any) => item.name);

    let grading: any = GradingList.filter((item: any) => {
      return item.selectedValue === true;
    }).map((item: any) => item.name);

    let shapes: any = ShapesList.filter((item: any) => {
      return item.selectedValue === true;
    }).map((item: any) => item.value.toUpperCase());

    if (colors.length > 0) {
      const name =
        colors.length === 1 &&
          colors[0] === "UNDER N" &&
          colors[0] === "UNDER S" &&
          colors[0] === "FANCY"
          ? this.props.annualAuction !== undefined
            ? "productSearchDescription,subItems.productSearchDescription"
            : `productSearchDescription`
          : this.props.annualAuction !== undefined
            ? "multiColor,subItems.multiColor"
            : "multiColor";
      localArray.push({ match: "in", name, value: colors });
    }

    if (this.props.isPreviewPage) {
      if (clarity.length > 0) {
        localArray.push({
          match: "in",
          name:
            this.props.annualAuction !== undefined
              ? "clarity,subItems.clarity"
              : "clarity",
          value: clarity,
        });
      }
    } else {
      if (clarity.length > 0) {
        localArray.push({
          match: "in",
          name:
            this.props.annualAuction !== undefined
              ? "clarity,subItems.clarity"
              : "clarity",
          value: clarity,
        });
      }
    }

    if (grading.length > 0) {
      localArray.push({
        match: "in",
        name:
          this.props.annualAuction !== undefined ? "lab,subItems.lab" : "lab",
        value: grading,
      });
    }
    if (shapes.length > 0) {
      localArray.push({
        match: "in",
        name:
          this.props.annualAuction !== undefined
            ? "shape,subItems.shape"
            : "shape",
        value: shapes,
      });
    }
    if (this.state.cutOption !== "--Please Select--") {
      localArray.push({
        match: "exact",
        name:
          this.props.annualAuction !== undefined
            ? "cutGrade,subItems.cutGrade"
            : "cutGrade",
        value:
          this.state.cutOption.value === undefined
            ? this.state.cutOption
            : this.state.cutOption.value,
      });
    }
    if (this.state.polishOption !== "--Please Select--") {
      localArray.push({
        match: "exact",
        name:
          this.props.annualAuction !== undefined
            ? "polishAbbreviation,subItems.polishAbbreviation"
            : "polishAbbreviation",
        value:
          this.state.polishOption.value === undefined
            ? this.state.polishOption
            : this.state.polishOption.value,
      });
    }
    if (this.state.intensity !== "--Please Select--") {
      localArray.push({
        match: "exact",
        name:
          this.props.annualAuction !== undefined
            ? "fancyIntensity,subItems.fancyIntensity"
            : "fancyIntensity",
        value:
          this.state.intensity.value === undefined
            ? this.state.intensity
            : this.state.intensity.value,
      });
    }
    if (this.state.symmetryOption !== "--Please Select--") {
      localArray.push({
        match: "exact",
        name:
          this.props.annualAuction !== undefined
            ? "symmetryAbbreviation,subItems.symmetryAbbreviation"
            : "symmetryAbbreviation",
        value:
          this.state.symmetryOption.value === undefined
            ? this.state.symmetryOption
            : this.state.symmetryOption.value,
      });
    }
    if (
      this.state.startingCaratPrice !== "" &&
      this.state.endingCaratPrice === ""
    ) {
      this.setState({
        message: `Please Enter to Carat Price`,
      });
    }
    if (
      this.state.endingCaratPrice !== "" &&
      this.state.startingCaratPrice === ""
    ) {
      this.setState({
        message: `Please Enter from Carat Price`,
      });
    }
    if (
      this.state.endingCaratPrice !== "" &&
      this.state.startingCaratPrice !== ""
    ) {
      if (
        parseInt(this.state.endingCaratPrice) <
        parseInt(this.state.startingCaratPrice)
      ) {
        this.setState({
          message: `"To" carat should be greater than "From" carat`,
        });
      } else if (
        parseInt(this.state.endingCaratPrice) >
        parseInt(this.state.startingCaratPrice)
      ) {
        localArray.push({
          match: "between",
          name:
            this.props.annualAuction !== undefined
              ? "caratMinimumPrice,subItems.caratMinimumPrice"
              : "caratMinimumPrice",
          gte: startingCaratPrice,
          lte: endingCaratPrice,
        });
      } else if (
        parseInt(this.state.endingCaratPrice) ===
        parseInt(this.state.startingCaratPrice)
      ) {
        this.setState({
          message: `"To" carat should not be equal "From" carat`,
        });
      }
    }
    if (
      this.state.startingTotalPrice !== "" &&
      this.state.endingTotalPrice === ""
    ) {
      this.setState({
        message: `Please Enter to Total Price`,
      });
    }
    if (
      this.state.endingTotalPrice !== "" &&
      this.state.startingTotalPrice === ""
    ) {
      this.setState({
        message: `Please Enter From Total Price`,
      });
    }
    if (
      this.state.endingTotalPrice !== "" &&
      this.state.startingTotalPrice !== ""
    ) {
      if (
        parseInt(this.state.endingTotalPrice) <
        parseInt(this.state.startingTotalPrice)
      ) {
        this.setState({
          message: `"To" price should be greater than "From" price`,
        });
      } else if (
        parseInt(this.state.endingTotalPrice) >
        parseInt(this.state.startingTotalPrice)
      ) {
        localArray.push({
          match: "between",
          name:
            this.props.annualAuction !== undefined
              ? "startingPrice,subItems.startingPrice"
              : "startingPrice",
          gte: startingTotalPrice,
          lte: endingTotalPrice,
        });
      }
    }
    if (this.state.fromWeight !== "" && this.state.toWeight === "") {
      this.setState({
        message: `Please enter to weight`,
      });
    }
    if (this.state.toWeight !== "" && this.state.fromWeight === "") {
      this.setState({
        message: `Please enter from weight`,
      });
    }
    if (this.state.toWeight !== "" && this.state.fromWeight !== "") {
      if (parseFloat(this.state.fromWeight) < parseFloat(this.state.toWeight)) {
        localArray.push({
          match: "between",
          name: "carat",
          gte: fromWeight,
          lte: toWeight,
        });
      } else if (
        parseFloat(this.state.fromWeight) > parseFloat(this.state.toWeight)
      ) {
        this.setState({
          message: `"To" weight should be greater than "From" weight`,
        });
      }
    }
    if (this.state.fancyColor !== "--Please Select--") {
      localArray.push({
        match: "exact",
        name:
          this.props.annualAuction !== undefined
            ? "fancyColor,subItems.fancyColor"
            : "fancyColor",
        value: this.state.fancyColor.value === undefined
          ? this.state.fancyColor
          : this.state.fancyColor.value,
      });
    }
    let values = localArray;
    localArray.map((item: any, index: number) => {
      if (item.name === "auctionId") {
        localArray.splice(index, 1);
      }
    });

    const data = {
      filters: {
        and: localArray,
      },
      page: {
        start: 0,
        count: 20,
        sort: {
          orders: [
            {
              property:
                this.props.annualAuction === undefined
                  ? "serialNo"
                  : "productEndDate",
              direction: "ASC",
            },
          ],
        },
      },
    };

    if (
      this.props.isPreviewPage &&
      (this.props.auctionId === null || filteredValues === null)
    ) {
      if (localArray.length > 0) {
        if (!oAuth()) {
          this.props.history.push("/");
        } else {
          this.Auth.postRequest(
            this.props.annualAuction === undefined
              ? searchAuctionFilterUrl
              : searchAuctionFilterUrl,
            data,
            (status: any, response: any) => {
              if (status === 200) {
                this.props.filteredAuctions(response, true, values);
                this.setState({
                  loadData: false,
                });
              } else {
                this.setState({
                  loadData: false,
                });
              }
            }
          );
        }
      } else {
        this.setState({
          loadData: false,
        });
        this.props.filteredAuctions(null, false, null);
      }
    } else {
      this.setState({
        loadData: false,
      });
      this.props.filteredAuctions("", true, values);
    }
  }

  resetValue(e: any) {
    e.preventDefault();
    ColorsList.map((color: any, index: number) => {
      return (ColorsList[index].selectedValue = false);
    });
    ClarityList.map((clarity: any, index: number) => {
      return (ClarityList[index].selectedValue = false);
    });
    GradingList.map((grading: any, index: number) => {
      return (GradingList[index].selectedValue = false);
    });
    ShapesList.map((shape: any, index: number) => {
      return (ShapesList[index].selectedValue = false);
    });
    this.setState({
      loadData: false,
      startingCaratPrice: "",
      endingCaratPrice: "",
      startingTotalPrice: "",
      endingTotalPrice: "",
      fromWeight: "",
      toWeight: "",
      intensity: "--Please Select--",
      fancyColor: "--Please Select--",
      cutOption: "--Please Select--",
      polishOption: "--Please Select--",
      symmetryOption: "--Please Select--",
      isInputDisable: false,
      message: "",
    });
    localStorage.removeItem("filteredValues");
    localStorage.removeItem("filteredParcelValues");
    this.props.filteredAuctions(null, false, null);
  }

  render() {
    const { t } = this.props;
    return (
      <StyledGridWrapper>
        <form onSubmit={this.handleSubmit.bind(this)}>
          <StyledFilterGrid container={true} spacing={2}>
            <Grid item={true} xs={12}>
              <FieldSet legend={t("common:preview:filterName:shape")}>
                <StyledHorizontalList>
                  {ShapesList.map((shape, index) => {
                    return (
                      <StyledShapeWrapper
                        key={index}
                        onClick={() => this.shapeName(shape, index)}
                      >
                        <div>
                          {shape.selectedValue ? (
                            <StyledShapeImage
                              src={`/images/shapes/${shape.image.colored}`}
                            />
                          ) : (
                            <StyledShapeImage
                              src={`/images/shapes/${shape.image.grey}`}
                            />
                          )}
                        </div>
                        <StyledShapeName>{shape.name}</StyledShapeName>
                      </StyledShapeWrapper>
                    );
                  })}
                </StyledHorizontalList>
              </FieldSet>
            </Grid>
            <Grid item={true} xs={12} sm={12} md={12} lg={6} xl={6}>
              <FieldSet legend={t("common:preview:filterName:color")}>
                <StyledHorizontalList>
                  {ColorsList.map((color: any, index: number) => {
                    return (
                      <StyledWrapper key={index}>
                        <StyledName
                          isSelected={color.selectedValue}
                          onClick={() => this.selectedColor(color, index)}
                        >
                          {color.name}
                        </StyledName>
                      </StyledWrapper>
                    );
                  })}
                </StyledHorizontalList>
              </FieldSet>
            </Grid>
            <Grid item={true} xs={12} sm={8} md={8} lg={4} xl={4}>
              <FieldSet legend={t("common:preview:filterName:clarity")}>
                <StyledHorizontalList>
                  {ClarityList.map((clarity, index) => {
                    return (
                      <StyledWrapper key={index}>
                        <StyledName
                          isSelected={clarity.selectedValue}
                          onClick={() => this.selectedClarity(clarity, index)}
                        >
                          {clarity.name}
                        </StyledName>
                      </StyledWrapper>
                    );
                  })}
                </StyledHorizontalList>
              </FieldSet>
            </Grid>
            <Grid item={true} xs={12} sm={4} md={4} lg={2} xl={2}>
              <FieldSet legend={t("common:preview:filterName:grading")}>
                <StyledHorizontalList>
                  {GradingList.map((grading, index) => {
                    return (
                      <StyledWrapper key={index}>
                        <StyledName
                          isSelected={grading.selectedValue}
                          onClick={() => this.selectedGrading(grading, index)}
                          style={{ fontSize: "0.8em" }}
                        >
                          {grading.name}
                        </StyledName>
                      </StyledWrapper>
                    );
                  })}
                </StyledHorizontalList>
              </FieldSet>
            </Grid>
            <Grid item={true} xs={12}>
              <StyledMessage>{this.state.message}</StyledMessage>
            </Grid>
            <Grid item={true} xs={12} sm={6} md={6} lg={8} xl={8}>
              <FieldSet legend={t("common:preview:filterName:price")}>
                <StyledFilterPriceWrapper container={true} spacing={0}>
                  <Grid item={true} xs={6}>
                    <StyledFilterPriceWrapper container={true} spacing={0}>
                      <StyledGrid item={true} xs={12}>
                        <StyledLabel className="fontBold">
                          <span>&#165;</span>
                          {t("common:preview:filters:price:ct")}
                        </StyledLabel>
                      </StyledGrid>
                      <StyledGrid item={true} xs={6} md={6}>
                        <StyledValue
                          type="number"
                          min="0"
                          max="1000000"
                          step="any"
                          name="fromCarat"
                          id="fromCarat"
                          value={this.state.startingCaratPrice}
                          placeholder={`${t("common:preview:filters:from")}`}
                          onChange={this.handleChange.bind(this)}
                        />
                      </StyledGrid>
                      <StyledGrid item={true} xs={6} md={6}>
                        <StyledValue
                          type="number"
                          min="0"
                          max="1000000"
                          step="any"
                          name="toCarat"
                          id="toCarat"
                          value={this.state.endingCaratPrice}
                          placeholder={`${t("common:preview:filters:to")}`}
                          onChange={this.handleChange}
                        />
                      </StyledGrid>
                    </StyledFilterPriceWrapper>
                  </Grid>
                  <Grid item={true} xs={6}>
                    <StyledFilterPriceWrapper container={true} spacing={0}>
                      <StyledGrid item={true} xs={12}>
                        <StyledLabel className="fontBold">
                          {t("common:preview:filters:price:total")}
                        </StyledLabel>
                      </StyledGrid>
                      <StyledGrid item={true} xs={6} md={6}>
                        <StyledValue
                          min="0"
                          max="1000000"
                          type="number"
                          step="any"
                          name="fromPrice"
                          id="fromPrice"
                          value={this.state.startingTotalPrice}
                          placeholder={`${t("common:preview:filters:from")}`}
                          onChange={this.handleChange}
                        />
                      </StyledGrid>
                      <StyledGrid item={true} xs={6} md={6}>
                        <StyledValue
                          min="0"
                          max="1000000"
                          type="number"
                          step="any"
                          name="toPrice"
                          id="toPrice"
                          value={this.state.endingTotalPrice}
                          placeholder={`${t("common:preview:filters:to")}`}
                          onChange={this.handleChange}
                        />
                      </StyledGrid>
                    </StyledFilterPriceWrapper>
                  </Grid>
                </StyledFilterPriceWrapper>
              </FieldSet>
            </Grid>
            <Grid item={true} xs={12} sm={6} md={6} lg={4} xl={4}>
              <FieldSet legend={t("common:preview:filterName:weight")}>
                <StyledFilterWeightContainer container={true} spacing={0}>
                  <StyledGrid item={true} xs={12}>
                    <StyledLabel className="fontBold">CT</StyledLabel>
                  </StyledGrid>
                  <StyledGrid item={true} xs={6} md={6}>
                    <StyledValue
                      type="number"
                      min="0"
                      step="any"
                      max="1000000"
                      name="fromWeight"
                      id="fromWeight"
                      placeholder={`${t("common:preview:filters:from")}`}
                      onChange={this.handleChange.bind(this)}
                      value={this.state.fromWeight}
                    />
                  </StyledGrid>
                  <StyledGrid item={true} xs={6} md={6}>
                    {this.state.isInputDisable !== true ? (
                      <StyledValue
                        type="number"
                        min="0"
                        step="any"
                        max="1000000"
                        name="toWeight"
                        id="toWeight"
                        placeholder={`${t("common:preview:filters:to")}`}
                        onChange={this.handleChange.bind(this)}
                        value={this.state.toWeight}
                      />
                    ) : (
                      <StyledValue
                        type="number"
                        disabled
                        min="0"
                        step="any"
                        max="1000000"
                        name="toWeight"
                        id="toWeight"
                        placeholder={`${t("common:preview:filters:to")}`}
                        onChange={this.handleChange.bind(this)}
                        value={this.state.toWeight}
                      />
                    )}
                  </StyledGrid>
                </StyledFilterWeightContainer>
              </FieldSet>
            </Grid>
            <Grid item={true} xs={12} sm={6} md={6} lg={7} xl={7}>
              <FieldSet legend={t("common:preview:filterName:fancyColor")}>
                <StyledFilterFancyColorContainer container={true} spacing={0}>
                  <Grid item={true} xs={6} lg={6} md={6} sm={6} xl={6}>
                    <StyledFilterGridWrapper item={true} xs={12}>
                      <StyledLabel className="fontBold">
                        {t("common:preview:filters:fancyColor:intensity")}
                      </StyledLabel>
                    </StyledFilterGridWrapper>
                    <StyledFilterGridWrapper item={true} xs={12}>
                      <StyledFilterValue item md={11}>
                        <Select
                          value={this.state.intensity}
                          onChange={this.intensity}
                          options={intensityOptions}
                          placeholder={this.state.intensity}
                          styles={{
                            // Fixes the overlapping problem of the component
                            menu: (provided) => ({
                              ...provided,
                              zIndex: 9999,
                            }),
                          }}
                        />
                      </StyledFilterValue>
                    </StyledFilterGridWrapper>
                  </Grid>
                  <Grid item={true} xs={6} lg={6} md={6} sm={6} xl={5}>
                    <StyledGrid item={true} xs={12}>
                      <StyledLabel className="fontBold">
                        {t("common:preview:filters:fancyColor:color")}
                      </StyledLabel>
                    </StyledGrid>
                    <StyledGrid item={true} xs={12}>
                      <StyledFilterValue item md={11}>
                        <Select
                          value={this.state.fancyColor}
                          onChange={this.fancyColor}
                          options={fancyColorOptions}
                          placeholder={this.state.fancyColor}
                          styles={{
                            // Fixes the overlapping problem of the component
                            menu: (provided) => ({
                              ...provided,
                              zIndex: 9999,
                            }),
                          }}
                        />
                      </StyledFilterValue>
                    </StyledGrid>
                  </Grid>
                </StyledFilterFancyColorContainer>
              </FieldSet>
            </Grid>
            <Grid item={true} xs={12} sm={6} md={6} lg={5} xl={5}>
              <FieldSet
                legend={t("common:preview:filterName:cutPolishSymmetry")}
              >
                <StyledFilterFancyColorContainer container={true} spacing={0}>
                  <Grid item={true} xs={4}>
                    <StyledFilterFancyColorContainer
                      container={true}
                      spacing={0}
                    >
                      <StyledGrid item={true} xs={12}>
                        <StyledLabel>
                          {t("common:preview:filters:cutFilter:cut")}
                        </StyledLabel>
                      </StyledGrid>
                      <StyledGrid item={true} xs={12}>
                        <StyledFilterValue item md={11}>
                          <Select
                            value={this.state.cutOption}
                            onChange={this.cutOption}
                            options={cutOptions}
                            placeholder={this.state.cutOption}
                            styles={{
                              // Fixes the overlapping problem of the component
                              menu: (provided) => ({
                                ...provided,
                                zIndex: 9999,
                              }),
                            }}
                          />
                        </StyledFilterValue>
                      </StyledGrid>
                    </StyledFilterFancyColorContainer>
                  </Grid>
                  <Grid item={true} xs={4}>
                    <StyledFilterFancyColorContainer
                      container={true}
                      spacing={0}
                    >
                      <StyledGrid className="fontBold" item={true} xs={12}>
                        <StyledLabel>
                          {t("common:preview:filters:cutFilter:polish")}
                        </StyledLabel>
                      </StyledGrid>
                      <StyledGrid item={true} xs={12}>
                        <StyledFilterValue item md={11}>
                          <Select
                            value={this.state.polishOption}
                            onChange={this.polishOption}
                            options={polistAndSymmetryOptions}
                            placeholder={this.state.polishOption}
                            styles={{
                              // Fixes the overlapping problem of the component
                              menu: (provided) => ({
                                ...provided,
                                zIndex: 9999,
                              }),
                            }}
                          />
                        </StyledFilterValue>
                      </StyledGrid>
                    </StyledFilterFancyColorContainer>
                  </Grid>
                  <Grid item={true} xs={4}>
                    <StyledFilterFancyColorContainer
                      container={true}
                      spacing={0}
                    >
                      <StyledGrid className="fontBold" item={true} xs={12}>
                        <StyledLabel>
                          {t("common:preview:filters:cutFilter:symmetry")}
                        </StyledLabel>
                      </StyledGrid>
                      <StyledGrid item={true} xs={12}>
                        <StyledFilterValue item md={11}>
                          <Select
                            value={this.state.symmetryOption}
                            onChange={this.symmetryOption}
                            options={polistAndSymmetryOptions}
                            placeholder={this.state.symmetryOption}
                            styles={{
                              // Fixes the overlapping problem of the component
                              menu: (provided) => ({
                                ...provided,
                                zIndex: 9999,
                              }),
                            }}
                          />
                        </StyledFilterValue>
                      </StyledGrid>
                    </StyledFilterFancyColorContainer>
                  </Grid>
                </StyledFilterFancyColorContainer>
              </FieldSet>
            </Grid>
            <StyledButtonContainer item={true} xs={12}>
              <StyledButton onSubmit={this.handleSubmit.bind(this)}>
                {t("common:button:search")}
              </StyledButton>
              <StyledButton onClick={this.resetValue.bind(this)}>
                {t("common:button:reset")}
              </StyledButton>
            </StyledButtonContainer>
          </StyledFilterGrid>
        </form>
        {this.state.loadData === true && (
          <div className="loading">
            <LoadingSpinner />
          </div>
        )}
      </StyledGridWrapper>
    );
  }
}
