import React from "react";
import { StyledMuiDataTableLayoutGrid, StyledTypography } from "../MypageStyle";
import { useTranslation } from "react-i18next";
import ProductDescription from "../../shared/ProductDescription";
import { convertProductPrice } from "../../pendingProductsTab/TableProps";
import { DataGrid, GridColDef, GridOverlay } from "@material-ui/data-grid";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Typography } from "@material-ui/core";
import { StyledBtnContainer } from "../../365Auction/component/DiamondProductStyle";
import Chip from "@mui/material/Chip";
import { StyledMuiDataTableBtnContainer } from "../../pendingProductsTab/PendingProductsStyle";
import { useHistory } from "react-router";
import { getRapNet } from "../../products/preview/TenderGenericFunc";
import { getSplitDate, getSplitedTime } from "../../../utils/DateTimeUtils";
import EllipsisText from "../../../common/elements/EllipsisText";

const faHeartProps = faHeart as IconProp;
interface Iprops {
  data?: any;
  totalElements: number;
  changePageNum: (page: number) => void;
  isLoading: boolean;
  page: number;
  isSellPage: boolean;
  handlePrebid: (id: string, data: any, isPrebid: boolean) => void;
  handleNegotiate: (row: any) => void;
  handleWatchlist: (
    auctionId: string,
    productId: string,
    isWatch: boolean
  ) => void;
  exportIds: (ids: any) => void;
  setPage: (page: number) => void;
  itemperpage: number;
  onSort: (payload: any) => void;
}
let prevSortState: any; // for quick fix

function MypageTable(props: Iprops) {
  let {
    data,
    changePageNum,
    totalElements,
    isLoading,
    page,
    isSellPage,
    handlePrebid,
    handleWatchlist,
    exportIds,
    handleNegotiate,
    setPage,
    itemperpage,
  } = props;
  let { t } = useTranslation();
  let history = useHistory();
  let languageCode =
    localStorage.getItem("language") === "EN"
      ? "en"
      : localStorage.getItem("language") === "zh_CN"
        ? "zh_CN"
        : "jp";

  const columns: GridColDef[] = [
    {
      field: "serialNo",
      headerName: "S.no.",
      sortable: true,
      editable: false,
      filterable: false,
      // flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params: any) => {
        return (
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '5px'
          }}>
            <span
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => {
                window.open(
                  `/${languageCode}/my-page/diamonds/details/${params.row.auctionId}/${params.row.perId}/${params.row.auctionStartDate}`,
                  "detailsWindowName",
                  "height=700px,width=800px,scrollbars,toolbar,status,resizable"
                );
              }}
            >
              {params.value}
            </span>
            {!params.row?.reserved && <strong style={{ color: 'red' }}>NR</strong>}
          </div>
        );
      },
    },
    {
      field: "auctionName",
      headerName: "Auction Name",
      width: 200,
      flex: 1,
      sortable: true,
      editable: false,
      filterable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "description",
      headerName: `${t("common:newCommanTrans:productdescription")}`,
      width: 260,
      flex: 1,
      sortable: false,
      editable: false,
      filterable: true,
      headerAlign: "center",
      renderCell: (params: any) => (
        <Typography style={{ whiteSpace: "break-spaces", fontSize: "1em" }}>
          {params.row?.productSearchDescription !== undefined &&
            params.row?.productSearchDescription !== null ? (
            <EllipsisText
              text={params.row?.productSearchDescription}
              length={100}
              tail={"..."}
            />
          ) : (
            <></>
          )}
        </Typography>
      ),
    },
    {
      field: "caratMinimumPrice",
      headerName: "Price",
      width: 350,
      sortable: true,
      // flex: 1,
      editable: false,
      filterable: false,
      // align: "center",
      headerAlign: "center",
      renderCell: (params: any) => (
        <>
          {isSellPage ? (
            <> {renderSellPriceSection(params.row)}</>
          ) : (
            <> {renderbuyPriceSection(params.row)}</>
          )}
        </>
      ),
    },
    {
      field: "rapOff",
      headerName: "Rap Off",
      width: 300,
      flex: 1,
      sortable: false,
      editable: false,
      filterable: false,
      // align: "center",
      headerAlign: "center",
      renderCell: (params: any) => (
        <div>
          <StyledTypography color="textPrimary">
            <span>Starting Rap:</span> {getRapNet(params.row.rapnetPercentage)}{" "}
          </StyledTypography>
          <StyledTypography color="textPrimary">
            <span>Bid Rap:</span>
            {getRapnetPercentage(params.row, params.row.currentBidPrice)}{" "}
          </StyledTypography>
        </div>
      ),
    },
    {
      field: "Action",
      headerName: "Status",
      sortable: false,
      flex: 1,
      editable: false,
      filterable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params: any) => (
        <>
          {isSellPage ? (
            <Grid
              container
              spacing={1}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {renderStateAction(params.row.state, params.row)}
            </Grid>
          ) : (
            <Grid
              container
              spacing={3}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {renderBuyStateAction(params.row.state, params.row)}
            </Grid>
          )}
        </>
      ),
      width: 380,
    },
  ];

  const getRapnetPercentage = (rowData: any, currentBidPrice: any) => {
    let productPricePerCaratUSD: any;
    let rapoffValue: any;
    if (
      rowData.rapnetPercentage === undefined ||
      rowData.rapnetPercentage === null ||
      rowData.rapnetPercentage === 0 ||
      rowData.rapnetPercentage === "0"
    ) {
      return "-";
    } else {
      if (rowData.parcelType !== "Jewellery") {
        if (rowData.auctionState === "NotStarted") {
          if (rowData.preBidPrice === undefined || rowData.preBidPrice === 0) {
            return "-";
          }
          if (rowData.currency.code === "JPY") {
            productPricePerCaratUSD =
              rowData.preBidPrice * rowData.exchangeRates[0].rate;
          } else {
            productPricePerCaratUSD = rowData.preBidPrice;
          }
          rapoffValue = (
            (1 - productPricePerCaratUSD / rowData.rapnetPrice) *
            -100.0
          ).toFixed(2);

          return rapoffValue !== "NaN" ? rapoffValue : "-";
        } else {
          if (rowData.state !== "Available") {
            if (rowData.currency.code === "JPY") {
              productPricePerCaratUSD =
                rowData.currentBidPrice * rowData.exchangeRates[0].rate;
            } else {
              productPricePerCaratUSD = rowData.currentBidPrice;
            }
            rapoffValue = (
              (1 - productPricePerCaratUSD / rowData.rapnetPrice) *
              -100.0
            ).toFixed(2);
            return rapoffValue !== "NaN" ? rapoffValue : "-";
          } else {
            return "-";
          }
        }
      } else {
        return "-";
      }
    }
  };

  const renderStateAction = (state: any, rowData: any) => {
    let stateUi;
    switch (state) {
      case "Sold":
        stateUi = (
          <span
            style={{
              color: "blue",
              cursor: "pointer",
              textDecoration: "underline",
            }}
            onClick={() => {
              history.push(`/invoice`);
            }}
          >
            INVOICE
          </span>
        );
        break;
      case "Hold":
        stateUi = <span>{state}</span>;
        break;
      case "Unsold":
        stateUi = <span>{state}</span>;
        break;

      default:
        stateUi = (
          <StyledTypography color="textPrimary">
            <span style={{ whiteSpace: "break-spaces" }}>
              Auction scheduled on{" "}
              {getUserAuctionTime(rowData.auctionStartDate)}
            </span>
          </StyledTypography>
        );
        break;
    }
    return stateUi;
  };

  const renderBuyStateAction = (state: any, rowData: any) => {
    if (state === "Sold") {
      return <>{state}</>;
    } else if (state === "Hold") {
      return (
        <span>
          {rowData?.negotiable ? (
            <StyledMuiDataTableBtnContainer>
              {rowData?.negotiated ? (
                <Chip
                  variant="outlined"
                  disabled={true}
                  size="small"
                  color="primary"
                  style={{ cursor: "pointer", color: "#2660ad" }}
                  label={t("common:pendingProduct:button:negotiate")}
                />
              ) : (
                <Chip
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={() => {
                    handleNegotiate(rowData);
                  }}
                  style={{ cursor: "pointer", color: "#2660ad" }}
                  label={t("common:pendingProduct:button:negotiate")}
                />
              )}
            </StyledMuiDataTableBtnContainer>
          ) : (
            <span>Unsold</span>
          )}
        </span>
      );
    } else if (state === "Unsold") {
      return <>{state}</>;
    } else {
      if (rowData.watched && rowData.preBidPrice === 0) {
        return (
          <>
            <Grid
              item
              style={{
                display: "flex",
                alignItems: "start",
              }}
            >
              <FontAwesomeIcon
                icon={faHeartProps}
                style={{ color: "red", cursor: "pointer" }}
                onClick={() => {
                  handleWatchlist(rowData.auctionId, rowData.perId, false);
                }}
              />
            </Grid>
            <Grid>
              <StyledBtnContainer>
                {rowData?.auctionType === "Live" && (
                  <Chip
                    variant="outlined"
                    size="small"
                    color="primary"
                    label="Prebid"
                    style={{ cursor: "pointer", color: "#2660ad" }}
                    onClick={() => {
                      handlePrebid(rowData.perId, rowData, true);
                    }}
                  />
                )}
              </StyledBtnContainer>
            </Grid>
          </>
        );
      } else if (rowData.preBidPrice !== 0 && rowData.watched) {
        return (
          <>
            <Grid
              item
              style={{
                display: "flex",
                alignItems: "start",
              }}
            >
              <FontAwesomeIcon
                icon={faHeartProps}
                style={{ color: "red", cursor: "pointer" }}
                onClick={() => {
                  handleWatchlist(rowData.auctionId, rowData.perId, false);
                }}
              />
            </Grid>
            <Grid item>
              <StyledBtnContainer>
                {rowData?.auctionType === "Live" && (
                  <Chip
                    variant="outlined"
                    size="small"
                    color="primary"
                    label="Cancel Prebid"
                    style={{ cursor: "pointer", color: "#2660ad" }}
                    onClick={() => {
                      handlePrebid(rowData.perId, rowData, false);
                    }}
                  />
                )}
              </StyledBtnContainer>
            </Grid>
          </>
        );
      } else if (rowData.preBidPrice !== 0 && rowData.watched === false) {
        return (
          <Grid item>
            <StyledBtnContainer>
              {rowData?.auctionType === "Live" && (
                <Chip
                  variant="outlined"
                  size="small"
                  color="primary"
                  label="Cancel Prebid"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handlePrebid(rowData.perId, rowData, false);
                  }}
                />
              )}
            </StyledBtnContainer>
          </Grid>
        );
      } else if (rowData.preBidPrice !== 0 && rowData.watched === undefined) {
        return (
          <>
            <Grid
              item
              style={{
                display: "flex",
                alignItems: "start",
              }}
            >
              <FontAwesomeIcon
                icon={faHeartProps}
                style={{ color: "grey", cursor: "pointer" }}
                onClick={() => {
                  handleWatchlist(rowData.auctionId, rowData.perId, true);
                }}
              />
            </Grid>
            <Grid item>
              <StyledBtnContainer>
                {rowData?.auctionType === "Live" && (
                  <Chip
                    variant="outlined"
                    size="small"
                    color="primary"
                    label="Cancel Prebid"
                    style={{ cursor: "pointer", color: "#2660ad" }}
                    onClick={() => {
                      handlePrebid(rowData.perId, rowData, false);
                    }}
                  />
                )}
              </StyledBtnContainer>
            </Grid>
          </>
        );
      } else if (rowData.preBidPrice === 0 && rowData.watched === false) {
        return (
          <Grid item>
            <StyledBtnContainer>
              {rowData?.auctionType === "Live" && (
                <Chip
                  variant="outlined"
                  size="small"
                  color="primary"
                  label="Prebid"
                  style={{ cursor: "pointer", color: "#2660ad" }}
                  onClick={() => {
                    handlePrebid(rowData.perId, rowData, true);
                  }}
                />
              )}
            </StyledBtnContainer>
          </Grid>
        );
      }
    }
  };

  const renderSellPriceSection = (row: any) => {
    return (
      <div style={{ justifyContent: "center" }}>
        <StyledTypography>
          <span>A. Starting Price:</span>
          {convertProductPrice(row, row.caratMinimumPrice)}
        </StyledTypography>
        <StyledTypography>
          <span>B. Reserve Price:</span>
          {convertProductPrice(row, row.caratSellingPrice)}
        </StyledTypography>
        <StyledTypography>
          <span>C. Bid Price:</span>
          {row.state !== "Available" ? (
            <> {convertProductPrice(row, row.currentBidPrice)}</>
          ) : (
            <>-</>
          )}
        </StyledTypography>
        <StyledTypography>
          <span>D. Winning Price:</span>{" "}
          {row.state === "Sold" || row.state === "Unsold" ? (
            <> {convertProductPrice(row, row.currentBidPrice)}</>
          ) : (
            <>-</>
          )}
        </StyledTypography>
      </div>
    );
  };

  const renderbuyPriceSection = (row: any) => {
    return (
      <div>
        <StyledTypography>
          <span>A. Starting Price:</span>
          {convertProductPrice(row, row.caratMinimumPrice)}
        </StyledTypography>
        <StyledTypography>
          <span>B. Reserve Price:</span>
          {row.state === "Hold" ? (
            <>{convertProductPrice(row, row.caratSellingPrice)}</>
          ) : (
            <>-</>
          )}
        </StyledTypography>
        <StyledTypography>
          <span>C. Bid Price:</span>
          {row.state === "Available" ? (
            <> {convertProductPrice(row, row.preBidPrice)}</>
          ) : (
            <>{prebidRender(row)}</>
          )}
        </StyledTypography>
        <StyledTypography>
          <span>D. Winning Price:</span>{" "}
          {row.state === "Sold" || row.state === "Unsold" ? (
            <> {convertProductPrice(row, row.currentBidPrice)}</>
          ) : (
            <>-</>
          )}
        </StyledTypography>
      </div>
    );
  };

  // prebid logic
  const prebidRender = (row: any) => {
    if (row.preBidPrice === 0) {
      return convertProductPrice(row, row.highestBidPrice);
    } else {
      if (row.preBidPrice < row.highestBidPrice) {
        return convertProductPrice(row, row.highestBidPrice);
      } else {
        if (row.preBidPrice !== undefined) {
          return convertProductPrice(row, row.preBidPrice);
        } else {
          return convertProductPrice(row, row.highestBidPrice);
        }
      }
    }
  };

  const getUserAuctionTime = (date: any) => {
    let timezone: any = localStorage.getItem("timezoneName");
    if (timezone !== null) {
      return ` ${getSplitDate(date, timezone)} ${getSplitedTime(
        date,
        timezone
      )}`;
    }
  };

  const getTableBodyHeight = () => {
    let heightOfInnerTable = window.innerHeight - 290;
    return `${heightOfInnerTable}px`;
  };

  function CustomNoRowsOverlay() {
    return (
      <GridOverlay>
        <div>No Product Found.</div>
      </GridOverlay>
    );
  }

  return (
    <StyledMuiDataTableLayoutGrid
      style={{ height: getTableBodyHeight(), width: "100%" }}
    >
      <DataGrid
        rows={data}
        page={page}
        rowCount={totalElements}
        columns={columns}
        paginationMode="server"
        pageSize={itemperpage}
        rowsPerPageOptions={[]}
        pagination
        rowHeight={120}
        loading={isLoading}
        disableColumnMenu
        disableSelectionOnClick
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        getRowId={(row) => row.perId}
        checkboxSelection
        onSelectionModelChange={(itm: any) => {
          exportIds(itm);
        }}
        onPageChange={(page: number) => {
          setPage(page);
          changePageNum(page);
        }}
        components={{
          NoRowsOverlay: CustomNoRowsOverlay,
        }}
      />
    </StyledMuiDataTableLayoutGrid>
  );
}

export default MypageTable;
