import React from "react";
import ReactDOM from "react-dom";
import "./base.css";
import App from "./App";
import Root from "./Root";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { url } from "./common/config/app.endpoints";
import { withRedux } from "./common/config/config";

Sentry.init({
  // dsn: url === 'https://myauctionstech.in' ? 'https://4d524ef5375b42aa948533e28a36dda3@o511806.ingest.sentry.io/5785938' : 'https://4d1a30942b084fe987259607e896be38@o511806.ingest.sentry.io/5785916',
  dsn: url === 'https://myauctionstech.in' || url === 'https://myauctionsdemo.in' ? 'https://4d524ef5375b42aa948533e28a36dda3@o511806.ingest.sentry.io/5785938' : 'https://4d1a30942b084fe987259607e896be38@o511806.ingest.sentry.io/5785916',

  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  withRedux ? <Root /> : <App />,
  document.getElementById("root")
);
