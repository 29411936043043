import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import DiamondProductsList from "./MyPageProductsList";

import { StyledTextWrapper } from "../../../../pages/preview/PreviewPageStyles";
import MypageActionPaginationForProducts from "./MypageActionPaginationForProducts";


const StyledCoreDataGridContainer = styled(Grid)``;
const StyledDataWrapper = styled(Grid)`
  margin-top: 15px;
`;
const StyledGridWrapper = styled.div``;
const StyledPaginationContainer = styled(Grid)`
  margin-top: 1em;
  padding: 20px 0;
`;

interface IGridData {
  list: any[];
  totalElements: number;
}
interface IProps {
  gridData: IGridData;
  sortingProps: any;
  onCardClickHandler: (product: any) => void;
  fetchAuctionProducts: () => void;
  onWatch: () => void;
  onSort: (option: any) => void;
  setPage: (page: any) => void;
  page: number;
  itemperpage: number;
  exportIds: any[];
  selectItemInGridView: (x: any) => void;
  handleWatchlist: (
    auctionId: string,
    productId: string,
    isWatch: boolean
  ) => void;
  isPageChange: boolean;
  isSellPage: boolean;
}

const MyPageProductsGrid = (props: IProps) => {
  const { t } = useTranslation();

  const {
    gridData,
    onCardClickHandler,
    fetchAuctionProducts,
    setPage,
    page,
    itemperpage,
    exportIds,
    selectItemInGridView,
    handleWatchlist,
    isPageChange,
    isSellPage,
  } = props;
  const { list, totalElements } = gridData;

  const [currentPage, setCurrentPage] = useState<number>(1);
  useEffect(() => {
    if (page === 0) setCurrentPage(1);
    else setCurrentPage(page+1);
    return () => {
      setCurrentPage(page + 1);
    };
  }, [isPageChange]);

  const paginateCallBackHandler = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const onPageChange = (isFetchData: boolean, currentPage: number) => {
    const count = (currentPage - 1);
    setPage(count);
  }

  return (
    <StyledCoreDataGridContainer container={true} spacing={0}>
      <StyledDataWrapper item={true} xs={12}>
        <StyledGridWrapper>
          {list?.length > 0 ? (
            <DiamondProductsList
              currentRecords={list}
              onCardClickHandler={onCardClickHandler}
              fetchAuctionProducts={fetchAuctionProducts}
              selectedExportId={exportIds}
              selectItemInGridView={selectItemInGridView}
              handleWatchlist={handleWatchlist}
              isSellPage={isSellPage}
            />
          ) : (
            <StyledTextWrapper item={true} xs={12} lg={12} md={12}>
              {list?.length === 0 && t("common:preview:noProductFound")}
            </StyledTextWrapper>
          )}
        </StyledGridWrapper>
      </StyledDataWrapper>
      <StyledPaginationContainer item={true} xs={12}>
        {list?.length > 0 && (
          <MypageActionPaginationForProducts
            t={t}
            totalElements={totalElements}
            callbackForApiCalling={onPageChange}
            currentPage={currentPage}
            itemsPerPage={itemperpage}
            paginateCallback={paginateCallBackHandler}
          />
        )}
      </StyledPaginationContainer>
    </StyledCoreDataGridContainer>
  );
};

export default MyPageProductsGrid;
