import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import PubSub from "pubsub-js";
import { Grid } from "@material-ui/core";
import styled from "styled-components";
import { buttonGradient } from "../../common/materials/LinearGradient";

// Application Modules (Pages)
import LandingPage from "../../modules/LandingPage";
import Home from "../../modules/Home";
import Preview from "../../modules/Preview";
import Auction from "../../modules/Auction";
import Watchlist from "../../modules/Watchlist";
import Invoice from "../../modules/Invoice";
import Profile from "../../modules/Profile";
import PageNotFound from "../../modules/PageNotFound";
import AuthService from "../../components/auth/AuthService";
import ProductView from "../../components/products/preview/ProductView";
import { languageCodesArray } from "../../common/localization/i18next";
import KycForm from "../../modules/KycForm";
import FAQ from "../../modules/FAQ";
import ShareProductDetailsView from "../../components/products/ShareProductDetailsView";
import PaymentForm from "../../components/stripe/PaymentForm";
import AboutUs from "../../modules/AboutUs";
import ProductDetailsViewNew from "../../components/products/ProductDetailsViewNew";
import SubDomain from "../../modules/SubDomain";
import SubdomainProfile from "../../modules/SubdomainProfile";
import MjDiamonds from "../../modules/MjDiamonds";
import { domainName } from '../../common/config/app.endpoints';
import MKO from "../../modules/MKO";
import SysqureInd from "../../modules/SysqureInd";
import JewelleryProductView from "../../components/products/preview/JewelleryProductView";
import JewelleryProductDetailsView from "../../components/products/JewelleryProductDetailsView";
import ShareJewellerylink from "../../components/products/ShareJewellerylink";
import Nihonauction from "../../modules/Nihonauction";
import TendorAuctionView from "../../components/products/preview/TendorAuctionView";
import TendorPreviewView from "../../components/products/preview/TendorPreviewView";
import JewelleryTendorAuctionView from "../../components/products/preview/JewelleryTendorAuctionView";
import JewelleryTendorPreviewView from "../../components/products/preview/JewelleryTendorPreviewView";
import TendorDetailsView from "../../components/products/TendorDetailsView";
import TendorDetailsViewNew from "../../components/products/TendorDetailsViewNew";
import Selby from "../../modules/Selby";
import Inquiry from "../../modules/Inquiry";
import Hokuyu from "../../modules/Hokuyu";

import CreateWebSite from "../../modules/CreateWebSite";
import PendingProductPage from "../../components/pendingProductsTab/PendingProductsPage";
import Takeyama from "../../modules/Takeyama";
import Dialuster from "../../modules/Dialuster";
import Kiraga from "../../modules/Kiraga";
import ProductAuctionContainer from "../../components/365Auction/ProductAuctionContainer";
import NotificationPage from "../../common/elements/notification/NotificationPage";
import CommonDetailsView from "../../components/365Auction/component/detailsPage/CommonDetailsView";
import ProductCartSection from "../../components/365Auction/component/cartUi/ProductCartSection";
import ProductAuctionInvoice from "../../modules/ProductAuctionInvoice";
import Okura from "../../modules/Okura";
import { newHomePage } from "../../common/config/config";
import NewLandingPage from "../../pages/LandingPage/LandingPage";
import AuctionList from "../../pages/AuctionList/AuctionList";
import NewAboutUs from "../../pages/NewAboutUs/AboutUs";
import SellPage from "../../pages/SellPage/SellPage";
import ZipangTermsAndConditionPage from "../../pages/terms/ZipangTermsAndConditionPage";
import Mypage from "../../components/mypage/Mypage";
import SingleProductDetails from "../../components/mypage/components/SingleProductDetails";
import NewProductDetailsPage from "../../components/products/newProductDetails/NewProductDetailsPage";
import NewJwlProductDetailsPage from "../../components/products/newProductDetails/NewJwlProductDetailsPage";
import CreateWebDummy from "../../components/shared/subdomainDummy/CreateWebDummy";
import NewLiveAuctionContainer from "../../components/auction-version-2/auction/components/container/NewLiveAuctionContainer";
import NewJewelleryLiveAuctionContainer from "../../components/auction-version-2/auction/components/container/NewJewelleryLiveAuctionContainer";
import GlowStar from "../../modules/GlowStar";
import ZipangLandingPage from "../../pages/zipang/ZipangLandingPage";
import Privacy from "../../pages/Privacy";
import OurTeamPage from "../../pages/zipang/OurTeam/OurTeamPage";
import AuctionListZipang from "../../pages/AuctionList/AuctionListZipang";


const Auth = new AuthService();
export const oAuth = () => {
  const isLoggedInUser = Auth.loggedIn();
  return isLoggedInUser;
};

export interface IBasicAppRouterProps {
  t?: any;
  i18n?: any;
}

const StyledParticipateWrapper = styled(Grid)`
  text-align: center;
  padding: 1em 2em;
`;

const StyledHeadingTextWrapper = styled.div`
  font-size: 1.5em;
  padding-bottom: 1.5em;
  font-family: Calibre-Regular;
  color: #5298e3;
  font-weight: bold;
`;

const StyledTextWrapper = styled.div`
  font-size: 1.5em;
  font-family: Calibre-Regular;
  color: ${(props) => props.theme.colors.base.grey};
`;

const StyledButton = styled.button`
  color: ${(props) => props.theme.colors.base.white};
  font-size: 1.2em;
  padding: 0.5em 0.5em;
  padding-top: 15px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 20px;
  text-align: center;
  width: 80px;
  border-radius: 10px;
  font-family: Calibre-SemiBold;
  background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  background: ${buttonGradient};
  border: 1px solid ${(props) => props.theme.colors.base.white};
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.base.textColor};
    cursor: pointer;
  }
`;
// Function to get the Redirect url from email

const setInitialUrl = () => {
  let isInitialUrl = true;
  const pathname = decodeURIComponent(`${window.location.pathname}`);
  const pathArray = pathname.split("/");

  const urlParams = new URLSearchParams(window.location.search);
  const decodeUrl: any = new URLSearchParams(decodeURIComponent(`${window.location.href}`));
  const sourceFromUrl = urlParams.get("source");
  const auctionNameFromUrl = decodeUrl.get("auctionName");
  const changePlanUrl = urlParams.get("changePlan");
  const redirectTo = urlParams.get("redirectTo");

  if (isInitialUrl && sourceFromUrl === 'email') {
    if(redirectTo === "add-inventory"){
      localStorage.setItem("sellerBtnClick", "true");
    }else{
      localStorage.setItem('urlRedirectFromEmail', `${pathArray[2]}?${urlParams}`);
      isInitialUrl = false;
    }
  } else if (isInitialUrl && redirectTo === "preview") {
    localStorage.setItem("urlRedirectFromEmail", `/preview`);
    isInitialUrl = false;
  } else if (isInitialUrl && sourceFromUrl === "admin") {
    localStorage.setItem(
      "urlRedirectFromAdmin",
      `${pathArray.slice(2).join("/")}?${urlParams}`
    );
    isInitialUrl = false;
  } else if (isInitialUrl && sourceFromUrl === "auctionId") {
    localStorage.setItem(
      "urlPendingProductFromEmail",
      `${pathArray[2]}/${pathArray[3]}?${urlParams}`
    );
    isInitialUrl = false;
  } else if (isInitialUrl && auctionNameFromUrl === "365products") {
    localStorage.setItem(
      "urlProductAuctionFromEmail",
      `/365products/${decodeUrl.get("auctionType")}`
    );
  } else if (isInitialUrl && changePlanUrl === "isChangePlan") {
    localStorage.setItem("changePlanUrlFromUrl", changePlanUrl);
  }
}

const BasicAppRouter = React.forwardRef((props: IBasicAppRouterProps, ref) => {
  const [isUserDeactivate, setIsUserDeactivate] = useState(false);
  setInitialUrl();
  const { t, i18n } = props;

  localStorage.removeItem("pathname");

  const pathname = new URL(window.location.href).pathname;
  const pathArray = pathname.split("/");
  var langCode = pathArray[1] === "" ? window.location.href.includes("zipang.") ? "en" : "jp" : pathArray[1] === "zipang" ? "en" : pathArray[1];

  if (langCode === "en") {
    langCode = "en";
    localStorage.setItem("language", "EN");
  } else if (langCode === "zh_CN") {
    langCode = "zh_CN";
    window.localStorage.setItem("language", "zh_CN");
  } else {
    langCode = "jp";
    localStorage.setItem("language", "日本語");
  }

  var lang = localStorage.getItem("language");

  var languageCode =
    langCode !== "" && languageCodesArray.indexOf(langCode) !== -1
      ? langCode
      : languageCodesArray[0];
  const baseName = `/${languageCode}`;
  if (
    pathname !== "/en" &&
    pathname !== "/jp" &&
    pathname !== "/" &&
    pathname !== `/preview` &&
    !pathname.includes('sell') &&
    !pathname.includes('newaboutus') &&
    !pathname.includes('team-page') &&
    !pathname.includes('auctionslist') &&
    !pathname.includes('zipangAuctionslist') &&
    pathname !== `/preview` &&
    pathname !== "/auction" &&
    pathname !== `/365products` &&
    pathname !== `${baseName}/preview` &&
    pathname !== `${baseName}/auction` &&
    pathname !== "/watchlist" &&
    pathname !== `${baseName}/watchlist` &&
    pathname !== "/profile" &&
    pathname !== `${baseName}/profile` &&
    pathname !== "/faq" &&
    pathname !== `${baseName}/faq` &&
    pathname !== "/termsandconditions" &&
    pathname !== `${baseName}/termsandconditions` &&
    pathname !== "/auction/details/:auctionId/:auctionName/:totalProducts" &&
    pathname !==
    `${baseName}/auction/details/:auctionId/:auctionName/:totalProducts`
  ) {
    if (pathArray[1].includes("en") || pathArray[1].includes("jp") || pathArray[1].includes("zh_CN")) {
      if (
        pathArray[1].includes("en") ||
        pathArray[1].includes("jp") ||
        pathArray[1].includes("zh_CN")
      ) {
        var replacedPath = pathname.replace(`/${pathArray[1]}`, "");
        localStorage.setItem("pathname", replacedPath);
      }
    }
  }
  if (lang === "EN") {
    languageCode = "en";
    window.localStorage.setItem("language", "EN");
  } else if (lang === "zh_CN") {
    languageCode = "zh_CN";
    window.localStorage.setItem("language", "zh_CN");
  } else {
    languageCode = "jp";
    window.localStorage.setItem("language", "日本語");
  }
  if (i18n.language !== languageCode) {
    i18n.changeLanguage(languageCode);
  }

  useEffect(() => {
    var subdomain = window.location.hostname;
    var finalDomainName = `${domainName}`.replace(
      /(^\w+:|^)\/\//,
      ""
    );
    subdomain = subdomain.replace(`.${finalDomainName}`, "");
    if (subdomain === "mjdiamonds") {
      const modifiedURL = window.location.href.replace(subdomain, 'zipang');
      // Redirect to the modified URL
      window.location.replace(modifiedURL);
    }
    PubSub.subscribe("Deactivate", function (msg: any, data: any) {
      setIsUserDeactivate(true);
    });
  }, []);

  return (
    <Router basename={baseName}>
      <div>
        <Switch>
          <Route
            path="/"
            render={(props) => {
              var subdomain = window.location.hostname;
              var finalDomainName = `${domainName}`.replace(
                /(^\w+:|^)\/\//,
                ""
              );
              subdomain = subdomain.replace(`.${finalDomainName}`, "");
              if (subdomain !== null && subdomain !== "") {
                if (subdomain.toString().toLowerCase().startsWith("www.")) {
                  subdomain = subdomain.substring(4);
                } else if (subdomain.toString().toLowerCase() === "www") {
                  subdomain = "";
                }
              }
              const LandingPageComponent = newHomePage
                ? NewLandingPage
                : LandingPage;

              PubSub.publish("ProfileStatus", "Yes");
              if (
                subdomain.includes(".") ||
                subdomain.includes("localhost") ||
                subdomain === ""
              ) {
                return oAuth() ? (
                  <Home t={t} {...props} />
                ) : (
                  <LandingPageComponent t={t} {...props} />
                );
              } else {
                return oAuth() ? (
                  <Home t={t} {...props} />
                ) : (
                  <Redirect to={`/${subdomain}`} />
                );
              }
            }}
            exact
          />
          <Route
            path="/auctionslist/:auctionType"
            render={(props) => <AuctionList t={t} {...props} />}
          />
          <Route
            path="/zipangAuctionslist/:auctionType"
            render={(props) => <AuctionListZipang t={t} {...props} />}
          />
          <Route
            path="/newaboutus"
            render={(props) => <NewAboutUs t={t} {...props} />}
          />
          <Route
            path="/sell"
            render={(props) => <SellPage t={t} {...props} />}
          />
          <Route
            path="/zipang"
            render={(props) =>
              oAuth() ? <Home t={t} {...props} /> : <ZipangLandingPage />
            }
          />
          <Route
            path="/glowstardiam"
            render={(props) =>
              oAuth() ? <Home t={t} {...props} /> : <GlowStar />
            }
          />
          {/* <Route
            path="/mjdiamonds"
            render={(props) =>
              oAuth() ? (
                <Home t={t} {...props} />
              ) : (
                <MjDiamonds t={t} {...props} />
              )
            }
          /> */}
          <Route path="/mjdiamonds">
            <Redirect to="/zipang" />
          </Route>
          <Route
            path="/mko"
            render={(props) =>
              oAuth() ? <Home t={t} {...props} /> : <MKO t={t} {...props} />
            }
          />
          <Route
            path="/sysquareind"
            render={(props) =>
              oAuth() ? (
                <Home t={t} {...props} />
              ) : (
                <SysqureInd t={t} {...props} />
              )
            }
          />
          <Route
            path="/okura"
            render={(props) =>
              oAuth() ? <Home t={t} {...props} /> : <Okura t={t} {...props} />
            }
          />
          <Route
            path="/nihonauction"
            render={(props) =>
              oAuth() ? (
                <Home t={t} {...props} />
              ) : (
                <Nihonauction t={t} {...props} />
              )
            }
          />
          <Route
            path="/Selby"
            render={(props) =>
              oAuth() ? <Home t={t} {...props} /> : <Selby t={t} {...props} />
            }
          />
          <Route
            path="/hokuyu"
            render={(props) =>
              oAuth() ? (
                <Home t={t} {...props} />
              ) : (
                <Hokuyu t={t} {...props} />
              )
            }
          />
          <Route
            path="/createfreewebsite"
            render={(props) =>
              oAuth() ? (
                <Home t={t} {...props} />
              ) : (
                <CreateWebSite t={t} {...props} />
              )
            }
          />
          <Route
            path="/webDemo"
            render={(props) =>
              oAuth() ? <Home t={t} {...props} /> : <CreateWebDummy />
            }
          />
          <Route
            path="/dialuster"
            render={(props) =>
              oAuth() ? (
                <Home t={t} {...props} />
              ) : (
                <Dialuster t={t} {...props} />
              )
            }
          />
          <Route
            path="/kiraga"
            render={(props) =>
              oAuth() ? (
                <Home t={t} {...props} />
              ) : (
                <Kiraga t={t} {...props} />
              )
            }
          />
          <Route
            path="/takeyama"
            render={(props) =>
              oAuth() ? (
                <Home t={t} {...props} />
              ) : (
                <Takeyama t={t} {...props} />
              )
            }
          />
          <Route
            path="/home"
            render={(props) =>
              oAuth() ? <Home t={t} {...props} /> : <Redirect to="/" />
            }
            exact={true}
          />
          <Route
            path="/home/details/:auctionId/:productId/:auctionDate"
            render={(props) =>
              oAuth() ? <NewProductDetailsPage /> : <Redirect to="/" />
            }
            exact={true}
          />
          <Route
            path="/inquiry"
            render={(props) =>
              oAuth() ? <Inquiry t={t} {...props} /> : <Redirect to="/" />
            }
            exact={true}
          />
          <Route
            path="/preview"
            render={(props) =>
              oAuth() ? <Preview t={t} {...props} /> : <Redirect to="/" />
            }
            exact={true}
          />
          <Route
            path="/preview/home"
            render={(props) =>
              oAuth() ? <Preview t={t} {...props} /> : <Redirect to="/" />
            }
            exact={true}
          />
          <Route
            path="/preview/diamond"
            render={(props) =>
              oAuth() ? <Preview t={t} {...props} /> : <Redirect to="/" />
            }
            exact={true}
          />{" "}
          <Route
            path="/preview/jewellery"
            render={(props) =>
              oAuth() ? <Preview t={t} {...props} /> : <Redirect to="/" />
            }
            exact={true}
          />
          <Route
            path="/preview/products/:auctionId/:auctionName/:auctionDate"
            render={(props) =>
              oAuth() ? <ProductView t={t} {...props} /> : <Redirect to="/" />
            }
            exact={true}
          />
          <Route
            path="/preview/tendor/products/:auctionId/:auctionUserId/:auctionName/:auctionDate"
            render={(props) =>
              oAuth() ? (
                <TendorPreviewView t={t} {...props} />
              ) : (
                <Redirect to="/" />
              )
            }
            exact={true}
          />
          <Route
            path="/preview/tendor/jewellery/products/:auctionId/:auctionUserId/:auctionName/:auctionDate"
            render={(props) =>
              oAuth() ? (
                <JewelleryTendorPreviewView t={t} {...props} />
              ) : (
                <Redirect to="/" />
              )
            }
            exact={true}
          />
          <Route
            path="/preview/jewelleyProducts/:auctionId/:auctionName/:auctionDate"
            render={(props) =>
              oAuth() ? (
                <JewelleryProductView t={t} {...props} />
              ) : (
                <Redirect to="/" />
              )
            }
            exact={true}
          />
          <Route
            path="/preview/tendor/products/:auctionId/:auctionName/:auctionDate"
            render={(props) =>
              oAuth() ? (
                <TendorPreviewView t={t} {...props} />
              ) : (
                <Redirect to="/" />
              )
            }
            exact={true}
          />
          <Route
            path="/preview/tendor/jewellery/products/:auctionId/:auctionName/:auctionDate"
            render={(props) =>
              oAuth() ? (
                <JewelleryTendorPreviewView t={t} {...props} />
              ) : (
                <Redirect to="/" />
              )
            }
            exact={true}
          />
          <Route
            path="/preview/tendor/products/:auctionId/:auctionUserId/:auctionName/:auctionDate"
            render={(props) =>
              oAuth() ? (
                <TendorPreviewView t={t} {...props} />
              ) : (
                <Redirect to="/" />
              )
            }
            exact={true}
          />
          <Route
            path="/preview/tendor/jewellery/products/:auctionId/:auctionUserId/:auctionName/:auctionDate"
            render={(props) =>
              oAuth() ? (
                <JewelleryTendorPreviewView t={t} {...props} />
              ) : (
                <Redirect to="/" />
              )
            }
            exact={true}
          />
          <Route
            path="/preview/details/:auctionId/:productId/:auctionDate"
            render={(props) =>
              oAuth() ? <NewProductDetailsPage /> : <Redirect to="/" />
            }
            exact={true}
          />
          {/* <Route
              path="/preview/details/:auctionId/:productId/:auctionDate"
              render={(props) =>
                oAuth() ? (
                  <ProductDetailsViewNew t={t} {...props} />
                ) : (
                  <Redirect to="/" />
                )
              }
              exact={true}
            /> */}
          <Route
            path="/preview/tender/details/:auctionId/:productId/:auctionDate"
            render={(props) =>
              oAuth() ? (
                <TendorDetailsViewNew t={t} {...props} />
              ) : (
                <Redirect to="/" />
              )
            }
            exact={true}
          />
          <Route
            path="/preview/tender/jewellery/details/:auctionId/:productId/:auctionDate"
            render={(props) =>
              oAuth() ? (
                <TendorDetailsView t={t} {...props} />
              ) : (
                <Redirect to="/" />
              )
            }
            exact={true}
          />
          <Route
            path="/preview/jewelleryPreview/details/:auctionId/:productId/:auctionDate"
            render={(props) =>
              oAuth() ? (
                <NewJwlProductDetailsPage />
              ) : (
                // <JewelleryProductDetailsView t={t} {...props} />
                <Redirect to="/" />
              )
            }
            exact={true}
          />
          <Route
            path="/home/jewellery/details/:auctionId/:productId/:auctionDate"
            render={(props) =>
              oAuth() ? <NewJwlProductDetailsPage /> : <Redirect to="/" />
            }
            exact={true}
          />
          <Route
            path="/home/tendor/details/:auctionId/:productId/:auctionDate"
            render={(props) =>
              oAuth() ? (
                <TendorDetailsViewNew t={t} {...props} />
              ) : (
                <Redirect to="/" />
              )
            }
            exact={true}
          />
          <Route
            path="/home/tendor/jewellery/details/:auctionId/:productId/:auctionDate"
            render={(props) =>
              oAuth() ? (
                <TendorDetailsView t={t} {...props} />
              ) : (
                <Redirect to="/" />
              )
            }
            exact={true}
          />
          <Route
            path="/auction"
            render={(props) =>
              oAuth() ? <Auction t={t} {...props} /> : <Redirect to="/" />
            }
            exact={true}
          />
          <Route
            path="/auction/home"
            render={(props) =>
              oAuth() ? <Auction t={t} {...props} /> : <Redirect to="/" />
            }
            exact={true}
          />
          <Route
            path="/auction/diamond"
            render={(props) =>
              oAuth() ? <Auction t={t} {...props} /> : <Redirect to="/" />
            }
            exact={true}
          />
          <Route
            path="/auction/jewellery"
            render={(props) =>
              oAuth() ? <Auction t={t} {...props} /> : <Redirect to="/" />
            }
            exact={true}
          />
          <Route
            path="/auction/details/:auctionId/:auctionName/:totalProducts"
            render={(props) =>
              oAuth() ? (
                <NewLiveAuctionContainer t={t} {...props} />
              ) : (
                <Redirect to="/" />
              )
            }
          />
          <Route
            path="/auction/tender/details/:auctionId/:auctionUserId"
            render={(props) =>
              oAuth() ? (
                <TendorAuctionView t={t} {...props} />
              ) : (
                <Redirect to="/" />
              )
            }
          />
          <Route
            path="/auction/tender/details/:auctionId/:auctionUserId/:totalProducts"
            render={(props) =>
              oAuth() ? (
                <TendorAuctionView t={t} {...props} />
              ) : (
                <Redirect to="/" />
              )
            }
          />
          <Route
            path="/jewelleryAuction/details/:auctionId/:auctionName/:totalProducts"
            render={(props) =>
              oAuth() ? (
                <NewJewelleryLiveAuctionContainer t={t} {...props} />
              ) : (
                <Redirect to="/" />
              )
            }
          />
          <Route
            path="/auction/tender/jewellery/details/:auctionId/:auctionUserId"
            render={(props) =>
              oAuth() ? (
                <JewelleryTendorAuctionView t={t} {...props} />
              ) : (
                <Redirect to="/" />
              )
            }
          />
          <Route
            path="/auction/tender/jewellery/details/:auctionId/:auctionUserId/:totalProducts"
            render={(props) =>
              oAuth() ? (
                <JewelleryTendorAuctionView t={t} {...props} />
              ) : (
                <Redirect to="/" />
              )
            }
          />
          <Route
            path="/watchlist/:auctionId/:auctionUserId/:auctionDate/:auctionType"
            render={(props) =>
              oAuth() ? <Watchlist t={t} {...props} /> : <Redirect to="/" />
            }
            exact={true}
          />
          <Route
            path="/watchlist/details/:auctionId/:productId/:auctionDate"
            render={(props) =>
              oAuth() ? (
                <ProductDetailsViewNew t={t} {...props} />
              ) : (
                <Redirect to="/" />
              )
            }
            exact={true}
          />
          <Route
            path="/watchlist/jewellery/details/:auctionId/:productId/:auctionDate"
            render={(props) =>
              oAuth() ? (
                <JewelleryProductDetailsView t={t} {...props} />
              ) : (
                <Redirect to="/" />
              )
            }
            exact={true}
          />
          <Route
            path="/invoice"
            render={(props) =>
              oAuth() ? <Invoice t={t} {...props} /> : <Redirect to="/" />
            }
            exact={true}
          />
          <Route
            path="/invoice/365DaysAuctionsinvoice"
            render={(props) =>
              oAuth() ? (
                <ProductAuctionInvoice t={t} {...props} />
              ) : (
                <Redirect to="/" />
              )
            }
            exact={true}
          />
          {/* add new route for invoice categoryType */}
          <Route
            path="/invoice/home"
            render={(props) =>
              oAuth() ? <Invoice t={t} {...props} /> : <Redirect to="/" />
            }
            exact={true}
          />
          <Route
            path="/invoice/diamond"
            render={(props) =>
              oAuth() ? <Invoice t={t} {...props} /> : <Redirect to="/" />
            }
            exact={true}
          />
          <Route
            path="/invoice/jewellery"
            render={(props) =>
              oAuth() ? <Invoice t={t} {...props} /> : <Redirect to="/" />
            }
            exact={true}
          />
          <Route
            path="/profile"
            render={(props) =>
              oAuth() ? <Profile t={t} {...props} /> : <Redirect to="/" />
            }
            exact={true}
          />
          <Route
            path="/KycForm/:userId"
            render={(props) => <KycForm t={t} {...props} />}
            exact={true}
          />
          <Route
            path="/product/:productId/:auctionDate/detail"
            render={(props) => <ShareProductDetailsView t={t} {...props} />}
            exact={true}
          />
          <Route
            path="/product/jewellery/:productId/:auctionDate/detail"
            render={(props) => <ShareJewellerylink t={t} {...props} />}
            exact={true}
          />
          <Route
            path="/product/tender/jewellery/:productId/:auctionDate/detail"
            render={(props) => <ShareJewellerylink t={t} {...props} />}
            exact={true}
          />
          {/* Teppo flow */}
          <Route
            path="/product/pending"
            render={(props) =>
              oAuth() ? (
                <PendingProductPage t={t} {...props} />
              ) : (
                <Redirect to="/" />
              )
            }
            exact={true}
          />
          <Route
            path="/my-page"
            render={(props) =>
              oAuth() ? <Mypage t={t} {...props} /> : <Redirect to="/" />
            }
            exact={true}
          />
          <Route
            path="/my-page/buypage"
            render={(props) =>
              oAuth() ? <Mypage t={t} {...props} /> : <Redirect to="/" />
            }
            exact={true}
          />
          <Route
            path="/my-page/:category/details/:auctionId/:productId/:auctionDate"
            render={() =>
              oAuth() ? (
                <SingleProductDetails redirectFromPage={"/my-page"} />
              ) : (
                <Redirect to="/" />
              )
            }
            exact={true}
          />
          <Route
            path="/notificationpage"
            render={(props) =>
              oAuth() ? (
                <NotificationPage t={t} {...props} />
              ) : (
                <Redirect to="/" />
              )
            }
            exact={true}
          />
          {/* 365 ProductAuction All Route */}
          <Route
            path="/365products"
            render={(props) =>
              oAuth() ? (
                <ProductAuctionContainer t={t} {...props} />
              ) : (
                <Redirect to="/" />
              )
            }
            exact={true}
          />
          <Route
            path="/365products/:auctionType"
            render={(props) =>
              oAuth() ? (
                <ProductAuctionContainer t={t} {...props} />
              ) : (
                <Redirect to="/" />
              )
            }
            exact={true}
          />
          <Route
            path="/productCart/:auctionId/:categoryType"
            render={(props) =>
              oAuth() ? (
                <ProductCartSection t={t} {...props} />
              ) : (
                <Redirect to="/" />
              )
            }
            exact={true}
          />
          <Route
            path="/home/365products/details/:auctionId/:auctionUserId/:productId/:auctionDate"
            render={(props) =>
              oAuth() ? (
                <CommonDetailsView t={t} {...props} />
              ) : (
                <Redirect to="/" />
              )
            }
            exact={true}
          />
          <Route
            path="/365products/details/:auctionId/:auctionUserId/:productId/:auctionDate"
            render={(props) =>
              oAuth() ? (
                <CommonDetailsView t={t} {...props} />
              ) : (
                <Redirect to="/" />
              )
            }
            exact={true}
          />
          <Route
            path="/termsandconditions"
            render={() => <ZipangTermsAndConditionPage />}
            exact={true}
          />
          <Route
            path="/faq"
            render={(props) => <FAQ t={t} {...props} />}
            exact={true}
          />
          <Route
            path="/PaymentForm/:userId"
            render={(props) => <PaymentForm t={t} {...props} />}
            exact={true}
          />
          <Route
            path="/AboutUs"
            render={(props) => <AboutUs t={t} {...props} />}
            exact={true}
          />
          <Route
            path="/SubDomain"
            render={(props) => <SubDomain t={t} {...props} />}
            exact={true}
          />
          <Route
            path="/SubdomainProfile"
            render={(props) =>
              oAuth() ? (
                <SubdomainProfile t={t} {...props} />
              ) : (
                <Redirect to="/" />
              )
            }
            exact={true}
          />
          <Route
            path="/Privacy-Policy"
            render={() => <Privacy />}
            exact={true}
          />
          <Route
            path="/newaboutus"
            render={(props) => <NewAboutUs t={t} {...props} />}
          />
          <Route
            path="/team-page"
            render={(props) => <OurTeamPage {...props} />}
          />
          <Route component={() => <PageNotFound t={t} {...props} />} />
        </Switch>
        {isUserDeactivate ? (
          <Dialog aria-labelledby="simple-dialog-title" open={true}>
            <StyledParticipateWrapper item md={12} xs={12} lg={12}>
              <StyledHeadingTextWrapper> Error </StyledHeadingTextWrapper>
              <StyledTextWrapper>
                {t("common:blockUserPopup:firstLine")}
              </StyledTextWrapper>
              <StyledTextWrapper>
                {t("common:blockUserPopup:secondLine")}
              </StyledTextWrapper>
              <StyledButton
                onClick={() => {
                  Auth.logout();
                  setIsUserDeactivate(false);
                }}
              >
                {t("common:button:ok")}
              </StyledButton>
            </StyledParticipateWrapper>
          </Dialog>
        ) : null}
      </div>
    </Router>
  );
});

export default BasicAppRouter;
