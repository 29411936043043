import { Grid } from "@material-ui/core";
import styled, { css } from "styled-components";
import { backgroundGradient } from "../../../../common/materials/LinearGradient";

const StyledAuctionbidWrapper = styled(Grid)`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  .notDisablebutton {
    background: ${backgroundGradient};
    cursor: pointer;
  }
  .disableButton {
    cursor: default;
    background: ${(props) => props.theme.colors.base.lightGrey};
  }

  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    padding: 10px 10px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    padding: 10px 20px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    padding: 10px 20px;
  }
`;
const StyledTitleWrapper = styled(Grid)`
  display: flex;
  align-items: center;
  font-size: 0.7em;
  .height {
    height: 100%;
  }
`;

interface IChangeColorProps {
  changeColor?: String;
}

const StyledCurrentBid = styled(Grid)<IChangeColorProps>`
  background: ${(props) => props.theme.colors.base.white};
  box-shadow: 2px 0px 5px 2px #dcdcdc;
  border-radius: 20px;
  padding-top: 10px;
  margin-top: 10px;
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    font-size: 0.8em;
    padding-top: 5px;
    margin-top: 5px;
  }
  ${(props) =>
    props.changeColor === "white" &&
    css`
      background: white;
    `}
  ${(props) =>
    props.changeColor === "#FFEB9C" &&
    css`
      background: #ffeb9c;
    `}

    ${(props) =>
    props.changeColor === "#FBE4D5" &&
    css`
      background: #fbe4d5;
    `}
`;

const StyledContainernew = styled.div`
  display: flex;
  justify-content: center;
  height: 600px;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (width: 320px) {
    height: 530px;
    overflow: auto;
  }
  @media (width: 280px) {
    height: 560px;
    overflow: auto;
  }
  @media (width: 1280px) {
    height: 660px;
    overflow: auto;
  }
  @media (width: 1366px) {
    height: 690px;
    overflow: auto;
    ::-webkit-scrollbar {
      display: none;
    }
  }
  @media (min-width: 1366px) {
    height: 700px;
    overflow: auto;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

export {
  StyledContainernew,
  StyledCurrentBid,
  StyledTitleWrapper,
  StyledAuctionbidWrapper,
};