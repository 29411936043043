import React, { useEffect, useRef, useState } from "react";
import { match } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import MUIDataTable, {
  MUIDataTableColumnDef,
  MUIDataTableOptions,
} from "mui-datatables";
import IRouterComponentProps from "../../../models/IRouterComponentProps";
import AuthService from "../../auth/AuthService";
import {
  productTextSearch,
  auctionTendorProductUrl,
  tendorBidUrl,
  productDownload,
  getBidInfo,
  getAuctionEndTimeUrl,
  productUrl,
  getMyLatestBidsForUser,
} from "../../../common/config/app.endpoints";
import AppLayout from "../../layouts/AppLayout";
import Alert from "../../../common/elements/Alert";
import LoadingSpinner from "../../../common/elements/LoadingSpinner";
import ApiTimeout from "../../../modules/ApiTimeout";
import { oAuth } from "../../../routes/basic/BasicAppRouter";
import {
  StyledButton,
  StyledModal,
} from "../../../common/materials/GenericStyleComponent";
import BlockUserModal from "../BlockUser/BlockUserModal";
import { MuiThemeProvider, useTheme } from "@material-ui/core/styles";
import update from "immutability-helper";
import JewelleryFilter from "./JewelleryFilter";
import moment from "moment";
import CommonFilter from "../CommonFilter";
import Countdown from "react-countdown";
import TendorJewelleryMyBids from "./TendorJewelleryMyBids";
import ViewEndedModal from "../auction/ViewEndedModal";
import ActionTenderPagesize from "../../core-grid/grid-actions/ActionTenderPagesize";
import EllipsisText from "../../../common/elements/EllipsisText";
import {
  createTheme,
  IconButton,
  Menu,
  MenuItem,
  useMediaQuery,
} from "@material-ui/core";
import TenderListOfProduct from "./tenderMobileComponets/TenderListOfProduct";
import { StyledTextWrapper } from "./JewelleryTendorPreviewView";
import { StyledButtonView, Styledview } from "./TendorPreviewView";
import copy from "copy-to-clipboard";
import {
  faEdit,
  faHeart,
  faShareSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ViewTenderExtendedModal from "../auction/ViewTenderExtendedModal";
import {
  convertedValue,
  getMyLatestStoredBids,
  getReturnMyLatestStoredBid,
} from "./TenderGenericFunc";
import { StyledHeaderContainer } from "./TendorAuctionView";
import JwlBulkBidPopup from "./bidPopupComponent/JwlBulkBidPopup";
import JwlTenderSlider from "./bidPopupComponent/JwlTenderSlider";
import CommanCoreDataGridOfTender from "./tenderMobileComponets/CommanCoreDataGridOfTender";
import { openJewelleryCertificateLinkPopup } from "../../../common/GenericFunctions";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PubSub from "pubsub-js";
import AlertReadOnlyUser from "../../../common/elements/AlertReadOnlyUser";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import ActionTenderPagesizeComman from "../../core-grid/grid-actions/ActionTenderPagesizeComman";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { RootState } from "../../../redux/reducer";
import {
  reSetIsMybids,
  reSetJwlIsMybids,
} from "../../../redux/slice/commanPagination/commanPaginationSlice";
import TendorTabLayoutJwl from "./TendorTabLayoutJwl";
import {
  resetJewelleryCurrentApiStartCount,
  selectJewelleryCurrentApiStartCount,
  selectJewelleryItemPerPage,
  setJewelleryCurrentApiStartCount,
  setJewelleryItemPerPage,
} from "../../../redux/slice/commanPagination/commanPaginationSlice";
import {
  resetTenderGridProductAllProductIndexes,
  resetTenderjewelleryAllProductSelectAll,
  resetTenderJwlIsGridView,
  selectTenderJwlGridView,
  setTenderGridProductAllProductIndexes,
  setTenderjewelleryAllProductSelectAll,
  setTenderJwlIsGridView,
} from "../../../redux/slice/annual/commanAnnualSlice";
import { StyledSelectAllButtonHeader } from "../../365Auction/component/DiamondProductStyle";
import { biddingProductDetails } from "../../../redux/slice/commonProduct/commonProductDetailsSlice";
import BasicSearchBox from "../../core-grid/grid-actions/BasicSearchBox";

const faEditProps = faEdit as IconProp;
const faHeartProps = faHeart as IconProp;
const faShareSquareProps = faShareSquare as IconProp;
interface DetailParams {
  auctionId: string;
  auctionUserId: string;
  auctionDate: string;
  auctionName: string;
}

export interface IProductViewProps extends IRouterComponentProps {
  match: match<DetailParams>;
  t?: any;
  location: any;
}
const StyledLayoutGridMain = styled(Grid)`
  .MuiIconButton-edgeEnd {
    display: contents !important;
    transition: width 5s !important;
  }
  .MuiAccordionSummary-root {
    min-height: 10px !important;
    transition: width 4s !important;
  }
  .MuiPaper-root {
    box-shadow: none !important;
  }
  .AccordionHeader {
    width: 100%;
    transition: width 1s;

    @keyframes fadeMe {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    ${(props) => props.theme.breakpoints.down("md")} {
      width: 100%;
    }
  }
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    padding: 10px 10px;
  }
`;

const StyledLayoutGrid = styled(Grid)`
  padding: 10px;
  .MuiPaper-root {
    width: 100%;
  }
  .MuiTableCell-head {
    padding: 10px 5px !important;
    // z-index: 0;
  }
  .MuiTableCell-root {
    padding: 7px;
    border: 1px solid lightgray;
  }
  .MuiToolbar-regular {
    min-height: 0px;
  }
  .MuiTableCell-head {
    background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
    color: white;
    text-align: center;
    font-weight: 700;
    border: 1px solid lightgray;
  }

  .MuiTableCell-root {
    text-align: center;
    // position: relative;
  }

  .MuiTableCell-head .MuiButton-root {
    color: white;
    text-align: center;
    font-weight: 700;
  }
  .linkCss {
    text-decoration: underline;
    color: blue;
    cursor: pointer;
  }

  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    padding: 10px 10px;
  }
`;
const StyledBtnContainer = styled.div`
  text-align: center;
`;

const StyledCoreDataGridContainer = styled(Grid)``;
const StyledActionBarContainer = styled(Grid)`
  align-items: center;
  padding: 10px;
  margin: 10px 0px 10px 0px;
  border-radius: 5px;
  box-shadow: 2px 0px 5px 2px ${(props) => props.theme.colors.base.lightGrey};
  justify-content: flex-end;
  &:focus {
    outline: none;
  }
`;

export const StyledButtonGray = styled.button`
  color: ${(props) => props.theme.colors.base.black};
  border: none;
  font-size: 0.8em;
  padding: 0.6em;
  margin: 0.3em;
  border-radius: 5px;
  background: ${(props) => props.theme.colors.base.gray};
  border: 1px solid ${(props) => props.theme.colors.base.white};
`;

const StyledButtonHeader = styled.button`
  color: ${(props) => props.theme.colors.base.grey};
  font-size: 1em;
  padding: 0.5em;
  margin: 0.3em;
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.colors.base.lightGrey};
  background: ${(props) => props.theme.colors.base.veryVeryLightGrey};
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.base.textColor};
    cursor: pointer;
  }

  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 0.8em;
  }
`;

const StyledTabLayout = styled(Grid)``;

const StyledTimeGridContainer = styled(Grid)`
  padding: 10px;
  justify-content: flex-end;
`;

const StyledTimer = styled.div`
  font-size: 1.3em;
  font-family: "Calibre-Regular";
  color: ${(props) => props.theme.colors.base.textColor};

  .countdownText {
    font-weight: bold;
    width: 100%;
    text-align: center;
  }
  .dateTimeStyle {
    width: 100%;
    display: flex;
    padding: 5px;
    align-items: center;
    justify-content: center;
  }

  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 1em;
    margin-top: 0.5em;
  }
`;
const StyledContent = styled(Grid)`
  text-align: center;
`;

const StyledNameContent = styled(Grid)`
  text-align: center;
  font-size: 1.3em;
  font-family: "Calibre-Regular";
  color: ${(props) => props.theme.colors.base.textColor};
`;

const StyledName = styled(Grid)`
  dispaly: flex;
  padding: 10px;
  @media (min-width: 1024px) {
    text-align: end;
  }
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 1.1em;
    // margin-top: 0.4em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const StyledBoxContent = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 20px;
  border-top: 4px solid #000f7e;
`;

const StyledDropDown = styled.img`
  width: 2em;
  height: 2em;
  cursor: pointer;
`;

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 5px;
`;

let data: any = {};
let totalElement: number = 0;
let page: number = 0;

let payload: any = {};
let startCount: number = 0;
let endCount: number = 10;
let rowSelectedData: any;
let updatedRowData: any;
let updateRowIndex: any;
let auctionProductResult: any;
let seconds: any = "";

let filter: any = [];
let filteredValues: any = "";
let filteredParcelValues: any = "";
let body: any = [];
let auctionName: string = "";

let details: any;
let length: any;
let timeLeftLableColor: boolean;

//single product redirect
let redirectProduct: any = [];

const columnIndexes = {
  serialNo: 0,
  productId: 1,
  auctionUserId: 2,
  productCode: 4,
  lab: 5,
  certificate: 6,
  description: 7,
  currency: 8,
  remarks: 9,
  notes: 10,
  peicePerCt: 11,
  myLatestBids: 12,
  currentBidPrice: 13,
  noOfBids: 14,
  rowColor: 15,
  exchangeRate: 16,
  noOfBidders: 19,
  currentBidValue: 17,
  hieghestBidValue: 18,
  watched: 20,
  sellerAuctionUserId: 21,
  previousHighestBidder: 23,
  holdPreviousBidder: 24,
};
let date: string | string[] = "";
let auctionDate: any;
let indexsForBulkBidItems: any = [];
let myLatestBidForProduct: any = [];
let isReadOnlyUser: any;
let isAuctionUserId: boolean;
const initialState = {
  mouseX: null,
  mouseY: null,
};
const JewelleryTendorAuctionView = (props: IProductViewProps) => {
  const getMuiTheme = () =>
    createTheme({
      overrides: {
        MUIDataTableSelectCell: {
          fixedHeader: {
            background: "white",
          },
          fixedLeft: {
            background: "white",
          },
        },
      },
    });

  const [auctionProducts, setAuctionProducts] = useState([]);
  const [openToast, setOpenToast] = useState(false);
  const [showAlertMessage, setShowAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [alertType, setAlertType] = useState("");
  const [isShowSearchBox, setShowSearchBox] = useState(true);

  const [apiTimeout, setApiTimeout] = useState(false);
  const [onAction, setOnAction] = useState(false);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const [userBlock, setUserBlock] = useState(false);
  const [textSearchArray, setTextSearchArray] = useState([]);
  const [optionValue, setOptionValue] = useState("");
  const [openEndedModal, setOpenEndedModal] = useState(false);
  const [showTenderSlider, setshowTenderSlider] = useState(false);
  const [openTenderExtended, setOpenTenderExtended] = useState(false);
  const [showBulkBidPopup, setShowBulkBidPopup] = useState(false);
  const [showBulkBidButton, setShowBulkBidButton] = useState(false);
  const [isUserClosedExtendedPopup, setIsUserClosedExtendedPopup] =
    useState(false);
  const [isDataRefresh, setIsDataRefresh] = useState(false);
  const [isDataTableRefresh, setIsDataTableRefresh] = useState(false);
  const [exportExcelDownload, setExportExcelDownload] = useState(true);
  const [isTopElementHide, setIsTopElementHide] = useState(false);
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [openReadOnlyUserToast, setOpenReadOnlyUserToast] = useState(false);
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [isTableExpanded, setIsTableExpanded] = useState(false);
  const [tabValue, setTabValue] = useState({
    allProducts: true,
    myBids: false,
  });
  const [currentProductId, setCurrentProductId] = useState<string>("");
  const [mouseState, setMouseState] = React.useState<{
    mouseX: null | number;
    mouseY: null | number;
  }>(initialState);
  const Auth = new AuthService();
  const { t } = props;
  const eventSource: any = useRef<any>();
  const maxRetryCount = 2;
  const countRef = useRef(0);

  const jwlItemPerPageRef = useRef(10);
  const selectJwlPageUseRef = useRef(0);

  const [shouldGetAllData, setShouldGetAllData] = useState(false);
  const [singleBiddingPopup, setSingleBiddingPopup] = useState(false);

  const dispatch: AppDispatch = useDispatch();

  const isGridView: any = useSelector((state: RootState) =>
    selectTenderJwlGridView(state)
  );
  useEffect(() => {
    getAuctionEndTime(props.match.params.auctionId);
    dispatch(resetTenderGridProductAllProductIndexes());
    dispatch(resetTenderjewelleryAllProductSelectAll());
    //data table api calling
    isReadOnlyUser = localStorage.getItem("isReadOnlyUser");
    isReadOnlyUser = JSON.parse(isReadOnlyUser);
    localStorage.removeItem("currentPageOfTenderAllProducts");
    // localStorage.removeItem("dropDownFilterVal");
    localStorage.removeItem("auctionUserId");
    localStorage.removeItem("userId");
    localStorage.removeItem("jwlitemPerPageSelected");
    localStorage.removeItem("jwlactivePageNumber");
    localStorage.removeItem("jwlapiStartCount");
    //note: we are removing jwlcurrentPageOfTenderAllProducts from localstorage since we already loaded that page in "getTendorAucProducts()"
    localStorage.removeItem("jwlcurrentPageOfTenderAllProducts");
    getMyLatestBids(props.match.params.auctionId);
    getAllUpdatedData();
    // refresh bid value
    dispatch(reSetIsMybids());

    setTimeout(() => {
      setIsDataRefresh(!isDataRefresh);
    }, 3000);
    return () => {
      if (eventSource.current !== undefined || eventSource.current !== null) {
        if (eventSource.current.close !== undefined) {
          eventSource.current.close();
        }
      }
      auctionName = "";
    };
  }, []);

  const selectcurrentApiStartCountValue: any = useSelector((state: RootState) =>
    selectJewelleryCurrentApiStartCount(state)
  );

  const selectItemPerPageValue: any = useSelector((state: RootState) =>
    selectJewelleryItemPerPage(state)
  );

  useEffect(() => {
    localStorage.setItem(
      "jwlapiStartCount",
      JSON.stringify(selectcurrentApiStartCountValue * selectItemPerPageValue)
    );
    getTendorAucProducts(
      props.match.params.auctionId,
      selectcurrentApiStartCountValue
    );
  }, [selectcurrentApiStartCountValue, selectItemPerPageValue]);

  useEffect(() => {
    if (shouldGetAllData) {
      getAllUpdatedData();
      setShouldGetAllData(false);
    }
  }, [shouldGetAllData]);

  useEffect(() => {
    let isShowDrawerIcon: any = localStorage.getItem("isShowDrawerIcon");
    isShowDrawerIcon = JSON.parse(isShowDrawerIcon);
    if (isShowDrawerIcon !== null) {
      if (isShowDrawerIcon) {
        const accordionHeader: any = document.querySelector(".AccordionHeader");
        if (accordionHeader !== null) {
          accordionHeader.style.width = isShowDrawerIcon
            ? "calc(100% - 60px)"
            : "calc(100% - 240px)";
        }
      }
    }
    if (tabValue.allProducts === false) {
      setIsTopElementHide(true);
      setIsTableExpanded(false);
    } else {
      setIsTopElementHide(false);
    }
  });

  const handleChangeAcco =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
      if (isExpanded === false) {
        setIsTableExpanded(false);
      } else {
        setIsTableExpanded(true);
      }
    };

  const getAllUpdatedData = () => {
    if (countRef.current < maxRetryCount) {
      eventSource.current = new EventSource(getBidInfo);
      eventSource.current.onmessage = (event: any) => {
        updatedRowData = JSON.parse(event.data);
        if (updatedRowData.alive) {
          //This will listen only for events
        } else {
          if (props.match.params.auctionId === updatedRowData.auctionId) {
            updateRowIndex = auctionProductResult.findIndex(
              (product: any) => product.perId === updatedRowData.perId
            );
            const mapping: any = {};
            if (
              auctionProductResult !== undefined &&
              auctionProductResult.length > 0
            ) {
              auctionProductResult.map((items: any) => {
                mapping[items.perId] = {
                  watched: items.watched,
                };
                return true;
              });
            }

            var updatedArr: any;
            if (updateRowIndex !== -1) {
              updatedArr = update(auctionProductResult, {
                $splice: [[updateRowIndex, 1, updatedRowData]],
              });
            }

            const arr = [...updatedArr];
            // auctionProductResult = updatedArr;
            arr.map((items: any) => {
              if (mapping[items.perId] !== undefined) {
                items.watched = mapping[items.perId].watched;
              }
              return true;
            });

            auctionProductResult = arr;
            setAuctionProducts(auctionProductResult);
            setShouldGetAllData(false);
          }
        }
      };
      eventSource.current.onerror = (err: any) => {
        console.error("EventSource failed:", err);
        countRef.current = countRef.current + 1;
        setShouldGetAllData(true);
      };
    }
  };

  const getAuctionEndTime = (auctionId: string, callBack?: any) => {
    if (!oAuth()) {
      props.history.push("/");
    } else {
      Auth.getRequest(
        `${getAuctionEndTimeUrl}/${auctionId}/end_time`,
        async (status: number, response: any) => {
          if (status === 200) {
            await getLeftTime(response.endDate);
            timeLeftLableColor = response.tenderExtended;
            setIsLoading(false);
            if (response.state === "Ended") {
              setOpenEndedModal(true);
            }

            if (callBack !== undefined) {
              callBack(response);
            }
          } else if (status === 403) {
            setUserBlock(true);
          } else if (status === 505) {
            setApiTimeout(true);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            setOnAction(false);
            setShowAlertMessage(response[0].message);
            setAlertType("error");
            setOpenToast(true);
          }
        }
      );
    }
  };

  //Note: Get my All LatestBid using this Api
  const getMyLatestBids = (auctionId: string) => {
    if (!oAuth()) {
      props.history.push("/");
    } else {
      Auth.getRequest(
        `${getMyLatestBidsForUser}?auctionId=${auctionId}`,
        (status: number, response: any) => {
          if (status === 200) {
            setIsLoading(false);
            // setMyLatestBidForProduct(response);
            myLatestBidForProduct = response;

            setTimeout(() => {
              setIsDataTableRefresh(!isDataTableRefresh);
            }, 2000);
          } else if (status === 403) {
            setUserBlock(true);
          } else if (status === 505) {
            setApiTimeout(true);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            setOnAction(false);
            setShowAlertMessage("Internal Server Error");
            setAlertType("error");
            setOpenToast(false);
          }
        }
      );
    }
  };

  const getTendorAucProducts = (
    auctionId: number | string,
    currentPage: number,
    isLoadPreSelectedPage?: boolean
  ) => {
    setOpenToast(false);
    body = [];
    filteredValues = localStorage.getItem("filteredValues");
    filteredValues = JSON.parse(filteredValues);

    if (filteredValues !== undefined) {
      if (filteredValues === null || filteredValues.length === 0) {
        filter = null;
      } else {
        filteredValues.map((item: any, index: number) => {
          if (item.name === "auctionId") {
            filteredValues.splice(index, 1);
          }
          return true;
        });
        filter = filteredValues;
      }
    } else {
      filteredParcelValues = localStorage.getItem("filteredParcelValues");
      filteredParcelValues = JSON.parse(filteredParcelValues);

      if (filteredParcelValues !== undefined) {
        if (
          filteredParcelValues === null ||
          filteredParcelValues.length === 0
        ) {
          filter = null;
        } else {
          filteredParcelValues.map((item: any, index: number) => {
            if (item.name === "auctionId") {
              filteredParcelValues.splice(index, 1);
            }
            return true;
          });
          filter = filteredParcelValues;
        }
      }
    }

    getBody(auctionId, currentPage, endCount, isLoadPreSelectedPage);
  };

  const getBody = (
    auctionId: string | number,
    currentPage: number,
    endCount: number,
    isLoadPreSelectedPage?: boolean
  ) => {
    localStorage.setItem(
      "jwlcurrentPageOfTenderAllProducts",
      currentPage.toString()
    );

    data = {};

    filteredValues = localStorage.getItem("filteredValues");
    filteredValues = JSON.parse(filteredValues);

    if (
      selectcurrentApiStartCountValue !== null &&
      selectcurrentApiStartCountValue !== 0
    ) {
      startCount = selectcurrentApiStartCountValue * selectItemPerPageValue;
    } else {
      startCount = selectcurrentApiStartCountValue;
    }

    if (
      selectcurrentApiStartCountValue != null &&
      selectcurrentApiStartCountValue !== 0
    ) {
      page = selectcurrentApiStartCountValue;
    } else {
      page = 0;
    }

    if (selectItemPerPageValue !== null) {
      let savedCount: any = selectItemPerPageValue;
      endCount = savedCount;
    }

    //handle drop downfilter value

    if (
      localStorage.getItem("dropDownFilterVal") !== undefined &&
      localStorage.getItem("dropDownFilterVal") !== null
    ) {
      var tempArr = localStorage.getItem("dropDownFilterVal");
      if (tempArr !== null) var dropDownValues = JSON.parse(tempArr);
      var property = dropDownValues[0].property;
      var direction = dropDownValues[0].direction;
    }

    if (
      filter === null &&
      (filteredValues == null ||
        filteredValues === undefined ||
        filteredValues === "null" ||
        filteredValues.length === 0)
    ) {
      payload = {
        filters: {
          and: [
            {
              match: "exact",
              name: "auctionId",
              value: auctionId,
            },
            {
              match: "exact",
              name: "parcelType",
              value: "Jewellery",
            },
          ],
        },
        page: {
          start: isLoadPreSelectedPage ? currentPage * 10 : startCount,
          count: endCount,
          sort: {
            orders:
              property === "serialNo"
                ? [
                    {
                      property:
                        property !== null && property !== undefined
                          ? property
                          : "serialNo",
                      direction:
                        direction !== null && direction !== undefined
                          ? direction
                          : "ASC",
                    },
                  ]
                : [
                    {
                      property:
                        property !== null && property !== undefined
                          ? property
                          : "serialNo",
                      direction:
                        direction !== null && direction !== undefined
                          ? direction
                          : "ASC",
                    },
                    {
                      property: "serialNo",
                      direction:
                        direction !== null && direction !== undefined
                          ? direction
                          : "ASC",
                    },
                  ],
          },
        },
      };
    } else {
      body = filteredValues ? filteredValues : filter;
      body.map((item: any, index: number) => {
        if (item.name === "auctionId") {
          body.splice(index, 1);
        }
        return true;
      });
      payload = {
        filters: {
          or: body,
          and: [
            {
              match: "exact",
              name: "auctionId",
              value: props.match.params.auctionId,
            },
          ],
        },
        page: {
          start: isLoadPreSelectedPage ? currentPage * 10 : startCount,
          count: endCount,
          sort: {
            orders:
              property === "serialNo"
                ? [
                    {
                      property:
                        property !== null && property !== undefined
                          ? property
                          : "serialNo",
                      direction:
                        direction !== null && direction !== undefined
                          ? direction
                          : "ASC",
                    },
                  ]
                : [
                    {
                      property:
                        property !== null && property !== undefined
                          ? property
                          : "serialNo",
                      direction:
                        direction !== null && direction !== undefined
                          ? direction
                          : "ASC",
                    },
                    {
                      property: "serialNo",
                      direction:
                        direction !== null && direction !== undefined
                          ? direction
                          : "ASC",
                    },
                  ],
          },
        },
      };
    }

    let val = localStorage.getItem("optionValue");

    if (val !== "" && val !== "null" && val != null) {
      setOptionValue(val);
      payload = {
        filters: {
          or: [
            {
              match: "anywhere",
              name: "remarks",
              value: val,
            },
          ],
          and: [
            {
              match: "exact",
              name: "auctionId",
              value: props.match.params.auctionId,
            },
          ],
        },
        page: {
          start: isLoadPreSelectedPage ? currentPage * 10 : startCount,
          count: endCount,
          sort: {
            orders: [
              {
                property: "serialNo",
                direction: "ASC",
              },
            ],
          },
        },
      };
    }
    callApi(payload);
  };

  const handleBidClick = (tableData: any) => {
    rowSelectedData = tableData.rowData;
    rowSelectedData = tableData.rowData;
    getProductsDetails(auctionProducts);
    details = tableData.rowIndex;
    setshowTenderSlider(true);
  };

  //rediract from bid now button of Jewellery product details page -------
  const renderAutomaticBidPopup = (data: any) => {
    const rediractProductId: any = localStorage.getItem(
      "currentJewelleryProductIdOfBidNow"
    );

    if (rediractProductId !== null) {
      const indexOfProduct = auctionProducts.findIndex(
        (item: any) => item.perId === rediractProductId
      );
      rowSelectedData = data.rowData;
      details = indexOfProduct;
      length = auctionProducts.length;
      if (details !== -1) {
        setshowTenderSlider(true);
      } else {
        console.log(biddingProductDetails);
        redirectProduct.push(biddingProductDetails);
        console.log(redirectProduct);
        if (biddingProductDetails !== undefined) {
          const indexOfProduct = redirectProduct.findIndex(
            (item: any) => item.perId === rediractProductId
          );
          rowSelectedData = biddingProductDetails;
          details = indexOfProduct;
          length = redirectProduct.length;
          setSingleBiddingPopup(true);
        }
      }
      setTimeout(() => {
        localStorage.removeItem("currentJewelleryProductIdOfBidNow");
      }, 3000);
    }
  };

  const renderAutomaticBidPopupInMobile = (data: any) => {
    const rediractProductId: any = localStorage.getItem(
      "currentJewelleryProductIdOfBidNow"
    );
    if (rediractProductId !== null) {
      const indexOfProduct = auctionProducts.findIndex(
        (item: any) => item.perId === rediractProductId
      );
      rowSelectedData = data;
      details = indexOfProduct;
      length = auctionProducts.length;
      if (details !== -1) {
        setshowTenderSlider(true);
      } else {
        redirectProduct.push(biddingProductDetails);
        if (biddingProductDetails !== undefined) {
          const indexOfProduct = redirectProduct.findIndex(
            (item: any) => item.perId === rediractProductId
          );
          rowSelectedData = biddingProductDetails;
          details = indexOfProduct;
          length = redirectProduct.length;
          setSingleBiddingPopup(true);
        }
      }
      setTimeout(() => {
        localStorage.removeItem("currentJewelleryProductIdOfBidNow");
      }, 3000);
    }
  };

  const getProductsDetails = (product: any) => {
    length = auctionProducts.length;
    product.map((items: any, index: any) => {
      if (items.perId === rowSelectedData[columnIndexes.productId]) {
        details = index;
      }
      return true;
    });
    return details;
  };

  //index of Mobile ui component
  const getBoxIndex = (boxIndex: any) => {
    length = auctionProducts.length;
    details = boxIndex;
    setshowTenderSlider(true);
  };

  const autoCompleteSearch = (val: any) => {
    //handle local storege
    localStorage.setItem("optionValue", val);
    getTendorAucProducts(props.match.params.auctionId, 0);
  };

  const handleFilters = () => {
    setIsFiltersOpen(!isFiltersOpen);
    setShowSearchBox(!isShowSearchBox);
  };

  const textSearchListing = (value: String) => {
    payload = {
      filters: {
        or: [
          {
            match: "anywhere",
            name: "remarks",
            value: value,
          },
          {
            match: "anywhere",
            name: "productDescription",
            value: value,
          },
        ],
      },
    };

    if (value === "" || value.length === 1) {
      setTextSearchArray([]);
    } else {
      if (value.length > 1) {
        Auth.postRequest(
          productTextSearch,
          payload,
          (status: any, response: any) => {
            if (status === 200) {
              setTextSearchArray(response.results);
            }
          }
        );
      }
    }
  };

  const callApi = (payload: any) => {
    if (!oAuth()) {
      props.history.push("/");
    } else {
      Auth.postRequest(
        `${auctionTendorProductUrl}?auctionId=${props.match.params.auctionId}`,
        payload,
        (status: any, response: any) => {
          setIsLoading(false);
          if (status === 200) {
            setAuctionProducts(response.results);
            totalElement = response.totalElements;
            if (response.totalElements === 0) {
              totalElement = 0;
            }
            setTimeout(() => {
              setIsDataTableRefresh(!isDataTableRefresh);
            }, 2000);
            auctionProductResult = response.results;
            if (response.results.length !== 0) {
              auctionName = response?.results[0]?.auctionName;
              auctionDate = response?.results[0]?.auctionStartDate;
              date = auctionDate.split(" ", 2);
            }
          } else if (status === 403) {
            setUserBlock(true);
          } else if (status === 505) {
            setApiTimeout(true);
            setOnAction(false);
          } else if (status === 404) {
            setIsLoading(false);
            setOnAction(false);
            setShowAlertMessage(`${t("common:invalidRequest")}`);
            setAlertType("error");
            setOpenToast(true);
          } else {
            setIsLoading(false);
            setOnAction(false);
            setShowAlertMessage(response[0].message);
            setAlertType("error");
            setOpenToast(true);
          }
        }
      );
    }
  };

  const openProductDetail = (
    auctionId: string,
    perId: string,
    auctionDate: string
  ) => {
    localStorage.setItem("jwlauctionUserId", props.match.params.auctionUserId);
    props.history.push(
      `/preview/tender/jewellery/details/${auctionId}/${perId}/${date[0]}?isAuction=true&uniqid=${props.match.params.auctionUserId}`
    );
  };
  const openDetailsView = (
    auctionId: string,
    perId: string,
    auctionDate: string
  ) => {
    window.open(
      `/preview/tender/jewellery/details/${auctionId}/${perId}/${date[0]}?isAuction=true&uniqid=${props.match.params.auctionUserId}`,
      "_blank"
    );
  };

  const rightClickEvent = (
    event: React.MouseEvent<HTMLDivElement>,
    productId: string
  ) => {
    event.preventDefault();
    setMouseState({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
    setCurrentProductId(productId);
  };

  const handleClose = () => {
    setMouseState(initialState);
  };

  const getTableBodyHeight = () => {
    var h = window.innerHeight;
    let heightOfInnerTable: any;
    if (isTableExpanded) {
      heightOfInnerTable = h - 400;
    } else {
      heightOfInnerTable = h - 230;
    }
    if (auctionProducts.length > 7) {
      return `${heightOfInnerTable}px`;
    }
  };

  const options: MUIDataTableOptions = {
    filter: false,
    download: "false",
    print: "false",
    viewColumns: "false",
    count: totalElement,
    page: page,
    serverSide: true,
    search: false,
    rowsPerPage: selectItemPerPageValue,
    rowsPerPageOptions: [0],
    selectableRows: "multiple",
    selectableRowsHeader: true,
    selectableRowsOnClick: false,

    isRowSelectable: (dataIndex: any, selectedRows: any) => {
      const disableRowIndexsArray = getDisableRowIndex();
      return disableRowIndexsArray.includes(dataIndex) ? false : true;
    },
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
      return (
        <div className="mailIconDiv">
          <IconButton color="primary">
            <i className="zmdi zmdi-email"></i>
          </IconButton>
        </div>
      );
    },
    onChangePage: (currentPage: number) => {
      localStorage.setItem(
        "jwlapiStartCount",
        JSON.stringify(currentPage * jwlItemPerPageRef.current)
      );
      dispatch(setJewelleryCurrentApiStartCount({ currentPage }));
      // getTendorAucProducts(props.match.params.auctionId, currentPage);
    },
    onSearchChange: (searchTxt: any) => {
      //
    },
    setRowProps: (row: any, dataIndex: any, rowIndex: any) => {
      if (row[columnIndexes.previousHighestBidder] === undefined) {
        if (row[columnIndexes.holdPreviousBidder] === undefined) {
          if (
            row[columnIndexes.auctionUserId] !==
            props.match.params.auctionUserId
          ) {
            return { style: { background: "white" } };
          } else {
            return { style: { background: row[columnIndexes.rowColor] } };
          }
        } else {
          if (
            props.match.params.auctionUserId ===
            row[columnIndexes.holdPreviousBidder]
          ) {
            return { style: { background: "#ffeb9c" } };
          } else {
            return { style: { background: row[columnIndexes.rowColor] } };
          }
        }
      } else {
        if (
          props.match.params.auctionUserId ===
          row[columnIndexes.previousHighestBidder]
        ) {
          return { style: { background: "#FBE4D5" } };
        } else {
          return { style: { background: row[columnIndexes.rowColor] } };
        }
      }
    },
    onColumnSortChange: (price: any, direction: any) => {
      let order = "";
      if (direction === "asc") {
        order = "desc";
      } else {
        order = "asc";
      }
      sort(price, order);
    },
    onRowSelectionChange: (
      currentRowsSelected: any,
      allRowsSelected: any,
      rowsSelected: any
    ) => {
      indexsForBulkBidItems = rowsSelected;
      setOpenToast(false);
      indexsForBulkBidItems.map((indexs: any) => {
        let {
          sellerAuctionUserId,
          previousHighestBidder,
          auctionUserId,
          holdPreviousBidder,
        } = auctionProducts[indexs];
        if (sellerAuctionUserId === props.match.params.auctionUserId) {
          return { ...options, isRowSelectable: "false" };
        } else if (previousHighestBidder !== undefined) {
          if (props.match.params.auctionUserId === previousHighestBidder) {
            return { ...options, isRowSelectable: "false" };
          }
        } else if (holdPreviousBidder !== undefined) {
          if (props.match.params.auctionUserId === holdPreviousBidder) {
            return { ...options, isRowSelectable: "false" };
          }
        } else if (auctionUserId === props.match.params.auctionUserId) {
          return { ...options, isRowSelectable: "false" };
        }
      });
      if (indexsForBulkBidItems.length >= 0) {
        setShowBulkBidButton(true);
      } else {
        setShowBulkBidButton(false);
      }
      if (indexsForBulkBidItems.length === 0) {
        setShowBulkBidButton(false);
      }
      if (indexsForBulkBidItems.length > 10) {
        setShowAlertMessage(
          `${t("common:Tender:validation:productSelection")}`
        );
        setAlertType("error");
        setOpenToast(true);
      }
    },
    tableBodyHeight: getTableBodyHeight(),
  };

  const onGridSelectionChange = (rowsSelected: any) => {
    indexsForBulkBidItems = rowsSelected;
    setOpenToast(false);
    if (indexsForBulkBidItems.length >= 0) {
      setShowBulkBidButton(true);
    } else {
      setShowBulkBidButton(false);
    }
    if (indexsForBulkBidItems.length === 0) {
      setShowBulkBidButton(false);
    }
    if (indexsForBulkBidItems.length > 10) {
      setShowAlertMessage(`${t("common:Tender:validation:productSelection")}`);
      setAlertType("error");
      setOpenToast(true);
    }
  };

  const boxColor = (items: any) => {
    const {
      auctionUserId,
      rowColor,
      previousHighestBidder,
      holdPreviousBidder,
    } = items;
    if (previousHighestBidder === undefined) {
      if (holdPreviousBidder === undefined) {
        if (auctionUserId !== props.match.params.auctionUserId) {
          return "white";
        } else {
          return rowColor;
        }
      } else {
        if (props.match.params.auctionUserId === holdPreviousBidder) {
          return "#ffeb9c";
        } else {
          return rowColor;
        }
      }
    } else {
      if (props.match.params.auctionUserId === previousHighestBidder) {
        return "#FBE4D5";
      } else {
        return rowColor;
      }
    }
  };

  const getDisableRowIndex = () => {
    return auctionProducts.map((items: any, index: number) => {
      if (isReadOnlyUser) {
        return index;
      } else {
        if (items.sellerAuctionUserId === props.match.params.auctionUserId) {
          return index;
        } else if (items.previousHighestBidder !== undefined) {
          if (
            props.match.params.auctionUserId === items.previousHighestBidder
          ) {
            return index;
          }
        } else if (items.holdPreviousBidder !== undefined) {
          if (props.match.params.auctionUserId === items.holdPreviousBidder) {
            return index;
          }
        } else if (items.auctionUserId === props.match.params.auctionUserId) {
          return index;
        }
      }
    });
  };

  //server side sorting
  const filterSort = (price: any, direction: any) => {
    let order = "";
    if (direction === "asc") {
      order = "desc";
    } else {
      order = "asc";
    }
    sort(price, order);
  };

  const sort = (value: any, direction: any) => {
    sortData(value, direction);
  };

  //sorting
  const sortData = (values: any, direction: any) => {
    let tempArr: any;
    if (direction === "asc") {
      tempArr = [{ property: values, direction: "ASC" }];
      localStorage.setItem("dropDownFilterVal", JSON.stringify(tempArr));
    } else {
      tempArr = [{ property: values, direction: "DESC" }];
      localStorage.setItem("dropDownFilterVal", JSON.stringify(tempArr));
    }
    localStorage.removeItem("jwlapiStartCount");
    if (selectcurrentApiStartCountValue === 0) {
      getTendorAucProducts(props.match.params.auctionId, 0);
    } else {
      dispatch(setJewelleryCurrentApiStartCount({ currentPage: 0 }));
    }
  };

  const columns: MUIDataTableColumnDef[] = [
    {
      name: "serialNo",
      label: "Serial No",
      options: {
        filter: true,
        sort: true,
        display: true,
        setCellProps: () => ({
          style: { minWidth: "70px", maxWidth: "100px" },
        }),
      },
    },
    {
      name: "perId",
      label: "Product Id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "auctionUserId",
      label: "Auction User Id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "",
      label: `${t("common:Tender:tableColumnNames:ActionsTender")}`,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <div
              style={{
                display: "flex",
                gridGap: "10px",
                justifyContent: "center",
              }}
            >
              <div
                title={`Note`}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  openProductDetail(
                    props.match.params.auctionId,
                    tableMeta.rowData[1],
                    props.match.params.auctionDate
                  )
                }
              >
                <FontAwesomeIcon
                  icon={faEditProps}
                  style={{ color: "#014a99" }}
                />
              </div>
              <div
                title={`Add Product into Wishlist`}
                style={{ cursor: "pointer" }}
              >
                {isReadOnlyUser ? (
                  <FontAwesomeIcon
                    icon={faHeartProps}
                    style={{ color: "gray" }}
                    onClick={() => {
                      renderReadOnlyUserToast(
                        tableMeta.rowData[columnIndexes.sellerAuctionUserId]
                      );
                    }}
                  />
                ) : (
                  <>
                    {tableMeta.rowData[columnIndexes.sellerAuctionUserId] !==
                    props.match.params.auctionUserId ? (
                      <>
                        {getWatchedProduct(
                          tableMeta.rowData[columnIndexes.watched],
                          tableMeta.rowData[columnIndexes.productId],
                          props.match.params.auctionId,
                          tableMeta.rowData[1]
                        )}
                      </>
                    ) : (
                      <FontAwesomeIcon
                        icon={faHeartProps}
                        style={{ color: "gray" }}
                        onClick={() => {
                          renderReadOnlyUserToast(
                            tableMeta.rowData[columnIndexes.sellerAuctionUserId]
                          );
                        }}
                      />
                    )}
                  </>
                )}
              </div>
              <div
                title={`Share Product`}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  shareButtonAction(
                    tableMeta.rowData[columnIndexes.productId],
                    props.match.params.auctionDate
                  )
                }
              >
                <FontAwesomeIcon
                  icon={faShareSquareProps}
                  style={{ color: "#014a99" }}
                />
              </div>
            </div>
          );
        },
      },
    },
    {
      name: "code",
      label: `${t("common:Tender:tableColumnNames:sku")}`,
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <span
              className="linkCss"
              onClick={() =>
                openProductDetail(
                  props.match.params.auctionId,
                  tableMeta.rowData[1],
                  props.match.params.auctionDate
                )
              }
            >
              {value}
            </span>
          );
        },
      },
    },
    {
      name: "lab",
      label: `${t("common:Tender:tableColumnNames:lab")}`,
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any) => {
          if (
            value !== "AGT" &&
            value !== "agt" &&
            value !== "" &&
            value !== undefined
          ) {
            return (
              <span
                className="linkCss"
                onClick={() =>
                  openJewelleryCertificateLinkPopup(
                    value,
                    tableMeta.rowData[columnIndexes.certificate]
                  )
                }
              >
                {value}
              </span>
            );
          } else {
            return <span>{value}</span>;
          }
        },
      },
    },
    {
      name: "certificate",
      label: `${t("common:Tender:tableColumnNames:certification")}`,
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "jewelleryDetails",
      label: `${t("common:Tender:tableColumnNames:description")}`,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any) => {
          return (
            <EllipsisText
              text={getDescription(value)}
              length={70}
              tail={"..."}
            />
          );
        },
      },
    },
    {
      name: "currency",
      label: "currency",
      options: {
        display: false,
        filter: false,
        sort: false,
        customBodyRender: (value: any) => {
          return <div>{value.code}</div>;
        },
      },
    },
    {
      name: "remarks",
      label: `${t("common:Tender:tableColumnNames:remark")}`,
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <EllipsisText
              text={getRemarks(tableMeta.rowData[columnIndexes.remarks])}
              length={30}
              tail={"..."}
            />
          );
        },
      },
    },
    {
      name: "notes",
      label: `${t("common:Tender:tableColumnNames:notes")}`,
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "caratMinimumPrice",
      label: `${t("common:Tender:tableColumnNames:jewelleryPrice")}`,
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <div>
              {convertedValue(
                tableMeta.rowData[columnIndexes.currency],
                value,
                tableMeta.rowData[columnIndexes.exchangeRate][0].rate
              )}
            </div>
          );
        },
      },
    },
    {
      name: "myLatestBid",
      label: `${t("common:Tender:tableColumnNames:placeBid")}`,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <>
              {isDataRefresh ? (
                <div>
                  {value === undefined || value === null
                    ? 0
                    : handelMyBidsLabel(
                        myLatestBidForProduct,
                        tableMeta.rowData[columnIndexes.productId],
                        tableMeta.rowData[columnIndexes.currency],
                        tableMeta.rowData[columnIndexes.exchangeRate][0].rate
                      )}
                </div>
              ) : (
                <div>
                  {value === undefined || value === null
                    ? 0
                    : handelMyBidsLabel(
                        myLatestBidForProduct,
                        tableMeta.rowData[columnIndexes.productId],
                        tableMeta.rowData[columnIndexes.currency],
                        tableMeta.rowData[columnIndexes.exchangeRate][0].rate
                      )}
                </div>
              )}
              {getBidNowButtonDisable(
                tableMeta.rowData[columnIndexes.sellerAuctionUserId],
                props.match.params.auctionUserId,
                tableMeta.rowData[columnIndexes.auctionUserId],
                tableMeta,
                getReturnMyLatestStoredBid(
                  myLatestBidForProduct,
                  tableMeta.rowData[columnIndexes.productId]
                ),
                tableMeta.rowData[columnIndexes.currentBidPrice],
                tableMeta.rowData[columnIndexes.productId],
                tableMeta.rowData[columnIndexes.previousHighestBidder],
                tableMeta.rowData[columnIndexes.holdPreviousBidder]
              )}
            </>
          );
        },
      },
    },
    {
      name: "currentBidPrice",
      label: `${t("common:Tender:tableColumnNames:liveHighestBid")}`,
      options: {
        filter: true,
        sort: true,
        display: true,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <div>
              {convertedValue(
                tableMeta.rowData[columnIndexes.currency],
                value,
                tableMeta.rowData[columnIndexes.exchangeRate][0].rate
              )}
            </div>
          );
        },
      },
    },
    {
      name: "noOfBidders",
      label: `${t("common:newCommanTrans:noOfBiddersBids")}`,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <div>
              {getNoOfBiddersAndNoOfBids(
                tableMeta.rowData[columnIndexes.noOfBids],
                tableMeta.rowData[columnIndexes.noOfBidders]
              )}
            </div>
          );
        },
      },
    },
    {
      name: "rowColor",
      label: "Bid Color",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "exchangeRates",
      label: "Exchange Rate",
      options: {
        filter: false,
        sort: false,
        display: false,
        customBodyRender: (value: any) => {
          return <div>{value[0].rate}</div>;
        },
      },
    },
    {
      name: "myLatestBid",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "currentBidPrice",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "noOfBids",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "watched",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "sellerAuctionUserId",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "tenderSecondHighestBid",
      label: `${t("common:Tender:tableColumnNames:liveHighestBid")}`,
      options: {
        filter: false,
        sort: false,
        display: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <div>
              {convertedValue(
                tableMeta.rowData[columnIndexes.currency],
                value,
                tableMeta.rowData[columnIndexes.exchangeRate][0].rate
              )}
            </div>
          );
        },
      },
    },
    {
      name: "previousHighestBidder",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "holdPreviousBidder",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
  ];

  //get bid now button disable
  const getBidNowButtonDisable = (
    sellerId: any,
    auctionUserIdForProduct: any,
    tableAuctionUserId: any,
    tableDataForUIBox: any,
    myBidsForProduct: any,
    currentBidPriceForProduct: any,
    currentproductId: any,
    previousBidderId: any,
    holdPreviousBidder: any
  ) => {
    const Obj = myLatestBidForProduct.filter(
      (item: any) => item.productId === currentproductId
    );
    const mybidPriceforProduct = Obj.map((item: any, index: number) => {
      return item.myLatestBid;
    });
    renderAutomaticBidPopup(tableDataForUIBox);
    if (isReadOnlyUser) {
      return (
        <StyledBtnContainer
          onClick={() => {
            renderReadOnlyUserToast(sellerId);
          }}
        >
          <StyledButtonGray>
            {t("common:inquiry:button:bidNow")}
          </StyledButtonGray>
        </StyledBtnContainer>
      );
    } else {
      if (sellerId === auctionUserIdForProduct) {
        return (
          <StyledBtnContainer
            onClick={() => {
              renderReadOnlyUserToast(sellerId);
            }}
          >
            <StyledButtonGray>
              {t("common:inquiry:button:bidNow")}
            </StyledButtonGray>
          </StyledBtnContainer>
        );
      } else if (holdPreviousBidder !== undefined) {
        if (props.match.params.auctionUserId === holdPreviousBidder) {
          return (
            <StyledBtnContainer>
              <StyledButtonGray>
                {t("common:inquiry:button:bidNow")}
              </StyledButtonGray>
            </StyledBtnContainer>
          );
        } else {
          return (
            <StyledBtnContainer>
              <StyledButton onClick={() => handleBidClick(tableDataForUIBox)}>
                {t("common:inquiry:button:bidNow")}
              </StyledButton>
            </StyledBtnContainer>
          );
        }
      } else if (previousBidderId !== undefined) {
        if (props.match.params.auctionUserId === previousBidderId) {
          return (
            <StyledBtnContainer>
              <StyledButtonGray>
                {t("common:inquiry:button:bidNow")}
              </StyledButtonGray>
            </StyledBtnContainer>
          );
        } else {
          return (
            <StyledBtnContainer>
              <StyledButton onClick={() => handleBidClick(tableDataForUIBox)}>
                {t("common:inquiry:button:bidNow")}
              </StyledButton>
            </StyledBtnContainer>
          );
        }
      } else if (tableAuctionUserId === props.match.params.auctionUserId) {
        return (
          <StyledBtnContainer>
            <StyledButtonGray>
              {t("common:inquiry:button:bidNow")}
            </StyledButtonGray>
          </StyledBtnContainer>
        );
      } else if (mybidPriceforProduct[0] > currentBidPriceForProduct) {
        return (
          <StyledBtnContainer>
            <StyledButtonGray>
              {t("common:inquiry:button:bidNow")}
            </StyledButtonGray>
          </StyledBtnContainer>
        );
      } else {
        if (tableAuctionUserId === props.match.params.auctionUserId) {
          return (
            <StyledBtnContainer>
              <StyledButtonGray>
                {" "}
                {t("common:inquiry:button:bidNow")}
              </StyledButtonGray>
            </StyledBtnContainer>
          );
        } else {
          return (
            <StyledBtnContainer>
              <StyledButton onClick={() => handleBidClick(tableDataForUIBox)}>
                {t("common:inquiry:button:bidNow")}
              </StyledButton>
            </StyledBtnContainer>
          );
        }
      }
    }
  };

  //get My All MyBid
  const handelMyBidsLabel = (
    myStoredBids: any,
    currentProductId: any,
    currency: any,
    exchangeRate: any
  ) => {
    const Obj = myLatestBidForProduct.filter(
      (item: any) => item.productId === currentProductId
    );
    const mybidPriceforProduct = Obj.map((item: any, index: number) => {
      return item.productId;
    });

    return getMyLatestStoredBids(
      myLatestBidForProduct,
      currentProductId,
      currency,
      exchangeRate,
      mybidPriceforProduct
    );
  };

  const shareButtonAction = (perId: any, auctionDate: any) => {
    const { t } = props;
    let languageCode =
      localStorage.getItem("language") === "EN"
        ? "en"
        : localStorage.getItem("language") === "zh_CN"
        ? "zh_CN"
        : "jp";
    copy(
      `${window.location.hostname}/${languageCode}/product/jewellery/${perId}/${date[0]}/detail`
    );
    setAlertType("success");
    setOpenToast(true);
    setShowAlertMessage(`${t("common:preview:linkCopied")}`);
    setTimeout(() => {
      setOpenToast(false);
    }, 2000);
  };

  const getNoOfBiddersAndNoOfBids = (noOfBidders: any, noOfBids: any) => {
    return `${noOfBidders}/${noOfBids}`;
  };

  const getRemarks = (remarks: any) => {
    if (remarks !== "" && remarks !== undefined && remarks !== null) {
      return remarks;
    } else {
      return "";
    }
  };

  const getDescription = (jewelleryDetails: any) => {
    var jewelleryType: string = "";
    var jewelleryCondition: string = "";
    var jewelleryDescription: string = "";
    if (jewelleryDetails !== undefined && jewelleryDetails !== null) {
      if (
        jewelleryDetails.jewelleryType !== undefined &&
        jewelleryDetails.jewelleryType !== null
      ) {
        jewelleryType = jewelleryDetails.jewelleryType;
      } else {
        jewelleryType = "";
      }

      if (
        jewelleryDetails.jewelleryCondition !== undefined &&
        jewelleryDetails.jewelleryCondition !== null
      ) {
        jewelleryCondition = jewelleryDetails.jewelleryCondition;
      } else {
        jewelleryCondition = "";
      }

      if (
        jewelleryDetails.jewelleryDescription !== undefined &&
        jewelleryDetails.jewelleryDescription !== null
      ) {
        jewelleryDescription = jewelleryDetails.jewelleryDescription;
      } else {
        jewelleryDescription = "";
      }
    }

    return (
      jewelleryType + " " + jewelleryCondition + " " + jewelleryDescription
    );
  };

  const closeModal = () => {
    Auth.logout();
    props.history.replace("/");
  };

  // Tender bulk bid popup
  const bulkBidPopupHandler = () => {
    setShowBulkBidPopup(true);
  };

  const handleBtnValue = (
    values: number,
    currentBidPrice: any,
    productId: any,
    myBidValues: any
  ) => {
    var data = [
      {
        bidIncreasedBy: values,
        bidPrice: currentBidPrice,
        auctionUserId: props.match.params.auctionUserId,
        auctionId: props.match.params.auctionId,
        productId: productId,
      },
    ];
    let updateRowIndex = myLatestBidForProduct.findIndex(
      (product: any) => product.productId === myBidValues.productId
    );
    if (updateRowIndex === -1) {
      var myLatestBidForProductObjectData: any = [
        ...myLatestBidForProduct,
        myBidValues,
      ];
      myLatestBidForProduct = myLatestBidForProductObjectData;
    } else {
      var updatedArray = update(myLatestBidForProduct, {
        $splice: [[updateRowIndex, 1, myBidValues]],
      });
      myLatestBidForProduct = updatedArray;
    }
    bidProduct(data);
  };

  const bulkBidHandler = (values: any[], myBidBulkValues: any) => {
    myLatestBidForProduct = myBidBulkValues;
    bidProduct(values);
    onGridSelectionChange(0);
    PubSub.publish("resetGridBoxIndex", []);
  };

  const bidProduct = (payloadBid: any) => {
    if (!oAuth()) {
      props.history.push("/");
    } else {
      Auth.postRequest(
        tendorBidUrl,
        payloadBid,
        (status: any, response: any) => {
          if (status === 200) {
            setIsDataRefresh(!isDataRefresh);
            getMyLatestBids(props.match.params.auctionId);
          } else if (status === 403) {
            setUserBlock(true);
          } else if (status === 505) {
            setApiTimeout(true);
            setOnAction(false);
          } else if (status === 404) {
            setIsLoading(false);
            setOnAction(false);
            setShowAlertMessage(`${t("common:invalidRequest")}`);
            setAlertType("error");
            setOpenToast(true);
          } else {
            setIsLoading(false);
            setOnAction(false);
            setShowAlertMessage(response[0].message);
            setAlertType("error");
            setOpenToast(true);
          }
        }
      );
    }
  };

  const exportExcel = () => {
    let exportProductIds = auctionProducts.slice(0, 1).map((item: any) => {
      return item.auctionId;
    });
    setExportExcelDownload(false);
    Auth.postRequestForExcel(
      `${productDownload}?auctionId=${exportProductIds}&auctionType=Jewellery`,
      { ids: "" },
      (status: any, response: any) => {
        if (status === 200) {
          if (response !== undefined) {
            const url = window.URL.createObjectURL(
              new Blob([response], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `Products.xlsx`);
            document.body.appendChild(link);
            link.click();
          }
          setTimeout(() => {
            setExportExcelDownload(true);
          }, 5000);
        } else {
          //todo
        }
      }
    );
  };

  const tabItemClick = (value: any) => {
    if (value === "1") {
      setTabValue({
        allProducts: true,
        myBids: false,
      });
      dispatch(reSetJwlIsMybids());
      // localStorage.removeItem("tenderitemsPerPage");
      // localStorage.removeItem("currentStart");
    } else {
      setTabValue({
        allProducts: false,
        myBids: true,
      });
    }
  };

  const filteredAuction = async (
    auctions: any,
    isFilter: boolean,
    values: any,
    isCustomFilter?: any
  ) => {
    localStorage.removeItem("jwlapiStartCount");
    if (selectItemPerPageValue !== null) {
      let savedCount: any = selectItemPerPageValue;
      endCount = savedCount;
    }
    if (
      localStorage.getItem("dropDownFilterVal") !== undefined &&
      localStorage.getItem("dropDownFilterVal") !== null
    ) {
      var tempArr = localStorage.getItem("dropDownFilterVal");
      if (tempArr !== null) var dropDownValues = JSON.parse(tempArr);
      var property = dropDownValues[0].property;
      var direction = dropDownValues[0].direction;
    }

    if (values === null) {
      filter = null;
      payload = {
        filters: {
          and: [
            {
              match: "exact",
              name: "parcelType",
              value: "Jewellery",
            },
            {
              match: "exact",
              name: "auctionId",
              value: props.match.params.auctionId,
            },
          ],
        },
        page: {
          start: startCount,
          count: endCount,
          sort: {
            orders:
              property === "serialNo"
                ? [
                    {
                      property:
                        property !== null && property !== undefined
                          ? property
                          : "serialNo",
                      direction:
                        direction !== null && direction !== undefined
                          ? direction
                          : "ASC",
                    },
                  ]
                : [
                    {
                      property:
                        property !== null && property !== undefined
                          ? property
                          : "serialNo",
                      direction:
                        direction !== null && direction !== undefined
                          ? direction
                          : "ASC",
                    },
                    {
                      property: "serialNo",
                      direction:
                        direction !== null && direction !== undefined
                          ? direction
                          : "ASC",
                    },
                  ],
          },
        },
      };
    } else {
      body = [];
      values.map((item: any, index: number) => {
        if (item.name === "auctionId") {
          values.splice(index, 1);
        }
        return true;
      });
      body = values;
      payload = {
        filters: {
          or: body,
          and: [
            {
              match: "exact",
              name: "auctionId",
              value: props.match.params.auctionId,
            },
          ],
        },
        page: {
          start: startCount,
          count: endCount,
          sort: {
            orders:
              property === "serialNo"
                ? [
                    {
                      property:
                        property !== null && property !== undefined
                          ? property
                          : "serialNo",
                      direction:
                        direction !== null && direction !== undefined
                          ? direction
                          : "ASC",
                    },
                  ]
                : [
                    {
                      property:
                        property !== null && property !== undefined
                          ? property
                          : "serialNo",
                      direction:
                        direction !== null && direction !== undefined
                          ? direction
                          : "ASC",
                    },
                    {
                      property: "serialNo",
                      direction:
                        direction !== null && direction !== undefined
                          ? direction
                          : "ASC",
                    },
                  ],
          },
        },
      };
      if (isCustomFilter === undefined) {
        filter = body;
        localStorage.setItem("filteredValues", JSON.stringify(body));
        localStorage.setItem("filteredJewelleryValues", JSON.stringify(body));
      }
    }

    await filterCAllback(payload);
  };

  const filterCAllback = (dataVal: any) => {
    if (selectcurrentApiStartCountValue === 0) {
      callApi(dataVal);
    } else {
      dispatch(setJewelleryCurrentApiStartCount({ currentPage: 0 }));
    }
  };

  const getLeftTime = (startDate: any) => {
    let date: string | null = "";
    let time: string | null = "";
    let hour: any = "";
    let minutes: any = "";
    let timeDifference: any = "";
    let timezone: any = "";

    if (startDate !== null || startDate !== undefined) {
      let previewTime = moment.utc(startDate);
      if (timezone !== null) {
        timezone = localStorage.getItem("timezoneName");
      }
      if (previewTime !== null || previewTime !== undefined) {
        let result = moment.tz(previewTime, timezone);
        let resultFormatter = result.format();
        if (resultFormatter !== "Invalid date") {
          let splitDateTime = resultFormatter.split("T", 2);
          let splitTime: any = "";
          if (splitDateTime[1].includes("+")) {
            splitTime = splitDateTime[1].split("+", 2);
          } else {
            splitTime = splitDateTime[1].split("-", 2);
          }
          date = splitDateTime[0];
          time = splitTime[0];
          let dateTime = `${date} ${time}`;
          //let start: any = moment(dateTime);
          let end: any = moment(dateTime);
          timezone = localStorage.getItem("timezoneName");
          //let end: any = moment(new Date().toLocaleString("en-US", { timeZone: timezone }));
          let start: any = moment(
            new Date().toLocaleString("en-US", { timeZone: timezone })
          );
          hour = start.diff(end, "hours");
          minutes = start.diff(end, "minutes");
          timeDifference = localStorage.getItem("timeDifference");
          timeDifference = timeDifference / 1000;
          timeDifference = parseInt(timeDifference);
          //var timeDiff = start - end;
          var timeDiff = end - start;
          seconds = Math.round(timeDiff);
          {
            timeDifference[0] === "-"
              ? (seconds = seconds - timeDifference)
              : (seconds = seconds + timeDifference);
          }
        }
      }
    }
    setTimeout(() => {
      getLeftTime(startDate);
    }, 1000);
  };

  //get time
  const renderer = ({
    formatted: { days, hours, minutes, seconds },
    completed,
  }: any) => {
    if (completed) {
      if (
        days === "00" &&
        hours === "00" &&
        minutes === "00" &&
        seconds === "00"
      ) {
        getTimePopup();
        return <span>00:00:00:00</span>;
      }
    } else {
      return (
        <span>
          {days}:{hours}:{minutes}:{seconds}
        </span>
      );
    }
  };

  const getTimePopup = () => {
    const callBack = (response: any) => {
      const { state, tenderExtended } = response;
      if (
        state === "Started" &&
        tenderExtended === true &&
        isUserClosedExtendedPopup === false
      ) {
        setOpenTenderExtended(true);
      } else if (state === "Ended") {
        setOpenEndedModal(true);
        setOpenTenderExtended(false);
      }
      timeLeftLableColor = tenderExtended;
    };
    setTimeout(() => {
      getAuctionEndTime(props.match.params.auctionId, callBack);
    }, 4000);
  };

  const TenderauctionEndedModal = () => {
    props.history.push("/auction");
  };

  const TenderTimeExtended = () => {
    setIsUserClosedExtendedPopup(true);
    getAllUpdatedData();
    getTendorAucProducts(props.match.params.auctionId, 0);
    setOpenTenderExtended(false);
    const interval = setInterval(() => {
      window.location.reload();
      clearInterval(interval);
    }, 1000);
  };

  const setItemsPerPageHandler = (itemsPerPage: number) => {
    localStorage.setItem("jwlcurrentPageOfTenderAllProducts", "0");
    setCurrentPage(1);
    setItemsPerPage(itemsPerPage);
    jwlItemPerPageRef.current = itemsPerPage;
    dispatch(setJewelleryItemPerPage({ itemsPerPage }));
    dispatch(resetJewelleryCurrentApiStartCount());
  };

  function setCurrentPage(currentPage: number) {
    return currentPage;
  }

  function setItemsPerPage(itemsPerPage: number) {
    return itemPerPage(true, 0, itemsPerPage);
  }

  const itemPerPage = (value: boolean, start: number, itemPerPage: number) => {
    if (value === true) {
      localStorage.setItem(
        "jwlitemPerPageSelected",
        JSON.stringify(itemPerPage)
      );
      getUpdatedProducts(value, start, itemPerPage);
    }
  };

  const getUpdatedProducts = (value: any, start: any, endRecords: any) => {
    if (value === true) {
      getProducts(start, endRecords);
    }
  };

  const getProducts = (start: number, itemsPerPage: number) => {
    startCount = start;
    endCount = itemsPerPage;
    localStorage.setItem("jwlapiStartCount", JSON.stringify(start));
    let currentPage = start / itemsPerPage;
    setIsLoading(true);
    if (currentPage === selectcurrentApiStartCountValue) {
      setIsLoading(false);
    }
    dispatch(setJewelleryCurrentApiStartCount({ currentPage }));
    dispatch(setJewelleryItemPerPage({ itemsPerPage }));
    PubSub.publish("resetGridBoxIndex", []);
    dispatch(resetTenderGridProductAllProductIndexes());
    dispatch(resetTenderjewelleryAllProductSelectAll());
    onGridSelectionChange(0);
  };

  const closebidPopup = () => {
    setshowTenderSlider(false);
    setShowBulkBidPopup(false);
    setSingleBiddingPopup(false);
    PubSub.publish("resetGridBoxIndex", []);
  };

  const theme = useTheme();
  const notSmallDevices = useMediaQuery(theme.breakpoints.between("md", "xl"));
  let isAuctionPage: any = true;
  let isCategoryPageType: any = false;

  const handleMyBids = (auctionsProduct: any) => {
    if (auctionsProduct.length > 0) {
      setAuctionProducts(auctionsProduct);
    }
  };

  const addToWatchList = (
    productId: string | number,
    auctionId: string | number,
    serialNo: string | number
  ) => {
    setOnAction(true);
    setOpenToast(false);
    if (!oAuth()) {
      props.history.push("/");
    } else {
      Auth.postRequest(
        `${productUrl}/${auctionId}/${productId}/watch`,
        "payload",
        (status: any, response: any) => {
          if (response !== undefined) {
            if (status === 200) {
              callApi(payload);
              setIsLoading(false);
              setOnAction(false);
              setShowAlertMessage(`${t("common:preview:addedToWatchMsg")}`);
              setAlertType("success");
              setOpenToast(true);
            } else if (status === 403) {
              setUserBlock(true);
            } else if (status === 404) {
              setIsLoading(false);
              setShowAlertMessage(`${t("common:invalidRequest")}`);
              setOnAction(false);
              setAlertType("error");
              setOpenToast(true);
            } else if (status === 505) {
              setApiTimeout(true);
              setOnAction(false);
            } else {
              setIsLoading(false);
              setShowAlertMessage(response[0].message);
              setOnAction(false);
              setAlertType("error");
              setOpenToast(true);
            }
          } else {
            setIsLoading(false);
            setShowAlertMessage(response);
            setOnAction(false);
            setAlertType("error");
            setOpenToast(true);
          }
        }
      );
    }
  };
  //get Watched Product
  const getWatchedProduct = (
    watched: any,
    productId: any,
    auctionId: any,
    serialNo: any
  ) => {
    if (watched === true) {
      return (
        <FontAwesomeIcon
          icon={faHeartProps}
          style={{ color: "red", cursor: "pointer" }}
          onClick={() => removeFromWatchlist(productId, auctionId)}
        />
      );
    } else {
      return (
        <FontAwesomeIcon
          icon={faHeartProps}
          style={{ color: "gray" }}
          onClick={() => addToWatchList(productId, auctionId, serialNo)}
        />
      );
    }
  };
  const removeFromWatchlist = (
    productId: string | number,
    auctionId: string | number
  ) => {
    setOnAction(true);
    setOpenToast(false);
    if (Auth.loggedIn() === null) {
      props.history.push("/");
    } else {
      Auth.postRequest(
        `${productUrl}/${auctionId}/${productId}/unwatch`,
        "payload",
        (status: any, response: any) => {
          if (status === 200) {
            callApi(payload);
            setIsLoading(false);
            setOnAction(false);
            setShowAlertMessage(response.data.message);
            setAlertType("success");
            setOpenToast(true);
          } else if (status === 403) {
            setUserBlock(true);
          } else if (status === 404) {
            setIsLoading(false);
            setShowAlertMessage(`${t("common:invalidRequest")}`);
            setOnAction(false);
            setAlertType("error");
            setOpenToast(true);
          } else if (status === 505) {
            setApiTimeout(true);
            setOnAction(false);
          } else {
            setIsLoading(false);
            setOnAction(false);
            setShowAlertMessage(response[0].message);
            setAlertType("error");
            setOpenToast(true);
          }
        }
      );
    }
  };

  const watchlist = () => {
    PubSub.publish("resetGridBoxIndex", []);
    localStorage.removeItem("selectedFilterSort");
    // props.history.push(
    //   `/watchlist/${props.match.params.auctionId}/${props.match.params.auctionUserId}/${date[0]}/Popcorn`
    // );
    props.history.push("/my-page");
  };

  const listGridview = () => {
    onGridSelectionChange(0);
    PubSub.publish("resetGridBoxIndex", []);
    selectJwlPageUseRef.current = startCount;
    dispatch(resetTenderJwlIsGridView());
  };

  const Gridview = () => {
    onGridSelectionChange(0);
    PubSub.publish("resetGridBoxIndex", []);
    dispatch(setTenderJwlIsGridView({ gridView: false }));
  };

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    window.addEventListener("scroll", isStickytwo);
    return () => {
      window.removeEventListener("scroll", isSticky);
      window.removeEventListener("scroll", isStickytwo);
    };
  });

  const isSticky = (e: any) => {
    // const header: any = document.querySelector(".sticky-actionbar");
    // const scrollTop = window.scrollY;
    // scrollTop >= 520
    //   ? header?.classList.add("is-sticky")
    //   : header?.classList.remove("is-sticky");
  };

  const isStickytwo = (e: any) => {
    // const header: any = document.querySelector(".sticky-actionbartwo");
    // const scrollTop = window.scrollY;
    // const className =
    //   scrollTop >= 520
    //     ? header?.classList.add("is-stickytwo")
    //     : header?.classList.remove("is-stickytwo");
  };
  const renderReadOnlyUserToast = async (id: any) => {
    var joinedAuctions: any = localStorage.getItem("joinedAuctions");
    joinedAuctions = JSON.parse(joinedAuctions);
    if (joinedAuctions !== null) {
      joinedAuctions.filter((items: any, index: number) => {
        if (id === items.auctionUserId) {
          isAuctionUserId = true;
        }
        return true;
      });
    } else {
      if (id === props.match.params.auctionUserId) {
        isAuctionUserId = true;
      } else {
        isAuctionUserId = false;
      }
    }
    await renderPopup();
  };

  const renderPopup = () => {
    if (isReadOnlyUser) {
      setOpenReadOnlyUserToast(true);
    } else if (isAuctionUserId) {
      setOpenReadOnlyUserToast(true);
    } else {
      setOpenReadOnlyUserToast(false);
    }
    setTimeout(() => {
      setOpenReadOnlyUserToast(false);
    }, 3000);
  };

  const selectAllInGridView = () => {
    //calling select all function in grid View
    let arr: any = [];
    auctionProducts.map((productVal: any, index: any) => {
      if (arr.length < 10) {
        if (isReadOnlyUser) {
          //
        } else {
          if (
            productVal.sellerAuctionUserId === props.match.params.auctionUserId
          ) {
            //
          } else if (productVal.holdPreviousBidder !== undefined) {
            if (
              props.match.params.auctionUserId === productVal.holdPreviousBidder
            ) {
              //
            } else {
              arr = [...arr, index];
            }
          } else if (productVal.previousBidderId !== undefined) {
            if (
              props.match.params.auctionUserId === productVal.previousBidderId
            ) {
              //
            } else {
              arr = [...arr, index];
            }
          } else if (
            productVal.auctionUserId === props.match.params.auctionUserId
          ) {
            //
          } else {
            if (productVal.auctionUserId === props.match.params.auctionUserId) {
              //
            } else {
              arr = [...arr, index];
            }
          }
        }
      }
      return true;
    });

    if (isSelected) {
      setIsSelected(false);
      PubSub.publish("resetAnnualGridBoxIndex", []);
      arr = [];
      dispatch(resetTenderGridProductAllProductIndexes());
      dispatch(resetTenderjewelleryAllProductSelectAll());
      // PubSub.publish("jewellerySelectAllCheckBox", { isItemSelected: false, selectedIndexes: arr });
    } else {
      setIsSelected(true);
      dispatch(setTenderGridProductAllProductIndexes({ indexes: arr }));
      dispatch(setTenderjewelleryAllProductSelectAll({ isSelected: true }));
      // PubSub.publish("jewellerySelectAllCheckBox", { isItemSelected: true, selectedIndexes: arr });
    }
  };

  return (
    <AppLayout {...props}>
      {apiTimeout === true ? (
        <ApiTimeout t={t} {...props} />
      ) : (
        <Grid>
          {isLoading === true ? (
            <div className="loader">
              <LoadingSpinner />
            </div>
          ) : (
            <StyledLayoutGridMain>
              {tabValue.allProducts ? (
                <>
                  <StyledBoxContent title="Click here for advance search">
                    <StyledDropDown
                      src="/images/assets/doubledown.png"
                      alt="d"
                      style={{
                        transform: isFiltersOpen
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                      }}
                      onClick={handleFilters}
                    />
                  </StyledBoxContent>
                  {isFiltersOpen && (
                    <div style={{ padding: "20px" }}>
                      <JewelleryFilter
                        {...props}
                        t={t}
                        filterJewelleryAuction={filteredAuction}
                        auctionId={null}
                      />
                    </div>
                  )}
                </>
              ) : null}
              {tabValue.allProducts ? (
                <>
                  <StyledHeaderContainer container={true} spacing={0}>
                    <StyledCoreDataGridContainer container={true} spacing={0}>
                      <StyledActionBarContainer
                        container={true}
                        spacing={0}
                        className="coreActionBarContainer"
                      >
                        <StyledContainer>
                          <BasicSearchBox
                            autoCompleteSearch={autoCompleteSearch}
                            textSearchListing={textSearchListing}
                            setTextSearchArray={setTextSearchArray}
                          />
                        </StyledContainer>
                        {notSmallDevices ? (
                          <>
                            {isGridView === true ? (
                              <StyledSelectAllButtonHeader
                                onClick={selectAllInGridView}
                              >
                                {t("common:newCommanTrans:selectAll")}
                              </StyledSelectAllButtonHeader>
                            ) : null}
                          </>
                        ) : (
                          <StyledSelectAllButtonHeader
                            onClick={selectAllInGridView}
                          >
                            {t("common:newCommanTrans:selectAll")}
                          </StyledSelectAllButtonHeader>
                        )}
                        {showBulkBidButton === true ? (
                          <StyledButtonHeader onClick={bulkBidPopupHandler}>
                            {t("common:preview:tendor:bulkBid")}
                          </StyledButtonHeader>
                        ) : (
                          <></>
                        )}
                        <StyledButtonHeader onClick={watchlist}>
                          My-page
                        </StyledButtonHeader>
                        {exportExcelDownload ? (
                          <StyledButtonHeader onClick={exportExcel}>
                            {t("common:preview:pagination:export")}
                          </StyledButtonHeader>
                        ) : (
                          <StyledButtonHeader>
                            {t("common:preview:pagination:export")}
                          </StyledButtonHeader>
                        )}
                        <ActionTenderPagesize
                          setItemsPerPageHandler={setItemsPerPageHandler}
                          t={t}
                        />
                        {notSmallDevices ? (
                          <>
                            {isGridView === true ? (
                              <StyledButtonView onClick={Gridview}>
                                <Styledview src="/images/view/listView.png" />
                              </StyledButtonView>
                            ) : (
                              <StyledButtonView onClick={listGridview}>
                                <Styledview src="/images/view/gridview.jpeg" />
                              </StyledButtonView>
                            )}
                          </>
                        ) : null}
                      </StyledActionBarContainer>
                    </StyledCoreDataGridContainer>
                  </StyledHeaderContainer>
                </>
              ) : null}
              <StyledLayoutGrid container={true}>
                <Grid item={true} xs={12}>
                  <Grid
                    container={true}
                    spacing={0}
                    className="actionContainer"
                    style={{ padding: "0px 0px 0 8px", marginTop: '20px' }}
                  >
                    <Grid item xs={12} sm={12} md={12} lg={4} xl={3}>
                      <StyledTabLayout container spacing={0}>
                        <StyledContent item xs={12}>
                          <TendorTabLayoutJwl
                            {...props}
                            t={t}
                            tabClick={tabItemClick}
                          />
                        </StyledContent>
                      </StyledTabLayout>
                    </Grid>
                    <Grid item xs={12} sm={12} md={5} lg={4} xl={3}>
                      <StyledName>
                        <StyledNameContent>
                          <EllipsisText
                            text={auctionName}
                            length={28}
                            tail={"..."}
                          />
                        </StyledNameContent>
                      </StyledName>
                    </Grid>
                    <Grid item xs={12} sm={12} md={7} lg={4} xl={3}>
                      {seconds > 0 && (
                        <StyledTimeGridContainer container={true} spacing={0}>
                          {timeLeftLableColor ? (
                            <StyledTimer style={{ color: "red" }}>
                              <span>
                                {t("common:preview:tendor:timeLeft")}{" "}
                              </span>
                              <Countdown
                                date={Date.now() + seconds}
                                className="countdownText"
                                renderer={renderer}
                                zeroPadTime={2}
                              />
                            </StyledTimer>
                          ) : (
                            <StyledTimer>
                              <span>
                                {t("common:preview:tendor:timeLeft")}{" "}
                              </span>
                              <Countdown
                                date={Date.now() + seconds}
                                className="countdownText"
                                renderer={renderer}
                                zeroPadTime={2}
                              />
                            </StyledTimer>
                          )}
                        </StyledTimeGridContainer>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                {tabValue.allProducts ? (
                  <>
                    {isTopElementHide ? (
                      <StyledHeaderContainer
                        container={true}
                        spacing={0}
                        style={{ paddingTop: "20px" }}
                      >
                        <StyledCoreDataGridContainer
                          container={true}
                          spacing={0}
                        >
                          <StyledActionBarContainer
                            container={true}
                            spacing={0}
                            className="coreActionBarContainer"
                          >
                            {showBulkBidButton === true ? (
                              <StyledButtonHeader onClick={bulkBidPopupHandler}>
                                {t("common:preview:tendor:bulkBid")}
                              </StyledButtonHeader>
                            ) : (
                              <></>
                            )}
                            <StyledButtonHeader onClick={watchlist}>
                              {t("common:menu:watchlist")}
                            </StyledButtonHeader>
                            <StyledButtonHeader onClick={exportExcel}>
                              {t("common:preview:pagination:export")}
                            </StyledButtonHeader>
                            <ActionTenderPagesizeComman
                              setItemsPerPageHandler={setItemsPerPageHandler}
                              t={t}
                            />
                            {notSmallDevices ? (
                              <>
                                {isGridView === true ? (
                                  <StyledButtonView onClick={Gridview}>
                                    <Styledview src="/images/view/listView.png" />
                                  </StyledButtonView>
                                ) : (
                                  <StyledButtonView onClick={listGridview}>
                                    <Styledview src="/images/view/gridview.jpeg" />
                                  </StyledButtonView>
                                )}
                              </>
                            ) : null}
                          </StyledActionBarContainer>
                        </StyledCoreDataGridContainer>
                      </StyledHeaderContainer>
                    ) : null}

                    {isGridView === false && notSmallDevices && (
                      <MuiThemeProvider theme={getMuiTheme()}>
                        {isDataTableRefresh ? (
                          <MUIDataTable
                            title={""}
                            data={auctionProducts}
                            columns={columns}
                            options={options}
                          />
                        ) : (
                          <MUIDataTable
                            title={""}
                            data={auctionProducts}
                            columns={columns}
                            options={options}
                          />
                        )}
                      </MuiThemeProvider>
                    )}

                    {isGridView === true ? (
                      notSmallDevices && (
                        <>
                          <CommanCoreDataGridOfTender
                            t={t}
                            data={auctionProducts}
                            totalElements={totalElement}
                            itemPerPage={itemPerPage}
                            value={getUpdatedProducts}
                            noProductFound={handleMyBids}
                            jwlItemPerPageRef={selectItemPerPageValue}
                            selectJwlPageUseRef={selectJwlPageUseRef.current}
                          >
                            {auctionProducts.length > 0 ? (
                              <TenderListOfProduct
                                {...props}
                                watchlist={addToWatchList}
                                unWatchlist={removeFromWatchlist}
                                setShowBidPopupBidModal={getBoxIndex}
                                auctionName={props.match.params.auctionName}
                                auctionDate={date[0]}
                                t={t}
                                isAuctionPage={isAuctionPage}
                                isCategoryPageType={isCategoryPageType}
                                auctionUserId={props.match.params.auctionUserId}
                                boxColor={boxColor}
                                sortingValue={filterSort}
                                bulkBidIndexHandler={onGridSelectionChange}
                                myForProductAllProduct={myLatestBidForProduct}
                                renderAutomaticBidPopupInMobile={
                                  renderAutomaticBidPopupInMobile
                                }
                                renderReadOnlyUserToast={
                                  renderReadOnlyUserToast
                                }
                                rightClickEvent={rightClickEvent}
                              />
                            ) : (
                              <Grid container={true} spacing={0}>
                                <StyledTextWrapper item xs={12}>
                                  {t("common:preview:noProductFound")}
                                </StyledTextWrapper>
                              </Grid>
                            )}
                          </CommanCoreDataGridOfTender>
                        </>
                      )
                    ) : (
                      <> </>
                    )}

                    {notSmallDevices || (
                      <>
                        <CommanCoreDataGridOfTender
                          t={t}
                          data={auctionProducts}
                          totalElements={totalElement}
                          itemPerPage={itemPerPage}
                          value={getUpdatedProducts}
                          noProductFound={handleMyBids}
                          jwlItemPerPageRef={selectItemPerPageValue}
                          selectJwlPageUseRef={selectJwlPageUseRef.current}
                        >
                          {auctionProducts.length > 0 ? (
                            <TenderListOfProduct
                              {...props}
                              watchlist={addToWatchList}
                              unWatchlist={removeFromWatchlist}
                              setShowBidPopupBidModal={getBoxIndex}
                              auctionName={props.match.params.auctionName}
                              auctionDate={date[0]}
                              t={t}
                              isAuctionPage={isAuctionPage}
                              isCategoryPageType={isCategoryPageType}
                              auctionUserId={props.match.params.auctionUserId}
                              sortingValue={filterSort}
                              boxColor={boxColor}
                              bulkBidIndexHandler={onGridSelectionChange}
                              myForProductAllProduct={myLatestBidForProduct}
                              renderAutomaticBidPopupInMobile={
                                renderAutomaticBidPopupInMobile
                              }
                              renderReadOnlyUserToast={renderReadOnlyUserToast}
                              rightClickEvent={rightClickEvent}
                            />
                          ) : (
                            <Grid container={true} spacing={0}>
                              <StyledTextWrapper item xs={12}>
                                {t("common:preview:noProductFound")}
                              </StyledTextWrapper>
                            </Grid>
                          )}
                        </CommanCoreDataGridOfTender>
                      </>
                    )}
                  </>
                ) : (
                  <TendorJewelleryMyBids t={t} {...props} />
                )}
                <Menu
                  keepMounted
                  open={mouseState.mouseY !== null}
                  onClose={handleClose}
                  anchorReference="anchorPosition"
                  anchorPosition={
                    mouseState.mouseY !== null && mouseState.mouseX !== null
                      ? { top: mouseState.mouseY, left: mouseState.mouseX }
                      : undefined
                  }
                >
                  <MenuItem
                    onClick={() =>
                      openDetailsView(
                        props.match.params.auctionId,
                        currentProductId,
                        props.match.params.auctionDate
                      )
                    }
                  >
                    Details
                  </MenuItem>
                </Menu>
                {openToast === true && (
                  <Alert
                    showAlertMessage={showAlertMessage}
                    alertType={alertType}
                  />
                )}
                {onAction === true && (
                  <div className="loading">
                    <LoadingSpinner />
                  </div>
                )}
                {userBlock === true && (
                  <StyledModal open={userBlock}>
                    <BlockUserModal t={t} {...props} closeModal={closeModal} />
                  </StyledModal>
                )}
                {openEndedModal === true && (
                  <StyledModal
                    open={openEndedModal}
                    onClose={TenderauctionEndedModal}
                  >
                    <ViewEndedModal
                      t={t}
                      {...props}
                      closeModal={TenderauctionEndedModal}
                    />
                  </StyledModal>
                )}
                {openTenderExtended === true && (
                  <StyledModal
                    open={openTenderExtended}
                    onClose={TenderTimeExtended}
                  >
                    <ViewTenderExtendedModal
                      t={t}
                      {...props}
                      closeModal={TenderTimeExtended}
                    />
                  </StyledModal>
                )}

                {showTenderSlider === true && (
                  <StyledModal open={showTenderSlider} onClose={closebidPopup}>
                    <JwlTenderSlider
                      t={t}
                      {...props}
                      items={auctionProducts}
                      currentIndex={details}
                      sec={seconds}
                      timeRender={renderer}
                      PopupClose={closebidPopup}
                      handleBtnValue={handleBtnValue}
                      isFirstButtonDisables={false}
                      auctionDate={date[0]}
                      totalElements={totalElement}
                      endCount={endCount}
                      auctionUserId={props.match.params.auctionUserId}
                      length={length}
                      boxColor={boxColor}
                      watchedFunction={addToWatchList}
                      unwatched={removeFromWatchlist}
                    />
                  </StyledModal>
                )}
                {singleBiddingPopup === true && (
                  <StyledModal
                    open={singleBiddingPopup}
                    onClose={closebidPopup}
                  >
                    <JwlTenderSlider
                      t={t}
                      {...props}
                      items={redirectProduct}
                      currentIndex={details}
                      sec={seconds}
                      timeRender={renderer}
                      PopupClose={closebidPopup}
                      handleBtnValue={handleBtnValue}
                      isFirstButtonDisables={false}
                      auctionDate={date[0]}
                      totalElements={redirectProduct.length}
                      endCount={endCount}
                      auctionUserId={props.match.params.auctionUserId}
                      length={length}
                      boxColor={boxColor}
                      watchedFunction={addToWatchList}
                      unwatched={removeFromWatchlist}
                    />
                  </StyledModal>
                )}
                {showBulkBidPopup === true && (
                  <StyledModal open={showBulkBidPopup} onClose={closebidPopup}>
                    <JwlBulkBidPopup
                      {...props}
                      items={auctionProducts}
                      popupClose={closebidPopup}
                      handleBtnValue={bulkBidHandler}
                      auctionUserId={props.match.params.auctionUserId}
                      length={length}
                      productIndexs={indexsForBulkBidItems}
                      myLatestBidForProducts={myLatestBidForProduct}
                    />
                  </StyledModal>
                )}
                {openReadOnlyUserToast === true && (
                  <AlertReadOnlyUser
                    isReadOnlyUser={isReadOnlyUser}
                    isSeller={isAuctionUserId}
                  />
                )}
              </StyledLayoutGrid>
            </StyledLayoutGridMain>
          )}
        </Grid>
      )}
    </AppLayout>
  );
};

export default JewelleryTendorAuctionView;