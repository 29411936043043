import React, { useEffect, useState } from "react";
import GenericCarousel from "../../../common/elements/GenericCarousel";
import {
  StyledHeroContainer,
  StyledHeroBannerText,
  StyledHeroBannerSubText,
  StyledCarouselContainer,
  StyledHeroSecondCenterContainer,
} from "./StyledCommon";

import { useMediaQuery } from "@material-ui/core";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const StyledImageContainer = styled.img<{ isMobile: boolean }>`
  width: 100%;
  object-fit: ${(props) => (props.isMobile ? "fill" : "fill")};
  height: ${(props) => (props.isMobile ? "70vh" : "480px")};
  ${(props) => props.theme.breakpoints.only("xl")} {
    height: 580px;
  }
  @media (width: 820px) {
    height: 615px;
  }
  @media (min-width: 800px) and (max-width: 1024px) {
    height: 620px;
  }
`;

const StyledDiv = styled.div<{ isMobile: boolean }>`
  height: ${(props) => (props.isMobile ? "70vh" : "480px")};
  @media (width: 820px) {
    height: 615px;
  }
  ${(props) => props.theme.breakpoints.only("xl")} {
    height: 580px;
  }
`;

const StyledLogo = styled.img<{ isMobile: boolean }>`
  width: 55%;
  height: ${(props) => (props.isMobile ? "50px" : "75px")};
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    width: 90%;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    width: 55%;
  }
  @media (min-width: 768px) and (max-width: 830px) {
    width: 70%;
  }
`;

const StyledImage = ({
  src = "",
  alt = "carouselstyledimage",
  mobileSrc = "",
  tabsrc = ""
}) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const isTab = useMediaQuery("(min-width: 820px) and (max-width: 1024px)");
  return (
    <StyledDiv isMobile={isMobile}>
      <StyledImageContainer
        isMobile={isMobile}
        src={isMobile ? mobileSrc : isTab ? tabsrc : src}
        alt={alt}
      />
    </StyledDiv>
  );
};

const heroImages = [
  {
    alt: "hero image #1",
    src: "/images/zipangLandingPage/desktoplogos.png",
    tabsrc: "/images/zipangLandingPage/tabletlogos.png",
    mobileSrc: "/images/zipangLandingPage/mobilelogos.png",
  },
  {
    alt: "hero image #1",
    src: "/images/zipangLandingPage/greenDiamondsDesktop.jpg",
    tabsrc: "/images/zipangLandingPage/greenDiamondsTab.jpg",
    mobileSrc: "/images/zipangLandingPage/greenDiamondsMob.jpg",
  },
];

const LandingHero = ({
  isMobile,
  inViewRef,
  setUpdatedIndex,
}: {
  isMobile: boolean;
  inViewRef: React.Ref<any>;
  setUpdatedIndex: (index: number) => void;
}) => {
  const { t } = useTranslation();
  const [slideNumber, setSlideNumber] = useState(0);
  const slideConfig = {
    1: {
      heading1: t("common:hero:4:heading1"),
      heading2: t("common:hero:4:heading2"),
      textAlign: "left",
      buttonAlign: "center",
      subHeading: t("common:hero:4:subHeading"),
    },
  } as { [key: number]: any };

  const handleCarouselSlideChange = (slideNumber: number) => {
    setSlideNumber(slideNumber);
    setUpdatedIndex(slideNumber);
  }

  useEffect(() => {
    trialModalHandler();
  }, []);

  const trialModalHandler = () => {
    let modalDisplayed = localStorage.getItem("modalDisplayed");
    !modalDisplayed &&
      setTimeout(() => {
        localStorage.setItem("modalDisplayed", "1");
      }, 4000);
  };
  const slide = slideConfig[slideNumber];
  const isForImageMobile = useMediaQuery("(max-width: 768px)");


  return (
    <StyledHeroContainer isMobile={isMobile}>
      {slideNumber === 1 && (
        <StyledHeroSecondCenterContainer>
          <StyledHeroBannerText
            align={isMobile ? "center" : slide?.textAlign}
            isMobile={isMobile}
          >
            <div>{slide?.heading1}</div>
          </StyledHeroBannerText>
          <StyledHeroBannerSubText
            align={isMobile ? "center" : slide?.textAlign}
            isMobile={isMobile}
            style={{ marginLeft: "30px" }}
          >
            <div>{slide?.heading2}</div>
            {slide?.subHeading}
          </StyledHeroBannerSubText>
        </StyledHeroSecondCenterContainer>
      )}

      <StyledCarouselContainer>
        <GenericCarousel
          navButtonsAlwaysVisible={false}
          indicators={heroImages.length > 1}
          onChange={handleCarouselSlideChange}
        >
          {heroImages.map((item, i) => (
            <StyledImage
              key={i}
              src={item.src}
              mobileSrc={item.mobileSrc}
              alt={item.alt}
              tabsrc={item.tabsrc}
            />
          ))}
        </GenericCarousel>
      </StyledCarouselContainer>
    </StyledHeroContainer>
  );
};

export default LandingHero;
