import React, { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import styled, { css } from "styled-components";
import { useHistory } from "react-router";
import {
  StyledComponent,
  StyledModal,
} from "../../../../common/materials/GenericStyleComponent";
import AlertReadOnlyUser from "../../../../common/elements/AlertReadOnlyUser";
import Alert from "../../../../common/elements/Alert";
import PreBidConfirmationPopup from "../../details/PreBidConfirmationPopup";
import { AuctionState } from "../../../../enums/auctionEnum";
import { cancelPreBidPriceApi, setPreBidPriceApi } from "./PrebidService";
import { Box } from "@mui/material";
import { getNumberFormat } from "../../../../services/NumberFormatService";

const StyledContainer = styled(Grid)`
  padding: 2px;
  justify-content: center;
`;

interface IInputProps {
  disabled?: boolean;
}

const StyledInput = styled.input<IInputProps>`
  padding: 5px;
  width: 100%;
  text-align: right;
  border: none;
  border-radius: 2px;
  font-size: 0.7em;
  background: ${(props) => props.theme.colors.base.white};
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  ::placeholder {
    color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  }
  ${(props) =>
    props.disabled &&
    css`
      ::placeholder {
        color: ${(props) => props.theme.colors.base.black};
      }
      background: ${(props) => props.theme.colors.base.white};
      border: 1px solid ${(props) => props.theme.colors.base.lightGrey};
    `}
`;

const StyledInputWrapper = styled.div`
  display: flex;
  border: 1px solid ${(props) => props.theme.colors.base.lightGrey};
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  background: ${(props) => props.theme.colors.base.white};
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
`;

interface IButtonProps {
  cancel?: boolean;
  solid?: boolean;
  disabled?: boolean;
}

const StyledTextWrapper = styled.div`
  padding: 2px 2px;
  border: none;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${(props) => props.theme.colors.base.white};
  background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  font-size: 0.7em;
`;

const StyledCancelButton = styled.button<IButtonProps>`
  padding: 5px 8px;
  border-radius: 3px;
  border: none;
  font-size: 0.8em;
  margin-left: 5px;
  color: ${(props) => props.theme.colors.base.white};
  background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  ${(props) =>
    props.cancel &&
    css`
      color: ${(props) => props.theme.colors.base.white};
      background: ${(props) =>
        props.theme.colors.base.buttonGradientFirstColor};
    `}
  ${(props) =>
    props.disabled &&
    css`
      cursor: default;
      background: #659ec7;
      color: ${(props) => props.theme.colors.base.black};
    `}
`;

const StyledDropDown = styled.img`
  width: 2em;
  height: 2em;
  cursor: pointer;
`;

const StyledLabelConversion = styled.label`
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  font-size: 1em;
  display: inline-block;
`;

interface IProps {
  items: any;
  count: number;
  apiCall?: any;
  isReadOnlyUser: any;
  isMobileUi: boolean;
}
let joinedAuctions: any = [];

const PreviewPrebiding = React.forwardRef((props: IProps, ref) => {
  let { items, isReadOnlyUser, count, apiCall, isMobileUi } = props;
  let {
    auctionState,
    parcelType,
    sellerAuctionUserId,
    preBidPrice,
    currency,
    exchangeRates,
    caratMinimumPrice,
    auctionId,
    perId,
  } = items;

  const { t } = useTranslation();
  let userCurrencyCode = localStorage.getItem("preferredCurrency");
  let userCurrencySymbol = localStorage.getItem("preferredCurrencySymbol");

  const [biddingPrice, setBiddingPrice] = useState("");
  const [openToast, setOpenToast] = useState(false);
  const [openReadOnlyUserToast, setOpenReadOnlyUserToast] = useState(false);
  const [showAlertMessage, setShowAlertMessage] = useState("");
  const [isPreBidDone, setIsPreBidDone] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [isAuctionUserId, setIsAuctionUserId] = useState(false);
  const [userBlock, setUserBlock] = useState(false);
  const [openPreBidPopUpModal, setOpenPreBidPopUpModal] = useState(false);
  const [eStore, setEStore] = useState();
  const [preBiddingPrice, setPreBiddingPrice] = useState("");
  const [preBidConversion, setPreBidConversion] = useState("");
  let history = useHistory();

  useEffect(() => {
    joinedAuctions = localStorage.getItem("joinedAuctions");
    joinedAuctions = JSON.parse(joinedAuctions);
    if (joinedAuctions !== null) {
      joinedAuctions.filter((items: any, index: number) => {
        if (sellerAuctionUserId === items.auctionUserId) {
          setIsAuctionUserId(true);
        }
      });
    } else {
      setIsAuctionUserId(false);
    }
    if (preBidPrice === null || preBidPrice === undefined) {
      setIsPreBidDone(false);
    } else {
      setIsPreBidDone(true);
    }
    return () => {
      setIsPreBidDone(false);
      setIsAuctionUserId(false);
    };
  }, [preBidPrice, sellerAuctionUserId]);

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setBiddingPrice(e.target.value);

    let targetVal: any = e.target.value;
    let bidingPriceZero = await getBracketValue();
    if (parcelType !== "Diamond") {
      if (userCurrencyCode !== currency["code"]) {
        let bidPrice = targetVal + bidingPriceZero;
        let conversion = new Intl.NumberFormat("us-en").format(
          Math.round(Number(bidPrice) * exchangeRates[0]["rate"])
        );
        let conversions = new Intl.NumberFormat("us-en").format(
          Math.round(Number(bidPrice))
        );
        setPreBidConversion(
          `${currency["symbol"]} ${conversions} (${userCurrencySymbol} ${conversion})`
        );
      } else {
        setPreBidConversion("");
      }
    } else {
      if (userCurrencyCode !== currency["code"]) {
        let bidPrice = targetVal + bidingPriceZero;
        let conversion = new Intl.NumberFormat("us-en").format(
          Math.round(Number(bidPrice) * exchangeRates[0]["rate"])
        );
        let conversions = new Intl.NumberFormat("us-en").format(
          Math.round(Number(bidPrice))
        );
        setPreBidConversion(
          `${currency["symbol"]} ${conversions} (${userCurrencySymbol} ${conversion})`
        );
      } else {
        setPreBidConversion("");
      }
    }
    // when user clear the prebid amount
    if (targetVal === "") {
      setPreBidConversion("");
    }
  };

  const showPreBidPopUp = async () => {
    const validate = (bidPrice: string) => {
      if (biddingPrice === "") {
        setShowAlertMessage(`${t("common:preview:preBidding:enterAmount")}`);
        setAlertType("error");
        setOpenToast(true);
      } else if (bidPrice <= caratMinimumPrice) {
        setBiddingPrice("");
        setShowAlertMessage(
          `${t("common:preview:preBidding:validationOnCorrectAmount")}`
        );
        setAlertType("error");
        setOpenToast(true);
      } else {
        if (userCurrencyCode !== currency["code"]) {
          const conversion = getNumberFormat(
            Math.round(Number(bidPrice) * exchangeRates[0]["rate"])
          );
          setPreBidConversion(`${userCurrencySymbol}${conversion}`);
        } else {
          setPreBidConversion("");
        }

        setPreBiddingPrice(
          `${currency["symbol"]}${getNumberFormat(Number(bidPrice))}`
        );
        setOpenPreBidPopUpModal(true);
      }
    };
    let bidingPriceZero = await getBracketValue();
    const bidPrice = biddingPrice + bidingPriceZero;
    validate(bidPrice);
  };

  const handlePreBidSubmitPopup = async (e: any) => {
    // e.preventDefault();
    let bidPrice = "";
    const letters = /^[0-9]+$/;
    let bidingPriceZero = await getBracketValue();
    bidPrice = biddingPrice + bidingPriceZero;

    if (bidPrice.match(letters) && bidPrice > caratMinimumPrice) {
      let { response, status } = await setPreBidPriceApi({
        auctionId,
        productId: perId,
        bidPrice,
      });
      if (status === 200) {
        setOpenPreBidPopUpModal(false);
        setBiddingPrice("");
        setAlertType("success");
        setShowAlertMessage(response.data.message);
        setOpenToast(true);
        setIsPreBidDone(true);
        apiCall();
      } else if (status === 403) {
        setUserBlock(true);
      } else if (status === 404) {
        setBiddingPrice("");
        setShowAlertMessage(`${t("common:invalidRequest")}`);
        setAlertType("error");
        setOpenToast(true);
      } else if (status === 412) {
        setTimeout(() => {
          history.push("/preview");
        }, 2000);
        setIsPreBidDone(false);
        setBiddingPrice("");
        setShowAlertMessage(response);
        setAlertType("error");
        setOpenToast(true);
      } else {
        setIsPreBidDone(false);
        setBiddingPrice("");
        setShowAlertMessage(`${t("common:somethingWentWrong")}`);
        setAlertType("error");
        setOpenToast(true);
      }
    } else if (biddingPrice === "") {
      setAlertType("error");
      setShowAlertMessage(
        `${t(
          parcelType === "Jewellery"
            ? "common:preview:preBidding:jwlEnterAmount"
            : "common:preview:preBidding:enterAmount"
        )}`
      );
      setOpenToast(true);
    } else {
      setIsPreBidDone(false);
      setBiddingPrice("");
      setShowAlertMessage(
        `${t("common:preview:preBidding:validationOnCorrectAmount")}`
      );
      setAlertType("error");
      setOpenToast(true);
    }
    setTimeout(() => {
      setOpenToast(false);
    }, 2000);
  };

  const onSubmit = (e: any) => {
    if (checkPrebidPrice()) {
      handlePreBidSubmit(e);
      e.preventDefault();
      showPreBidPopUp();
      setEStore(e);
    } else {
      handleCancelSubmit(e);
    }
  };

  const preBidModalYesButtonPressed = () => {
    handlePreBidSubmitPopup(eStore);
  };

  const getBracketValue = () => {
    let bidingPriceZero: any = "";
    const isJPY = currency["code"] === "JPY" ? true : false;
    const bidPrice = caratMinimumPrice; // starting price

    if (parcelType === "Parcel" && parcelType !== undefined) {
      if (isJPY) {
        if (bidPrice < 10000) {
          bidingPriceZero = "00";
        } else if (bidPrice >= 10000) {
          bidingPriceZero = "000";
        }
      } else {
        if (bidPrice >= 100 && bidPrice < 30000) {
          bidingPriceZero = "0";
        } else if (bidPrice >= 30000) {
          bidingPriceZero = "000";
        }
      }
    } else {
      if (isJPY) {
        if (bidPrice < 10000) {
          bidingPriceZero = "00";
        } else if (bidPrice >= 10000) {
          bidingPriceZero = "000";
        }
      } else {
        if (bidPrice >= 100 && bidPrice < 30000) {
          bidingPriceZero = "0";
        } else if (bidPrice >= 30000) {
          bidingPriceZero = "000";
        }
      }
    }
    return bidingPriceZero;
  };

  const handlePreBidSubmit = async (e: any) => {
    e.preventDefault();
    let bidPrice = "";
    const letters = /^[0-9]+$/;
    let bidingPriceZero = await getBracketValue();
    bidPrice = biddingPrice + bidingPriceZero;

    if (bidPrice.match(letters) && bidPrice > caratMinimumPrice) {
      if (userCurrencyCode !== currency["code"]) {
        const conversion = getNumberFormat(
          Math.round(Number(bidPrice) * exchangeRates[0]["rate"])
        );
        setPreBidConversion(`${userCurrencySymbol}${conversion}`);
      } else {
        setPreBidConversion("");
      }

      setPreBiddingPrice(
        `${currency["symbol"]}${getNumberFormat(Number(bidPrice))}`
      );
      setOpenPreBidPopUpModal(true);
    } else if (biddingPrice === "") {
      setAlertType("error");
      setShowAlertMessage(
        `${t(
          parcelType === "Jewellery"
            ? "common:preview:preBidding:jwlEnterAmount"
            : "common:preview:preBidding:enterAmount"
        )}`
      );
      setOpenToast(true);
    } else {
      setIsPreBidDone(false);
      setBiddingPrice("");
      setShowAlertMessage(
        `${t("common:preview:preBidding:validationOnCorrectAmount")}`
      );
      setAlertType("error");
      setOpenToast(true);
    }
  };

  const handleCancelSubmit = async (e: any) => {
    e.preventDefault();
    let { response, status } = await cancelPreBidPriceApi({
      auctionId,
      productId: perId,
    });
    if (status === 200) {
      setOpenPreBidPopUpModal(false);
      setBiddingPrice("");
      setPreBidConversion("");
      setShowAlertMessage("Pre-bid price removed successfully");
      setAlertType("success");
      setOpenToast(true);
      apiCall();
    } else if (status === 403) {
      setUserBlock(true);
    } else if (status === 404) {
      setAlertType("error");
      setShowAlertMessage(`${t("common:invalidRequest")}`);
      setOpenToast(true);
    } else if (status === 412) {
      setTimeout(() => {
        history.push("/preview");
      }, 2000);
      setIsPreBidDone(false);
      setBiddingPrice("");
      setShowAlertMessage(response);
      setAlertType("error");
      setOpenToast(true);
    } else {
      setShowAlertMessage(`${t("common:somethingWentWrong")}`);
      setAlertType("error");
      setOpenToast(true);
    }
    setTimeout(() => {
      setOpenToast(false);
    }, 2000);
  };

  const renderReadOnlyUserToast = () => {
    if (isReadOnlyUser) {
      setOpenReadOnlyUserToast(true);
    } else if (isAuctionUserId) {
      setOpenReadOnlyUserToast(true);
    } else {
      setOpenReadOnlyUserToast(false);
    }
    setTimeout(() => {
      setOpenReadOnlyUserToast(false);
    }, 3000);
  };

  const checkAuctionState = () => {
    return (
      auctionState === AuctionState.NOTSTARTED ||
      auctionState === "" ||
      auctionState === "undefined" ||
      auctionState === null
    );
  };

  const checkAuctionUserIdAndCount = () => {
    return isAuctionUserId === false && count === 0;
  };

  const checkPrebidPrice = () => {
    return (
      preBidPrice === null || preBidPrice === undefined || preBidPrice === 0
    );
  };

  const renderCurrencyCode = () => {
    let bidZeroText;
    const isJPY = currency["code"] === "JPY" ? true : false;
    const bidPrice = caratMinimumPrice;

    if (parcelType === "Parcel" && parcelType !== undefined) {
      if (isJPY) {
        if (bidPrice < 10000) {
          bidZeroText = "00";
        } else if (bidPrice >= 10000) {
          bidZeroText = "000";
        }
      } else {
        if (bidPrice >= 100 && bidPrice < 30000) {
          bidZeroText = 0;
        } else if (bidPrice >= 30000) {
          bidZeroText = "000";
        }
      }
    } else {
      if (isJPY) {
        if (bidPrice < 10000) {
          bidZeroText = "00";
        } else if (bidPrice >= 10000) {
          bidZeroText = "000";
        }
      } else {
        if (bidPrice >= 100 && bidPrice < 30000) {
          bidZeroText = 0;
        } else if (bidPrice >= 30000) {
          bidZeroText = "000";
        }
      }
    }

    return (
      <StyledTextWrapper>
        {currency && currency["code"] !== "JPY" ? (
          <>{bidZeroText}$</>
        ) : (
          <>{bidZeroText}¥</>
        )}
      </StyledTextWrapper>
    );
  };

  const renderInput = (disabled: boolean) => {
    const isDisabled = disabled ? disabled : false;
    return (
      <>
        {isDisabled ? (
          <span
            onClick={(e: any) => {
              e.preventDefault();
              renderReadOnlyUserToast();
            }}
          >
            <StyledInput
              disabled={true}
              type="number"
              min="0"
              name="preBiddingPrice"
              id="preBiddingPrice"
              placeholder={`${t("common:preview:preBidding:placeholderText")}`}
            />
          </span>
        ) : (
          <>
            {isReadOnlyUser ? (
              <StyledInput
                disabled={true}
                type="number"
                min="0"
                name="preBiddingPrice"
                id="preBiddingPrice"
                placeholder={`${t(
                  "common:preview:preBidding:placeholderText"
                )}`}
                style={{ background: "lightgrey", border: "1px solid grey" }}
              />
            ) : (
              <StyledInput
                type="number"
                min="0"
                name="preBiddingPrice"
                id="preBiddingPrice"
                placeholder={`${t(
                  "common:preview:preBidding:placeholderText"
                )}`}
                onChange={(e: any) => {
                  if (isDisabled === false) {
                    const isJPY = currency["code"] === "JPY" ? true : false;
                    let lengthValue: any = isJPY ? 6 : 8;
                    if (e.target?.value?.length <= lengthValue) {
                      handleChange(e);
                    }
                    return;
                  }
                }}
                value={biddingPrice}
                disabled={isDisabled}
              />
            )}
          </>
        )}
      </>
    );
  };

  const renderInputForParcelTypeDiamond = () => {
    return (
      <>
        {checkPrebidPrice() && (
          <StyledInputWrapper>
            <>
              {checkAuctionState() ? (
                <Grid item xs={12}>
                  {checkAuctionUserIdAndCount()
                    ? renderInput(false)
                    : renderInput(true)}
                </Grid>
              ) : (
                renderInput(true)
              )}
              {renderCurrencyCode()}
            </>
          </StyledInputWrapper>
        )}
      </>
    );
  };

  const renderInputForJewellery = () => {
    return (
      <>
        {checkPrebidPrice() && (
          <StyledInputWrapper>
            <>
              {checkAuctionState() ? (
                <Grid item xs={12}>
                  {checkAuctionUserIdAndCount()
                    ? renderInput(false)
                    : renderInput(true)}
                </Grid>
              ) : (
                renderInput(true)
              )}
              {renderCurrencyCode()}
            </>
          </StyledInputWrapper>
        )}
      </>
    );
  };

  const renderSubmitButtonTemplate = () => {
    return (
      <>
        {checkAuctionUserIdAndCount() ? (
          <>
            <StyledDropDown
              src="/images/assets/bidIcon.png"
              alt="bidIcon"
              title="Prebid"
              onClick={handlePreBidSubmit}
            />
          </>
        ) : (
          <>
            <StyledDropDown
              src="/images/assets/bidIcon.png"
              alt="bidIcon"
              title="Prebid"
              onClick={(e: any) => {
                renderReadOnlyUserToast();
              }}
            />
          </>
        )}
      </>
    );
  };

  const renderCancelButtonTemplate = () => {
    return (
      <>
        {checkAuctionUserIdAndCount() ? (
          <StyledCancelButton cancel={true} onClick={handleCancelSubmit}>
            Cancel
          </StyledCancelButton>
        ) : (
          <>
            <StyledDropDown
              src="/images/assets/bidIcon.png"
              alt="bidIcon"
              title="Prebid disable"
            />
          </>
        )}
      </>
    );
  };

  const convertedPlaceHolderValue = (
    currencyCodeVal: any,
    price: any,
    exchangeRate: any
  ) => {
    //get user currency code form local storeage
    let userCurrencyCode = localStorage.getItem("preferredCurrency");
    let userCurrencySymbol = localStorage.getItem("preferredCurrencySymbol");

    if (
      currencyCodeVal === undefined ||
      currencyCodeVal === null ||
      price === undefined ||
      price === null ||
      price === 0 ||
      price === "" ||
      exchangeRate === undefined ||
      exchangeRate === null
    ) {
      return " ";
    } else {
      if (userCurrencyCode !== currencyCodeVal.code) {
        let conversion = new Intl.NumberFormat("us-en").format(
          Math.round(price * exchangeRate)
        );
        let conversions = new Intl.NumberFormat("us-en").format(
          Math.round(price)
        );
        return `${currencyCodeVal.symbol} ${conversions} (${userCurrencySymbol} ${conversion})`;
      } else {
        let conversions = new Intl.NumberFormat("us-en").format(
          Math.round(price)
        );
        return `${userCurrencySymbol} ${conversions}`;
      }
    }
  };

  const convertedAmt = (
    currencyCodeVal: any,
    price: any,
    exchangeRate: any,
    carat: any,
    parcelType: any
  ) => {
    //get user currency code form local storeage
    let amt: any = price.replace(/[^0-9]/g, "") * carat;
    if (
      currencyCodeVal === undefined ||
      currencyCodeVal === null ||
      amt === undefined ||
      amt === null ||
      amt === 0 ||
      exchangeRate === undefined ||
      exchangeRate === null ||
      parcelType === "Jewellery"
    ) {
      return "";
    } else {
      const rate = exchangeRate?.[0]?.["rate"] ?? 1;
      if (userCurrencyCode !== currencyCodeVal.code) {
        let conversion = new Intl.NumberFormat("us-en").format(
          Math.round(amt * rate)
        );
        let conversions = new Intl.NumberFormat("us-en").format(
          Math.round(amt)
        );
        return ` ${conversions} ${currencyCodeVal.symbol}  (${conversion}${userCurrencySymbol})`;
      } else {
        let conversions = new Intl.NumberFormat("us-en").format(
          Math.round(amt)
        );
        return `${conversions} ${userCurrencySymbol}`;
      }
    }
  };

  return (
    <StyledComponent tabIndex={-1}>
      <StyledContainer
        container={true}
        spacing={0}
        className="PreBiddingContainer"
      >
        <Grid item={true} xs={12}>
          <form onSubmit={onSubmit} name="preBiddingForm" id="preBiddingForm">
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {parcelType !== "Jewellery"
                  ? renderInputForParcelTypeDiamond()
                  : renderInputForJewellery()}
                {checkPrebidPrice() ? (
                  <>
                    {checkAuctionState() ? (
                      renderSubmitButtonTemplate()
                    ) : (
                      <>
                        <StyledDropDown
                          src="/images/assets/bidIcon.png"
                          alt="bidIcon"
                          onClick={(e: any) => {
                            e.preventDefault();
                            renderReadOnlyUserToast();
                          }}
                        />
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {checkAuctionState() ? (
                      renderCancelButtonTemplate()
                    ) : (
                      <StyledCancelButton disabled={true}>
                        Cancel
                      </StyledCancelButton>
                    )}
                  </>
                )}
              </Box>
              {isMobileUi && (
                <StyledLabelConversion style={{ margin: 0 }}>
                  {checkPrebidPrice() ? (
                    <>
                      {preBidConversion}{" "}
                      {parcelType === "Jewellery"
                        ? ""
                        : preBidConversion !== ""
                          ? "/ct"
                          : ""}
                    </>
                  ) : (
                    <>
                      {convertedPlaceHolderValue(
                        items.currency,
                        items.preBidPrice ?? 0,
                        items.exchangeRates[0].rate
                      )}
                      {parcelType === "Jewellery" ? "" : "/ct"}
                    </>
                  )}
                </StyledLabelConversion>
              )}
            </Box>
          </form>
        </Grid>

        {openReadOnlyUserToast && (
          <AlertReadOnlyUser
            isReadOnlyUser={isReadOnlyUser}
            isSeller={isAuctionUserId}
          />
        )}
        {openToast && (
          <Alert showAlertMessage={showAlertMessage} alertType={alertType} />
        )}
        {openPreBidPopUpModal && (
          <StyledModal open={openPreBidPopUpModal}>
            <PreBidConfirmationPopup
              t={t}
              {...props}
              message={t("common:preview:preBidding:preBidPopUpMessage") + ": "}
              biddingAmount={`${preBiddingPrice} ${parcelType === "Jewellery"
                ? ""
                : preBidConversion !== ""
                  ? ""
                  : "/ct"
                }`}
              conversionAmount={
                preBidConversion === ""
                  ? ""
                  : `(${preBidConversion}) ${parcelType === "Jewellery" ? "" : "/ct"
                  }`
              }
              onConfirmModal={preBidModalYesButtonPressed}
              onCancelModal={() => setOpenPreBidPopUpModal(false)}
              caratAmount={convertedAmt(
                items.currency,
                preBiddingPrice ?? 0,
                items?.exchangeRates,
                Number(items.carat),
                items.parcelType
              )}
            />
          </StyledModal>
        )}
      </StyledContainer>
    </StyledComponent>
  );
});

export default PreviewPrebiding;
