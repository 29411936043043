import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useTranslation } from "react-i18next";
import LoopIcon from "@material-ui/icons/Loop";
import ProductDescription from "../../../shared/ProductDescription";
import { convertedValue } from "../TenderGenericFunc";
import {
  StyledButton,
  StyledComponent,
} from "../../../../common/materials/GenericStyleComponent";
import {
  BulkBidButtonContainer,
  ProductCountContainer,
  ProductNameContainer,
  StyledAuctionbidWrapper,
  StyledButtonWrapper,
  StyledCloseicon,
  StyledContainerWrapper,
} from "./W_P_Styles";

import update from "immutability-helper";
import {
  cancelPreBidPriceApi,
  setMultiplePreBidPriceApi,
} from "./PrebidService";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AuctionState } from "../../../../enums/auctionEnum";
import { Grid } from "@material-ui/core";
const faHeartProps = faHeart as IconProp;
interface IProps {
  items?: any;
  auctionId: any;
  popupClose: any;
  productIndexs: any;
  auctionUserId: string;
  callApi: (id: string) => void;
}

let productPricePerCaratUSD: any = 0;
let rapnetPercentage: number = 0;
let bulkBidValue: number = 0;
let tempVal;
let rapPriceMapping: any = {
  perIdMap: {},
  rapnetPercentageMap: {},
};
let sourceFromUrl;
let isReadOnlyUser: any;
const BulkWatchlistPopup = (props: IProps) => {
  let { items, productIndexs, popupClose, callApi, auctionId } = props;
  const { t } = useTranslation();
  const [bidProducts, setBidProduct] = useState(items);
  const [bidPriceInputData, setBidPriceInputData] = useState<any>({});

  useEffect(() => {
    isReadOnlyUser = localStorage.getItem("isReadOnlyUser");
    isReadOnlyUser = JSON.parse(isReadOnlyUser);
  }, []);

  const getBidValue = (e: any, value: any) => {
    //bids update in  productBidValue variable

    const targeName = e.target.name;
    const targeValue = value;

    const num = Math.trunc(targeValue);
    if (!isNaN(num)) {
      const hasProperty = Object.keys(bidPriceInputData).includes(targeName);
      if (hasProperty === false) {
        bulkBidValue = 0;
        tempVal = 0;
      }
      if (hasProperty) {
        tempVal = bidPriceInputData[targeName] + num;
        bidPriceInputData[targeName] = tempVal;
        bulkBidValue = tempVal;
      } else {
        tempVal = 0;
        bidPriceInputData[targeName] = num;
        bulkBidValue = num;
      }
      //somehow update data
      setBidPriceInputData({ ...bidPriceInputData });
    }
    getRapnetPercentage(bulkBidValue, e);
  };

  const getRapnetPercentage = (price: any, e: any) => {
    const targetId = e.target.name;
    const targetName = e.target.name;
    const productIndex = parseInt(targetId, 10);
    rapPriceMapping.perIdMap[targetName] = price;

    const obj = items.find((item: any) => item.perId === targetId);
    if (productIndex !== undefined) {
      if (obj.rapnetPercentage !== undefined || obj.rapnetPercentage !== 0) {
        const totalBidPrice =
          obj.caratMinimumPrice + rapPriceMapping.perIdMap[obj.perId];
        if (obj.currency.code === "JPY") {
          productPricePerCaratUSD = totalBidPrice * obj.exchangeRates[0].rate;
        } else {
          productPricePerCaratUSD = totalBidPrice;
        }
        rapPriceMapping.rapnetPercentageMap[obj.perId] =
          obj.rapnetPrice === undefined || obj.rapnetPercentage === 0
            ? "-"
            : parseFloat(
              (
                (1 - productPricePerCaratUSD / obj.rapnetPrice) *
                -100.0
              ).toFixed(2)
            );
      }
    }
  };

  //bulk bid payload

  let myLatestBidForProducts: any = [];

  const bulkBidBtnValuehandle = async () => {
    const bidPayload: any = [];
    let userCompany = localStorage.getItem("companyName");
    productIndexs.map((rowIndex: any) => {
      const bidValue = parseInt(bidPriceInputData[items[rowIndex].perId], 10);
      if (bidValue) {
        const obj = {
          id: items[rowIndex].perId,
          companyName: userCompany,
          productSerialNo: items[rowIndex].serialNo,
          preBidPrice: items[rowIndex].caratMinimumPrice + bidValue,
          watched: bidProducts[rowIndex]?.watched ? true : false,
        };

        const myBidsValueObj = {
          productId: items[rowIndex].perId,
          myLatestBid: items[rowIndex].caratMinimumPrice + bidValue,
        };

        let updateRowIndex = myLatestBidForProducts.findIndex(
          (product: any) => product.productId === myBidsValueObj.productId
        );
        if (updateRowIndex === -1) {
          var myLatestBidForProductObjectData: any = [
            ...myLatestBidForProducts,
            myBidsValueObj,
          ];
          myLatestBidForProducts = myLatestBidForProductObjectData;
        } else {
          var updatedArray = update(myLatestBidForProducts, {
            $splice: [[updateRowIndex, 1, myBidsValueObj]],
          });
          myLatestBidForProducts = updatedArray;
        }
        bidPayload.push(obj);
      } else {
        const obj = {
          id: items[rowIndex].perId,
          companyName: userCompany,
          productSerialNo: items[rowIndex].serialNo,
          preBidPrice:
            items[rowIndex].preBidPrice !== 0 ? items[rowIndex].preBidPrice : 0,
          watched: bidProducts[rowIndex]?.watched ? true : false,
        };

        bidPayload.push(obj);
      }
    });

    let { status } = await setMultiplePreBidPriceApi({
      auctionId,
      bidPayload,
    });

    if (status === 200) {
      popupClose();
      callApi(auctionId);
    }
  };

  const getTableHight = () => {
    if (productIndexs.length > 4) {
      return 390;
    }
  };

  const bidButtonHandeler = (data: any) => {
    const isJPY = data.currency.code === "JPY" ? true : false;
    const bidPrice = data.caratMinimumPrice;

    var button1 = 0;
    var button2 = 0;
    var button3 = 0;

    var isFirstBtnEnable = false;

    if (data.parcelType === "Parcel" && data.parcelType !== undefined) {
      if (isJPY) {
        if (bidPrice < 10000) {
          button1 = isJPY ? 100 : 1;
          button2 = isJPY ? 500 : 5;
          button3 = isJPY ? 1000 : 10;
          isFirstBtnEnable = true;
        } else if (bidPrice >= 10000 && bidPrice < 100000) {
          button1 = isJPY ? 100 : 10;
          button2 = isJPY ? 500 : 50;
          button3 = isJPY ? 1000 : 100;
        } else if (bidPrice >= 100000) {
          button1 = isJPY ? 1000 : 10;
          button2 = isJPY ? 5000 : 50;
          button3 = isJPY ? 10000 : 100;
        }
      } else {
        if (bidPrice < 100) {
          button1 = 1;
          button2 = 5;
          button3 = 10;
          isFirstBtnEnable = true;
        } else if (bidPrice >= 100 && bidPrice < 1000) {
          button1 = 1;
          button2 = 5;
          button3 = 10;
        } else if (bidPrice >= 1000 && bidPrice < 30000) {
          button1 = 10;
          button2 = 50;
          button3 = 100;
        } else if (bidPrice >= 30000 && bidPrice < 100000) {
          isFirstBtnEnable = true;
          button1 = 1000;
          button2 = 5000;
          button3 = 10000;
        } else if (bidPrice >= 100000) {
          button1 = 1000;
          button2 = 5000;
          button3 = 10000;
        }
      }
    } else {
      if (isJPY) {
        if (bidPrice < 10000) {
          button1 = isJPY ? 100 : 1;
          button2 = isJPY ? 500 : 5;
          button3 = isJPY ? 1000 : 10;
          isFirstBtnEnable = true;
        } else if (bidPrice >= 10000 && bidPrice < 100000) {
          button1 = isJPY ? 1000 : 10;
          button2 = isJPY ? 5000 : 50;
          button3 = isJPY ? 10000 : 100;
          isFirstBtnEnable = true;
        } else if (bidPrice >= 100000) {
          button1 = isJPY ? 1000 : 10;
          button2 = isJPY ? 5000 : 50;
          button3 = isJPY ? 10000 : 100;
        }
      } else {
        if (bidPrice < 100) {
          button1 = 1;
          button2 = 5;
          button3 = 10;
          isFirstBtnEnable = true;
        } else if (bidPrice >= 100 && bidPrice < 1000) {
          button1 = 10;
          button2 = 50;
          button3 = 100;
          isFirstBtnEnable = true;
        } else if (bidPrice >= 1000 && bidPrice < 30000) {
          button1 = 10;
          button2 = 50;
          button3 = 100;
        } else if (bidPrice >= 30000 && bidPrice < 100000) {
          isFirstBtnEnable = true;
          button1 = 1000;
          button2 = 5000;
          button3 = 10000;
        } else if (bidPrice >= 100000) {
          button1 = 1000;
          button2 = 5000;
          button3 = 10000;
        }
      }
    }

    return { button1, button2, button3, isFirstBtnEnable };
  };

  const commasButtonRender = (value: number) => {
    return new Intl.NumberFormat("us-en").format(
      Math.round(value)
    );
  }
  const getbidButtonRender = (productObj: any, index: any) => {
    let { button1, button2, button3, isFirstBtnEnable } =
      bidButtonHandeler(productObj);

    return (
      <>
        {productObj.auctionState === "NotStarted" ? (
          <StyledAuctionbidWrapper item xs={12}>
            <Grid item xs={4}>
              {isFirstBtnEnable ? (
                <StyledButton
                  className="notDisablebutton"
                  id={`${index}`}
                  name={`${productObj.perId}`}
                  onClick={(e) => getBidValue(e, button1)}
                >
                  + {productObj.currency.symbol} {commasButtonRender(button1)}
                </StyledButton>
              ) : (
                <StyledButton className="disableButton">
                  + {productObj.currency.symbol} {commasButtonRender(button1)}
                </StyledButton>
              )}
            </Grid>
            <Grid item xs={4}>
              <StyledButton
                className="notDisablebutton"
                id={`${index}`}
                name={`${productObj.perId}`}
                onClick={(e) => getBidValue(e, button2)}
              >
                + {productObj.currency.symbol} {commasButtonRender(button2)}
              </StyledButton>
            </Grid>
            <Grid item xs={4}>
              <StyledButton
                className="notDisablebutton"
                name={`${productObj.perId}`}
                id={`${index}`}
                onClick={(e) => getBidValue(e, button3)}
              >
                + {productObj.currency.symbol} {commasButtonRender(button3)}
              </StyledButton>
            </Grid>
          </StyledAuctionbidWrapper>
        ) : (
          <StyledAuctionbidWrapper item xs={12}>
            <Grid item xs={4}>
              <StyledButton className="disableButton">
                + {productObj.currency.symbol} {commasButtonRender(button1)}
              </StyledButton>
            </Grid>
            <Grid item xs={4}>
              <StyledButton className="disableButton">
                + {productObj.currency.symbol} {commasButtonRender(button1)}
              </StyledButton>
            </Grid>
            <Grid item xs={4}>
              <StyledButton className="disableButton">
                + {productObj.currency.symbol} {commasButtonRender(button1)}
              </StyledButton>
            </Grid>
          </StyledAuctionbidWrapper>
        )}
      </>
    );
  };

  const refreshButtonHandler = (
    productCurrentBid: any,
    currentProductId: any
  ) => {
    rapPriceMapping.perIdMap[currentProductId] = 0;
    const obj = items.find((item: any) => item.perId === currentProductId);
    if (currentProductId !== undefined) {
      if (obj.rapnetPercentage === undefined || obj.rapnetPercentage === 0) {
      } else {
        const totalBidPrice = rapPriceMapping.perIdMap[obj.perId];
        if (obj.currency.code === "JPY") {
          productPricePerCaratUSD = totalBidPrice * obj.exchangeRates[0].rate;
        } else {
          productPricePerCaratUSD = totalBidPrice;
        }
        rapPriceMapping.rapnetPercentageMap[obj.perId] = parseFloat(
          ((1 - productPricePerCaratUSD / obj.rapnetPrice) * -100.0).toFixed(2)
        );
      }
    }

    if (!isNaN(productCurrentBid)) {
      const hasProperty =
        Object.keys(bidPriceInputData).includes(currentProductId);
      if (hasProperty) {
        var tempVal = 0;
        bidPriceInputData[currentProductId] = tempVal;
        bulkBidValue = 0;
      } else {
        bidPriceInputData[currentProductId] = 0;
        bulkBidValue = 0;
      }
      setBidPriceInputData({ ...bidPriceInputData });
    }
  };

  const bidButtonRender = (items: any, index: number) => {
    return <>{getbidButtonRender(items, index)}</>;
  };

  const cancelPrebidHandler = async (currentProduct: any) => {
    let auctionId = currentProduct.auctionId;
    let { status } = await cancelPreBidPriceApi({
      auctionId,
      productId: currentProduct.perId,
    });
    if (status === 200) {
      callApi(auctionId);
    }
  };

  const handleWatchlist = async (
    product: any,
    proudctId: any,
    isWatch: boolean
  ) => {
    // let exist = product.some((obj: any) => obj.hasOwnProperty("watched"));
    const newArr: any = await product.map((obj: any, index: any) => {
      if (obj.perId === proudctId) {
        return {
          ...obj,
          watched: isWatch,
        };
      }
      return obj;
    });
    setBidProduct(newArr);
  };

  const bulkwatchlistHandler = () => {
    let newArr: any = [];
    if (bidProducts.filter((e: any) => e.watched === true).length > 0) {
      bidProducts.map((obj: any) => {
        if (obj.perId !== "") {
          newArr.push({
            ...obj,
            watched: false,
          });
        } else {
          newArr.push(obj);
        }
      });
    } else {
      bidProducts.map((obj: any) => {
        if (obj.perId !== "") {
          newArr.push({
            ...obj,
            watched: true,
          });
        } else {
          newArr.push(obj);
        }
      });
    }
    setBidProduct(newArr);
  };

  const openProductDetail = (
    auctionId: string,
    perId: string,
    auctionDate: any,
    parcelType: string
  ) => {
    const urlParams = new URLSearchParams(window.location.search);
    sourceFromUrl = urlParams.get("source");
    let myString = auctionDate;

    // Regular expression to match the date pattern
    const datePattern = /\d{4}-\d{2}-\d{2}/;

    // Find the first occurrence of the date pattern in the string
    const match: any = myString.match(datePattern);

    // Extract the date from the match array
    myString =
      match[0] === null && match[0] === undefined ? auctionDate : match[0];

    if (parcelType === "Jewellery") {
      let languageCode =
        localStorage.getItem("language") === "EN"
          ? "en"
          : localStorage.getItem("language") === "zh_CN"
            ? "zh_CN"
            : "jp";
      window.open(
        `/${languageCode}/my-page/diamonds/details/${auctionId}/${perId}/${myString}`,
        "detailsWindowName",
        "height=700px,width=800px,scrollbars,toolbar,status,resizable"
      );
    } else {
      let languageCode =
        localStorage.getItem("language") === "EN"
          ? "en"
          : localStorage.getItem("language") === "zh_CN"
            ? "zh_CN"
            : "jp";
      window.open(
        `/${languageCode}/my-page/diamonds/details/${auctionId}/${perId}/${myString}`,
        "detailsWindowName",
        "height=700px,width=800px,scrollbars,toolbar,status,resizable"
      );
    }
  };

  const checkAuctionState = () => {
    let auctionState = items[0]?.auctionState;
    return (
      auctionState === AuctionState.NOTSTARTED ||
      auctionState === "" ||
      auctionState === "undefined" ||
      auctionState === null
    );
  };

  return (
    <StyledComponent>
      <StyledContainerWrapper>
        <div
          style={{
            background: "lightgray",
            flexDirection: "row",
            display: "flex",
            height: "40px",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: "1px solid lightgray",
          }}
        >
          <ProductCountContainer style={{ color: "#1e3c63" }}>
            {t("common:Tender:tableColumnNames:bulkBid")}
          </ProductCountContainer>
          <ProductNameContainer className="fontBold">
            <StyledCloseicon
              src="/images/close-button2.ico"
              onClick={popupClose}
            ></StyledCloseicon>
          </ProductNameContainer>
        </div>

        <TableContainer component={Paper} style={{ height: getTableHight() }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow style={{ border: "1px solid lightgray" }}>
                <TableCell
                  style={{
                    border: "1px solid lightgray",
                  }}
                >
                  {t("common:preview:productsList:serialNo")}
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgray",
                    minWidth: "200px",
                  }}
                >
                  {t("common:Tender:tableColumnNames:details")}
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgray",
                  }}
                >
                  Starting Price
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgray",
                  }}
                >
                  {t("common:Tender:tableColumnNames:rapOff")}
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgray",
                  }}
                >
                  {t("common:Tender:tableColumnNames:yourBidPrice")}
                </TableCell>
                <TableCell>{/* refresh icon */}</TableCell>
                <TableCell>
                  <FontAwesomeIcon
                    icon={faHeartProps}
                    title="Watched"
                    style={{
                      color:
                        bidProducts?.filter((e: any) => e.watched === true)
                          .length > 0
                          ? "grey"
                          : "red",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      bulkwatchlistHandler();
                    }}
                  />
                </TableCell>
                <TableCell>
                  {/* {t("common:Tender:tableColumnNames:yourBidPrice")} */}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {productIndexs.slice(0, 10).map((row: any, index: any) => (
                <TableRow key={index} style={{ border: "1px solid lightgray" }}>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      color: "#1e3c63",
                      textDecoration: "underline",
                      cursor: "pointer",
                      border: "1px solid lightgray",
                    }}
                    onClick={() =>
                      openProductDetail(
                        items[row].auctionId,
                        items[row].perId,
                        items[row].auctionStartDate,
                        items[row].parcelType
                      )
                    }
                  >
                    {items[row].serialNo}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "1px solid lightgray",
                      minWidth: "200px",
                    }}
                  >
                    <ProductDescription
                      truncateLength={65}
                      data={items[row]}
                      isTendor={true}
                    />
                  </TableCell>
                  <TableCell
                    style={{
                      border: "1px solid lightgray",
                    }}
                  >
                    {convertedValue(
                      items[row].currency,
                      items[row].caratMinimumPrice,
                      items[row].exchangeRates[0].rate
                    )}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "1px solid lightgray",
                    }}
                  >
                    {items[row].parcelType !== "Jewellery" ? (
                      <>
                        {rapPriceMapping.perIdMap[items[row].perId]
                          ? rapPriceMapping.rapnetPercentageMap[
                          items[row].perId
                          ]
                          : items[row].rapnetPercentage}
                      </>
                    ) : (
                      <>-</>
                    )}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "1px solid lightgray",
                    }}
                  >
                    {items[row].preBidPrice === 0 ||
                      items[row].preBidPrice === null ||
                      items[row].preBidPrice === undefined ? (
                      <>
                        {convertedValue(
                          items[row].currency,
                          parseInt(bidPriceInputData[items[row].perId], 10)
                            ? items[row].caratMinimumPrice +
                            parseInt(
                              bidPriceInputData[items[row].perId],
                              10
                            )
                            : items[row].caratMinimumPrice,
                          items[row].exchangeRates[0].rate
                        )}
                      </>
                    ) : (
                      <>
                        {convertedValue(
                          items[row].currency,
                          parseInt(bidPriceInputData[items[row].perId], 10)
                            ? items[row].preBidPrice +
                            parseInt(
                              bidPriceInputData[items[row].perId],
                              10
                            )
                            : items[row].preBidPrice,
                          items[row].exchangeRates[0].rate
                        )}
                      </>
                    )}
                  </TableCell>
                  <TableCell>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        refreshButtonHandler(
                          items[row].caratMinimumPrice,
                          items[row].perId
                        )
                      }
                    >
                      <LoopIcon />
                    </div>
                  </TableCell>
                  <TableCell>
                    <div style={{ cursor: "pointer" }}>
                      {bidProducts[row]?.watched ? (
                        <FontAwesomeIcon
                          icon={faHeartProps}
                          style={{ color: "red", cursor: "pointer" }}
                          onClick={() => {
                            handleWatchlist(
                              bidProducts,
                              items[row].perId,
                              false
                            );
                          }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faHeartProps}
                          style={{ color: "grey", cursor: "pointer" }}
                          onClick={() => {
                            handleWatchlist(
                              bidProducts,
                              items[row].perId,
                              true
                            );
                          }}
                        />
                      )}
                    </div>
                  </TableCell>
                  <TableCell
                    style={{
                      minWidth: "150px",
                      border: "1px solid lightgray",
                    }}
                  >
                    {items[row].preBidPrice === 0 ||
                      items[row].preBidPrice === null ||
                      items[row].preBidPrice === undefined ? (
                      <StyledButtonWrapper item xs={12}>
                        {bidButtonRender(items[row], index)}
                      </StyledButtonWrapper>
                    ) : (
                      <StyledButtonWrapper item xs={12}>
                        {checkAuctionState() ? (
                          <StyledButton
                            className="notDisablebutton"
                            id={`${index}`}
                            onClick={() => {
                              cancelPrebidHandler(items[row]);
                            }}
                          >
                            Cancel
                          </StyledButton>
                        ) : (
                          <StyledButton
                            className="disablebutton"
                            disabled={true}
                            style={{ background: "lightgrey", color: "black" }}
                          >
                            Cancel
                          </StyledButton>
                        )}
                      </StyledButtonWrapper>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <BulkBidButtonContainer>
          <StyledButton
            onClick={bulkBidBtnValuehandle}
            style={{ float: "right" }}
          >
            {t("common:Tender:button:placeBid")}
          </StyledButton>
        </BulkBidButtonContainer>
      </StyledContainerWrapper>
    </StyledComponent>
  );
};
export default BulkWatchlistPopup;
