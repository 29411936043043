import React, { useRef } from "react";

import { makeStyles, Menu } from "@material-ui/core";

import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

import Colors from "../../../common/materials/colors";
import ButtonComp from "../../../common/elements/Button";
import {
  StyledRelativeComponent,
  StyledButton,
  StyledMenuContainer,
  StyledMenuItem,
} from "./StyledComponent";
import { useTranslation } from "react-i18next";
import {
  redirectToOtherDomain,
  setRedirectPathPostAuth,
  toggleLoginPopup,
} from "../Utils";

const faChevronDownProps = faChevronDown as IconProp;
const useStyles = makeStyles({
  paper: {
    borderTop: `4px solid ${Colors.darkBlue}`,
  },
});

const HeaderMenu = ({
  handleSellDiamonds,
}: {
  handleSellDiamonds: () => void;
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { t } = useTranslation();
  const menuSpace = useRef(null);

  const headerMenuItems = [
    { text: t("common:header:menu:jpa"), url: "zipang" },
    { text: t("common:header:menu:oa"), url: "preview" },
    { text: t("common:header:menu:365a"), url: "365products" },
    { text: t("common:header:menu:ma"), url: "auctioneer" },
  ];

  const handleClick = () => {
    setAnchorEl(menuSpace.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRedirect = (url: string) => {
    if (url.includes("zipang") || url.includes("auctioneer")) {
      return redirectToOtherDomain(url);
    }
    setRedirectPathPostAuth(url);
    toggleLoginPopup();
    handleClose();
  };

  const classes = useStyles();

  return (
    <StyledRelativeComponent>
      <StyledButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <span>{t("common:header:buttons:ea")}</span>&nbsp;
        <FontAwesomeIcon
          icon={faChevronDownProps}
          style={{ color: Colors.bidBtnBackground }}
        />
      </StyledButton>
      <StyledMenuContainer ref={menuSpace}></StyledMenuContainer>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={classes}
      >
        {headerMenuItems.map((item) => (
          <StyledMenuItem
            key={item.text}
            onClick={() => handleRedirect(item.url)}
          >
            {item.text}
          </StyledMenuItem>
        ))}
      </Menu>
      <ButtonComp onClick={handleSellDiamonds} type="contained-dark">
        {t("common:header:buttons:sellYDiamonds")}
      </ButtonComp>
    </StyledRelativeComponent>
  );
};

export default HeaderMenu;
