import React, {  useEffect, useState } from 'react';
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";

import AppLayout from '../components/layouts/AppLayout';
import IRouterComponentProps from '../models/IRouterComponentProps';
import AuthService from '../components/auth/AuthService';
import { watchlistUrl, productUrl, annualMyWatchedProducts } from '../common/config/app.endpoints';
import Alert from '../common/elements/Alert';
import ProductListAtWatchlist from '../components/products/watchlist/ProductListAtWatchlist';
import LoadingSpinner from '../common/elements/LoadingSpinner';
import ApiTimeout from './ApiTimeout';
import { oAuth } from '../routes/basic/BasicAppRouter';
import BlockUserModal from '../components/products/BlockUser/BlockUserModal';
import { StyledModal } from '../common/materials/GenericStyleComponent';
import CoreDataGridoWatchList from '../components/core-grid/grid-data/CoreDataGridoWatchList';
import { match } from 'react-router';
import JewelleryProductListAtWatchlist from '../components/products/watchlist/JewelleryProductListAtWatchlist';
import CoreDataGridOfJewelleryWatchlist from '../components/core-grid/grid-data/CoreDataGridOfJewelleryWatchlist';
import WatchlistBiddingCompnent from './watchlistComponent/WatchlistBiddingCompnent';
import DiamondProductAuctionWatchlist from '../components/365Auction/component/watchlistComponent/DiamondProductAuctionWatchlist';
import JewelleryProductAuctionWatchlist from '../components/365Auction/component/watchlistComponent/JewelleryProductAuctionWatchlist';
import { useDispatch } from 'react-redux';
import { resetCurrentApiStartCount, resetJewelleryCurrentApiStartCount, resetAnnualItemPerPage, resetAnnualCurrentApiStartCount, resetAnnualJewelleryItemPerPage, resetAnnualJewelleryCurrentApiStartCount } from '../redux/slice/commanPagination/commanPaginationSlice';
import { AppDispatch } from '../redux/store';

const StyledGrid = styled(Grid)`
    ${props => props.theme.breakpoints.between('xs', 'md')} {
        padding: 10px 10px;
        min-height: 200px;
    } 
    ${props => props.theme.breakpoints.between('md', 'lg')} {
        padding: 20px 10px;
        min-height: 400px;
    }
    ${props => props.theme.breakpoints.between('lg', 'xl')} {
        padding: 10px 10px;
        min-height: 600px;
    } 
`;

const StyledTextWrapper = styled(Grid)`
    width: 100%;
    text-align: center;
    font-size: 1.5em;
    padding: 2em;
    font-family: Calibre-Regular;
    color: ${(props) => props.theme.colors.base.grey};
`;


interface DetailParams {
  auctionId: string;
  auctionUserId: any;
  auctionDate: any;
  auctionType?: any;
}
export interface IProductViewProps extends IRouterComponentProps {
  match: match<DetailParams>;
  t?: any;
  location: any;
}

let data = {};
let startCount = 0;
let endCount = 20;
let parcelType: any = "";
let auctionTypeF: any = '';
let auctionName: any = '';
let auctionState: any = "";

let watchlistObjectdata = {};

const Watchlist = (props: IProductViewProps) => {
  const [auctionProducts, setAuctionProducts] = useState([]);
  const [auctionAnnualProducts, setAuctionAnnualProducts] = useState([]);
  const [openToast, setOpenToast] = useState(false);
  const [showAlertMessage, setShowAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [totalElements, setTotalElements] = useState("");
  const [apiTimeout, setApiTimeout] = useState(false);
  const [onUpdateRecords, setOnUpdateRecords] = useState(false);
  const [userBlock, setUserBlock] = useState(false);
  const [auctionType, setAuctionType] = useState("");
  const Auth = new AuthService();
  const { t } = props;
  let isJewellery: any = false;
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    if (!oAuth()) {
      props.history.push("/");
    } else {
      setOpenToast(false);
      setIsLoading(true);
      getData();
      localStorage.removeItem("annualitemsPerPage");
      localStorage.removeItem("filteredValues");
      localStorage.removeItem("auctionState");
    }
    
    return () => {
      setIsLoading(false);
      parcelType = "";
      localStorage.removeItem("currentStart");
      localStorage.removeItem("annualitemsPerPage");
      localStorage.removeItem("filteredValues");
      localStorage.removeItem("filteredParcelValues");
      localStorage.removeItem("filteredJewelleryValues");
      localStorage.removeItem("filteredValues");
      localStorage.removeItem("annualdropDownFilterVal");
      localStorage.removeItem("dropDownFilterVal");
    };
  }, []);

  const getData = () => {
    startCount = 0;
    localStorage.setItem("currentStart","0");
    data = {
      filters: {
        and: [
          {
            match: "exact",
            name: "auctionId",
            value: `${props.match.params.auctionId}`,
          },
        ],
      },
      page: {
        start: startCount,
        count: 20,
        sort: {
          orders: [
            {
              property: "serialNo",
              direction: "ASC",
            },
          ],
        },
      },
    };
    if (
      localStorage.getItem("currentStart") !== null &&
      localStorage.getItem("currentStart") !== "0"
    ) {
      let savedCount: any = localStorage.getItem("currentStart");
      startCount = parseInt(savedCount);
    }
    if (localStorage.getItem("itemsPerPage") !== null) {
      let savedCount: any = localStorage.getItem("itemsPerPage");
      endCount = parseInt(savedCount);
    }
    if (localStorage.getItem("currentPdPage") !== null) {
      let pageCount: any = localStorage.getItem("currentPdPage");
      let start = (parseInt(pageCount) - 1) * endCount;
      startCount = parseInt(start.toString());
      localStorage.removeItem("currentPdPage");
    }
    if (props.match.params.auctionType === "Annual") {
      callAnnualWatchedApi(data);
    } else {
      callApi(data);
    }   
  };

  const itemPerPage = (value: boolean, start: number, itemPerPage: number) => {
    if (value === true) {
      getUpdatedProducts(value, start, itemPerPage);
      startCount = start;
      endCount = itemPerPage;
     
      localStorage.setItem("itemsPerPage", JSON.stringify(itemPerPage));
    }
  };

  const getUpdatedProducts = (value: any, start: any, endRecords: any) => {
    startCount = start;
    endCount = endRecords;
    localStorage.setItem("currentStart", JSON.stringify(startCount));
    if (value === true) {
      data = {
        filters: {
          and: [
            {
              match: "exact",
              name: "auctionId",
              value: `${props.match.params.auctionId}`,
            },
          ],
        },
        page: {
          start: startCount,
          count: endCount,
          sort: {
            orders: [
              {
                property: "serialNo",
                direction: "ASC",
              },
            ],
          },
        },
      };
       if (props.match.params.auctionType === "Annual"){
         callAnnualWatchedApi(data);
       }else{
         callApi(data);
       }   
    }
  };

  const callApi = (data: any) => {
    setOnUpdateRecords(true);
    setOpenToast(false);
    if (!oAuth()) {
      props.history.push("/");
    } else {
      let fullWatchListUrl = `${watchlistUrl}`;
      Auth.postRequest(
        fullWatchListUrl,
        data,
        (status: number, response: any) => {
          if (status === 200) {
            if (
              startCount !== 0 &&
              response.results.length === 0
            ) {
              getData();
              setIsLoading(false);
              setOnUpdateRecords(false);
              setAuctionProducts(response.results);
            }  else {
              let localArray = response.results;
              setIsLoading(false);
              setOnUpdateRecords(false);
              setTotalElements(response.totalElements);
              setAuctionProducts(localArray);
              response.results
                .slice(0, 1)
                .map((product: any, index: number) => {
                  parcelType = product.parcelType;
                  auctionState = product.auctionState;
                  setAuctionType(product.auctionType);
                  watchlistObjectdata = {
                    auctionId: product.auctionId,
                    auctionName: product.auctionName,
                    auctionUserId: props.match.params.auctionUserId,
                    auctionDate: product.startDate,
                    auctionState: product.auctionState,
                  };
                });
            }
          } else if (status === 403) {
            setIsLoading(false);
            setUserBlock(true);
          } else if (status === 404) {
            setIsLoading(false);
            setOnUpdateRecords(false);
            setAlertType("error");
            setOpenToast(true);
            setShowAlertMessage(`${t("common:invalidRequest")}`);
          } else if (status === 505) {
            setApiTimeout(true);
            setIsLoading(false);
            setOnUpdateRecords(false);
          } else {
            setIsLoading(false);
            setOnUpdateRecords(false);
          }
        }
      );
    }
  };

  const callAnnualWatchedApi = (data: any) => {
    setOnUpdateRecords(true);
    setOpenToast(false);
    if (!oAuth()) {
      props.history.push("/");
    } else {
      let fullWatchListUrl = `${annualMyWatchedProducts}/${props.match.params.auctionId}/watchlist`;
      Auth.postRequest(
        fullWatchListUrl,
        data,
        (status: number, response: any) => {
          if (status === 200) {
            if (
              startCount !== 0 &&
              response.results.length === 0 &&
              response.results.length !== "" &&
              response.results.length === null
            ) {
              getData();
            } else if (response.results.length === 0) {
              setIsLoading(false);
              setOnUpdateRecords(false);
              setAuctionProducts(response.results);
            } else {
              let localArray: any = response.results;
              setIsLoading(false);
              setOnUpdateRecords(false);
              setTotalElements(response.totalElements);
              setAuctionProducts(localArray);
              setAuctionAnnualProducts(localArray)
              response.results.slice(0, 1).map((product: any) => {
                parcelType = product.parcelType;
                auctionState = product?.auctionState;
                setAuctionType(product?.auctionType);
              });
            }
          } else if (status === 403) {
            setIsLoading(false);
            setUserBlock(true);
          } else if (status === 404) {
            setIsLoading(false);
            setOnUpdateRecords(false);
            setAlertType("error");
            setOpenToast(true);
            setShowAlertMessage(`${t("common:invalidRequest")}`);
          } else if (status === 505) {
            setApiTimeout(true);
            setIsLoading(false);
            setOnUpdateRecords(false);
          } else {
            setIsLoading(false);
            setOnUpdateRecords(false);
          }
        }
      );
    }
  };

  const removeFromWatchlist = (productId: string | number, auctionId: any) => {
    setOpenToast(false);
    if (!oAuth()) {
      props.history.push("/");
    } else {
      Auth.postRequest(
        `${productUrl}/${auctionId}/${productId}/unwatch`,
        "data",
        (status: any, response: any) => {
          if (status === 200) {          
            callApi(data);
            setShowAlertMessage(response.data.message);
            setAlertType("success");
            setOpenToast(true);
          } else if (status === 403) {
            setUserBlock(true);
          } else if (status === 404) {
            setShowAlertMessage(`${t("common:invalidRequest")}`);
            setAlertType("error");
            setOpenToast(true);
          } else if (status === 505) {
            setApiTimeout(true);
            setIsLoading(false);
            setOnUpdateRecords(false);
          } else {
            setShowAlertMessage(response[0].message);
            setAlertType("error");
            setOpenToast(true);
          }
        }
      );
    }
  };

  const closeModal = () => {
    Auth.logout();
    props.history.replace("/");
  };

  const handleBackBtn = () => {
    localStorage.removeItem("selectedFilterSort");
    auctionProducts.slice(0, 1).map((product: any, index: number) => {
      auctionTypeF = product?.auctionType;
      auctionName = product?.auctionName;
    });
    if (props.match.params.auctionType === "Annual") {
      parcelType === "Jewellery" ? props.history.push(`/365products/jewellery`) : props.history.push(`/365products`);
    } else {
      if (parcelType === "Jewellery") {
        if (auctionTypeF === "Popcorn_Bidding") {
          props.history.push(
            `/preview/tendor/jewellery/products/${props.match.params.auctionId}/${props.match.params.auctionUserId}/${auctionName}/${props.match.params.auctionDate}?auctioneerid=${props.match.params.auctionUserId}`
          );
        } else {
          props.history.push(
            `/preview/jewelleyProducts/${props.match.params.auctionId}/${auctionName}/${props.match.params.auctionDate}?auctioneerid=${props.match.params.auctionUserId}`
          );
        }
      } else {
        if (auctionTypeF === "Popcorn_Bidding") {
          props.history.push(
            `/preview/tendor/products/${props.match.params.auctionId}/${props.match.params.auctionUserId}/${auctionName}/${props.match.params.auctionDate}?auctioneerid=${props.match.params.auctionUserId}`
          );
        } else {
          props.history.push(
            `/preview/products/${props.match.params.auctionId}/${auctionName}/${props.match.params.auctionDate}?auctioneerid=${props.match.params.auctionUserId}`
          );
        }
      }
    }
    
    dispatch(resetCurrentApiStartCount());
    dispatch(resetJewelleryCurrentApiStartCount());
    dispatch(resetAnnualItemPerPage());
    dispatch(resetAnnualCurrentApiStartCount());
    dispatch(resetAnnualJewelleryItemPerPage());
    dispatch(resetAnnualJewelleryCurrentApiStartCount());
  };

  return (
    <AppLayout t={t} {...props}>
      {apiTimeout === true ? (
        <ApiTimeout t={t} {...props} />
      ) : (
        <StyledGrid container={true} spacing={0}>
          {isLoading === true ? (
            <div className="loader">
              <LoadingSpinner />
            </div>
          ) : (
            <Grid item={true} xs={12}>
              {parcelType === "Jewellery" ? (
                <div>
                  {auctionProducts.length > 0 ? (
                    <>
                      {auctionType !== "Popcorn_Bidding" ? (
                        <>
                          {props.match.params.auctionType === "Annual" ? (
                            <JewelleryProductAuctionWatchlist
                              {...props}
                              setShowAlertMessage={setShowAlertMessage}
                              setOpenToast={setOpenToast}
                              setAlertType={setAlertType}
                              handleBackBtns={handleBackBtn}
                              removeFromWatchlist={removeFromWatchlist}
                            />
                          ) : (
                            <CoreDataGridOfJewelleryWatchlist
                              t={t}
                              data={auctionProducts}
                              totalElements={totalElements}
                              itemPerPage={itemPerPage}
                              value={getUpdatedProducts}
                              handleBackBtns={handleBackBtn}
                            >
                              <JewelleryProductListAtWatchlist
                                {...props}
                                t={t}
                                removeFromWatchlist={removeFromWatchlist}
                              />
                            </CoreDataGridOfJewelleryWatchlist>
                          )}
                        </>
                      ) : (
                        <WatchlistBiddingCompnent
                          {...props}
                          category={parcelType}
                          data={auctionProducts}
                          totalElements={totalElements}
                          itemPerPage={itemPerPage}
                          watchlistObjectdata={watchlistObjectdata}
                          removeFromWatchlist={removeFromWatchlist}
                          handleBackBtns={handleBackBtn}
                          auctionState={auctionState}
                        />
                      )}
                    </>
                  ) : (
                    <StyledTextWrapper item={true} xs={12} lg={12} md={12}>
                      {" "}
                      {t("common:watchList:watchlistEmpty")}
                    </StyledTextWrapper>
                  )}
                </div>
              ) : (
                <div>
                  {auctionProducts.length > 0 ? (
                    <>
                      {auctionType !== "Popcorn_Bidding" ? (
                        <>
                          {props.match.params.auctionType === "Annual" ? (
                            <DiamondProductAuctionWatchlist
                              {...props}
                              setShowAlertMessage={setShowAlertMessage}
                              setOpenToast={setOpenToast}
                              setAlertType={setAlertType}
                              handleBackBtns={handleBackBtn}
                              removeFromWatchlist={removeFromWatchlist}
                            />
                          ) : (
                            <CoreDataGridoWatchList
                              t={t}
                              data={auctionProducts}
                              totalElements={totalElements}
                              itemPerPage={itemPerPage}
                              value={getUpdatedProducts}
                              handleBackBtns={handleBackBtn}
                            >
                              <ProductListAtWatchlist
                                {...props}
                                t={t}
                                removeFromWatchlist={removeFromWatchlist}
                              />
                            </CoreDataGridoWatchList>
                          )}
                        </>
                      ) : (
                        <WatchlistBiddingCompnent
                          {...props}
                          category={parcelType}
                          data={auctionProducts}
                          totalElements={totalElements}
                          itemPerPage={itemPerPage}
                          watchlistObjectdata={watchlistObjectdata}
                          removeFromWatchlist={removeFromWatchlist}
                          handleBackBtns={handleBackBtn}
                          auctionState={auctionState}
                        />
                      )}
                    </>
                  ) : (
                    <StyledTextWrapper item={true} xs={12} lg={12} md={12}>
                      {" "}
                      {t("common:watchList:watchlistEmpty")}
                    </StyledTextWrapper>
                  )}
                </div>
              )}
              {onUpdateRecords === true && (
                <div className="loading">
                  <LoadingSpinner />
                </div>
              )}
            </Grid>
          )}
          {openToast === true && (
            <Alert showAlertMessage={showAlertMessage} alertType={alertType} />
          )}
          {userBlock === true && (
            <StyledModal open={userBlock}>
              <BlockUserModal t={t} {...props} closeModal={closeModal} />
            </StyledModal>
          )}
        </StyledGrid>
      )}
    </AppLayout>
  );
}

export default Watchlist;