import React, { useState } from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import { Box, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
const StyledComponent = styled(Grid)`
  outline: none;
  .text {
    display: flex;
    text-align: center;
    align-items: center;
    font-size: 2em;
    margin-bottom: 10px;
    font-family: Calibre-Regular;
    color: ${(props) => props.theme.colors.base.grey};
  }
`;

interface Iprops {
  closeModal: () => void;
}
function PaymentAgreementModal(props: Iprops) {
  let { closeModal } = props;
  const [activeCondition, setActiveCondition] = useState(0);
  let { t } = useTranslation();
  const closeHandler = () => {
    if (activeCondition === 3) {
      closeModal();
    }
    setActiveCondition(activeCondition + 1);
  };

  const getConditionsDes = () => {
    let conditions: any;
    switch (activeCondition) {
      case 0:
        conditions = (
          <>
            <Typography
              variant="h5"
              fontWeight={600}
              style={{ fontSize: "1em", marginBottom: "10px" }}
            >
              {t("common:paymentAgreement:firstModal:paymentAgreementInEn")}
            </Typography>
            <Typography
              variant="h5"
              fontWeight={600}
              style={{
                fontSize: "1em",
                marginBottom: "10px",
                marginTop: "10px",
              }}
            >
              {t("common:paymentAgreement:firstModal:paymentAgreementInJp")}
            </Typography>
            <Typography
              variant="h5"
              fontWeight={600}
              style={{
                fontSize: "1em",
                marginBottom: "10px",
                marginTop: "10px",
              }}
            >
              {t("common:paymentAgreement:firstModal:paymentAgreementInZh")}
            </Typography>
          </>
        );
        break;
      case 1:
        conditions = (
          <>
            <Typography
              variant="h5"
              fontWeight={600}
              style={{ fontSize: "1em", marginBottom: "10px" }}
            >
              {t("common:paymentAgreement:secondModal:paymentAgreementInEn")}
            </Typography>
            <Typography
              variant="h5"
              fontWeight={600}
              style={{
                fontSize: "1em",
                marginBottom: "10px",
                marginTop: "10px",
              }}
            >
              {t("common:paymentAgreement:secondModal:paymentAgreementInJp")}
            </Typography>
            <Typography
              variant="h5"
              fontWeight={600}
              style={{
                fontSize: "1em",
                marginBottom: "10px",
                marginTop: "10px",
              }}
            >
              {t("common:paymentAgreement:secondModal:paymentAgreementInZh")}
            </Typography>
          </>
        );
        break;

      case 2:
        conditions = (
          <>
            <Typography
              variant="h5"
              fontWeight={600}
              style={{ fontSize: "1em", marginBottom: "10px" }}
            >
              {t("common:paymentAgreement:thirdModal:paymentAgreementInEn")}
            </Typography>
            <Typography
              variant="h5"
              fontWeight={600}
              style={{
                fontSize: "1em",
                marginBottom: "10px",
                marginTop: "10px",
              }}
            >
              {t("common:paymentAgreement:thirdModal:paymentAgreementInJp")}
            </Typography>
            <Typography
              variant="h5"
              fontWeight={600}
              style={{
                fontSize: "1em",
                marginBottom: "10px",
                marginTop: "10px",
              }}
            >
              {t("common:paymentAgreement:thirdModal:paymentAgreementInZh")}
            </Typography>
          </>
        );
        break;
      case 3:
        conditions = (
          <>
            <Typography
              variant="h5"
              fontWeight={600}
              style={{ fontSize: "1em", marginBottom: "10px" }}
            >
              {t("common:paymentAgreement:fourModal:paymentAgreementInEn")}
            </Typography>
            <Typography
              variant="h5"
              fontWeight={600}
              style={{
                fontSize: "1em",
                marginBottom: "10px",
                marginTop: "10px",
              }}
            >
              {t("common:paymentAgreement:fourModal:paymentAgreementInJp")}
            </Typography>
            <Typography
              variant="h5"
              fontWeight={600}
              style={{
                fontSize: "1em",
                marginBottom: "10px",
                marginTop: "10px",
              }}
            >
              {t("common:paymentAgreement:fourModal:paymentAgreementInZh")}
            </Typography>
          </>
        );
        break;

      default:
        break;
    }
    return conditions;
  };
  return (
    <StyledComponent>
      <Box
        sx={{
          width: "350px",
          background: "white",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          justifyContent: "center",
          textAlign: "center",
          padding: "14px",
        }}
      >
        {getConditionsDes()}
        <Button
          variant="contained"
          sx={{ width: "30%", mx: "auto", background: "#014a99" }}
          onClick={closeHandler}
        >
          ACCEPT
        </Button>
      </Box>
    </StyledComponent>
  );
}

export default PaymentAgreementModal;
