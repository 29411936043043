import { clockNotInSync } from "../LandingPage/Services";

const redirectPathKey = "hp-redirect-path";

export const getImageSource = (
  medias: Array<{ mediaType: string; presignedUrl: string }>
) => medias.find((media) => media.mediaType === "PHOTO");

export const toggleLoginPopup = () => {
  document.getElementById("sign-in")?.click();
};

export const redirectToOtherDomain = (subDomain: string) => {
  const host = window.location.hostname;
  let localization;
  const language = localStorage.getItem("language");
  if (language === "EN") {
    localization = "en";
  } if (language === "zh_CN") {
    localization = "zh_CN";
  } else {
    localization = "jp";
  }
  if (host.includes("myauctions.jp")) {
    window.location.href = `https://${subDomain}.myauctions.jp/${localization}`;
  } else {
    if (subDomain === "auctioneer") {
      return (window.location.href = `https://myauctionstech.in/admin/home`);
    }
    window.location.href = `https://${subDomain}.myauctionstech.in/${localization}`;
  }
};

export const demoProducts = () => {
  return [
    {
      redacted: true,
      auctionName: "",
      auctionType: "Live",
      parcelType: "Diamond",
      exchangeRates: [],
      carat: "",
      color: "",
      clarity: "",
      cutGrade: "",
      flour: "",
      polish: "",
      symmetryAbbreviation: "",
      shape: "",
      code: "",
      medias: [
        {
          mediaType: "PHOTO",
          presignedUrl: "",
        },
      ],
      buttonText: "",
      caratMinimumPrice: 0,
      startingPrice: 0,
      rapnetPercentage: 0,
      time: "",
      currentBidPrice: "",
      currency: {
        code: "",
        symbol: "",
      },
    },   
  ];
};

export const postJoinAuction = (status: any, response: any, history: any) => {
  let clockSynced = localStorage.getItem("clockNotSynced");
  if (clockSynced === "true") {
    let time: any = localStorage.getItem("timeInMilliSeconds");
    time = parseInt(time);
    clockNotInSync(time);
  }
  if (status === 200) {
    if (response !== undefined) {
      let pathname = getRedirectPath() || localStorage.getItem("pathname");
      localStorage.setItem("otpAuth", "false");
      const changePlanUrlFromUrl = localStorage.getItem("changePlanUrlFromUrl");
      if (changePlanUrlFromUrl === "isChangePlan") {
        return;
      }
      const urlRedirectFromEmail = localStorage.getItem("urlRedirectFromEmail");
      const urlRedirectFromAdmin = localStorage.getItem("urlRedirectFromAdmin");
      const urlPendingProductFromEmail = localStorage.getItem(
        "urlPendingProductFromEmail"
      );

      let redirectPath = pathname;
      const urlProductAuctionFromEmail = localStorage.getItem(
        "urlProductAuctionFromEmail"
      );
      if (pathname === "/365products" || pathname === "365products") {
        removeRedirectPath();
        return history.push("365products");
      } else if (pathname === "/preview" || pathname === "preview") {
        removeRedirectPath();
        redirectPath = "preview";
        return history.push("preview");
      } else if (pathname === "/home" || pathname === "home") {
        removeRedirectPath();
        redirectPath = "home";
        return history.push("preview");
      } else if (pathname === "/365products/jewellery") {
        redirectPath = "/365products/jewellery";
        localStorage.setItem("pathname", redirectPath);
      } else if (pathname === "/365products/Diamonds") {
        redirectPath = "/365products";
        localStorage.setItem("pathname", redirectPath);
      } else if (pathname === "/365products/Diamond") {
        redirectPath = "/365products";
        localStorage.setItem("pathname", redirectPath);
      }
      if (
        urlRedirectFromEmail !== undefined &&
        urlRedirectFromEmail !== "" &&
        urlRedirectFromEmail !== null
      ) {
        redirectPath = urlRedirectFromEmail;
      }
      if (
        urlRedirectFromAdmin !== undefined &&
        urlRedirectFromAdmin !== "" &&
        urlRedirectFromAdmin !== null
      ) {
        redirectPath = urlRedirectFromAdmin;
      }
      if (
        urlPendingProductFromEmail !== undefined &&
        urlPendingProductFromEmail !== "" &&
        urlPendingProductFromEmail !== null
      ) {
        redirectPath = urlPendingProductFromEmail;
      }
      if (
        urlProductAuctionFromEmail !== undefined &&
        urlProductAuctionFromEmail !== "" &&
        urlProductAuctionFromEmail !== null
      ) {
        redirectPath = urlProductAuctionFromEmail;
      }
      if (redirectPath) {
        return history.push(redirectPath);
      }
      // else {
      //   if (pathname) {
      //     const pathArray = pathname.split("/");
      //     if (
      //       pathArray[1].includes("KycForm") ||
      //       pathArray[1].includes("PaymentForm") ||
      //       pathArray[1].includes("paymentForm")
      //     ) {
      //       redirectPath = "/";
      //       localStorage.setItem("pathname", redirectPath);
      //     }
      //   }

      //   history.push(redirectPath);
      // }
      // this.props.history.push("/home");
      else {
        history.push("/home");
      }
    }
  } else {
    history.push("/home");
  }
  localStorage.removeItem("optionValue");
};

export const setRedirectPathPostAuth = (path: string) => {
  localStorage.setItem(redirectPathKey, path);
};

export const getRedirectPath = () => {
  return localStorage.getItem(redirectPathKey);
};

export const removeRedirectPath = () => {
  localStorage.removeItem(redirectPathKey);
};
