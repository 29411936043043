import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import IRouterComponentProps from "../../../../models/IRouterComponentProps";
import {
  StyledComponent,
  StyledGrid,
  StyledNotAvailableImage,
  StyledProductImage,
  StyledButtonWrapper,
  StyledCategoryImage,
  StyledHeading,
  StyledItemContainer,
  StyledValue,
  StyledCloseicon,
  StyledArrowicon,
  StyledArrowContainer,
  StyledBidWra,
  Styledbidcontainer,
  StyledDescriptionContainer,
  StyledPlaceHolderWrapper,
} from "./tenderSliderCss";
import Countdown from "react-countdown";
import {
  ProductCountContainer,
  ProductDescriptionContainer,
  ProductNameContainer,
  StyledCardContainer,
  StyledProductImageContainer,
  StyledWatchListContainer,
} from "./TenderAuctionStyle";
import LoadingSpinner from "../../../../common/elements/LoadingSpinner";
import {
  StyledButton,
  StyledModal,
} from "../../../../common/materials/GenericStyleComponent";
import BidConfirmationpopup from "../BidConfirmationpopup";
import styled, { css } from "styled-components";
import { backgroundGradient } from "../../../../common/materials/LinearGradient";
import copy from "copy-to-clipboard";
import Alert from "../../../../common/elements/Alert";
import { convertedValue } from "../TenderGenericFunc";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import ProductDescription from "../../../shared/ProductDescription";
import JwlTenderSliderCarousel from "../../details/JwlTenderSliderCarousel";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const faHeartProps = faHeart as IconProp;

const StyledAuctionbidWrapper = styled(Grid)`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  .notDisablebutton {
    background: ${backgroundGradient};
    cursor: pointer;
  }
  .disableButton {
    cursor: default;
    background: ${(props) => props.theme.colors.base.lightGrey};
  }

  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    padding: 10px 10px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    padding: 10px 20px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    padding: 10px 20px;
  }
`;
const StyledTitleWrapper = styled(Grid)`
  // padding: 0.5em;
  display: flex;
  align-items: center;
  font-size: 0.7em;
  .height {
    height: 100%;
  }
`;

interface IChangeColorProps {
  changeColor?: String;
}

export const StyledCurrentBid = styled(Grid)<IChangeColorProps>`
  background: ${(props) => props.theme.colors.base.white};
  box-shadow: 2px 0px 5px 2px #dcdcdc;
  border-radius: 20px;
  padding-top: 10px;
  margin-top: 10px;
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    font-size: 0.8em;
    padding-top: 5px;
    margin-top: 5px;
  }
  ${(props) =>
    props.changeColor === "white" &&
    css`
      background: white;
    `}
  ${(props) =>
    props.changeColor === "#FFEB9C" &&
    css`
      background: #ffeb9c;
    `}

    ${(props) =>
    props.changeColor === "#FBE4D5" &&
    css`
      background: #fbe4d5;
    `}
`;

const StyledContainernew = styled.div`
  display: flex;
  justify-content: center;
  height: 600px;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (width: 320px) {
    height: 530px;
    overflow: auto;
  }
  @media (width: 280px) {
    height: 560px;
    overflow: auto;
  }
  @media (width: 1280px) {
    height: 660px;
    overflow: auto;
  }
  @media (width: 1366px) {
    height: 690px;
    overflow: auto;
    ::-webkit-scrollbar {
      display: none;
    }
  }
  @media (min-width: 1366px) {
    height: 700px;
    overflow: auto;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;


interface IProps extends IRouterComponentProps {
  t: any;
  items?: any;
  auctionDate: any;
  PopupClose: any;
  timeRender: any;
  sec: any;
  handleBtnValue: (values: any, currentBidPrice: any, productId: any, mybidValues: any) => void;
  isFirstButtonDisables: boolean;
  currentIndex: any;
  totalElements: any;
  endCount: any;
  auctionUserId: any;
  length: any;
  watchedFunction: (productId: any, auctionId: any, serialNo: any) => void;
  unwatched: (productId: any, auctionId: any) => void;
  boxColor?: (items: any) => string;
}

let totalElements: string | number = 0;
let getUpdatedBidValue: any;
let finalProductbid: any;
let rowSelectedData: any;
let productPricePerCaratUSD: any = null;
let rapnetPercentage: any = null;
let getTendorSecondHighestBidValue: any;
const JwlTenderSlider = (props: IProps) => {
  let {
    t,
    items,
    PopupClose,
    timeRender,
    sec,
    handleBtnValue,
    currentIndex,
    endCount,
    auctionUserId,
    length,
    watchedFunction,
    unwatched,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [apiTimeout, setApiTimeout] = useState(false);
  const [showbidConfirmationPopup, setShowbidConfirmationPopup] =
    useState(false);
  const [bidValue, setBidValue] = useState();
  const [alertType, setAlertType] = useState("");
  const [openToast, setOpenToast] = useState(false);
  const [showAlertMessage, setShowAlertMessage] = useState("");
  const [currentProductIndex, setCurrentProductIndex] = useState(currentIndex);
  const [bulkBidValue, setBulkBidValue] = useState(0);
  const [isFirstButtonDisable, setisFirstButtonDisable] = useState(false);
  const [buttonVal, setButtonVal] = useState({
    first: 0,
    second: 0,
    thired: 0,
  });
  const [isRefresh, setIsRefresh] = useState(false);
  
  useEffect(() => {
    handleBidValue();
    setCurrentProductIndex(currentIndex);
    getUpdatedBidValue = items[currentProductIndex].currentBidPrice;
    getTendorSecondHighestBidValue =
      items[currentProductIndex].tenderSecondHighestBid;
    setBulkBidValue(0);
    finalProductbid = 0;
    rapnetPercentage = items[currentProductIndex].rapnetPercentage;
  }, []);

  useEffect(() => {
    refreshButtonrender(items[currentProductIndex].currentBidPrice);
    setIsRefresh(true);
  }, [items[currentProductIndex]?.currentBidPrice]);
  
  useEffect(()=>{
    if(getUpdatedBidValue !==  undefined){
    refreshButtonrender(getUpdatedBidValue)
    }
  },[getUpdatedBidValue])

  const closeBidsModal = () => {
    PopupClose();
    getUpdatedBidValue = "";
    getTendorSecondHighestBidValue = "";
    rapnetPercentage = "";
    setShowbidConfirmationPopup(false);
  };

  //button handling for pervious
  const previousArrow = () => {
    rapnetPercentage = "";
    setBulkBidValue(0);
    if (currentIndex === 0 || currentIndex > 0) {
      let updatedIndex: any;
      if (currentProductIndex === 0) {
        setCurrentProductIndex(0);
        updatedIndex = setCurrentProductIndex(0);
        getUpdatedBidValue = items[0].currentBidPrice;
        getTendorSecondHighestBidValue = items[0].tenderSecondHighestBid;
        rapnetPercentage = items[0].rapnetPercentage;
      } else {
        updatedIndex = currentProductIndex - 1;
        setCurrentProductIndex(updatedIndex);
        getUpdatedBidValue = items[updatedIndex].currentBidPrice;
        getTendorSecondHighestBidValue =
          items[updatedIndex].tenderSecondHighestBid;
        rapnetPercentage = items[updatedIndex].rapnetPercentage;
      }

      handleBidValue();
    }
  };

  const nextArrow = () => {
    setBulkBidValue(0);
    endCount = endCount - 1;
    rapnetPercentage = "";
    if (currentProductIndex === endCount) {
      setCurrentProductIndex(0);
      getUpdatedBidValue = items[currentProductIndex].currentBidPrice;
      getTendorSecondHighestBidValue =
        items[currentProductIndex].tenderSecondHighestBid;
      rapnetPercentage = items[currentProductIndex].rapnetPercentage;
    } else {
      if (length === 1) {
        setCurrentProductIndex(0);
      } else if (length - 1 === currentProductIndex) {
        setCurrentProductIndex(0);
        getUpdatedBidValue = items[0].currentBidPrice;
        getTendorSecondHighestBidValue = items[0].tenderSecondHighestBid;
        rapnetPercentage = items[0].rapnetPercentage;
      } else {
        var updatedIndex = currentProductIndex + 1;
        setCurrentProductIndex(updatedIndex);
        getUpdatedBidValue = items[updatedIndex].currentBidPrice;
        getTendorSecondHighestBidValue =
          items[updatedIndex].tenderSecondHighestBid;
        rapnetPercentage = items[updatedIndex].rapnetPercentage;
      }
    }
    handleBidValue();
  };

  const shareButtonAction = (parcelType: string) => {
    const { t } = props;
    let languageCode = localStorage.getItem("language") === "EN"
        ? "en"
        : localStorage.getItem("language") === "zh_CN"
        ? "zh_CN"
        : "jp";
    if (parcelType === "Jewellery") {
      copy(
        `${window.location.hostname}/${languageCode}/product/jewellery/${items[currentProductIndex].perId}/${props.auctionDate}/detail`
      );
    } else {
      copy(
        `${window.location.hostname}/${languageCode}/product/${items[currentProductIndex].perId}/${props.auctionDate}/detail`
      );
    }

    setAlertType("success");
    setOpenToast(true);
    setShowAlertMessage(`${t("common:preview:linkCopied")}`);
    setTimeout(() => {
      setOpenToast(false);
    }, 2000);
  };

  const getBidValue = (value: any) => {
    setIsRefresh(false);
    //bids update in  productBidValue variable
    var productBidValue = bulkBidValue + value;
    setBulkBidValue(productBidValue);

    getUpdatedBidValue =
      items[currentProductIndex].currentBidPrice + productBidValue;
    getTendorSecondHighestBidValue =
      items[currentProductIndex].tenderSecondHighestBid + productBidValue;
    finalProductbid = productBidValue;
    // handleBidValue();
    getRapnetPercentage(getUpdatedBidValue);
    getUpdatedBidValueFunction(getUpdatedBidValue);
    return `${getUpdatedBidValue}`;
  };
  const getUpdatedBidValueFunction = (value: any) => {
    return value;
  };

  const getRapnetPercentage = (getUpdatedBidValues: any) => {
    if (
      items[currentProductIndex].rapnetPercentage === undefined ||
      items[currentProductIndex].rapnetPercentage === 0
    ) {
      rapnetPercentage = "-";
    } else {
      if (items[currentProductIndex].currency.code === "JPY") {
        productPricePerCaratUSD =
          getUpdatedBidValues *
          items[currentProductIndex].exchangeRates[0].rate;
      } else {
        productPricePerCaratUSD = getUpdatedBidValues;
      }
      rapnetPercentage = (
        (1 - productPricePerCaratUSD / items[currentProductIndex].rapnetPrice) *
        -100.0
      ).toFixed(2);
      return rapnetPercentage;
    }
  };

  // retrun bid value
  const handleBtnVal = async (value: any, currentBidPrice: any, productId: any) => {
    const obj = {
      productId: productId,
      myLatestBid: getUpdatedBidValue,
    };
    handleBtnValue(value, currentBidPrice, productId, obj);
    setBulkBidValue(0);
    finalProductbid = 0;
    await refreshPlaceHolderBidValue();
  };

  //refresh bid price with live
  const refreshPlaceHolderBidValue = () => {
    setTimeout(() => {
      setIsRefresh(true);
      // handleBidValue();
    }, 1000);
  };

  const refreshBidbutton = () => {
    getUpdatedBidValue = items[currentProductIndex].currentBidPrice;
    getTendorSecondHighestBidValue =
      items[currentProductIndex].tenderSecondHighestBid;
    rapnetPercentage = "";
    setBulkBidValue(0);
    finalProductbid = 0;
  };

  const openProductDetail = (
    auctionId: string,
    perId: string,
    auctionDate: string,
    parcelType: string
  ) => {
    if (parcelType === "Jewellery") {
      props.history.push(
        `/preview/tender/jewellery/details/${auctionId}/${perId}/${auctionDate}?isAuction=true&uniqid=${auctionUserId}`
      );
    } else {
      props.history.push(
        `/preview/tender/details/${auctionId}/${perId}/${auctionDate}`
      );
    }
  };

  const handleBidValue = () => {
    if (
      items[currentProductIndex].parcelType === "Parcel" &&
      items[currentProductIndex].parcelType !== undefined
    ) {
      if (items[currentProductIndex].currency.code === "JPY") {
        if (items[currentProductIndex].currentBidPrice < 10000) {
          setButtonVal({
            first: 100,
            second: 500,
            thired: 1000,
          });
          setisFirstButtonDisable(false);
        } else if (
          items[currentProductIndex].currentBidPrice >= 10000 &&
          items[currentProductIndex].currentBidPrice < 100000
        ) {
          setButtonVal({
            first: 100,
            second: 500,
            thired: 1000,
          });
          setisFirstButtonDisable(true);
        } else if (items[currentProductIndex].currentBidPrice >= 100000) {
          setButtonVal({
            first: 1000,
            second: 5000,
            thired: 10000,
          });
          setisFirstButtonDisable(true);
        }
      } else {
        if (items[currentProductIndex].currentBidPrice < 100) {
          setButtonVal({
            first: 1,
            second: 5,
            thired: 10,
          });
          setisFirstButtonDisable(false);
        } else if (
          items[currentProductIndex].currentBidPrice >= 100 &&
          items[currentProductIndex].currentBidPrice < 1000
        ) {
          setButtonVal({
            first: 1,
            second: 5,
            thired: 10,
          });
          setisFirstButtonDisable(false);
        } else if (items[currentProductIndex].currentBidPrice >= 1000) {
          setButtonVal({
            first: 10,
            second: 50,
            thired: 100,
          });
          setisFirstButtonDisable(true);
        }
      }
    } else {
      if (items[currentProductIndex].currency.code === "JPY") {
        if (items[currentProductIndex].currentBidPrice < 10000) {
          setButtonVal({
            first: 100,
            second: 500,
            thired: 1000,
          });
          setisFirstButtonDisable(false);
        } else if (
          items[currentProductIndex].currentBidPrice >= 10000 &&
          items[currentProductIndex].currentBidPrice < 100000
        ) {
          setButtonVal({
            first: 1000,
            second: 5000,
            thired: 10000,
          });
          setisFirstButtonDisable(false);
        } else if (items[currentProductIndex].currentBidPrice >= 100000) {
          setButtonVal({
            first: 1000,
            second: 5000,
            thired: 10000,
          });
          setisFirstButtonDisable(true);
        }
      } else {
        if (items[currentProductIndex].currentBidPrice < 100) {
          setButtonVal({
            first: 1,
            second: 5,
            thired: 10,
          });
          setisFirstButtonDisable(false);
        } else if (
          items[currentProductIndex].currentBidPrice >= 100 &&
          items[currentProductIndex].currentBidPrice < 1000
        ) {
          setButtonVal({
            first: 10,
            second: 50,
            thired: 100,
          });
          setisFirstButtonDisable(false);
        } else if (items[currentProductIndex].currentBidPrice >= 1000) {
          setButtonVal({
            first: 10,
            second: 50,
            thired: 100,
          });
          setisFirstButtonDisable(true);
        }
      }
    }
  };
  const refreshButtonrender = (updatedBidPrice: any) => {
    if (
      items[currentProductIndex].parcelType === "Parcel" &&
      items[currentProductIndex].parcelType !== undefined
    ) {
      if (items[currentProductIndex].currency.code === "JPY") {
        if (updatedBidPrice < 10000) {
          setButtonVal({
            first: 100,
            second: 500,
            thired: 1000,
          });
          setisFirstButtonDisable(false);
        } else if (
          updatedBidPrice >= 10000 &&
          updatedBidPrice < 100000
        ) {
          setButtonVal({
            first: 100,
            second: 500,
            thired: 1000,
          });
          setisFirstButtonDisable(true);
        } else if (updatedBidPrice >= 100000) {
          setButtonVal({
            first: 1000,
            second: 5000,
            thired: 10000,
          });
          setisFirstButtonDisable(true);
        }
      } else {
        if (updatedBidPrice < 100) {
          setButtonVal({
            first: 1,
            second: 5,
            thired: 10,
          });
          setisFirstButtonDisable(false);
        } else if (
          updatedBidPrice >= 100 &&
          updatedBidPrice < 1000
        ) {
          setButtonVal({
            first: 10,
            second: 50,
            thired: 100,
          });
          setisFirstButtonDisable(false);
        } else if (updatedBidPrice >= 1000) {
          setButtonVal({
            first: 10,
            second: 50,
            thired: 100,
          });
          setisFirstButtonDisable(true);
        }
      }
    } else {
      if (items[currentProductIndex].currency.code === "JPY") {
        if (updatedBidPrice < 10000) {
          setButtonVal({
            first: 100,
            second: 500,
            thired: 1000,
          });
          setisFirstButtonDisable(false);
        } else if (
          updatedBidPrice >= 10000 &&
          updatedBidPrice < 100000
        ) {
          setButtonVal({
            first: 1000,
            second: 5000,
            thired: 10000,
          });
          setisFirstButtonDisable(false);
        } else if (updatedBidPrice >= 100000) {
          setButtonVal({
            first: 1000,
            second: 5000,
            thired: 10000,
          });
          setisFirstButtonDisable(true);
        }
      } else {
        if (updatedBidPrice < 100) {
          setButtonVal({
            first: 1,
            second: 5,
            thired: 10,
          });
          setisFirstButtonDisable(false);
        } else if (
          updatedBidPrice >= 100 &&
          updatedBidPrice < 1000
        ) {
          setButtonVal({
            first: 10,
            second: 50,
            thired: 100,
          });
          setisFirstButtonDisable(false);
        } else if (updatedBidPrice >= 1000) {
          setButtonVal({
            first: 10,
            second: 50,
            thired: 100,
          });
          setisFirstButtonDisable(true);
        }
      }
    }
  };

  const renderSerialNo = (data: any) => {
    if (data !== undefined) {
      return data.serialNo;
    } else {
      return "";
    }
  };

    const convertedPlaceHolderValue = (
      currencyCodeVal: any,
      price: any,
      exchangeRate: any
    ) => {
      //get user currency code form local storeage
      let userCurrencyCode = localStorage.getItem("preferredCurrency");
      let userCurrencySymbol = localStorage.getItem("preferredCurrencySymbol");

      if (
        currencyCodeVal === undefined ||
        currencyCodeVal === null ||
        price === undefined ||
        price === null ||
        exchangeRate === undefined ||
        exchangeRate === null
      ) {
        return `-`;
      } else {
        if (userCurrencyCode != currencyCodeVal.code) {
          let conversion = new Intl.NumberFormat('us-en').format(
            Math.round(price * exchangeRate)
          );
          let conversions = new Intl.NumberFormat('us-en').format(Math.round(price));
          return `${currencyCodeVal.symbol} ${conversions} (${userCurrencySymbol} ${conversion})`;
        } else {
          let conversions = new Intl.NumberFormat('us-en').format(Math.round(price));
          return `${userCurrencySymbol} ${conversions}`;
        }
      }
    };

    const bidButtonRender = (items: any) => {
      if (items.sellerAuctionUserId === auctionUserId) {
        return (
          <StyledAuctionbidWrapper item xs={12}>
            <Grid item xs={4}>
              <StyledButton className="disableButton">
                + {items.currency.symbol} {buttonVal.first}
              </StyledButton>
            </Grid>
            <Grid item xs={4}>
              <StyledButton className="disableButton">
                + {items.currency.symbol} {buttonVal.second}
              </StyledButton>
            </Grid>
            <Grid item xs={4}>
              <StyledButton className="disableButton">
                + {items.currency.symbol} {buttonVal.thired}
              </StyledButton>
            </Grid>
          </StyledAuctionbidWrapper>
        );
      } else if (items.holdPreviousBidder !== undefined) {
        if (auctionUserId === items.holdPreviousBidder) {
          return (
            <StyledAuctionbidWrapper item xs={12}>
              <Grid item xs={4}>
                <StyledButton className="disableButton">
                  + {items.currency.symbol} {buttonVal.first}
                </StyledButton>
              </Grid>
              <Grid item xs={4}>
                <StyledButton className="disableButton">
                  + {items.currency.symbol} {buttonVal.second}
                </StyledButton>
              </Grid>
              <Grid item xs={4}>
                <StyledButton className="disableButton">
                  + {items.currency.symbol} {buttonVal.thired}
                </StyledButton>
              </Grid>
            </StyledAuctionbidWrapper>
          );
        } else {
          return (
            <StyledAuctionbidWrapper item xs={12}>
              <Grid item xs={4}>
                {isFirstButtonDisable === true ? (
                  <StyledButton className="disableButton">
                    + {buttonVal.first}
                  </StyledButton>
                ) : (
                  <StyledButton
                    className="notDisablebutton"
                    onClick={() => getBidValue(buttonVal.first)}
                  >
                    + {items.currency.symbol} {buttonVal.first}
                  </StyledButton>
                )}
              </Grid>
              <Grid item xs={4}>
                <StyledButton
                  className="notDisablebutton"
                  onClick={() => getBidValue(buttonVal.second)}
                >
                  + {items.currency.symbol} {buttonVal.second}
                </StyledButton>
              </Grid>
              <Grid item xs={4}>
                <StyledButton
                  className="notDisablebutton"
                  onClick={() => getBidValue(buttonVal.thired)}
                >
                  + {items.currency.symbol} {buttonVal.thired}
                </StyledButton>
              </Grid>
            </StyledAuctionbidWrapper>
          );
        }
      } else if (items.previousHighestBidder !== undefined) {
        if (auctionUserId === items.previousHighestBidder) {
          return (
            <StyledAuctionbidWrapper item xs={12}>
              <Grid item xs={4}>
                <StyledButton className="disableButton">
                  + {items.currency.symbol} {buttonVal.first}
                </StyledButton>
              </Grid>
              <Grid item xs={4}>
                <StyledButton className="disableButton">
                  + {items.currency.symbol} {buttonVal.second}
                </StyledButton>
              </Grid>
              <Grid item xs={4}>
                <StyledButton className="disableButton">
                  + {items.currency.symbol} {buttonVal.thired}
                </StyledButton>
              </Grid>
            </StyledAuctionbidWrapper>
          );
        } else {
          return (
            <StyledAuctionbidWrapper item xs={12}>
              <Grid item xs={4}>
                {isFirstButtonDisable === true ? (
                  <StyledButton className="disableButton">
                    + {buttonVal.first}
                  </StyledButton>
                ) : (
                  <StyledButton
                    className="notDisablebutton"
                    onClick={() => getBidValue(buttonVal.first)}
                  >
                    + {items.currency.symbol} {buttonVal.first}
                  </StyledButton>
                )}
              </Grid>
              <Grid item xs={4}>
                <StyledButton
                  className="notDisablebutton"
                  onClick={() => getBidValue(buttonVal.second)}
                >
                  + {items.currency.symbol} {buttonVal.second}
                </StyledButton>
              </Grid>
              <Grid item xs={4}>
                <StyledButton
                  className="notDisablebutton"
                  onClick={() => getBidValue(buttonVal.thired)}
                >
                  + {items.currency.symbol} {buttonVal.thired}
                </StyledButton>
              </Grid>
            </StyledAuctionbidWrapper>
          );
        }
      } else if (items.auctionUserId === auctionUserId) {
        return (
          <StyledAuctionbidWrapper item xs={12}>
            <Grid item xs={4}>
              <StyledButton className="disableButton">
                + {items.currency.symbol} {buttonVal.first}
              </StyledButton>
            </Grid>
            <Grid item xs={4}>
              <StyledButton className="disableButton">
                + {items.currency.symbol} {buttonVal.second}
              </StyledButton>
            </Grid>
            <Grid item xs={4}>
              <StyledButton className="disableButton">
                + {items.currency.symbol} {buttonVal.thired}
              </StyledButton>
            </Grid>
          </StyledAuctionbidWrapper>
        );
      } else {
        if (items.auctionUserId === auctionUserId) {
          return (
            <StyledAuctionbidWrapper item xs={12}>
              <Grid item xs={4}>
                <StyledButton className="disableButton">
                  + {items.currency.symbol} {buttonVal.first}
                </StyledButton>
              </Grid>
              <Grid item xs={4}>
                <StyledButton className="disableButton">
                  + {items.currency.symbol} {buttonVal.second}
                </StyledButton>
              </Grid>
              <Grid item xs={4}>
                <StyledButton className="disableButton">
                  + {items.currency.symbol} {buttonVal.thired}
                </StyledButton>
              </Grid>
            </StyledAuctionbidWrapper>
          );
        } else {
          return (
            <StyledAuctionbidWrapper item xs={12}>
              <Grid item xs={4}>
                {isFirstButtonDisable === true ? (
                  <StyledButton className="disableButton">
                    + {buttonVal.first}
                  </StyledButton>
                ) : (
                  <StyledButton
                    className="notDisablebutton"
                    onClick={() => getBidValue(buttonVal.first)}
                  >
                    + {items.currency.symbol} {buttonVal.first}
                  </StyledButton>
                )}
              </Grid>
              <Grid item xs={4}>
                <StyledButton
                  className="notDisablebutton"
                  onClick={() => getBidValue(buttonVal.second)}
                >
                  + {items.currency.symbol} {buttonVal.second}
                </StyledButton>
              </Grid>
              <Grid item xs={4}>
                <StyledButton
                  className="notDisablebutton"
                  onClick={() => getBidValue(buttonVal.thired)}
                >
                  + {items.currency.symbol} {buttonVal.thired}
                </StyledButton>
              </Grid>
            </StyledAuctionbidWrapper>
          );
        }
      }
    };
  return (
    <StyledContainernew>
      <StyledComponent>
        <div
          style={{
            background: "white",
            flexDirection: "row",
            display: "flex",
            height: "37px",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: "1px solid lightgray",
          }}
        >
          <ProductCountContainer style={{ color: "#1e3c63" }}>
            {t("common:Tender:button:placeBid")}
          </ProductCountContainer>

          <ProductNameContainer
            className="fontBold"
            style={{
              color: "#1e3c63",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() =>
              openProductDetail(
                items[currentProductIndex].auctionId,
                items[currentProductIndex].perId,
                items[currentProductIndex].auctionStartDate,
                items[currentProductIndex].parcelType
              )
            }
          >
            {renderSerialNo(items[currentProductIndex])}
          </ProductNameContainer>

          <ProductNameContainer className="fontBold">
            {sec > 0 && (
              <Countdown
                date={Date.now() + sec}
                className="countdownText"
                renderer={timeRender}
                zeroPadTime={2}
              ></Countdown>
            )}
          </ProductNameContainer>
          <ProductNameContainer className="fontBold">
            <StyledCloseicon
              src="/images/close-button2.ico"
              onClick={closeBidsModal}
            ></StyledCloseicon>
          </ProductNameContainer>
        </div>
        <StyledGrid container={true}>
          <StyledArrowContainer>
            <StyledArrowicon
              src="/images/tenderauction/arrowLeft.png"
              onClick={previousArrow}
            ></StyledArrowicon>
          </StyledArrowContainer>
          <Grid
            item={true}
            xs={10}
            md={10}
            lg={10}
            xl={10}
            style={{ background: "white" }}
          >
            {isLoading === true ? (
              <div className="loader">
                <LoadingSpinner />
              </div>
            ) : (
              <div>
                <div>
                  <StyledCardContainer item={true} xs={12}>
                    <div
                      style={{
                        background: "white",
                        flexDirection: "row",
                        display: "flex",
                        height: "50px",
                        alignItems: "center",
                        justifyContent: "space-between",
                        boxShadow: "2px 0px 5px 2px #dcdcdc",
                      }}
                    >
                      <StyledWatchListContainer>
                        {items[currentProductIndex].watched === true ? (
                          <FontAwesomeIcon
                            icon={faHeartProps}
                            style={{ color: "red", cursor: "pointer" }}
                            onClick={() =>
                              unwatched(
                                items[currentProductIndex].perId,
                                items[currentProductIndex].auctionId
                              )
                            }
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faHeartProps}
                            style={{ color: "gray", cursor: "pointer" }}
                            onClick={() =>
                              watchedFunction(
                                items[currentProductIndex].perId,
                                items[currentProductIndex].auctionId,
                                items[currentProductIndex].serialNo
                              )
                            }
                          />
                        )}
                      </StyledWatchListContainer>
                      <ProductDescriptionContainer className="fontBold">
                        <StyledTitleWrapper item={true} xs={12}>
                          {items[currentProductIndex].parcelType ===
                          "Jewellery" ? (
                            <ProductDescription
                              truncateLength={50}
                              data={items[currentProductIndex]}
                              isTendor={true}
                            />
                          ) : (
                            <ProductDescription
                              truncateLength={80}
                              data={items[currentProductIndex]}
                              isTendor={true}
                            />
                          )}
                        </StyledTitleWrapper>
                      </ProductDescriptionContainer>
                      <StyledArrowicon
                        src="/images/tenderauction/ishare.png"
                        onClick={() =>
                          shareButtonAction(
                            items[currentProductIndex].parcelType
                          )
                        }
                      />
                    </div>
                    <Grid container={true} spacing={0}>
                      <StyledProductImageContainer
                        item={true}
                        xs={12}
                        md={12}
                        lg={12}
                      >
                        {items[currentProductIndex].medias !== undefined &&
                        items[currentProductIndex].medias.length > 0 ? (
                          <JwlTenderSliderCarousel
                            medias={items[currentProductIndex].medias}
                            parcelType={items[currentProductIndex].parcelType}
                            t={t}
                          />
                        ) : (
                          <StyledGrid container={true} spacing={0}>
                            <StyledNotAvailableImage>
                              <StyledProductImage src="/images/default-diamond.png"></StyledProductImage>
                            </StyledNotAvailableImage>
                          </StyledGrid>
                        )}
                      </StyledProductImageContainer>
                    </Grid>

                    <StyledDescriptionContainer>
                      <Grid
                        container={true}
                        spacing={0}
                        className="centerContent"
                      >
                        <StyledCurrentBid
                          changeColor={
                            props.boxColor
                              ? props.boxColor(items[currentProductIndex])
                              : items[currentProductIndex].rowColor
                          }
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                        >
                          <StyledItemContainer item={true} xs={12}>
                            <StyledHeading item={true} xs={6}>
                              {t(
                                "common:Tender:tableColumnNames:liveHighestBid"
                              )}
                            </StyledHeading>
                            <StyledValue item={true} xs={6}>
                              {convertedValue(
                                items[currentProductIndex].currency,
                                items[currentProductIndex].currentBidPrice,
                                items[currentProductIndex].exchangeRates[0].rate
                              )}
                            </StyledValue>
                          </StyledItemContainer>
                          <StyledItemContainer item={true} xs={12}>
                            {items[currentProductIndex].parcelType ===
                            "jewellery" ? (
                              <StyledHeading item={true} xs={6}>
                                {t(
                                  "common:Tender:tableColumnNames:jewelleryPrice"
                                )}
                              </StyledHeading>
                            ) : (
                              <StyledHeading item={true} xs={6}>
                                {t(
                                  "common:Tender:tableColumnNames:jewelleryPrice"
                                )}
                              </StyledHeading>
                            )}
                            <StyledValue item={true} xs={6}>
                              {convertedValue(
                                items[currentProductIndex].currency,
                                items[currentProductIndex].caratMinimumPrice,
                                items[currentProductIndex].exchangeRates[0].rate
                              )}
                            </StyledValue>
                          </StyledItemContainer>
                        </StyledCurrentBid>
                        <StyledPlaceHolderWrapper item xs={12}>
                          <Grid item>
                            <StyledCategoryImage src="/images/bidIcon.png" />
                          </Grid>
                          <Styledbidcontainer item xs={6} sm={6} md={6} xl={6}>
                            {isRefresh ? (
                              <StyledBidWra>
                                {convertedValue(
                                  items[currentProductIndex].currency,
                                  items[currentProductIndex].currentBidPrice,
                                  items[currentProductIndex].exchangeRates[0]
                                    .rate
                                )}
                              </StyledBidWra>
                            ) : (
                              <StyledBidWra>
                                {convertedPlaceHolderValue(
                                  items[currentProductIndex].currency,
                                  getUpdatedBidValue,
                                  items[currentProductIndex].exchangeRates[0]
                                    .rate
                                )}
                              </StyledBidWra>
                            )}
                          </Styledbidcontainer>
                          <Grid item>
                            {finalProductbid >= 1 ? (
                              <StyledButton
                                onClick={() =>
                                  handleBtnVal(
                                    finalProductbid,
                                    items[currentProductIndex].currentBidPrice,
                                    items[currentProductIndex].perId
                                  )
                                }
                                style={{
                                  color: "white",
                                }}
                              >
                                {t("common:Tender:button:placeBid")}
                              </StyledButton>
                            ) : (
                              <StyledButton
                                disabled
                                style={{
                                  background: "lightgray",
                                  color: "white",
                                }}
                              >
                                {t("common:Tender:button:placeBid")}
                              </StyledButton>
                            )}
                          </Grid>
                          <Grid item>
                            <StyledCategoryImage
                              onClick={refreshBidbutton}
                              src="/images/tenderauction/refresh.png"
                            />
                          </Grid>
                        </StyledPlaceHolderWrapper>

                        <StyledButtonWrapper item xs={12}>
                          {bidButtonRender(items[currentProductIndex])}
                        </StyledButtonWrapper>
                      </Grid>
                    </StyledDescriptionContainer>
                  </StyledCardContainer>
                </div>
              </div>
            )}
          </Grid>
          <StyledArrowContainer>
            <StyledArrowicon
              src="/images/tenderauction/arrowRight.png"
              onClick={nextArrow}
            />
          </StyledArrowContainer>
        </StyledGrid>
        {showbidConfirmationPopup === true && (
          <StyledModal open={showbidConfirmationPopup}>
            <BidConfirmationpopup
              {...t}
              {...props}
              bidval={bidValue}
              closeModal={closeBidsModal}
            />
          </StyledModal>
        )}
        {openToast === true && (
          <Alert showAlertMessage={showAlertMessage} alertType={alertType} />
        )}
      </StyledComponent>
    </StyledContainernew>
  );
};
export default JwlTenderSlider;