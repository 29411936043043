import { annualBuybid } from "../../../common/config/app.endpoints";
import AuthService from "../../auth/AuthService";

const Auth = new AuthService();

export const postbuyitNowData = async (data: any) => {
  return (await new Promise((res) => {
    const callback = (status: number, data: any) => {
      res({ response: data.data, status });
    };
    Auth.postRequest(annualBuybid, data, callback);
  })) as unknown as Promise<{
    response: any;
    status: number;
  }>;
};
