import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import { productUrl } from "../../../../common/config/app.endpoints";
import LoadingSpinner from "../../../../common/elements/LoadingSpinner";
import { sideMenuGradient } from "../../../../common/materials/LinearGradient";
import ApiTimeout from "../../../../modules/ApiTimeout";
import AuthService from "../../../auth/AuthService";
import ProductDescription from "../../../shared/ProductDescription";
import { useTranslation } from "react-i18next";
import {
  StyledBidContainer,
  StyledButton,
  StyledLabel,
  StyledTooltipContainer,
  StyledTooltipText,
} from "../../../365Auction/component/detailsPage/DetailsPageCss";
import DetailsNotes from "../../../products/details/DetailsNotes";
import PerItemDescription from "../../../products/details/PerItemDescription";
import AddorRemoveWatchlist from "../../../products/preview/AddorRemoveWatchlist";

import copy from "copy-to-clipboard";
import Alert from "../../../../common/elements/Alert";
import NewPreBiddingForm from "../../../../pages/preview/components/details/NewPreBiddingForm";
import { getNumberFormat } from "../../../../services/NumberFormatService";
import ItemDescription from "../../../../pages/preview/components/details/ItemDescription";
import { getRapnetPercentage } from "../../../../common/GenericFunctions";
import ProductCarouselNew from "../../../products/newProductDetails/container/ProductCarouselNew";
import { Box } from "@material-ui/core";
import JewelleryDetails from "../../../../pages/preview/components/details/JewelleryDetails";
import SubNavigationControls from "../../../../pages/preview/components/details/SubNavigationControls";
import ProductDetails from "../../../../pages/preview/components/details/ProductDetails";
import { getModifiedProductDetailsResponse, isSubItemsAvailable } from "../../../../utils/preview/DetailsPageUtils";
import { StyledNoImgGrid } from "../../../products/details/DetailsCarousel";
import { StyledNotAvailableImage, StyledProductImage } from "../../../../pages/preview/components/details/ProductCarouselStyles";
import { ParcelType } from "../../../../enums/productEnum";
import { StyledGrid } from "../../../../pages/preview/components/details/PreBiddingFormStyles";

let StyledPrebidingContainer = styled(Box)`
  color: #2660ad;
  border: 4px solid #2660ad;
  background: #bcd2e8;
  padding: 10px;
  margin: 10px;
`;

const StyledLayoutGrid = styled(Grid)`
  flexgrow: 1;
  width: 100% !important;
  display: flex-wrap;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  background: ${(props) => props.theme.colors.base.white};
`;

const StyledTitleContainer = styled(Grid)`
  ${(props) => props.theme.breakpoints.down("xs")} {
    padding: 15px 10px 10px;
  }
`;

const StyledBrandName = styled.div`
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  font-size: 1.5em;
  font-weight: bold;
`;

const StyledProductCarousalColumn = styled(Grid)`
  padding-right: 20px;
  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: 0px;
  }
`;

const StyledGridWrapper = styled(Grid)`
  display: flex;
  border-bottom: 1px solid
    ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  padding: 10px;
`;

const StyledContentWrapper = styled(Grid)`
  padding-top: 20px;
`;

const StyledHeaderContainer = styled(Grid)`
  padding: 0 !important;
  background: ${(props) => props.theme.colors.base.darkBlue};
  background: ${sideMenuGradient};
  height: 66px;
`;

const StyledWrapperTop = styled(Grid)`
  display: flex;
`;

const StyledSorryTextWrapper = styled(Grid)`
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 30px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    font-size: 70px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    font-size: 100px;
  }
  font-family: Calibre-Regular;
  color: ${(props) => props.theme.colors.base.darkGrey};
`;

const StyledTextWrapper = styled(Grid)`
  font-size: 30px;
  font-family: Calibre-Regular;
  color: ${(props) => props.theme.colors.base.grey};
`;

const StyledOOPsContainer = styled.div`
  text-align: center;
  width: 100%;
`;

const StyledWrapperID = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

interface IProps {
  redirectFromPage: string;
}

let fullData: any = [""];
let items: any = [];
let jewelleryDetails: any;
let jewelleryType: string | null;
let jewelleryCondition: string | null = "";
let jewelleryDescription: string | null = "";

let myString: any;

let isReadOnlyUser: any;
const ShareJewellerylink = (props: IProps) => {
  const [item, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isProductAvailable, setIsProductAvailable] = useState(false);
  const [apiTimeout, setApiTimeout] = useState(false);
  const [count, setCount] = useState(0);
  const [userBlock, setUserBlock] = useState(false);

  const [openToast, setOpenToast] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [showAlertMessage, setShowAlertMessage] = useState("");

  let history = useHistory();
  const { t } = useTranslation();
  let userCurrencyCode = localStorage.getItem("preferredCurrency");
  let userCurrencySymbol = localStorage.getItem("preferredCurrencySymbol");
  let Auth = new AuthService();
  // @ts-ignore
  const { productId, auctionDate } = useParams();

  useEffect(() => {
    setIsLoading(false);
    // Example string
    isReadOnlyUser = localStorage.getItem("isReadOnlyUser");
    isReadOnlyUser = JSON.parse(isReadOnlyUser);
    myString = auctionDate;

    // Regular expression to match the date pattern
    const datePattern = /\d{4}-\d{2}-\d{2}/;

    // Find the first occurrence of the date pattern in the string
    const match = myString.match(datePattern);
    // Extract the date from the match array
    myString = match[0];
    getDetails(productId);
  }, []);

  useEffect(() => {
    if (isSubItemsAvailable(items)) {
      setItems(getModifiedProductDetailsResponse(items));
      setCount(0);
    } else {
      setItems(items);
      setCount(0);
    }
  }, [items]);

  const getDetails = (productId: string | number) => {
    setOpenToast(false);
    setIsLoading(true);
    Auth.getRequest(
      `${productUrl}/${productId}/detail`,
      (status: number, response: any) => {
        if (status === 200) {
          if (response.subItems) {
            var rows = [];
            for (var i = 0; i < response.subItems.length; i++) {
              let obj = response.subItems[i];
              if (obj.medias !== undefined) {
                for (var j = 0; j < obj.medias.length; j++) {
                  rows.push(obj.medias[j]);
                }
              }
            }
            var objModify = response;
            objModify.medias = objModify.medias.concat(rows);
            // setOpenToast(true);
            items = objModify;
            fullData = response;
            setIsLoading(false);
            setIsProductAvailable(
              response.state === "Available" ||
                response.state === "Sold" ||
                response.state === "Hold" ||
                response.state === "Unsold"
                ? true
                : false
            );
          } else {
            items = response;
            fullData = response;
            setIsLoading(false);
            setIsProductAvailable(
              response.state === "Available" ||
                response.state === "Sold" ||
                response.state === "Hold" ||
                response.state === "Unsold"
                ? true
                : false
            );
          }
        } else if (status === 403) {
          setUserBlock(true);
        } else if (status === 505) {
          setApiTimeout(true);
          setIsLoading(false);
        }
      }
    );
  };

  if (
    jewelleryDetails !== undefined &&
    jewelleryDetails !== null &&
    jewelleryDetails !== ""
  ) {
    if (
      jewelleryDetails.jewelleryType !== undefined &&
      jewelleryDetails.jewelleryType !== null
    ) {
      jewelleryType = jewelleryDetails.jewelleryType;
    }

    if (
      jewelleryDetails.jewelleryCondition !== undefined &&
      jewelleryDetails.jewelleryCondition !== null
    ) {
      jewelleryCondition = jewelleryDetails.jewelleryCondition;
    }

    if (
      jewelleryDetails.jewelleryDescription !== undefined &&
      jewelleryDetails.jewelleryDescription !== null
    ) {
      jewelleryDescription = jewelleryDetails.jewelleryDescription;
    }
  }

  const previousButtonSubItem = () => {
    if (count === 1) {
      setItems(items);
      setCount(count - 1);
    } else if (items.subItems.length === count) {
      setItems(items.subItems[count - 2]);
      setCount(count - 1);
    } else {
      if (count > 0) {
        setItems(items.subItems[count - 2]);
        setCount(count - 1);
      }
    }
  };

  const nextButtonSubItem = () => {
    if (items.subItems.length > count) {
      setItems(items.subItems[count]);
      setCount(count + 1);
    }
  };

  const alertTypes = (
    alertType: string,
    openToast: boolean,
    showMessage: string
  ) => {
    setShowAlertMessage(showMessage);
    setAlertType(alertType);
    setOpenToast(openToast);
    setTimeout(() => {
      setOpenToast(false);
    }, 3000);
  };
  const timeout = (timeout: boolean) => {
    if (timeout) {
      setApiTimeout(true);
    }
  };

  const shareButtonAction = () => {
    let languageCode =
      localStorage.getItem("language") === "EN"
        ? "en"
        : localStorage.getItem("language") === "zh_CN"
          ? "zh_CN"
          : "jp";

    if (items.parcelType === "Jewellery") {
      copy(
        `${window.location.hostname}/${languageCode}/product/jewellery/${productId}/${myString}/detail`
      );
    } else {
      copy(
        `${window.location.hostname}/${languageCode}/product/${productId}/${myString}/detail`
      );
    }
    setAlertType("success");
    setOpenToast(true);
    setShowAlertMessage(`${t("common:preview:linkCopied")}`);
    setTimeout(() => {
      setOpenToast(false);
    }, 3000);
  };

  const PreBiddingDetail = (productData: any) => {
    const { currency, preBidPrice, exchangeRates } = productData;
    const rate = exchangeRates && exchangeRates[0]["rate"];

    // Selectors
    const preferredCurrency = localStorage.getItem("preferredCurrency");
    const preferredCurrencySymbol = localStorage.getItem(
      "preferredCurrencySymbol"
    );

    return (
      <>
        {preBidPrice !== undefined && preBidPrice !== null && (
          <StyledLabel>
            {preBidPrice && getNumberFormat(Number(preBidPrice))}{" "}
            {currency && currency["symbol"]}
            {preferredCurrency !== currency["code"] ? (
              <>
                {"("}
                {preBidPrice &&
                  rate &&
                  getNumberFormat(Math.round(Number(preBidPrice) * rate))}
                {`${preferredCurrencySymbol})`}
              </>
            ) : (
              ""
            )}
          </StyledLabel>
        )}
      </>
    );
  };

  const convertedAmt = (
    currencyCodeVal: any,
    price: any,
    exchangeRate: any,
    carat: any
  ) => {
    //get user currency code form local storeage
    let amt: any = price * carat;
    if (
      currencyCodeVal === undefined ||
      currencyCodeVal === null ||
      amt === undefined ||
      amt === null ||
      amt === 0 ||
      exchangeRate === undefined ||
      exchangeRate === null
    ) {
      return "";
    } else {
      if (userCurrencyCode !== currencyCodeVal.code) {
        let conversion = new Intl.NumberFormat("us-en").format(
          Math.round(amt * exchangeRate)
        );
        let conversions = new Intl.NumberFormat("us-en").format(
          Math.round(amt)
        );
        return ` ${conversions} ${currencyCodeVal.symbol}  (${conversion}${userCurrencySymbol})`;
      } else {
        let conversions = new Intl.NumberFormat("us-en").format(
          Math.round(amt)
        );
        return `${conversions} ${userCurrencySymbol}`;
      }
    }
  };
  const getMediasForCarosuel = () => {
    const medias = [...items.medias];
    for (let i = 0; i < medias.length; i++) {
      let mediaItem = medias[i];
      if (mediaItem.mediaType === "PHOTO") {
        if (mediaItem.primary === true) {
          medias.splice(i, 1);
          medias.unshift(mediaItem);
          break;
        }
      }
    }
    return medias;
  };

  const convertedPlaceHolderValue = (
    currencyCodeVal: any,
    price: any,
    exchangeRate: any
  ) => {
    //get user currency code form local storeage
    if (
      currencyCodeVal === undefined ||
      currencyCodeVal === null ||
      price === undefined ||
      price === null ||
      price === 0 ||
      exchangeRate === undefined ||
      exchangeRate === null
    ) {
      return " ";
    } else {
      if (userCurrencyCode !== currencyCodeVal.code) {
        let conversion = new Intl.NumberFormat("us-en").format(
          Math.round(price * exchangeRate)
        );
        let conversions = new Intl.NumberFormat("us-en").format(
          Math.round(price)
        );
        return `${conversions}${currencyCodeVal.symbol}  (${conversion} ${userCurrencySymbol})`;
      } else {
        let conversions = new Intl.NumberFormat("us-en").format(
          Math.round(price)
        );
        return `${conversions} ${userCurrencySymbol}`;
      }
    }
  };
  const isParcelTypeDiamond = () => {
    return items?.parcelType === ParcelType.DIAMOND;
  };
  const isParcelTypeJewellery = () => {
    return items?.parcelType === ParcelType.JEWELLERY;
  };

  const renderImageNotAvailable = () => {
    return (
      <StyledGrid container={true} spacing={0}>
        <StyledNotAvailableImage>
          <StyledProductImage
            src={`/images/${isParcelTypeDiamond() || isParcelTypeJewellery()
              ? "default-diamond.png"
              : "default-parcel.png"
              }`}
          ></StyledProductImage>
        </StyledNotAvailableImage>
      </StyledGrid>
    );
  };
  return (
    <Grid>
      {apiTimeout === true ? (
        <ApiTimeout t={t} {...props} />
      ) : (
        <Grid>
          {isLoading === true ? (
            <div className="loader">
              <LoadingSpinner />
            </div>
          ) : (
            [
              isProductAvailable ? (
                <StyledLayoutGrid
                  container={true}
                  spacing={0}
                  className="diamondProductContainer"
                  style={{ padding: "10px" }}
                >
                  <Grid container={true} spacing={0} className="GridContainer">
                    <StyledGridWrapper
                      item={true}
                      xs={12}
                      className="StyledGridWrapper"
                    >
                      <Grid
                        container={true}
                        spacing={0}
                        className="actionContainer"
                      >
                        <StyledTitleContainer item={true} xs={8}>
                          <StyledBrandName>
                            <ProductDescription
                              truncateLength={100}
                              data={items}
                            />
                          </StyledBrandName>
                        </StyledTitleContainer>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <Grid container={true} spacing={0}>
                            {items !== "" && count === 0 && (
                              <StyledTooltipContainer
                                item={true}
                                xs={6}
                                onClick={shareButtonAction}
                              >
                                <StyledTooltipText>
                                  <StyledButton>
                                    {t("common:preview:productDetail:share")}
                                  </StyledButton>
                                </StyledTooltipText>
                              </StyledTooltipContainer>
                            )}

                            <Grid item={true} xs={6}>
                              {items !== "" && count === 0 && (
                                <StyledTooltipText>
                                  <AddorRemoveWatchlist
                                    {...props}
                                    t={t}
                                    {...items}
                                    getDetails={getDetails}
                                    alertType={alertTypes}
                                    apiTimeout={timeout}
                                    isSeller={false}
                                    isReadOnlyUser={false}
                                  />
                                </StyledTooltipText>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </StyledGridWrapper>

                    <Grid item={true} xs={12}>
                      <StyledContentWrapper container={true} spacing={0}>
                        <StyledProductCarousalColumn
                          item={true}
                          xs={12}
                          sm={12}
                          md={6}
                          lg={6}
                          xl={6}
                        >
                          {items.medias !== undefined && items.medias.length > 0 ? (
                            <ProductCarouselNew
                              medias={getMediasForCarosuel()}
                              productData={items}
                            />
                          ) : (
                              <StyledNoImgGrid reserved={items?.reserved}>{renderImageNotAvailable()}</StyledNoImgGrid>
                          )}
                        </StyledProductCarousalColumn>
                        <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
                          <StyledBidContainer container={true} spacing={0}>
                            <Grid item={true} xs={12} sm={12}>
                              <Grid container={true} spacing={0}>
                                <Grid item={true} xs={12} xl={10} lg={10}>
                                  {items !== undefined && (
                                    <>
                                      <NewPreBiddingForm
                                        productData={items}
                                        count={count}
                                        getDetails={getDetails}
                                        PreBiddingDetail={PreBiddingDetail}
                                      />
                                    </>
                                  )}
                                </Grid>
                                <Grid
                                  item={true}
                                  xs={12}
                                  xl={8}
                                  lg={8}
                                  md={12}
                                  spacing={0}
                                >
                                  <StyledPrebidingContainer>
                                    <div
                                      style={{
                                        fontSize: "1.2em",
                                        fontWeight: "bold",
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "5px",
                                      }}
                                    >
                                      <span>PreBid Price:</span>
                                      {count === 0 ? (
                                        <span>{PreBiddingDetail(items)}</span>
                                      ) : (
                                        <>-</>
                                      )}
                                    </div>
                                    <div>
                                      <span>
                                        (
                                        {getRapnetPercentage(
                                          items.rapnetPercentage,
                                          items.exchangeRates[0].rate,
                                          items.currency,
                                          items.preBidPrice,
                                          items.rapnetPrice
                                        )}
                                        )
                                      </span>
                                    </div>
                                    <div>
                                      <span>Amount:</span>
                                      {count === 0 ? (
                                        <span>
                                          {convertedAmt(
                                            items.currency,
                                            items.preBidPrice ?? 0,
                                            items.exchangeRates[0].rate,
                                            Number(items.carat)
                                          )}
                                        </span>
                                      ) : (
                                        <>-</>
                                      )}
                                    </div>
                                  </StyledPrebidingContainer>
                                </Grid>

                                <Grid item={true} xs={12}>
                                  <Box
                                    style={{ marginLeft: "10px", gap: "5px" }}
                                  >
                                    <div
                                      style={{
                                        marginLeft: "10px",
                                        gap: "10px",
                                      }}
                                    >
                                      <span>Starting Price: </span>
                                      {count === 0 ? (
                                        <span>
                                          {convertedPlaceHolderValue(
                                            items.currency,
                                            items.caratMinimumPrice,
                                            items.exchangeRates[0].rate
                                          )}
                                        </span>
                                      ) : (
                                        <>-</>
                                      )}
                                    </div>
                                    <div
                                      style={{
                                        marginLeft: "10px",
                                        gap: "10px",
                                      }}
                                    >
                                      <span>Amount: </span>
                                      {count === 0 ? (
                                        <span>
                                          {convertedPlaceHolderValue(
                                            items.currency,
                                            items.startingPrice,
                                            items.exchangeRates[0].rate
                                          )}
                                        </span>
                                      ) : (
                                        <>-</>
                                      )}
                                    </div>
                                  </Box>
                                </Grid>
                                <Grid item={true} xs={12} xl={8} lg={8}>
                                  {items !== "" && (
                                    <DetailsNotes
                                      t={t}
                                      {...props}
                                      {...items}
                                      count={count}
                                      apiTimeout={timeout}
                                      noteSaved={() => getDetails(productId)}
                                      auctionType={items?.auctionType}
                                      isReadOnlyUser={isReadOnlyUser}
                                    />
                                  )}
                                  {items.note !== "" && (
                                    <StyledLabel>{items.note}</StyledLabel>
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                          </StyledBidContainer>
                        </Grid>
                        <Grid item={true} xs={12}>
                          <StyledWrapperID item={true} xs={12}>
                            {items !== undefined && (
                              <ItemDescription productDetails={item} />
                            )}
                            {isSubItemsAvailable(items) && (
                              <SubNavigationControls
                                isPrevDisabled={count === 0}
                                isNextDisabled={items.subItems.length === count}
                                onPrevious={previousButtonSubItem}
                                onNext={nextButtonSubItem}
                              />
                            )}
                          </StyledWrapperID>
                          {items !== "" && (
                            <>
                              {items.parcelType === "Jewellery" ? (
                                <JewelleryDetails
                                  isPDView={true}
                                  auctionDate={myString}
                                  productData={item}
                                  onClickSignIn={() => history.replace("/")}
                                />
                              ) : (
                                <ProductDetails
                                  isPDView={true}
                                  auctionDate={myString}
                                  productData={item}
                                  onClickSignIn={() => history.replace("/")}
                                />
                              )}
                            </>
                          )}
                        </Grid>
                      </StyledContentWrapper>
                    </Grid>
                  </Grid>
                </StyledLayoutGrid>
              ) : (
                <StyledLayoutGrid container={true} spacing={0}>
                  <StyledHeaderContainer
                    item={true}
                    xs={12}
                    className="headerContainer"
                  >
                    <StyledWrapperTop
                      container={true}
                      spacing={0}
                    ></StyledWrapperTop>
                  </StyledHeaderContainer>
                  <Grid container={true} spacing={0}>
                    <StyledOOPsContainer>
                      <StyledSorryTextWrapper>
                        {t("common:pageNotFound:oops")}
                      </StyledSorryTextWrapper>
                      <StyledTextWrapper>
                        {t(
                          "common:preview:perItemDescription:noProductMessage"
                        )}
                      </StyledTextWrapper>
                    </StyledOOPsContainer>
                  </Grid>
                </StyledLayoutGrid>
              ),
            ]
          )}
          {openToast && (
            <Alert showAlertMessage={showAlertMessage} alertType={alertType} />
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default ShareJewellerylink;
