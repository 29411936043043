import { Autocomplete, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import styled from 'styled-components';

const StyledSearchBoxcontiner = styled.div`
  width: 200px;
  @media (min-width: 0px) and (max-width: 360px) {
    width: 90vw;
  }

  @media (min-width: 365px) and (max-width: 530px) {
    width: 86vw;
  }
  @media (width: 540px) {
    width: 320px;
  }
`;

interface Iprops {
  autoCompleteSearch: (value: any) => void;
  textSearchListing: (value: any) => void;
  setTextSearchArray: (value: any) => void;
}
const BasicSearchBox = (props: Iprops) => {
  let { autoCompleteSearch, textSearchListing, setTextSearchArray } = props;
  const [optionValue, setOptionValue] = useState("");
  
  useEffect(()=>{
    let searchValue: any = localStorage.getItem("optionValue");
    if (searchValue !== null && searchValue !== "null") {
      setOptionValue(searchValue);
    }
  },[])

  return (
    <div
      style={{
        flexDirection: "row",
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      <StyledSearchBoxcontiner>
        <Autocomplete
          id="free-solo-demo"
          freeSolo
          options={[]}
          onChange={(e: object, value: any | null) => {
            if (value === "null" || value === null) {
              setTextSearchArray([]);
            }
            setOptionValue(value);
            autoCompleteSearch(value);
          }}
          value={optionValue}
          renderInput={(params) => (
            <TextField
              {...params}
              label={"#Search"}
              margin="normal"
              variant="outlined"
              size="small"
              style={{
                margin: "0px",
                marginTop: "0px !important",
                marginBottom: "0px !important",
              }}
              onChange={(e) => textSearchListing(e.target.value)}
            />
          )}
        />
      </StyledSearchBoxcontiner>
    </div>
  );
}

export default BasicSearchBox