import { Box, Grid, Typography } from "@material-ui/core";
import styled from "styled-components";

const StyledMuiDataTableLayoutGrid = styled(Box)`
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: 0px 1px 3px #27405429;
  border-radius: 10px 10px 0px 0px;
  opacity: 1;

  font-weight: 700;
  .MuiDataGrid-root {
    display: flex;
    width: 100%;
  }
  .MuiDataGrid-main {
    display: flex;
    width: 100%;
    border: 1px solid lightgray;
    height: fit-content !important;
  }
  .MuiDataGrid-columnHeaders {
    text-align: center;
  }
  .MuiDataGrid-columnHeader {
    width: 100%;
  }
  .MuiDataGrid-columnGroupHeader {
    padding: 2px;
  }
  .MuiDataGrid-cell--textCenter {
    align: center;
  }
  .MuiDataGrid-root .MuiDataGrid-columnHeaderWrapper {
    background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
    color: white;
    font-weight: 700;
    font-size: 16px;
  }
  .MuiDataGrid-cell:focus-within {
    outline: none !important;
  }
  .li {
    padding: 5px;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px !important;
  }
`;

const StyledMuiDataTableBtnContainer = styled.div`
  text-align: center;
  cursor: pointer;
`;
const StyledMypageGrid = styled(Grid)`
  display: flex-wrap;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    padding-top: 10px;
  }
  .totalAmount {
    color: green;
  }
`;

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    margin-right: 5px;
  }
`;

const StyledActionsContainer = styled(Grid)`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
`;
const StyledActionSecondBarContainer = styled(Grid)`
  border-radius: 5px;
  justify-content: flex-end;
  &:focus {
    outline: none;
  }
`;

const StyledActionBarContainer = styled(Grid)`
  margin: 10px 0px 10px 0px;
  border-radius: 5px;
  box-shadow: 2px 0px 5px 2px ${(props) => props.theme.colors.base.lightGrey};
  justify-content: flex-end;
  &:focus {
    outline: none;
  }
`;
const StyledAmountHeading = styled.span`
  font-size: 1.2em;
  font-weight: 600;
`;

const StyledDescription = styled.div`
  padding: 0.2em;
  text-align: left;
  @media screen and (max-width: 600px) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media screen and (min-width: 600px) {
    width: 300px;
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
const StyledExportButton = styled.button`
  color: ${(props) => props.theme.colors.base.grey};
  font-size: 0.9em;
  padding: 0.3em;
  margin: 0.4em;
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.colors.base.lightGrey};
  background: ${(props) => props.theme.colors.base.veryVeryLightGrey};
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.base.textColor};
    cursor: pointer;
  }
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 0.8em;
  }
`;

const StyledReserveImg = styled.img`
  width: 2.4em;
  height: 2.4em;
  cursor: pointer;
`;

const StyledTypography = styled(Typography)`
  text-align: initial;
  font-size: 1em;
  display: flex;
  gap: 5px;
`;
export {
  StyledMuiDataTableLayoutGrid,
  StyledMuiDataTableBtnContainer,
  StyledMypageGrid,
  StyledContainer,
  StyledActionsContainer,
  StyledActionSecondBarContainer,
  StyledActionBarContainer,
  StyledAmountHeading,
  StyledDescription,
  StyledExportButton,
  StyledTypography,
  StyledReserveImg
};
