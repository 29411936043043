import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import MUIDataTable, {
  MUIDataTableColumnDef,
  MUIDataTableOptions,
} from "mui-datatables";
import styled from "styled-components";
import { Grid, Menu, MenuItem, MuiThemeProvider } from "@material-ui/core";
import { useHistory } from "react-router";

import update from "immutability-helper";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Countdown from "react-countdown";

import {
  faEdit,
  faHeart,
  faShareSquare,
} from "@fortawesome/free-solid-svg-icons";
import {
  createTheme,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import copy from "copy-to-clipboard";

import moment from "moment";

import PubSub from "pubsub-js";
import IRouterComponentProps from "../../../../models/IRouterComponentProps";
import AuthService from "../../../auth/AuthService";
import {
  annualBidUrl,
  annualMyLatestBidsForUser,
  annualMyWatchedProducts,
  getAnnualBidInfo,
  productDownload,
  productUrl,
} from "../../../../common/config/app.endpoints";
import { oAuth } from "../../../../routes/basic/BasicAppRouter";
import { openCertificateLinkPopup } from "../../../../common/GenericFunctions";
import EllipsisText from "../../../../common/elements/EllipsisText";
import {
  convertedValue,
  getDescription,
  getMyLatestStoredBids,
  getRapNet,
  getReturnMyLatestStoredBid,
} from "../../../products/preview/TenderGenericFunc";
import {
  StyledActionsContainer,
  StyledBackBtn,
  StyledBackImageContianer,
  StyledBtnContainer,
  StyledButtonGray,
  StyledButtonHeader,
  StyledCoreDataGridContainer,
  StyledHeaderContainer,
  StyledLayoutGrid,
  StyledName,
  StyledSelectAllButtonHeader,
  StyledTextWrapper,
} from "../DiamondProductStyle";
import {
  StyledButton,
  StyledModal,
} from "../../../../common/materials/GenericStyleComponent";
import ApiTimeout from "../../../../modules/ApiTimeout";
import LoadingSpinner from "../../../../common/elements/LoadingSpinner";
import { StyledLayoutGridMain } from "../../../../common/materials/GenericStyleMuitable";
import PageSizeSelector from "../../../core-grid/grid-actions/ActionPageSizeSelector";
import {
  StyledButtonView,
  Styledview,
} from "../../../products/preview/TendorPreviewView";
import BlockUserModal from "../../../products/BlockUser/BlockUserModal";
import ProductBiddingUi from "../biddingUi/ProductBiddingUi";
import AnnualBidPopup from "../../../products/preview/bidPopupComponent/AnnualBidPopup";
import AlertReadOnlyUser from "../../../../common/elements/AlertReadOnlyUser";
import ProductGridDataParent from "../mobileUi/ProductGridDataParent";
import ProductGridDataChildComponent from "../mobileUi/ProductGridDataChildComponent";
import ItemsCountofProduct from "../../../core-grid/grid-actions/ItemsCountofProduct";
import AnnualActionPageSizeSelector from "../../../core-grid/grid-actions/AnnualActionPageSizeSelector";

import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { AppDispatch } from "../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducer";
import {
  resetAnnualItemPerPage,
  resetAnnualCurrentApiStartCount,
  selectAnnualcurrentApiStartCount,
  selectAnnualItemPerPage,
  setAnnualCurrentApiStartCount,
  setAnnualItemPerPage,
} from "../../../../redux/slice/commanPagination/commanPaginationSlice";
import {
  resetDiamondGridProductWatchlistIndexes,
  resetDiamondWatchlistSelectAll,
  resetIsGridView,
  selectGridView,
  setDiamondGridProductWatchlistIndexes,
  setDiamondWatchlistSelectAll,
  setIsGridView,
} from "../../../../redux/slice/annual/commanAnnualSlice";
import ProductBiddingUiWatchlist from "../biddingUi/ProductBiddingUiWatchlist";
import ProductGridDataChildComponentWatchlist from "../mobileUi/ProductGridDataChildComponentWatchlist";

const faEditProps = faEdit as IconProp;
const faHeartProps = faHeart as IconProp;
const faShareSquareProps = faShareSquare as IconProp;

const StyledContent = styled(Grid)`
  text-align: center;
  font-size: 1.3em;
  font-family: "Calibre-Regular";
  color: ${(props) => props.theme.colors.base.textColor};
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 1.1em;
    margin-top: 0.4em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const StyledActionBarContainer = styled(Grid)`
  align-items: center;
  padding: 10px;
  margin: 10px 0px 10px 0px;
  border-radius: 5px;
  box-shadow: 2px 0px 5px 2px ${(props) => props.theme.colors.base.lightGrey};
  justify-content: flex-end;
  &:focus {
    outline: none;
  }
`;
interface IPendingProductProps extends IRouterComponentProps {
  setShowAlertMessage: any;
  setOpenToast: any;
  setAlertType: any;
  handleBackBtns: any;
  removeFromWatchlist: any;
}

let body: any = [];
let totalElement: number = 0;
let page: number = 0;

let payload: any = {};
let startCount: number = 0;
let endCount: number = 20;
let rowSelectedData: any;

let SelectedTableData: any;
let updatedRowData: any;
let updateRowIndex: any;
let auctionProductResult: any;
let getCurenntPerId: any = "";

let seconds: any = "";
let date: string | string[] = "";
let filter: any = [];
let filteredAnnualAucValues: any = "";
let filteredAnnualAucParcelValues: any = "";
let auctionName: string = "";

let details: any;

let indexsForBulkBidItems: any = [];
let myLatestBidForProduct: any = [];
// auction info
let auctionId: any;
let joinedAuctionUserId: any;
let auctionDate: any;
let isReadOnlyUser: any;
let isAuctionUserId: boolean;
let joinedAuctions: any = [];
let joinedAnnualAuctions: any = [];

const columnIndexes = {
  serialNo: 0,
  perId: 1,
  auctionUserId: 2,
  productCode: 5,
  lab: 6,
  certificate: 7,
  description: 8,
  productDescription: 9,
  carat: 10,
  color: 11,
  clarity: 12,
  remarks: 13,
  noOfPieces: 14,
  shape: 15,
  sieve: 16,
  cutGrade: 17,
  polish: 18,
  symmetryAbbreviation: 19,
  flour: 20,
  currency: 21,
  peicePerCt: 22,
  myLatestBids: 23,
  notes: 24,
  currentBidPrice: 25,
  repnetPrice: 26,
  noOfBids: 27,
  rowColor: 28,
  exchangeRate: 29,
  parcelType: 30,
  noOfBidders: 31,
  currentBidValue: 31,
  hieghestBidValue: 32,
  watched: 32,
  sellerAuctionUserId: 33,
  rapnetPercentage: 34,
  previousHighestBidder: 35,
  productStartDate: 36,
  productExtended: 37,
  holdPreviousBidder: 38,
};
let length: any;
const initialState = {
  mouseX: null,
  mouseY: null,
};
const DiamondProductAuctionWatchlist = (props: IPendingProductProps) => {
  let { setShowAlertMessage, setOpenToast, setAlertType, handleBackBtns } =
    props;

  const getMuiTheme = () =>
    createTheme({
      overrides: {
        MUIDataTableSelectCell: {
          fixedHeader: {
            background: "white",
          },
          fixedLeft: {
            background: "white",
          },
        },
      },
    });

  const theme = useTheme();
  const [auctionProducts, setAuctionProducts] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [apiTimeout, setApiTimeout] = useState<boolean>(false);
  const [onAction, setOnAction] = useState<boolean>(false);
  const [userBlock, setUserBlock] = useState<boolean>(false);
  const [showSinglebidPopup, setShowSinglebidPopup] = useState<boolean>(false);
  const [showBulkBidButton, setShowBulkBidButton] = useState<boolean>(false);
  const [uiBoxView, setUiBoxView] = useState<boolean>(false);
  const [isDataRefresh, setIsDataRefresh] = useState<boolean>(false);
  const notSmallDevices = useMediaQuery(theme.breakpoints.between("md", "xl"));
  const [isTopElementHide, setIsTopElementHide] = useState<boolean>(false);
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [openReadOnlyUserToast, setOpenReadOnlyUserToast] =
    useState<boolean>(false);
  const [isTableExpanded, setIsTableExpanded] = useState<boolean>(false);
  const [showBulkBidPopup, setShowBulkBidPopup] = useState<boolean>(false);
  const [shouldGetAllData, setShouldGetAllData] = useState<boolean>(false);
  const [isDataTableRefresh, setIsDataTableRefresh] = useState<boolean>(false);
  const [isSort, setIsSort] = useState<boolean>(false);
  const [currentProductId, setCurrentProductId] = useState<string>("");
  const [productdate, setProductStartDate] = useState<string>("");
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [mouseState, setMouseState] = React.useState<{
    mouseX: null | number;
    mouseY: null | number;
  }>(initialState);
  const { t } = useTranslation();
  let history = useHistory();
  const Auth = new AuthService();

  const eventSource: any = useRef<any>();
  const maxRetryCount = 2;
  const countRef = useRef(0);
  const itemsPerPageUseRef = useRef(20);
  const selectPageUseRef = useRef(0);

  const maxCallApiCount = 1;
  const countAnnualRef = useRef(0);
  const dispatch: AppDispatch = useDispatch();

  const isGridView: any = useSelector((state: RootState) =>
    selectGridView(state)
  );

  useEffect(() => {
    dispatch(resetDiamondGridProductWatchlistIndexes());
    dispatch(resetDiamondWatchlistSelectAll());
    setIsLoading(true);
    localStorage.removeItem("dropDownFilterVal");
    localStorage.removeItem("annualdropDownFilterVal");
    localStorage.removeItem("filteredAnnualAucValues");
    localStorage.removeItem("filteredAnnualAucParcelValues");
    localStorage.removeItem("jwlFilteredValues");
    localStorage.removeItem("filteredJewelleryValues");

    isReadOnlyUser = localStorage.getItem("isReadOnlyUser");
    isReadOnlyUser = JSON.parse(isReadOnlyUser);
    dispatch(resetAnnualItemPerPage());
    dispatch(resetAnnualCurrentApiStartCount());
    joinedAuctions = localStorage.getItem("joinedAnnualAuctions");
    joinedAuctions = JSON.parse(joinedAuctions);

    if (joinedAuctions !== null) {
      joinedAuctions.filter((items: any) => {
        if (items.category === "Diamonds") {
          auctionId = items.perId;
          joinedAuctionUserId = items.auctionUserId;
          auctionDate = items.startDate;
          auctionName = items.name;
        }
      });
    }
    if (auctionId !== undefined) {
      //Event source Connection to the server
      getAllUpdatedData();
      //product Get Api
      // getAuctionProducts(auctionId, 0);
      getMyLatestBids(auctionId);
    } else {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
        setApiTimeout(false);
      }, 2000);
    }

    return () => {
      if (eventSource.current !== undefined || eventSource.current !== null) {
        if (eventSource.current?.close !== undefined) {
          eventSource.current?.close();
        }
      }
      localStorage.removeItem("urlProductAuctionFromEmail");
    };
  }, []);

  const selectcurrentApiStartCountValue: any = useSelector((state: RootState) =>
    selectAnnualcurrentApiStartCount(state)
  );

  const selectItemPerPageValue: any = useSelector((state: RootState) =>
    selectAnnualItemPerPage(state)
  );

  useEffect(() => {
    localStorage.setItem(
      "currentStart",
      JSON.stringify(selectcurrentApiStartCountValue * selectItemPerPageValue)
    );
    if (auctionId !== undefined) {
      getAuctionProducts(auctionId, selectcurrentApiStartCountValue);
    }
  }, [selectItemPerPageValue, selectcurrentApiStartCountValue]);

  useEffect(() => {
    if (shouldGetAllData) {
      getAllUpdatedData();
      setShouldGetAllData(false);
    }
  }, [shouldGetAllData]);

  const getAllUpdatedData = () => {
    if (countRef.current < maxRetryCount) {
      eventSource.current = new EventSource(
        `${getAnnualBidInfo}?auctionId=${auctionId}`
      );
      eventSource.current.onmessage = (event: any) => {
        updatedRowData = JSON.parse(event.data);
        if (updatedRowData !== undefined) {
          if (updatedRowData.alive) {
            //This will listen only for events
          } else {
            if (auctionId === updatedRowData.auctionId) {
              updateRowIndex = auctionProductResult.findIndex(
                (product: any) => product.perId === updatedRowData.perId
              );
              const mapping: any = {};
              if (
                auctionProductResult !== undefined &&
                auctionProductResult.length > 0
              ) {
                auctionProductResult.map((items: any) => {
                  mapping[items.perId] = {
                    watched: items.watched,
                  };
                });
              }
              var updatedArr = update(auctionProductResult, {
                $splice: [[updateRowIndex, 1, updatedRowData]],
              });
              const arr = [...updatedArr];
              arr.map((items: any) => {
                if (mapping[items.perId] !== undefined) {
                  items.watched = mapping[items.perId].watched;
                }
              });
              auctionProductResult = arr;
              setAuctionProducts(auctionProductResult);
              setShouldGetAllData(false);
            }
          }
        }
      };
      eventSource.current.onerror = (err: any) => {
        console.error("EventSource failed:", err);
        countRef.current = countRef.current + 1;
        setShouldGetAllData(true);
      };
    }
  };

  const getAuctionProducts = (
    auctionId: number | string,
    currentPage: number,
    isLoadPreSelectedPage?: boolean
  ) => {
    setOpenToast(false);
    body = [];
    filteredAnnualAucValues = "";
    filteredAnnualAucParcelValues = "";

    if (localStorage.getItem("tabSelectedVal") === "1") {
      filteredAnnualAucValues = localStorage.getItem("filteredAnnualAucValues");
      filteredAnnualAucValues = JSON.parse(filteredAnnualAucValues);

      if (filteredAnnualAucValues !== undefined) {
        if (
          filteredAnnualAucValues === null ||
          filteredAnnualAucValues.length === 0
        ) {
          filter = null;
        } else {
          filteredAnnualAucValues.map((item: any, index: number) => {
            if (item.name === "auctionId") {
              filteredAnnualAucValues.splice(index, 1);
            }
          });
          filter = filteredAnnualAucValues;
        }
      }
    } else {
      filteredAnnualAucParcelValues = localStorage.getItem(
        "filteredAnnualAucParcelValues"
      );
      filteredAnnualAucParcelValues = JSON.parse(filteredAnnualAucParcelValues);

      if (filteredAnnualAucParcelValues !== undefined) {
        if (
          filteredAnnualAucParcelValues === null ||
          filteredAnnualAucParcelValues.length === 0
        ) {
          filter = null;
        } else {
          filteredAnnualAucParcelValues.map((item: any, index: number) => {
            if (item.name === "auctionId") {
              filteredAnnualAucParcelValues.splice(index, 1);
            }
          });
          filter = filteredAnnualAucParcelValues;
        }
      }
    }
    getApiBody(auctionId, currentPage, endCount);
  };

  const getApiBody = (
    auctionId: string | number,
    currentPage: number,
    endCount: number,
    isLoadPreSelectedPage?: boolean
  ) => {
    //pagination rediration condition
    payload = {};
    getCurenntPerId = localStorage.getItem("currentPDItem");

    if (localStorage.getItem("tabSelectedVal") === "1") {
      filteredAnnualAucValues = localStorage.getItem("filteredAnnualAucValues");
      filteredAnnualAucValues = JSON.parse(filteredAnnualAucValues);
    } else {
      filteredAnnualAucParcelValues = localStorage.getItem(
        "filteredAnnualAucParcelValues"
      );
      filteredAnnualAucParcelValues = JSON.parse(filteredAnnualAucParcelValues);
    }

    if (
      localStorage.getItem("annualdropDownFilterVal") !== undefined &&
      localStorage.getItem("annualdropDownFilterVal") !== null
    ) {
      var tempArr = localStorage.getItem("annualdropDownFilterVal");
      if (tempArr !== null) var dropDownValues = JSON.parse(tempArr);
      var property = dropDownValues[0].property;
      var direction = dropDownValues[0].direction;
    }

    if (
      selectcurrentApiStartCountValue !== null &&
      selectcurrentApiStartCountValue !== 0
    ) {
      startCount = selectcurrentApiStartCountValue * selectItemPerPageValue;
    } else {
      startCount = selectcurrentApiStartCountValue;
    }

    if (
      selectcurrentApiStartCountValue != null &&
      selectcurrentApiStartCountValue !== 0
    ) {
      page = selectcurrentApiStartCountValue;
    } else {
      page = 0;
    }

    if (selectItemPerPageValue !== null) {
      let savedCount: any = selectItemPerPageValue;
      endCount = savedCount;
    }

    if (
      (filter === null &&
        (filteredAnnualAucValues == null ||
          filteredAnnualAucValues === undefined ||
          filteredAnnualAucValues === "null" ||
          filteredAnnualAucValues.length === 0)) ||
      (filter === null &&
        (filteredAnnualAucParcelValues == null ||
          filteredAnnualAucParcelValues === undefined ||
          filteredAnnualAucParcelValues === "null" ||
          filteredAnnualAucParcelValues.length === 0))
    ) {
      payload = {
        page: {
          start: isLoadPreSelectedPage ? currentPage * 20 : startCount,
          count: selectItemPerPageValue,
          sort: {
            orders: [
              {
                property:
                  property !== null && property !== undefined
                    ? property
                    : "productEndDate",
                direction:
                  direction !== null && direction !== undefined
                    ? direction
                    : "ASC",
              },
            ],
          },
        },
      };
    } else {
      if (
        filteredAnnualAucValues !== null &&
        filteredAnnualAucValues.length > 0
      ) {
        body = filteredAnnualAucValues ? filteredAnnualAucValues : filter;
      } else {
        body = filteredAnnualAucParcelValues
          ? filteredAnnualAucParcelValues
          : filter;
      }

      body.map((item: any, index: number) => {
        if (item.name === "auctionId") {
          body.splice(index, 1);
        }
      });

      if (localStorage.getItem("tabSelectedVal") === "1") {
        body.push({
          match: "exact",
          name: "auctionId",
          value: auctionId,
        });
        payload = {
          page: {
            start: isLoadPreSelectedPage ? currentPage * 20 : startCount,
            count: selectItemPerPageValue,
            sort: {
              orders: [
                {
                  property:
                    property !== null && property !== undefined
                      ? property
                      : "productEndDate",
                  direction:
                    direction !== null && direction !== undefined
                      ? direction
                      : "ASC",
                },
              ],
            },
          },
        };
      } else {
        let andArr = [];
        andArr.push(
          {
            match: "exact",
            name: "auctionId",
            value: selectItemPerPageValue,
          },
          {
            match: "exact",
            name: "parcelType",
            value: "Parcel",
          }
        );
        payload = {
          filters: {
            or: body,
            and: andArr,
          },
          page: {
            start: isLoadPreSelectedPage ? currentPage * 20 : startCount,
            count: selectItemPerPageValue,
            sort: {
              orders: [
                {
                  property:
                    property !== null && property !== undefined
                      ? property
                      : "productEndDate",
                  direction:
                    direction !== null && direction !== undefined
                      ? direction
                      : "ASC",
                },
              ],
            },
          },
        };
      }
    }
    callApi(payload);
    // setIsTableExpanded(false);
  };

  const callApi = async (payload: any) => {
    if (!oAuth()) {
      history.push("/");
    } else {
      Auth.postRequest(
        `${annualMyWatchedProducts}/${auctionId}/watchlist`,
        payload,
        (status: any, response: any) => {
          setIsLoading(true);
          if (status === 200) {
            if (startCount !== 0 && response.results.length === 0) {
              dispatch(resetAnnualCurrentApiStartCount());
              selectPageUseRef.current = 0;
              localStorage.setItem("currentStart", "0");
            } else {
              // setIsTableExpanded(false);
              setAuctionProducts(response.results);
              totalElement = response.totalElements;
              auctionProductResult = response.results;
              setIsLoading(false);
              setIsDataTableRefresh(!isDataTableRefresh);
              countAnnualRef.current = 0;
            }
          } else if (status === 403) {
            setUserBlock(true);
          } else if (status === 404) {
            setIsLoading(false);
            setShowAlertMessage(`${t("common:invalidRequest")}`);
            setOnAction(false);
            setAlertType("error");
            setOpenToast(true);
          } else if (status === 505) {
            setApiTimeout(true);
            setOnAction(false);
          } else {
            setIsLoading(false);
            setOnAction(false);
            setShowAlertMessage(response[0].message);
            setAlertType("error");
            setOpenToast(true);
          }
        }
      );
    }
  };

  //get all mybids for Individual product

  const getMyLatestBids = (auctionId: string) => {
    if (!oAuth()) {
      props.history.push("/");
    } else {
      Auth.getRequest(
        `${annualMyLatestBidsForUser}?auctionId=${auctionId}`,
        (status: number, response: any) => {
          if (status === 200) {
            setIsLoading(false);
            myLatestBidForProduct = response;
          } else if (status === 403) {
            setUserBlock(true);
          } else if (status === 505) {
            setApiTimeout(true);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            setOnAction(false);
            setShowAlertMessage("Internal Server Error");
            setAlertType("error");
            setOpenToast(false);
          }
        }
      );
    }
  };

  const handleBidClick = (tableData: any) => {
    rowSelectedData = tableData.rowData;
    SelectedTableData = tableData.tableData;
    getProductsDetails(SelectedTableData);
    details = tableData.rowIndex;
    //TODO
    setShowSinglebidPopup(true);
  };

  const getProductsDetails = (productsDetails: any) => {
    length = productsDetails.length;
    productsDetails.map((items: any, index: any) => {
      if (items.perId === rowSelectedData[columnIndexes.perId]) {
        details = index;
      }
    });
    return details;
  };

  //rediract from bid now button of product details page -------
  const renderAutomaticBidPopup = (data: any) => {
    const rediractProductId: any = localStorage.getItem(
      "currentProductIdOfBidNow"
    );
    if (rediractProductId !== null) {
      const indexOfProduct = data.tableData.findIndex(
        (item: any) => item.perId === rediractProductId
      );
      rowSelectedData = data.rowData;
      SelectedTableData = data.tableData;
      details = indexOfProduct;
      if (details !== -1) {
        setShowSinglebidPopup(true);
      }
      localStorage.removeItem("currentProductIdOfBidNow");
    }
  };

  const renderAutomaticBidPopupInMobile = (data: any) => {
    const rediractProductId: any = localStorage.getItem(
      "currentProductIdOfBidNow"
    );
    if (rediractProductId !== null) {
      const indexOfProduct = auctionProducts.findIndex(
        (item: any) => item.perId === rediractProductId
      );
      rowSelectedData = data;
      SelectedTableData = auctionProducts;
      details = indexOfProduct;
      if (details !== -1) {
        setShowSinglebidPopup(true);
      }
      localStorage.removeItem("currentProductIdOfBidNow");
    }
  };

  const handleBtnValue = (
    values: number,
    currentBidPrice: any,
    productId: any,
    myBidValues: any
  ) => {
    var data = [
      {
        bidIncreasedBy: values,
        bidPrice: currentBidPrice,
        auctionUserId: joinedAuctionUserId,
        auctionId: auctionId,
        productId: productId,
      },
    ];
    let updateRowIndex = myLatestBidForProduct.findIndex(
      (product: any) => product.productId === myBidValues.productId
    );
    if (updateRowIndex === -1) {
      var myLatestBidForProductObjectData: any = [
        ...myLatestBidForProduct,
        myBidValues,
      ];
      myLatestBidForProduct = myLatestBidForProductObjectData;
    } else {
      var updatedArray = update(myLatestBidForProduct, {
        $splice: [[updateRowIndex, 1, myBidValues]],
      });
      myLatestBidForProduct = updatedArray;
    }
    bidProduct(data);
    PubSub.publish("resetAnnualDiamsGridBoxIndex", []);
  };

  const bulkBidHandler = (values: any[], myBidBulkValues: any) => {
    myLatestBidForProduct = myBidBulkValues;
    bidProduct(values);
    onGridSelectionChange(0);
    PubSub.publish("resetAnnualDiamsGridBoxIndex", []);
    dispatch(resetDiamondGridProductWatchlistIndexes());
    dispatch(resetDiamondWatchlistSelectAll());
    setIsSelected(false);
  };

  const bidProduct = (payloadBid: any) => {
    if (!oAuth()) {
      props.history.push("/");
    } else {
      Auth.postRequest(
        annualBidUrl,
        payloadBid,
        (status: any, response: any) => {
          if (status === 200) {
            setIsDataRefresh(!isDataRefresh);
            getMyLatestBids(auctionId);
          } else if (status === 403) {
            setUserBlock(true);
          } else if (status === 505) {
            setApiTimeout(true);
            setOnAction(false);
          } else if (status === 404) {
            setIsLoading(false);
            setOnAction(false);
            setShowAlertMessage(`${t("common:invalidRequest")}`);
            setAlertType("error");
            setOpenToast(true);
          } else {
            setIsLoading(false);
            setOnAction(false);
            setShowAlertMessage(response[0].message);
            setAlertType("error");
            setOpenToast(true);
          }
        }
      );
    }
  };

  const getTableBodyHeight = () => {
    var h = window.innerHeight;
    let heightOfInnerTable: any;
    if (isTableExpanded) {
      heightOfInnerTable = h - 370;
    } else {
      heightOfInnerTable = h - 210;
    }
    if (auctionProducts.length > 6) {
      return `${heightOfInnerTable}px`;
    }
  };

  const options: MUIDataTableOptions = {
    filter: "false",
    download: "false",
    print: "false",
    viewColumns: "false",
    count: totalElement,
    page: page,
    serverSide: true,
    search: false,
    rowsPerPage: selectItemPerPageValue,
    rowsPerPageOptions: [0],
    selectableRows: "multiple",
    selectableRowsHeader: true,
    selectableRowsOnClick: false,
    selectableRowsHideCheckboxes: false,
    isRowSelectable: (dataIndex: any, selectedRows: any) => {
      const disableRowIndexsArray = getDisableRowIndex();
      return disableRowIndexsArray.includes(dataIndex) ? false : true;
    },
    onChangePage: (currentPage) => {
      setIsLoading(true);
      setIsTableExpanded(false);
      localStorage.setItem(
        "currentStart",
        JSON.stringify(currentPage * itemsPerPageUseRef.current)
      );
      dispatch(setAnnualCurrentApiStartCount({ currentPage }));
    },
    onSearchChange: (searchTxt: any) => {
      return searchTxt;
    },
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
      return (
        <div className="mailIconDiv">
          <IconButton color="primary">
            <i className="zmdi zmdi-email"></i>
          </IconButton>
        </div>
      );
    },
    setRowProps: (row: any, dataIndex: any, rowIndex: any) => {
      if (row[columnIndexes.previousHighestBidder] === undefined) {
        if (row[columnIndexes.holdPreviousBidder] === undefined) {
          if (row[columnIndexes.auctionUserId] !== joinedAuctionUserId) {
            return { style: { background: "white" } };
          } else {
            return { style: { background: row[columnIndexes.rowColor] } };
          }
        } else {
          if (joinedAuctionUserId === row[columnIndexes.holdPreviousBidder]) {
            return { style: { background: "#ffeb9c" } };
          } else {
            return { style: { background: row[columnIndexes.rowColor] } };
          }
        }
      } else {
        if (joinedAuctionUserId === row[columnIndexes.previousHighestBidder]) {
          return { style: { background: "#FBE4D5" } };
        } else {
          return { style: { background: row[columnIndexes.rowColor] } };
        }
      }
    },
    onColumnSortChange: (price: any, direction: any) => {
      let order = "";
      if (isSort === false) {
        order = "desc";
      } else {
        order = "asc";
      }
      setIsSort(!isSort);
      sort(price, order);
    },
    onRowSelectionChange: (
      currentRowsSelected: any,
      allRowsSelected: any,
      rowsSelected: any
    ) => {
      setOpenToast(false);
      indexsForBulkBidItems = rowsSelected;
      indexsForBulkBidItems.map((indexs: any) => {
        let {
          sellerAuctionUserId,
          previousHighestBidder,
          auctionUserId,
          holdPreviousBidder,
        } = auctionProducts[indexs];
        if (sellerAuctionUserId === auctionUserId) {
          return { ...options, isRowSelectable: "false" };
        } else if (previousHighestBidder !== undefined) {
          if (auctionUserId === previousHighestBidder) {
            return { ...options, isRowSelectable: "false" };
          }
        } else if (holdPreviousBidder !== undefined) {
          if (auctionUserId === holdPreviousBidder) {
            return { ...options, isRowSelectable: "false" };
          }
        } else if (joinedAuctionUserId === auctionUserId) {
          return { ...options, isRowSelectable: "false" };
        }
      });
      if (indexsForBulkBidItems.length > 0) {
        setShowBulkBidButton(true);
      } else {
        setShowBulkBidButton(false);
      }
      if (indexsForBulkBidItems.length === 0) {
        setShowBulkBidButton(false);
      }
      if (indexsForBulkBidItems.length > 10) {
        setShowAlertMessage(
          `${t("common:Tender:validation:productSelection")}`
        );
        setAlertType("error");
        setOpenToast(true);
      }
    },
    tableBodyHeight: getTableBodyHeight(),
    textLabels: {
      body: {
        noMatch: `${t("common:watchList:watchlistEmpty")}`,
      },
    },
  };

  const onGridSelectionChange = (rowsSelected: any) => {
    indexsForBulkBidItems = rowsSelected;
    setOpenToast(false);
    if (indexsForBulkBidItems.length >= 0) {
      setShowBulkBidButton(true);
    } else {
      setShowBulkBidButton(false);
    }
    if (indexsForBulkBidItems.length === 0) {
      setShowBulkBidButton(false);
    }
    if (indexsForBulkBidItems.length > 10) {
      setShowAlertMessage(`${t("common:Tender:validation:productSelection")}`);
      setAlertType("error");
      setOpenToast(true);
    }
  };

  const boxColor = (items: any) => {
    const {
      auctionUserId,
      rowColor,
      previousHighestBidder,
      holdPreviousBidder,
    } = items;
    if (previousHighestBidder === undefined) {
      if (holdPreviousBidder === undefined) {
        if (joinedAuctionUserId !== auctionUserId) {
          return "white";
        } else {
          return rowColor;
        }
      } else {
        if (joinedAuctionUserId === holdPreviousBidder) {
          return "#ffeb9c";
        } else {
          return rowColor;
        }
      }
    } else {
      if (joinedAuctionUserId === previousHighestBidder) {
        return "#FBE4D5";
      } else {
        return rowColor;
      }
    }
  };

  const getDisableRowIndex = () => {
    return auctionProducts.map((items: any, index: number) => {
      if (isReadOnlyUser) {
        return index;
      } else {
        if (items.sellerAuctionUserId === joinedAuctionUserId) {
          return index;
        } else if (items.previousHighestBidder !== undefined) {
          if (joinedAuctionUserId === items.previousHighestBidder) {
            return index;
          }
        } else if (items.holdPreviousBidder !== undefined) {
          if (joinedAuctionUserId === items.holdPreviousBidder) {
            return index;
          }
        } else if (items.auctionUserId === joinedAuctionUserId) {
          return index;
        }
      }
    });
  };

  // API calls
  const renderer = ({
    formatted: { days, hours, minutes, seconds },
    completed,
  }: any) => {
    if (completed) {
      if (
        days === "00" &&
        hours === "00" &&
        minutes === "00" &&
        seconds === "00"
      ) {
        getExtendedTime();
        return <span>00:00:00:00</span>;
      }
    } else {
      return (
        <span>
          {days}:{hours}:{minutes}:{seconds}
        </span>
      );
    }
  };
  const getExtendedTime = () => {
    if (countAnnualRef.current < maxCallApiCount) {
      getAuctionProducts(auctionId, 0);
      closebidPopup();
      countAnnualRef.current = countAnnualRef.current + 1;
    }
  };

  const getExtendedTimeGrid = (cPage: any) => {
    if (countAnnualRef.current < maxCallApiCount) {
      getAuctionProducts(auctionId, cPage);
      closebidPopup();
      countAnnualRef.current = countAnnualRef.current + 1;
    }
  };

  const productTimeLeft = (startDate: any) => {
    let date: string | null = "";
    let time: string | null = "";
    let timezone: any = "";

    if (startDate !== null || startDate !== undefined) {
      let previewTime = moment.utc(startDate);
      if (timezone !== null) {
        timezone = localStorage.getItem("timezoneName");
      }
      if (previewTime !== null || previewTime !== undefined) {
        let result = moment.tz(previewTime, timezone);
        let resultFormatter = result.format();
        if (resultFormatter !== "Invalid date") {
          let splitDateTime = resultFormatter.split("T", 2);
          let splitTime: any = "";
          if (splitDateTime[1].includes("+")) {
            splitTime = splitDateTime[1].split("+", 2);
          } else {
            splitTime = splitDateTime[1].split("-", 2);
          }
          date = splitDateTime[0];
          time = splitTime[0];
          let dateTime = `${date} ${time}`;
          let end: any = moment(dateTime);
          timezone = localStorage.getItem("timezoneName");
          let start: any = moment(
            new Date().toLocaleString("en-US", { timeZone: timezone })
          );
          var timeDiff = end - start;
          seconds = Math.round(timeDiff);
        }
      }
    }
    return (
      <Countdown
        date={Date.now() + seconds}
        className="countdownText"
        renderer={renderer}
        zeroPadTime={2}
      />
    );
  };

  const filterSort = (price: any, direction: any) => {
    let order = "";
    if (direction === "asc") {
      order = "desc";
    } else {
      order = "asc";
    }
    sort(price, order);
  };

  const sort = (value: any, direction: any) => {
    sortData(value, direction);
  };

  const sortData = (values: any, direction: any) => {
    let tempArr: any = [{ property: values, direction: direction }];

    if (direction === "asc") {
      tempArr = [{ property: values, direction: "ASC" }];
      localStorage.setItem("annualdropDownFilterVal", JSON.stringify(tempArr));
    } else {
      tempArr = [{ property: values, direction: "DESC" }];
      localStorage.setItem("annualdropDownFilterVal", JSON.stringify(tempArr));
    }
    if (selectcurrentApiStartCountValue === 0) {
      getAuctionProducts(auctionId, 0);
    } else {
      dispatch(resetAnnualCurrentApiStartCount());
    }
    selectPageUseRef.current = 0;
    localStorage.setItem("currentStart", "0");
  };

  const columns: MUIDataTableColumnDef[] = [
    {
      name: "serialNo",
      label: "Serial No",
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: "perId",
      label: "Product Id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "auctionUserId",
      label: "Auction User Id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "",
      label: `${t("common:Tender:tableColumnNames:ActionsTender")}`,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <div
              style={{
                display: "flex",
                gridGap: "10px",
                justifyContent: "center",
              }}
            >
              <div title={`Note`} style={{ cursor: "pointer" }}>
                <FontAwesomeIcon
                  icon={faEditProps}
                  style={{ color: "#5b9bd5" }}
                  onClick={() =>
                    openProductDetail(
                      auctionId,
                      tableMeta.rowData[1],
                      tableMeta.rowData[columnIndexes.productStartDate]
                    )
                  }
                />
              </div>
              <div
                title={`Add Product into Wishlist`}
                style={{ cursor: "pointer" }}
              >
                {isReadOnlyUser ? (
                  <FontAwesomeIcon
                    icon={faHeartProps}
                    style={{ color: "gray" }}
                    onClick={() => {
                      renderReadOnlyUserToast(
                        tableMeta.rowData[columnIndexes.sellerAuctionUserId]
                      );
                    }}
                  />
                ) : (
                  <>
                    {tableMeta.rowData[columnIndexes.sellerAuctionUserId] !==
                    joinedAuctionUserId ? (
                      <>
                        {getWatchedProduct(
                          tableMeta.rowData[columnIndexes.watched],
                          tableMeta.rowData[columnIndexes.perId],
                          auctionId,
                          tableMeta.rowData[1]
                        )}
                      </>
                    ) : (
                      <FontAwesomeIcon
                        icon={faHeartProps}
                        style={{ color: "gray" }}
                        onClick={() => {
                          renderReadOnlyUserToast(
                            tableMeta.rowData[columnIndexes.sellerAuctionUserId]
                          );
                        }}
                      />
                    )}
                  </>
                )}
              </div>
              <div
                title={`Share Product`}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  shareButtonAction(
                    tableMeta.rowData[columnIndexes.perId],
                    tableMeta.rowData[columnIndexes.productStartDate]
                  )
                }
              >
                <FontAwesomeIcon
                  icon={faShareSquareProps}
                  style={{ color: "#5b9bd5" }}
                />
              </div>
            </div>
          );
        },
      },
    },
    {
      name: "productEndDate",
      label: `${t("common:newCommanTrans:timeleft")}`,
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: { minWidth: "150px", maxWidth: "150px" },
        }),
        customBodyRender: (value: any, tableMeta: any) => {
          if (tableMeta.rowData[columnIndexes.productExtended] !== undefined) {
            if (tableMeta.rowData[columnIndexes.productExtended]) {
              return (
                <span style={{ color: "red" }}>{productTimeLeft(value)}</span>
              );
            } else {
              return <span>{productTimeLeft(value)}</span>;
            }
          }
        },
      },
    },
    {
      name: "code",
      label: `${t("common:Tender:tableColumnNames:productId")}`,
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: { minWidth: "150px", maxWidth: "150px" },
        }),
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <span
              className="linkCss"
              onClick={() =>
                openProductDetail(
                  auctionId,
                  tableMeta.rowData[1],
                  tableMeta.rowData[columnIndexes.productStartDate]
                )
              }
              onContextMenu={(event: any) =>
                rightClickEvent(
                  event,
                  tableMeta.rowData[1],
                  tableMeta.rowData[columnIndexes.productStartDate]
                )
              }
            >
              {value}
            </span>
          );
        },
      },
    },
    {
      name: "lab",
      label: `${t("common:Tender:tableColumnNames:lab")}`,
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "certificate",
      label: `${t("common:Tender:tableColumnNames:certification")}`,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any) => {
          if (
            tableMeta.rowData[columnIndexes.lab] !== "AGT" &&
            tableMeta.rowData[columnIndexes.lab] !== "agt" &&
            tableMeta.rowData[columnIndexes.lab] !== "" &&
            tableMeta.rowData[columnIndexes.lab] !== undefined
          ) {
            return (
              <span
                className="linkCss"
                onClick={() =>
                  openCertificateLinkPopup(
                    tableMeta.rowData[columnIndexes.lab],
                    tableMeta.rowData[columnIndexes.certificate]
                  )
                }
              >
                {value}
              </span>
            );
          } else {
            return <span>{value}</span>;
          }
        },
      },
    },
    {
      name: "description",
      label: `${t("common:Tender:tableColumnNames:description")}`,
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "productDescription",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "carat",
      label: `${t("common:Tender:tableColumnNames:carat")}`,
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: "color",
      label: `${t("common:Tender:tableColumnNames:color")}`,
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: "clarity",
      label: `${t("common:Tender:tableColumnNames:clarity")}`,
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: "remarks",
      label: `Remarks`,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <EllipsisText
              text={getDescription(
                tableMeta.rowData[columnIndexes.parcelType],
                tableMeta.rowData[columnIndexes.productDescription],
                tableMeta.rowData[columnIndexes.remarks]
              )}
              length={20}
              tail={"..."}
            />
          );
        },
      },
    },
    {
      name: "noOfPieces",
      label: `${t("common:Tender:tableColumnNames:noOfPieces")}`,
      options: {
        filter: false,
        sort: true,
        display: true,
        customBodyRender: (value: any, tableMeta: any) => {
          return <>{value !== 0 ? value : "-"}</>;
        },
      },
    },
    {
      name: "shape",
      label: `${t("common:Tender:tableColumnNames:shape")}`,
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: "sieve",
      label: `${t("common:Tender:tableColumnNames:Sieve")}`,
      options: {
        filter: false,
        sort: false,
        display: true,
        customBodyRender: (value: any, tableMeta: any) => {
          return <>{value !== "-" ? value : ""}</>;
        },
      },
    },
    {
      name: "cutGrade",
      label: `${t("common:Tender:tableColumnNames:cutGrade")}`,
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: "polish",
      label: `${t("common:Tender:tableColumnNames:polish")}`,
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: "symmetryAbbreviation",
      label: `${t("common:Tender:tableColumnNames:symmetryAbbreviation")}`,
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: "flour",
      label: `${t("common:Tender:tableColumnNames:flour")}`,
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: "currency",
      label: "currency",
      options: {
        filter: false,
        sort: false,
        display: false,
        customBodyRender: (value: any) => {
          return <div>{value.code}</div>;
        },
      },
    },
    {
      name: "caratMinimumPrice",
      label: `${t("common:Tender:tableColumnNames:pricePct")}`,
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({
          style: { minWidth: "120px", maxWidth: "120px" },
        }),
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <div>
              {convertedValue(
                tableMeta.rowData[columnIndexes.currency],
                value,
                tableMeta.rowData[columnIndexes.exchangeRate][0].rate
              )}
            </div>
          );
        },
      },
    },
    {
      name: "myLatestBid",
      label: `${t("common:Tender:tableColumnNames:placeBid")}`,
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: { minWidth: "130px", maxWidth: "140px" },
        }),
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <>
              {isDataRefresh ? (
                <div>
                  {value === undefined || value === null
                    ? 0
                    : handelMyBidsLabel(
                        myLatestBidForProduct,
                        tableMeta.rowData[columnIndexes.perId],
                        tableMeta.rowData[columnIndexes.currency],
                        tableMeta.rowData[columnIndexes.exchangeRate][0].rate
                      )}
                </div>
              ) : (
                <div>
                  {value === undefined || value === null
                    ? 0
                    : handelMyBidsLabel(
                        myLatestBidForProduct,
                        tableMeta.rowData[columnIndexes.perId],
                        tableMeta.rowData[columnIndexes.currency],
                        tableMeta.rowData[columnIndexes.exchangeRate][0].rate
                      )}
                </div>
              )}
              {getBidNowButtonDisable(
                tableMeta.rowData[columnIndexes.sellerAuctionUserId],
                joinedAuctionUserId,
                tableMeta.rowData[columnIndexes.auctionUserId],
                tableMeta,
                getReturnMyLatestStoredBid(
                  myLatestBidForProduct,
                  tableMeta.rowData[columnIndexes.perId]
                ),
                tableMeta.rowData[columnIndexes.currentBidPrice],
                tableMeta.rowData[columnIndexes.perId],
                tableMeta.rowData[columnIndexes.previousHighestBidder],
                tableMeta.rowData[columnIndexes.holdPreviousBidder]
              )}
            </>
          );
        },
      },
    },
    {
      name: "notes",
      label: `${t("common:Tender:tableColumnNames:notes")}`,
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "currentBidPrice",
      label: `${t("common:Tender:tableColumnNames:liveHighestBid")}`,
      options: {
        filter: false,
        sort: true,
        display: true,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <div>
              {convertedValue(
                tableMeta.rowData[columnIndexes.currency],
                value,
                tableMeta.rowData[columnIndexes.exchangeRate][0].rate
              )}
            </div>
          );
        },
      },
    },
    {
      name: "rapnetPercentage",
      label: `${t("common:Tender:tableColumnNames:rapOff")}`,
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <div>
              {getRapNet(tableMeta.rowData[columnIndexes.rapnetPercentage])}
            </div>
          );
        },
      },
    },
    {
      name: "noOfBidders",
      label: `${t("common:newCommanTrans:noOfBiddersBids")}`,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <div>
              {getNoOfBiddersAndNoOfBids(
                tableMeta.rowData[columnIndexes.noOfBids],
                tableMeta.rowData[columnIndexes.noOfBidders]
              )}
            </div>
          );
        },
      },
    },
    {
      name: "rowColor",
      label: "Bid Color",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "exchangeRates",
      label: "Exchange Rate",
      options: {
        filter: false,
        sort: false,
        display: false,
        customBodyRender: (value: any) => {
          return <div>{value[0].rate}</div>;
        },
      },
    },
    {
      name: "parcelType",
      label: "",
      options: {
        display: false,
      },
    },

    {
      name: "noOfBids",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "watched",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "sellerAuctionUserId",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "rapnetPercentage",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "previousHighestBidder",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "productStartDate",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "productExtended",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "holdPreviousBidder",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
  ];

  const handleChangeAcco =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
      if (isExpanded === false) {
        setIsTableExpanded(false);
      } else {
        setIsTableExpanded(true);
      }
    };

  const renderReadOnlyUserToast = async (perId: any) => {
    var joinedAuctions: any = localStorage.getItem("joinedAnnualAuctions");
    joinedAuctions = JSON.parse(joinedAuctions);
    if (joinedAuctions !== null) {
      joinedAuctions.filter((items: any, index: number) => {
        if (items.category === "Diamonds") {
          if (perId === items.auctionUserId) {
            isAuctionUserId = true;
          }
        }
      });
    } else {
      isAuctionUserId = false;
    }
    await renderPopup();
  };

  const renderPopup = () => {
    if (isReadOnlyUser) {
      setOpenReadOnlyUserToast(true);
    } else if (isAuctionUserId) {
      setOpenReadOnlyUserToast(true);
    } else {
      setOpenReadOnlyUserToast(false);
    }
    setTimeout(() => {
      setOpenReadOnlyUserToast(false);
    }, 3000);
  };

  //get bid now button disable
  const getBidNowButtonDisable = (
    sellerId: any,
    auctionUserIdForProduct: any,
    tableAuctionUserId: any,
    tableDataForUIBox: any,
    myBidsForProduct: any,
    currentBidPriceForProduct: any,
    currentproductId: any,
    previousBidderId: any,
    holdPreviousBidder: any
  ) => {
    renderAutomaticBidPopup(tableDataForUIBox);
    if (isReadOnlyUser) {
      return (
        <StyledBtnContainer
          onClick={() => {
            renderReadOnlyUserToast(sellerId);
          }}
        >
          <StyledButtonGray>
            {t("common:inquiry:button:bidNow")}
          </StyledButtonGray>
        </StyledBtnContainer>
      );
    } else {
      if (sellerId === auctionUserIdForProduct) {
        return (
          <StyledBtnContainer
            onClick={() => {
              renderReadOnlyUserToast(sellerId);
            }}
          >
            <StyledButtonGray>
              {t("common:inquiry:button:bidNow")}
            </StyledButtonGray>
          </StyledBtnContainer>
        );
      } else if (holdPreviousBidder !== undefined) {
        if (joinedAuctionUserId === holdPreviousBidder) {
          return (
            <StyledBtnContainer>
              <StyledButtonGray>
                {t("common:inquiry:button:bidNow")}
              </StyledButtonGray>
            </StyledBtnContainer>
          );
        } else {
          return (
            <StyledBtnContainer>
              <StyledButton onClick={() => handleBidClick(tableDataForUIBox)}>
                {t("common:inquiry:button:bidNow")}
              </StyledButton>
            </StyledBtnContainer>
          );
        }
      } else if (previousBidderId !== undefined) {
        if (joinedAuctionUserId === previousBidderId) {
          return (
            <StyledBtnContainer>
              <StyledButtonGray>
                {t("common:inquiry:button:bidNow")}
              </StyledButtonGray>
            </StyledBtnContainer>
          );
        } else {
          return (
            <StyledBtnContainer>
              <StyledButton onClick={() => handleBidClick(tableDataForUIBox)}>
                {t("common:inquiry:button:bidNow")}
              </StyledButton>
            </StyledBtnContainer>
          );
        }
      } else if (tableAuctionUserId === joinedAuctionUserId) {
        return (
          <StyledBtnContainer>
            <StyledButtonGray>
              {t("common:inquiry:button:bidNow")}
            </StyledButtonGray>
          </StyledBtnContainer>
        );
      } else {
        return (
          <StyledBtnContainer>
            <StyledButton onClick={() => handleBidClick(tableDataForUIBox)}>
              {t("common:inquiry:button:bidNow")}
            </StyledButton>
          </StyledBtnContainer>
        );
      }
    }
  };

  //get My All MyBid
  const handelMyBidsLabel = (
    myStoredBids: any,
    currentProductId: any,
    currency: any,
    exchangeRate: any
  ) => {
    const Obj = myLatestBidForProduct.filter(
      (item: any) => item.productId === currentProductId
    );
    const mybidPriceforProduct = Obj.map((item: any, index: number) => {
      return item.productId;
    });

    return getMyLatestStoredBids(
      myLatestBidForProduct,
      currentProductId,
      currency,
      exchangeRate,
      mybidPriceforProduct
    );
  };

  //get Watched Product
  const getWatchedProduct = (
    watched: any,
    productId: any,
    auctionId: any,
    serialNo: any
  ) => {
    return (
      <FontAwesomeIcon
        icon={faHeartProps}
        style={{ color: "red", cursor: "pointer" }}
        onClick={() => {
          removeFromWatchlist(productId, auctionId);
        }}
      />
    );
  };

  const removeFromWatchlist = (
    productId: string | number,
    auctionId: string | number
  ) => {
    setOnAction(true);
    setOpenToast(false);
    if (Auth.loggedIn() === null) {
      props.history.push("/");
    } else {
      Auth.postRequest(
        `${productUrl}/${auctionId}/${productId}/unwatch`,
        "payload",
        (status: any, response: any) => {
          if (status === 200) {
            // callApi(payload);
            setIsLoading(true);
            getAuctionProducts(auctionId, selectPageUseRef.current);
            setIsTableExpanded(false);
            setOnAction(false);
            setShowAlertMessage(response.data.message);
            setAlertType("success");
            setOpenToast(true);
          }
        }
      );
    }
  };

  const shareButtonAction = (perId: any, productStartDate: any) => {
    date = productStartDate.split(" ", 2);
    const { t } = props;
    let languageCode =
      localStorage.getItem("language") === "EN"
        ? "en"
        : localStorage.getItem("language") === "zh_CN"
        ? "zh_CN"
        : "jp";
    copy(
      `${window.location.hostname}/${languageCode}/product/${perId}/${date[0]}/detail`
    );
    setAlertType("success");
    setOpenToast(true);
    setShowAlertMessage(`${t("common:preview:linkCopied")}`);
    setTimeout(() => {
      setOpenToast(false);
    }, 2000);
  };

  const listGridview = () => {
    selectPageUseRef.current = startCount;
    setUiBoxView(true);
    dispatch(setIsGridView({ gridView: true }));
  };

  const Gridview = () => {
    setUiBoxView(false);
    dispatch(resetIsGridView());
  };

  const getNoOfBiddersAndNoOfBids = (noOfBidders: any, noOfBids: any) => {
    return `${noOfBidders}/${noOfBids}`;
  };

  const openProductDetail = (
    auctionId: string,
    perId: string,
    productStartDate: string
  ) => {
    date = productStartDate.split(" ", 2);
    props.history.push(
      `/365products/details/${auctionId}/${joinedAuctionUserId}/${perId}/${date[0]}`
    );
    dispatch(resetAnnualItemPerPage());
    dispatch(resetAnnualCurrentApiStartCount());
  };
  const openDetailsView = (
    auctionId: string,
    perId: string,
    productStartDate: string
  ) => {
    date = productStartDate.split(" ", 2);
    const language = localStorage.getItem("language");

    const languageCode =
      language === "EN" ? "en" : language === "zh_CN" ? "zh_CN" : "jp";

    window.open(
      `/${languageCode}/365products/details/${auctionId}/${joinedAuctionUserId}/${perId}/${date[0]}`,
      "_blank"
    );
    dispatch(resetAnnualItemPerPage());
    dispatch(resetAnnualCurrentApiStartCount());
  };

  const rightClickEvent = (
    event: React.MouseEvent<HTMLDivElement>,
    productId: string,
    productStartDate: string
  ) => {
    event.preventDefault();
    setMouseState({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
    setCurrentProductId(productId);
    setProductStartDate(productStartDate);
  };

  const handleClose = () => {
    setMouseState(initialState);
  };
  //paggination and sorting issue
  const setItemsPerPageHandler = (itemsPerPage: number) => {
    localStorage.setItem("currentPageOfTenderAllProducts", "0");
    dispatch(setAnnualItemPerPage({ itemsPerPage }));
    dispatch(resetAnnualCurrentApiStartCount());

    setCurrentPage(0);
    setItemsPerPage(itemsPerPage);
    itemsPerPageUseRef.current = itemsPerPage;
  };

  function setCurrentPage(currentPage: number) {
    return currentPage;
  }

  function setItemsPerPage(itemsPerPage: number) {
    return itemPerPage(true, 0, itemsPerPage);
  }
  const itemPerPage = (value: boolean, start: number, itemPerPage: number) => {
    if (value === true) {
      localStorage.setItem("annualitemsPerPage", JSON.stringify(itemPerPage));
      getUpdatedProducts(value, start, itemPerPage);
    }
  };

  const getUpdatedProducts = (value: any, start: any, endRecords: any) => {
    if (value === true) {
      getProducts(start, endRecords);
    }
  };

  const getProducts = (start: number, itemsPerPage: number) => {
    startCount = start;
    endCount = itemsPerPage;
    localStorage.setItem("currentStart", JSON.stringify(start));
    let Page: any = localStorage.getItem("currentPageOfTenderAllProducts");
    let currentPage = start / itemsPerPage;

    dispatch(setAnnualCurrentApiStartCount({ currentPage }));
    dispatch(setAnnualItemPerPage({ itemsPerPage }));
    PubSub.publish("resetAnnualDiamsGridBoxIndex", []);
    dispatch(resetDiamondGridProductWatchlistIndexes());
    dispatch(resetDiamondWatchlistSelectAll());
    onGridSelectionChange(0);
  };

  const handleMyBids = (auctionsProduct: any) => {
    if (auctionsProduct.length > 0) {
      setAuctionProducts(auctionsProduct);
    }
  };

  const addToWatchList = (
    productId: string | number,
    auctionId: string | number,
    serialNo: string | number
  ) => {
    setOnAction(true);
    setOpenToast(false);
    if (!oAuth()) {
      props.history.push("/");
    } else {
      Auth.postRequest(
        `${productUrl}/${auctionId}/${productId}/watch`,
        "payload",
        (status: any, response: any) => {
          if (response !== undefined) {
            if (status === 200) {
              callApi(payload);
              setIsTableExpanded(false);
              setIsLoading(false);
              setOnAction(false);
              setShowAlertMessage(`${t("common:preview:addedToWatchMsg")}`);
              setAlertType("success");
              setOpenToast(true);
            }
          }
        }
      );
    }
  };

  //index of Mobile ui component
  const getBoxIndex = (boxIndex: any) => {
    length = auctionProducts.length;
    details = boxIndex;
    setShowSinglebidPopup(true);
  };

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  const isSticky = (e: any) => {
    const header: any = document.querySelector(".AccordionHeader");
    const scrollTop = window.scrollY;
    const className =
      scrollTop >= 10
        ? header?.classList.add("AccordionHeaderscroll")
        : header?.classList.remove("AccordionHeaderscroll");
  };

  useEffect(() => {
    let isShowDrawerIcon: any = localStorage.getItem("isShowDrawerIcon");
    isShowDrawerIcon = JSON.parse(isShowDrawerIcon);
    if (isShowDrawerIcon !== null) {
      if (isShowDrawerIcon) {
        const accordionHeader: any = document.querySelector(".AccordionHeader");
        if (accordionHeader !== null) {
          accordionHeader.style.width = isShowDrawerIcon
            ? "calc(100% - 60px)"
            : "calc(100% - 240px)";
        }
      }
    }
  });

  const closebidPopup = () => {
    setShowSinglebidPopup(false);
    setShowBulkBidPopup(false);
    // PubSub.publish("resetAnnualDiamsGridBoxIndex", []);
  };

  const exportExcel = (auctionId: string) => {
    Auth.postRequestForExcel(
      `${productDownload}?auctionId=${auctionId}&auctionType=Annual&auctionProductsType=watched`,
      { ids: "" },
      (status: any, response: any) => {
        if (status === 200) {
          if (response !== undefined) {
            const url = window.URL.createObjectURL(
              new Blob([response], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `Products.xlsx`);
            document.body.appendChild(link);
            link.click();
          }
        }
      }
    );
  };

  const selectAllInGridView = () => {
    //calling select all function in grid View
    let arr: any = [];
    auctionProducts.map((productVal: any, index: any) => {
      if (arr.length < 10) {
        if (isReadOnlyUser) {
          //
        } else {
          if (productVal.sellerAuctionUserId === joinedAuctionUserId) {
            //
          } else if (productVal.holdPreviousBidder !== undefined) {
            if (joinedAuctionUserId === productVal.holdPreviousBidder) {
              //
            } else {
              //add
              arr = [...arr, index];
            }
          } else if (productVal.previousBidderId !== undefined) {
            if (joinedAuctionUserId === productVal.previousBidderId) {
              //
            } else {
              //add
              arr = [...arr, index];
            }
          } else if (productVal.auctionUserId === joinedAuctionUserId) {
            //
          } else {
            if (productVal.auctionUserId === joinedAuctionUserId) {
              //
            } else {
              //add
              arr = [...arr, index];
            }
          }
        }
      }
    });
    console.log("parent arr", arr);

    if (isSelected) {
      setIsSelected(false);
      PubSub.publish("resetAnnualGridBoxIndex", []);
      arr = [];
      dispatch(resetDiamondGridProductWatchlistIndexes());
      dispatch(resetDiamondWatchlistSelectAll());
      // PubSub.publish("jewellerySelectAllCheckBox", { isItemSelected: false, selectedIndexes: arr });
    } else {
      setIsSelected(true);
      dispatch(setDiamondGridProductWatchlistIndexes({ indexes: arr }));
      dispatch(setDiamondWatchlistSelectAll({ isSelected: true }));
      // PubSub.publish("jewellerySelectAllCheckBox", { isItemSelected: true, selectedIndexes: arr });
    }
  };

  return (
    <Grid>
      {apiTimeout === true ? (
        <ApiTimeout {...props} t={t} />
      ) : (
        <>
          {isLoading === true ? (
            <div className="loader">
              <LoadingSpinner />
            </div>
          ) : (
            <StyledLayoutGridMain>
              {isTopElementHide ? null : (
                <Accordion
                  onChange={handleChangeAcco("panel1")}
                  style={{
                    position: "fixed",
                    right: "0",
                    animation: "fadeMe 1s",
                  }}
                  className="AccordionHeader"
                >
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon
                        style={{
                          transform: expanded
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                        }}
                      />
                    }
                  ></AccordionSummary>
                  <div style={{ width: "100%", display: "block" }}>
                    <StyledCoreDataGridContainer container={true} spacing={0}>
                      <StyledActionBarContainer
                        container={true}
                        spacing={0}
                        className="coreActionBarContainer"
                      >
                        <Grid container={true} xs={12} lg={3} xl={2}>
                          <StyledBackImageContianer>
                            <StyledBackBtn
                              src="/images/controls/left_arrow_round_edges.png"
                              onClick={handleBackBtns}
                            />
                          </StyledBackImageContianer>
                          <Grid>
                            {/* <ItemsCountofProduct
                              t={t}
                              itemsPerPage={itemsPerPageUseRef.current}
                              totalElements={totalElement}
                              currentPage={selectPageUseRef.current}
                            /> */}
                          </Grid>
                        </Grid>
                        <StyledActionsContainer
                          item={true}
                          xs={12}
                          lg={9}
                          xl={10}
                        >
                          {notSmallDevices ? (
                            <>
                              {isGridView === true ? (
                                <StyledSelectAllButtonHeader
                                  onClick={selectAllInGridView}
                                >
                                  {t("common:newCommanTrans:selectAll")}
                                </StyledSelectAllButtonHeader>
                              ) : null}
                            </>
                          ) : (
                            <StyledSelectAllButtonHeader
                              onClick={selectAllInGridView}
                            >
                              {t("common:newCommanTrans:selectAll")}
                            </StyledSelectAllButtonHeader>
                          )}
                          {showBulkBidButton === true ? (
                            <StyledButtonHeader
                              onClick={() => {
                                setShowBulkBidPopup(true);
                              }}
                            >
                              {t("common:preview:tendor:bulkBid")}
                            </StyledButtonHeader>
                          ) : (
                            <></>
                          )}
                          <StyledButtonHeader
                            onClick={() => exportExcel(auctionId)}
                          >
                            {t("common:preview:pagination:export")}
                          </StyledButtonHeader>
                          <AnnualActionPageSizeSelector
                            setItemsPerPageHandler={setItemsPerPageHandler}
                            t={t}
                          />
                          {notSmallDevices ? (
                            <>
                              {isGridView === true ? (
                                <StyledButtonView onClick={Gridview}>
                                  <Styledview src="/images/view/listView.png" />
                                </StyledButtonView>
                              ) : (
                                <StyledButtonView onClick={listGridview}>
                                  <Styledview src="/images/view/gridview.jpeg" />
                                </StyledButtonView>
                              )}
                            </>
                          ) : null}
                        </StyledActionsContainer>
                      </StyledActionBarContainer>
                    </StyledCoreDataGridContainer>
                  </div>
                </Accordion>
              )}

              <StyledLayoutGrid
                container={true}
                style={{
                  paddingTop: notSmallDevices
                    ? isTableExpanded
                      ? "125px"
                      : "10px"
                    : "15px",
                }}
              >
                <>
                  <Grid item xs={12}>
                    <StyledName>
                      <StyledContent>
                        <EllipsisText
                          text={auctionName}
                          length={28}
                          tail={"..."}
                        />
                      </StyledContent>
                    </StyledName>
                  </Grid>
                  {auctionProducts.length > 0 ? (
                    <>
                      {isGridView === false && notSmallDevices && (
                        <MuiThemeProvider theme={getMuiTheme()}>
                          {isDataTableRefresh ? (
                            <MUIDataTable
                              title={""}
                              data={auctionProducts}
                              columns={columns}
                              options={options}
                            />
                          ) : (
                            <MUIDataTable
                              title={""}
                              data={auctionProducts}
                              columns={columns}
                              options={options}
                            />
                          )}
                        </MuiThemeProvider>
                      )}

                      {isGridView === true
                        ? notSmallDevices && (
                            <>
                              <ProductGridDataParent
                                t={t}
                                data={auctionProducts}
                                totalElements={totalElement}
                                itemPerPage={itemPerPage}
                                value={getUpdatedProducts}
                                noProductFound={handleMyBids}
                                itemsPerPageUseRef={selectItemPerPageValue}
                                selectPageUseRef={selectPageUseRef.current}
                              >
                                {auctionProducts.length > 0 ? (
                                  <ProductGridDataChildComponentWatchlist
                                    {...props}
                                    watchlist={addToWatchList}
                                    setShowBidPopupBidModal={getBoxIndex}
                                    auctionName={auctionName}
                                    auctionDate={date[0]}
                                    t={t}
                                    isAuctionPage={true}
                                    unWatchlist={removeFromWatchlist}
                                    auctionUserId={joinedAuctionUserId}
                                    sortingValue={filterSort}
                                    boxColor={boxColor}
                                    myForProductAllProduct={
                                      myLatestBidForProduct
                                    }
                                    bulkBidIndexHandler={onGridSelectionChange}
                                    renderAutomaticBidPopupInMobile={
                                      renderAutomaticBidPopupInMobile
                                    }
                                    propsUserId={joinedAuctionUserId}
                                    renderReadOnlyUserToast={
                                      renderReadOnlyUserToast
                                    }
                                    isCategoryPageType={true}
                                    isRefreshData={getExtendedTimeGrid}
                                    rightClickEvent={rightClickEvent}
                                  />
                                ) : (
                                  <Grid container={true} spacing={0}>
                                    <StyledTextWrapper item xs={12}>
                                      {t("common:watchList:watchlistEmpty")}
                                    </StyledTextWrapper>
                                  </Grid>
                                )}
                              </ProductGridDataParent>
                            </>
                          )
                        : null}

                      {notSmallDevices || (
                        <>
                          <ProductGridDataParent
                            t={t}
                            data={auctionProducts}
                            totalElements={totalElement}
                            itemPerPage={itemPerPage}
                            value={getUpdatedProducts}
                            noProductFound={handleMyBids}
                            itemsPerPageUseRef={selectItemPerPageValue}
                            selectPageUseRef={selectPageUseRef.current}
                          >
                            {auctionProducts.length > 0 ? (
                              <ProductGridDataChildComponentWatchlist
                                {...props}
                                watchlist={addToWatchList}
                                setShowBidPopupBidModal={getBoxIndex}
                                auctionName={auctionName}
                                auctionDate={date[0]}
                                t={t}
                                boxColor={boxColor}
                                isAuctionPage={true}
                                unWatchlist={removeFromWatchlist}
                                auctionUserId={joinedAuctionUserId}
                                propsUserId={joinedAuctionUserId}
                                sortingValue={filterSort}
                                bulkBidIndexHandler={onGridSelectionChange}
                                myForProductAllProduct={myLatestBidForProduct}
                                renderAutomaticBidPopupInMobile={
                                  renderAutomaticBidPopupInMobile
                                }
                                renderReadOnlyUserToast={
                                  renderReadOnlyUserToast
                                }
                                isCategoryPageType={true}
                                isRefreshData={getExtendedTimeGrid}
                                rightClickEvent={rightClickEvent}
                              />
                            ) : (
                              <Grid container={true} spacing={0}>
                                <StyledTextWrapper item xs={12}>
                                  {t("common:watchList:watchlistEmpty")}
                                </StyledTextWrapper>
                              </Grid>
                            )}
                          </ProductGridDataParent>
                        </>
                      )}
                    </>
                  ) : (
                    <Grid container={true} spacing={0}>
                      {notSmallDevices ? (
                        <MuiThemeProvider theme={getMuiTheme()}>
                          <MUIDataTable
                            title={""}
                            data={auctionProducts}
                            columns={columns}
                            options={options}
                          />
                        </MuiThemeProvider>
                      ) : (
                        <StyledTextWrapper item xs={12}>
                          {t("common:watchList:watchlistEmpty")}
                        </StyledTextWrapper>
                      )}
                    </Grid>
                  )}
                </>
                <Menu
                  keepMounted
                  open={mouseState.mouseY !== null}
                  onClose={handleClose}
                  anchorReference="anchorPosition"
                  anchorPosition={
                    mouseState.mouseY !== null && mouseState.mouseX !== null
                      ? { top: mouseState.mouseY, left: mouseState.mouseX }
                      : undefined
                  }
                >
                  <MenuItem
                    onClick={() =>
                      openDetailsView(auctionId, currentProductId, productdate)
                    }
                  >
                    Details
                  </MenuItem>
                </Menu>
                {onAction === true && (
                  <div className="loading">
                    <LoadingSpinner />
                  </div>
                )}
                {userBlock === true && (
                  <StyledModal open={userBlock}>
                    <BlockUserModal
                      t={t}
                      {...props}
                      closeModal={() => {
                        setUserBlock(false);
                      }}
                    />
                  </StyledModal>
                )}

                {showSinglebidPopup === true && (
                  <StyledModal
                    open={showSinglebidPopup}
                    onClose={closebidPopup}
                  >
                    <ProductBiddingUiWatchlist
                      t={t}
                      {...props}
                      items={auctionProducts}
                      currentIndex={details}
                      PopupClose={closebidPopup}
                      handleBtnValue={handleBtnValue}
                      auctionDate={date[0]}
                      totalElements={totalElement}
                      endCount={endCount}
                      auctionUserId={joinedAuctionUserId}
                      length={length}
                      watchedFunction={addToWatchList}
                      unwatched={removeFromWatchlist}
                      boxColor={boxColor}
                    />
                  </StyledModal>
                )}
                {showBulkBidPopup === true && (
                  <StyledModal open={showBulkBidPopup} onClose={closebidPopup}>
                    <AnnualBidPopup
                      {...props}
                      items={auctionProducts}
                      popupClose={closebidPopup}
                      handleBtnValue={bulkBidHandler}
                      auctionUserId={joinedAuctionUserId}
                      length={length}
                      productIndexs={indexsForBulkBidItems}
                      auctionDate={date[0]}
                      myLatestBidForProducts={myLatestBidForProduct}
                      isJewellery={false}
                    />
                  </StyledModal>
                )}
                {openReadOnlyUserToast === true && (
                  <AlertReadOnlyUser
                    isReadOnlyUser={isReadOnlyUser}
                    isSeller={isAuctionUserId}
                  />
                )}
              </StyledLayoutGrid>
            </StyledLayoutGridMain>
          )}
        </>
      )}
    </Grid>
  );
};

export default DiamondProductAuctionWatchlist;
