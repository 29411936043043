import React, { Component, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import styled, { css } from "styled-components";
import { annualTrackingApi, downloadandChangeShippingAddressInvoiceUrl, getInvoiceTracking, getMoreShipingAddress, shippingAnnualInvoice } from "../../../../common/config/app.endpoints";
import LoadingSpinner from "../../../../common/elements/LoadingSpinner";
import { StyledModal, StyledTitleText } from "../../../../common/materials/GenericStyleComponent";
import {
  backgroundGradient,
  buttonGradient,
} from "../../../../common/materials/LinearGradient";
import IRouterComponentProps from "../../../../models/IRouterComponentProps";
import { oAuth } from "../../../../routes/basic/BasicAppRouter";
import AuthService from "../../../auth/AuthService";
import { Card } from "@material-ui/core";
import ShippingAddressList from "../../views/ShippingAddressList";
import InvoiceAddressList from "./InvoiceAddressList";
import AddMoreForm from "./AddMoreForm";
import AddMoreFormAnnal from "./AddMoreFormAnnal";


interface selectedItem {
  isSelected: any;
}



const StyledComponent = styled(Grid)`
  outline: none;
  display: flex;
  border-radius: 10px;
`;

const StyledWrapper = styled(Grid)`
  background: ${(props) => props.theme.colors.base.white};
  padding: 10px;
  border-radius: 5px;
  &:focus {
    outline: none;
  }
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.base.scrollerColor};
    border-radius: 5px;
  }
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    max-height: 400px;
    width: 300px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    max-height: 500px;
    width: 400px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    max-height: 700px;
    width: 500px;
  }
`;

const StyledButtonContainer = styled(Grid)`
  text-align: center;
  margin: 20px 0;
`;

const StyledTitle = styled.h1`
  color: ${(props) => props.theme.colors.base.fieldSetBlueColor};
  text-align: center;
  margin-top: 10px;
`;

const StyledGridWrapper = styled(Grid)``;

const StyledButton = styled.button`
  color: ${(props) => props.theme.colors.base.white};
  border: none;
  font-size: 1em;
  padding: 0.6em;
  width: 80px;
  margin: 0.3em;
  border-radius: 5px;
  background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  background: ${buttonGradient};
  border: 1px solid ${(props) => props.theme.colors.base.white};
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.base.textColor};
    cursor: pointer;
  }
`;

const StyledMessage = styled.div`
  color: ${(props) => props.theme.colors.base.red};
  font-size: 1em;
  margin-top: 2em;
  margin-left: 1.2em;
`;

const StyledShippingWrapper = styled.div`
    max-height: 500px;
    overflow-y: scroll;
    width: 100%;
    padding: 10px 20px;
    ::-webkit-scrollbar {
        width: 3px;
    }
    ::-webkit-scrollbar-thumb {
        background: ${(props) => props.theme.colors.base.scrollerColor};
        border-radius: 5px;
    }
`;

const StyledAddMore = styled.div`
    text-align: right;
    cursor: pointer;
    margin-right: 10px;
    color: ${(props) => props.theme.colors.base.fieldSetBlueColor};
`;

const StyledGridAddMore = styled.div`
    display: flex;
    justify-content: flex-end;
`;
const StyledAddressList = styled(Grid)`
    // white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
`;


interface IProps extends IRouterComponentProps {
  t: any;
  shippingAddress: any;
  onClose: any;
  auctionId: string;
  sendMessage: (message: string) => void;
  apiTimeout: (timeout: boolean) => void;
  isAnnualType: boolean;
}

let formData: any = {};
const ShippingAllAddress = (props: IProps) =>{
  const {t} = props;
  const [selectedItem, setSelectedItem] =  useState<boolean>(false);
  const [message, setMessage] =  useState<string>("");
  const [isLoading, setIsLoading] =  useState<boolean>(false);
  const [isShippingAddressModalOpen, setIsShippingAddressModalOpen] =  useState<boolean>(false);
  const [addmoreShipingAddress, setAddmoreShipingAddress] = useState([]);
  const [selected, setSelected] = useState(false);
  const [isFormEditable, setIsFormEditable] = useState(false);
  const Auth = new AuthService();
  
  let userCurrencyCode = localStorage.getItem("preferredCurrency");
  const isUserCurrencyCode = () => {
    return userCurrencyCode && userCurrencyCode === "JPY";
  };

  useEffect(() => {
    getTrackingDetails();
    getShippingAddressData();    
    return () => {
      setSelected(false);
      if (props.shippingAddress.length === 0) {
      setMessage('')
    }
    }
  }, []);
  const getTrackingDetails = () => {
    Auth.getRequest(
      props.isAnnualType ? `${annualTrackingApi}?invoiceId=${props.auctionId}&invoiceType=${isUserCurrencyCode() ? "SIMPLE" : "INTERNATIONAL"} `: `${getInvoiceTracking}?auctionId=${props.auctionId}`,
      (status: number, response: any) => {
        if (status === 200) {
          if (response.shippingAddress !== undefined) {
            setIsFormEditable(response.finalChanges);
          }
        }
      }
    );
  }

  const getShippingAddressData = () => {
    Auth.getRequest(
      props.isAnnualType ? `${shippingAnnualInvoice}?invoiceId=${props.auctionId}&invoiceType=${isUserCurrencyCode() ? "SIMPLE" : "INTERNATIONAL"}` : `${getMoreShipingAddress}?auctionId=${props.auctionId}`,
      (status: number, response: any) => {
        if (status === 200) {
          if (response !== undefined) {
            if (response !== undefined) {
              if (response !== null) {
                response.forEach((item: any, i: number) => {
                  if (item.primary === true) {
                    response.splice(i, 1);
                    response.unshift(item);
                  }
                });
                setAddmoreShipingAddress(response);
              }
            }
          }
        }
      }
    );
  };

  const onAddShippingAddress = () => {   
    setIsShippingAddressModalOpen(true);   
  }

  const closeModal = () => {
   setIsShippingAddressModalOpen(false);
  }


    return (
      <StyledComponent tabIndex={-1}>
        <StyledWrapper container spacing={0}>
          <Grid item={true} xs={12} md={12}>
            <StyledTitle className="fontBold">
              <StyledTitleText>
                {t("common:profile:shippingAddress:title")}
              </StyledTitleText>
            </StyledTitle>
          </Grid>
          <Grid item={true} xs={12}>
            <StyledMessage>
              {message}
              <div>
                {selectedItem === true && (
                  <div>{`${t(
                    "common:invoice:validation:selectAddress:thirdLine"
                  )}`}</div>
                )}
              </div>
            </StyledMessage>
          </Grid>
          <StyledShippingWrapper>
            {addmoreShipingAddress !== null && (
              <div>
                {addmoreShipingAddress.length > 0 && (
                  <Grid container={true} spacing={1}>
                    {addmoreShipingAddress.map((items: any, index: number) => (
                      <StyledGridWrapper
                        key={index}
                        item={true}
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                      >
                        <Card>
                          <StyledAddressList
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                          >
                            <InvoiceAddressList
                              {...props}
                              auctionId={props.auctionId}
                              apiTimeout={props.apiTimeout}
                              {...items}
                              t={t}
                              didMount={getShippingAddressData}
                              isAddMoreEditable={isFormEditable}
                              isAnnualType={props.isAnnualType}
                              isJPY={isUserCurrencyCode()}
                            />
                          </StyledAddressList>
                        </Card>
                      </StyledGridWrapper>
                    ))}
                  </Grid>
                )}
              </div>
            )}
            <StyledGridAddMore>
              {isFormEditable !== true ? (
                <StyledAddMore onClick={() => onAddShippingAddress()}>
                  {t("common:profile:addMore")}
                </StyledAddMore>
              ) : (
                <StyledAddMore style={{ color: "gray" }}>
                  {t("common:profile:addMore")}
                </StyledAddMore>
              )}
            </StyledGridAddMore>
          </StyledShippingWrapper>
          <StyledButtonContainer item xs={12}>
            <StyledButton onClick={props.onClose}>
              {t("common:button:ok")}
            </StyledButton>
          </StyledButtonContainer>
        </StyledWrapper>
        {isShippingAddressModalOpen && (
          <StyledModal
            open={isShippingAddressModalOpen}
            onClose={() => closeModal()}
            className="registerModal"
          >
            {props.isAnnualType ? (
              <AddMoreFormAnnal
                {...props}
                closeModal={() => closeModal()}
                getUpdateData={getShippingAddressData}
                auctionId={props.auctionId}
                apiTimeout={props.apiTimeout}
                isJPY={isUserCurrencyCode()}
              />
            ) : (
              <AddMoreForm
                {...props}
                closeModal={() => closeModal()}
                getUpdateData={getShippingAddressData}
                auctionId={props.auctionId}
                apiTimeout={props.apiTimeout}
              />
            )}
          </StyledModal>
        )}
        {isLoading === true && (
          <div className="loading">
            <LoadingSpinner />
          </div>
        )}
      </StyledComponent>
    );
}

export default ShippingAllAddress;
