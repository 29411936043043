import React, { useState } from "react";
import styled from "styled-components";

import { Carousel } from "react-responsive-carousel";
import "../../../static/css/carousel.css";
import Grid from "@material-ui/core/Grid";
import ReactPlayer from "react-player";
import { GlassMagnifier } from "react-image-magnifiers";
import ViewVideoEnlargeModal from "../../../modules/ViewVideoEnlargeModal";
import Modal from "@material-ui/core/Modal";

const StyledArrowWrapper = styled.div`
  position: absolute;
  z-index: 2;
  top: 100px;
  width: 30px;
  ${(props) => props.theme.breakpoints.down("xl")} {
    top: 100px;
  }
  cursor: pointer;
`;

const StyledArrows = styled("img")``;

const StyledWrapperGrid = styled(Grid)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background: ${(props) => props.theme.colors.base.white};
`;

const StyledWrapperGrid1 = styled(Grid)`
  width: 98%;
   ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    .carousel .slide iframe {
      width: calc(100% - -130px) !important;
    }
  }
  height: auto;
`;

const StyledGrid = styled(Grid)`
  background: ${(props) => props.theme.colors.base.white};
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  ::-webkit-scrollbar {
    display: none;
  }
  height: 300px;
  ${(props) => props.theme.breakpoints.down("xs")} {
    height: 275px;
  }
  @media screen and (width: 768px) {
    height: 350px;
  }
`;

const StyledNotAvailableImage = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: ${(props) => props.theme.colors.base.white};
`;

const StyledCorouselWrapper = styled(Grid)`
  width: 100%;
`;


const StyledImageNotAvailable = styled("img")``;

const StyledFrame = styled.iframe`
  width: 100%;
  height: 360px;
  ${(props) => props.theme.breakpoints.down("xs")} {
    height: 170px;
  }
  ${(props) => props.theme.breakpoints.down("xl")} {
    height: 360px;
  }
`;

const StyledGridForImages = styled(Grid)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

const StyledLargeText = styled.span`
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  cursor: pointer;
  margin-left: 5px;
  font-weight: bold;
`;

const StyledLargeTxtImg = styled("img")`
  width: 25px !important;
  height: 25px !important;
`;

const StyledLargeTxtCon = styled(Grid)`
  display: flex;
  justify-content: center;
  padding: 5px;
  align-items: center;
`;

const StyledModal = styled(Modal)`
  display: flex;
  outline: none;
  align-items: center;
  justify-content: center;
`;

interface IProps {
  t: any;
  medias: any;
  parcelType: any;
}
var videoUrl: String = "";
var mediaTypeForFullView: String = "";

const JwlTenderSliderCarousel = (props: IProps) => {
  const { t, medias, parcelType } = props;
  const [onLargeVideo, setOnLargeVideo] = useState(false);
  const PlayerSlide = ({ url }: { url: string }) => (
    <ReactPlayer
      width="100%"
      url={url}
      playing={false}
      controls={true}
      muted={true}
      progressInterval={1000}
    />
  );

  const videoLargePopup = (url: any, mediaTypeFullview?: any) => {
    videoUrl = url;
    mediaTypeForFullView = mediaTypeFullview;
    setOnLargeVideo(true);
  };

  const onClose = () => {
    setOnLargeVideo(false);
  };

  return (
    <StyledGrid container={true} spacing={0}>
      <Grid item={true} xs={12}>
        {medias !== undefined ? (
          <div>
            {medias !== null && medias.length > 0 ? (
              <StyledCorouselWrapper>
                <Carousel
                  showStatus={false}
                  showThumbs={false}
                  showIndicators={false}
                  thumbWidth={40}
                  swipeable={false}
                  className=".carousel .slide .legend"
                  renderArrowPrev={(onClickHandler, hasPrev, label) => (
                    <StyledArrowWrapper
                      onClick={onClickHandler}
                      title={label}
                      style={{ left: 0 }}
                    >
                      {hasPrev ? (
                        <StyledArrows src="/images/controls/left_arrow_round_edges.png" />
                      ) : (
                        <StyledArrows src="/images/controls/left_arrow_round_edges_disabled.png" />
                      )}
                    </StyledArrowWrapper>
                  )}
                  renderArrowNext={(onClickHandler, hasNext, label) => (
                    <StyledArrowWrapper
                      onClick={onClickHandler}
                      title={label}
                      style={{ right: 5 }}
                    >
                      {hasNext ? (
                        <StyledArrows src="/images/controls/right_arrow_round_edges.png" />
                      ) : (
                        <StyledArrows src="/images/controls/right_arrow_round_edges_disabled.png" />
                      )}
                    </StyledArrowWrapper>
                  )}
                >
                  {medias.map((item: any, index: number) => (
                    <StyledWrapperGrid item={true} key={index}>
                      {item.mediaType === "PHOTO" ? (
                        <div>
                          {item.thumbnailPresignedUrl !== null ? (
                            <StyledGridForImages>
                              <StyledWrapperGrid1>
                                <GlassMagnifier
                                  imageSrc={`${item.thumbnailPresignedUrl}`}
                                  magnifierSize="50%"
                                  largeImageSrc={`${item.thumbnailPresignedUrl}`}
                                />
                              </StyledWrapperGrid1>
                            </StyledGridForImages>
                          ) : (
                            <StyledGrid container={true} spacing={0}>
                              <StyledNotAvailableImage>
                                {parcelType === "Diamond" ? (
                                  <StyledImageNotAvailable src="/images/default-diamond.png"></StyledImageNotAvailable>
                                ) : (
                                  <StyledImageNotAvailable src="/images/default-parcel.png"></StyledImageNotAvailable>
                                )}
                              </StyledNotAvailableImage>
                            </StyledGrid>
                          )}
                        </div>
                      ) : (
                        <div>
                          {item.presignedUrl === null ? (
                            <StyledGrid container={true} spacing={0}>
                              <StyledNotAvailableImage>
                                <StyledImageNotAvailable src="/images/video_not_available.png"></StyledImageNotAvailable>
                              </StyledNotAvailableImage>
                            </StyledGrid>
                          ) : (
                            <div>
                              {item.mediaType === "VIDEO" ? (
                                <Grid container={true} spacing={0}>
                                  <StyledLargeTxtCon item={true} xs={12}>
                                    <StyledLargeTxtImg src="/images/video-enlarge.png"></StyledLargeTxtImg>
                                    <StyledLargeText
                                      onClick={() =>
                                        videoLargePopup(
                                          `${item.presignedUrl}`,
                                          item.mediaType
                                        )
                                      }
                                    >
                                      {t(
                                        "common:preview:productDetail:viewFullScreen"
                                      )}
                                    </StyledLargeText>
                                  </StyledLargeTxtCon>
                                  <PlayerSlide url={`${item.presignedUrl}`} />
                                </Grid>
                              ) : (
                                <Grid container={true} spacing={0}>
                                  <StyledLargeTxtCon item={true} xs={12}>
                                    <StyledLargeTxtImg src="/images/video-enlarge.png"></StyledLargeTxtImg>
                                    <StyledLargeText
                                      onClick={() =>
                                        videoLargePopup(
                                          `${item.presignedUrl}`,
                                          item.mediaType
                                        )
                                      }
                                    >
                                      {t(
                                        "common:preview:productDetail:viewFullScreen"
                                      )}
                                    </StyledLargeText>
                                  </StyledLargeTxtCon>
                                  <div id="wrapfreamtender">
                                    {item.mediaType === "VIDEO" ? (
                                      <StyledFrame
                                        id="scaled-frametender"
                                        src={`${item.presignedUrl}`}
                                        style={{ margin: "0px" }}
                                      />
                                    ) : (
                                      <StyledFrame
                                        id="scaled-frametender"
                                        src={`${item.presignedUrl}`}
                                        style={{ margin: "0px" }}
                                      />
                                    )}
                                  </div>
                                </Grid>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </StyledWrapperGrid>
                  ))}
                </Carousel>
              </StyledCorouselWrapper>
            ) : (
              <StyledGrid container={true} spacing={0}>
                <StyledNotAvailableImage>
                  <StyledImageNotAvailable src="/images/default-diamond.png"></StyledImageNotAvailable>
                </StyledNotAvailableImage>
              </StyledGrid>
            )}
          </div>
        ) : (
          <StyledGrid container={true} spacing={0}>
            <StyledNotAvailableImage>
              <StyledImageNotAvailable src="/images/default-diamond.png"></StyledImageNotAvailable>
            </StyledNotAvailableImage>
          </StyledGrid>
        )}
      </Grid>
      {onLargeVideo === true && (
        <StyledModal
          open={onLargeVideo}
          onClose={onClose}
          className="loginModal"
        >
          <ViewVideoEnlargeModal
            videoUrl={videoUrl}
            mediaTypeForFullView={mediaTypeForFullView}
          />
        </StyledModal>
      )}
    </StyledGrid>
  );
};
export default JwlTenderSliderCarousel;
