import * as React from "react";
import styled from "styled-components";

const StyledSelectorContainer = styled.div`
  border: none;
  margin-right: 10px;
  select {
    padding: 0.6em 0.4em;
    margin: 0.3em;
    border: none;
    border-radius: 3px;
    font-size: 13px;
    font-weight: bold;
    border: 1px solid ${(props) => props.theme.colors.base.lightGrey};
    background: ${(props) => props.theme.colors.base.veryVeryLightGrey};
    color: ${(props) => props.theme.colors.base.grey};
    width: 100%;

    ${(props) => props.theme.breakpoints.between("xs", "sm")} {
      padding: 0.5em 0.3em;
      margin: 0.2em;
    }
  }
`;

interface Iprops {
  setValue: (name: string) => void;
  isSellPage:boolean;
}
let sellPageCategory = ["All", "Sold", "Hold", "Unsold"];
let buyPageCategory = ["WatchList&Prebid", "Sold", "Hold", "Unsold"];
export default function CategoryDropDown(props: Iprops) {
  let { setValue, isSellPage } = props;
  const [personName, setPersonName] = React.useState<string>("");
  const handleChange = (e:any) => {
    setPersonName(e.target.value);    
    setValue(e.target.value);
  };

  return (
    <StyledSelectorContainer>
      {isSellPage ? (
        <select onChange={handleChange}>
          {sellPageCategory.map((item: any, index: any) => (
            <option
              key={index}
              style={{ fontSize: "14px" }}
            >{`${item}`}</option>
          ))}
        </select>
      ) : (
        <select onChange={handleChange}>
          {buyPageCategory.map((item: any, index: any) => (
            <option
              key={index}
              style={{ fontSize: "14px" }}
            >{`${item}`}</option>
          ))}
        </select>
      )}
    </StyledSelectorContainer>
  );
}
