import React from "react";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import {
  markAsDefaultShippingAddresssUrl,
  deleteShippingAddress,
  setShipingAddressAsDefault,
  deleteShippingDetails,
  setshippingAnnualAddressAsDefault,
  deleteAnnualShippingAddress,
} from "../../../../common/config/app.endpoints";
import { backgroundGradient } from "../../../../common/materials/LinearGradient";
import IRouterComponentProps from "../../../../models/IRouterComponentProps";
import { oAuth } from "../../../../routes/basic/BasicAppRouter";
import AuthService from "../../../auth/AuthService";
import OptionMenuofShippingAddress from "../../../shared/OptionMenuofShippingAddress";
import EllipsisText from "../../../../common/elements/EllipsisText";

const StyledShippingAddress = styled.div``;

const StyledOption = styled.div`
  width: 100%;
  text-align: right;
`;

const StyledGrid = styled(Grid)`
  border: none;
  .padding {
    color: ${(props) => props.theme.colors.base.black};
    padding: 10px 10px;
  }
  .markasdefault {
    padding: 20px 10px;
    color: ${(props) => props.theme.colors.base.white};
    background: ${backgroundGradient};
  }
`;

interface IProps extends IRouterComponentProps {
  t?: any;
  id: string;
  blockNo: string;
  streetName: string;
  address: string;
  city: string;
  state: string;
  primary: boolean | null;
  country: string;
  didMount: () => void;
  message?: (message: string) => void;
  apiTimeout?: (timeout: boolean) => void;
  colorChange: any;
  auctionId: string;
  cityDetail: any;
  countryDetail: any;
  stateDetail: any;
  isAddMoreEditable: any;
  companyName: string;
  isAnnualType: boolean;
  isJPY: boolean;
  phone: string;
  pincode: string;
}

const InvoiceAddressList = (props: IProps) => {
  const Auth = new AuthService();
  const {
    primary,
    blockNo,
    streetName,
    city,
    state,
    country,
    id,
    t,
    auctionId,
    cityDetail,
    countryDetail,
    stateDetail,
    isAddMoreEditable,
    companyName,
    isAnnualType,
    isJPY,
    address,
    phone,
    pincode,
  } = props;

  const selectOptionforMarkDefault = () => {
    const data = {
      id: id,
      blockNo: blockNo,
      streetName: streetName,
      city: city,
      primary: primary,
      state: state,
      country: country,
      companyName: companyName,
      address: address,
      phone: phone,
      pincode: pincode,
    };
    if (!oAuth()) {
      props.history.push("/");
    } else {
      Auth.postRequest(
        isAnnualType
          ? `${setshippingAnnualAddressAsDefault}?invoiceId=${auctionId}&defaultAddress=${id}&invoiceType=${
              isJPY ? "SIMPLE" : "INTERNATIONAL"
            }`
          : `${setShipingAddressAsDefault}?auctionId=${auctionId}&defaultAddress=${id}`,
        data,
        (status: number, response: any) => {
          if (status === 200) {
            props.didMount();
          }
        }
      );
    }
  };

  const selectOptionforDelete = () => {
    const data = {
      id: id,
      blockNo: blockNo,
      streetName: streetName,
      city: city,
      state: state,
      country: country,
      companyName: companyName,
      address: address,
      phone: phone,
      pincode: pincode,
    };
    if (!oAuth()) {
      props.history.push("/");
    } else {
      Auth.postRequest(
        isAnnualType
          ? `${deleteAnnualShippingAddress}?invoiceId=${auctionId}&defaultAddress=${id}invoiceType=${
              isJPY ? "SIMPLE" : "INTERNATIONAL"
            }`
          : `${deleteShippingDetails}?auctionId=${auctionId}&defaultAddress=${id}`,
        data,
        (status: number, response: any) => {
          if (status === 200) {
            props.didMount();
          }
        }
      );
    }
  };
  const getBlockStreetName = (address: any) => {
    return `${address === undefined ? "" : address}`;
  };

  return (
    <Grid container={true} spacing={0}>
      {primary === true ? (
        <StyledGrid item xs={12}>
          <div className="markasdefault">
            <StyledShippingAddress>
              <EllipsisText text={companyName} length={70} tail={"..."} />
            </StyledShippingAddress>
            <StyledShippingAddress>
              <EllipsisText
                text={getBlockStreetName(address)}
                length={70}
                tail={"..."}
              />
            </StyledShippingAddress>
            <StyledShippingAddress>
              {" "}
              {cityDetail !== undefined
                ? cityDetail.name
                : city !== undefined
                ? city
                : ""}
            </StyledShippingAddress>
            <StyledShippingAddress>
              {stateDetail !== undefined
                ? stateDetail.name
                : state !== undefined
                ? state
                : ""}
              ,
              {countryDetail !== undefined
                ? countryDetail.name
                : country !== undefined
                ? country
                : ""}
              , {pincode}
            </StyledShippingAddress>
            <span>{phone}</span>
          </div>
        </StyledGrid>
      ) : (
        <StyledGrid item xs={12}>
          <div className="padding">
            <StyledOption>
              {isAddMoreEditable !== true ? (
                <OptionMenuofShippingAddress
                  t={t}
                  selectMarkasDefault={selectOptionforMarkDefault}
                  deleteAddress={selectOptionforDelete}
                />
              ) : (
                <></>
              )}
            </StyledOption>
            <StyledShippingAddress>
              <EllipsisText text={companyName} length={70} tail={"..."} />
            </StyledShippingAddress>
            <StyledShippingAddress>
              <EllipsisText
                text={getBlockStreetName(address)}
                length={70}
                tail={"..."}
              />
            </StyledShippingAddress>
            <StyledShippingAddress>
              {cityDetail !== undefined
                ? cityDetail.name
                : city !== undefined
                ? city
                : ""}
            </StyledShippingAddress>
            <StyledShippingAddress>
              {stateDetail !== undefined
                ? stateDetail.name
                : state !== undefined
                ? state
                : ""}
              ,
              {countryDetail !== undefined
                ? countryDetail.name
                : country !== undefined
                ? country
                : ""}
              , {pincode}
            </StyledShippingAddress>
            <span>{phone}</span>
          </div>
        </StyledGrid>
      )}
    </Grid>
  );
};

export default InvoiceAddressList;
